import React from 'react';
import { Form, Modal, Message, Button, Header } from 'semantic';
import { request } from 'utils/api';
import SearchDropdown from 'admin/components/SearchDropdown';

import { modal } from 'common/helpers';

@modal
export default class InviteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      email: '',
      creatorAccountId: this.props.creatorAccountId,
      role: 'admin',
    };
  }

  fetchCreatorAccounts = async (body) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body,
    });
    return data;
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
        touched: true,
      });

      // TODO fix for content creator when we support that
      const invite = {
        email: this.state.email,
        roles: [
          { role: 'creatorAccountAdmin', scope: 'creatorAccount', scopeRef: this.state.creatorAccountId },
          {
            role: 'manager',
            scope: 'creatorAccount',
            scopeRef: this.state.creatorAccountId,
          },
        ],
      };

      await request({
        method: 'POST',
        path: '/1/invites',
        body: invite,
      });

      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { loading, error } = this.state;

    return (
      <>
        <Modal.Header>Invite User</Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)}>
            {error && <Message error content={error.message} />}
            {!this.props.creatorAccountId && (
              <Form.Field required>
                <label>Creator Account</label>
                <SearchDropdown
                  fluid
                  value={this.state.creatorAccountId}
                  objectMode={false}
                  onChange={(e, { value }) => {
                    this.setState({
                      creatorAccountId: value,
                    });
                  }}
                  onDataNeeded={this.fetchCreatorAccounts}
                />
              </Form.Field>
            )}

            <Form.Input
              value={this.state.email || ''}
              required
              type="email"
              label="Email"
              onChange={(e, { value }) => this.setState({ email: value })}
            />

            <Header required>Roles</Header>
            <Form.Radio
              label="Owner"
              checked={this.state.role === 'admin'}
              onChange={(e) => {
                this.setState({ role: 'admin' });
              }}
            />
            <Form.Radio
              disabled
              checked={this.state.role === 'creator'}
              label="Content Creator"
              onChange={(e) => {
                this.setState({ role: 'admin' });
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary loading={loading} disabled={loading} content={'Send'} onClick={this.onSubmit} />
        </Modal.Actions>
      </>
    );
  }
}
