import React from 'react';
import { Form, Modal, Message, Button, Loader, Segment, Popup, Icon, Header } from 'semantic';
import { request } from 'utils/api';

import SearchDropdown from 'admin/components/SearchDropdown';
import AssetsField from 'admin/components/form-fields/Assets';
import { modal } from 'common/helpers';
import { withRouter } from 'react-router-dom';
import { withSession } from 'stores';

@modal
@withRouter
@withSession
export default class EditMyEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !!this.props.event?.id,
      error: null,
      hasError: false,
      enableChat: true,
      event: {
        status: 'draft',
        access: 'public',
        userOnly: false,
        isBundle: false,
        subType: 'session',
        type: 'vod',
      },
    };
  }

  componentDidMount() {
    if (this.props.event?.id) {
      this.fetchEvent();
    }
  }

  isUpdate() {
    return !!this.props.event;
  }

  setEventField(name, value) {
    this.setState({
      event: {
        ...this.state.event,
        [name]: value,
      },
    });
  }

  mapEventRefFields(event, fallback) {
    return {
      ...event,
      creatorAccount: event.creatorAccount?.id,
      cause: event.cause?.id || fallback,
      coverImage: event.coverImage?.id || event.coverImage || fallback,
      appClipCardImage: event.appClipCardImage?.id || event.appClipCardImage || fallback,
      products: (event.products || []).map((p) => p.id),
      streams: this.cleanStreamProducts(event),
    };
  }

  cleanStreamProducts(event) {
    const { products, streams = [] } = event;
    return streams.map((stream) => {
      return {
        ...stream,
        video: stream.video.id,
        chapters: stream.chapters.map((chapter) => {
          const { image, ...rest } = chapter;
          return {
            ...rest,
            ...(image && {
              image: image.id || image,
            }),
          };
        }),
        products: stream.products.filter((streamProduct) => {
          return products.some((product) => {
            return product.id === streamProduct.productId;
          });
        }),
      };
    });
  }

  onSubmit = async () => {
    try {
      const { event } = this.state;
      this.setState({
        loading: true,
      });
      let updatedEvent;
      if (this.isUpdate()) {
        const { data } = await request({
          method: 'PATCH',
          path: `/1/events/${event.id}`,
          body: {
            ...this.mapEventRefFields(event, null),
          },
        });
        updatedEvent = data;
      } else {
        const { data } = await request({
          method: 'POST',
          path: '/1/events',
          body: {
            ...this.mapEventRefFields(event),
          },
        });
        updatedEvent = data;

        if (this.state.enableChat) {
          await request({
            method: 'POST',
            path: `/1/events/${updatedEvent.id}/chat/enable`,
          });
        }
      }

      if (updatedEvent.type === 'live' && !updatedEvent.liveVideo) {
        // TODO: not sure why the model isn't taking care of this automatically
        const { data: liveVideo } = await request({
          method: 'POST',
          path: '/1/videos/live',
        });
        updatedEvent.streams.push({
          type: 'live',
          video: liveVideo,
        });

        const { data } = await request({
          method: 'PATCH',
          path: `/1/events/${updatedEvent.id}`,
          body: {
            streams: updatedEvent.streams.map((stream) => {
              return {
                ...stream,
                video: stream.video?.id,
                chapters: (stream.chapters || []).map((chapter) => {
                  return {
                    ...chapter,
                    image: chapter.image?.id || chapter.image,
                  };
                }),
              };
            }),
          },
        });
        updatedEvent = data;
      }

      this.triggerNofications(updatedEvent);

      if (this.isUpdate()) {
        this.props.close();
        this.props.onSave(updatedEvent);
      } else {
        this.props.history.push(`/events/${updatedEvent.id}`);
      }
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  fetchUsers = (props) => {
    const { event } = this.state;
    return request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...props,
        creatorAccount: event.creatorAccount?.id,
      },
    });
  };

  fetchProducts = async (body) => {
    const { event } = this.state;
    const { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        ...body,
        creatorAccount: event.creatorAccount?.id,
        types: ['base', 'variant', 'individual'],
      },
    });
    return data;
  };

  fetchCreatorAccounts = async (body) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body,
    });
    return data;
  };

  fetchEvent = async () => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/events/${this.props.event.id}`,
      });

      this.setState({
        loading: false,
        event: { ...data },
        originalEvent: { ...data },
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  getEventStatus() {
    const { event } = this.state;
    if (event.status == 'draft' && event.access === 'public') {
      return 'draft';
    } else if (event.status === 'unlisted' && event.access === 'public') {
      return 'private';
    } else if (event.status === 'active' && event.access === 'public' && event.publishScope === 'brand') {
      return 'public';
    } else if (event.status === 'active' && event.access === 'public') {
      return 'followers-only';
    }
  }

  setEventStatus = (newStatus) => {
    const { event } = this.state;
    let status = event.status;
    let access = event.access;
    let publishScope = event.publishScope;
    if (newStatus === 'draft') {
      status = 'draft';
      access = 'public';
      publishScope = null;
    } else if (newStatus === 'private') {
      status = 'unlisted';
      access = 'public';
      publishScope = null;
    } else if (newStatus === 'public') {
      status = 'active';
      access = 'public';
      publishScope = 'brand';
    } else if (newStatus === 'followers-only') {
      status = 'active';
      access = 'public';
      publishScope = null;
    }

    this.setState({
      event: {
        ...event,
        status,
        access,
        publishScope,
      },
    });
  };

  triggerNofications = (updatedEvent) => {
    const { event, originalEvent } = this.state;

    if (event.type !== 'vod' || event.subType !== 'session' || event.status !== 'active' || event.access !== 'public') {
      return;
    }

    if (originalEvent && originalEvent.status === 'active' && originalEvent.access === 'public') {
      return;
    }

    this.props.triggerNotification({
      header: 'Send nofications to clients',
      content: <p>Your clients will immediately be notified about the session {event.name}</p>,
      confirmButton: 'Notify Now',
      cancelButton: 'Don’t Notify',
      onConfirm: () =>
        request({
          method: 'POST',
          path: `/1/notifications/event/${updatedEvent.id}/published`,
        }),
    });
  };

  render() {
    const { event, loading, error, hasError } = this.state;
    const creatorAccountId = this.context.creatorAccount?.id || event?.creatorAccount?.id;
    const isUpdate = this.isUpdate();
    const isLoadingEvent = isUpdate && this.state.loading && !this.state.event?.id;

    const isSuperAdminOrManager = this.context.user.roles?.some((c) => ['superAdmin', 'manager'].includes(c.role));

    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit "${this.props.event?.name}"` : 'New Event'}</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          {isLoadingEvent && (
            <Segment basic loading style={{ height: '300px' }}>
              <Loader active />
            </Segment>
          )}
          {!isLoadingEvent && (
            <Form error={!!error || hasError}>
              <Form.Input
                required
                name="name"
                label="Name"
                type="text"
                value={event.name || ''}
                onChange={(e, { value }) => this.setEventField('name', value)}
              />

              <Form.TextArea
                name="description"
                label="Description"
                type="text"
                value={event.description || ''}
                onChange={(e, { value }) => this.setEventField('description', value)}
              />

              <Form.Field>
                <label>Products</label>

                <SearchDropdown
                  disabled={!creatorAccountId}
                  key={`${creatorAccountId}-${event.isBundle}`}
                  fluid
                  multiple
                  getOptionLabel={(item) => {
                    return [`${item.name} [${item.type}]`, item.sku].filter(Boolean).join(' - ');
                  }}
                  placeholder="Search by name or sku"
                  onDataNeeded={this.fetchProducts}
                  onChange={(e, { value }) => {
                    this.setEventField('products', event.isBundle ? [value] : value);
                  }}
                  value={event?.products || []}
                />
              </Form.Field>
              <Form.Group>
                <Form.Dropdown
                  fluid={false}
                  selection
                  name="status"
                  label="Status"
                  value={this.getEventStatus()}
                  options={[
                    {
                      text: 'Draft',
                      value: 'draft',
                    },
                    {
                      text: 'Private',
                      value: 'private',
                    },
                    {
                      text: 'Followers Only',
                      value: 'followers-only',
                    },
                    {
                      text: 'Public',
                      value: 'public',
                      disabled: !isSuperAdminOrManager,
                    },
                  ]}
                  onChange={(e, { value }) => this.setEventStatus(value)}
                />
              </Form.Group>

              <div style={{ display: 'flex' }}>
                <Form.Checkbox
                  label=" "
                  checked={event.userOnly}
                  onChange={(e, { checked }) => {
                    this.setState({
                      event: {
                        ...this.state.event,
                        userOnly: checked,
                      },
                    });
                  }}
                />
                <Popup
                  basic
                  content="This setting affects admin, not customers"
                  trigger={
                    <label>
                      Only visible to owner
                      <Icon style={{ marginLeft: '0.2em', verticalAlign: 'middle' }} name="info-circle" />
                    </label>
                  }
                />
              </div>

              <Header as="h4">Images</Header>

              <AssetsField
                label="Cover Image"
                required
                name="coverImage"
                value={event.coverImage || null}
                onChange={(upload) => this.setEventField('coverImage', upload)}
                onError={() => this.setState({ hasError: true })}
                creatorAccount={event.creatorAccount?.id}
              />

              <AssetsField
                label="AppClipCard Image"
                name="appClipCardImage"
                value={event.appClipCardImage || null}
                onChange={(upload) => this.setEventField('appClipCardImage', upload)}
                onError={() => this.setState({ hasError: true })}
                creatorAccount={event.creatorAccount?.id}
              />
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
