import React from 'react';
import PropTypes from 'prop-types';
import { withSession } from 'stores';

@withSession
export default class Protected extends React.Component {
  render() {
    const { children, endpoint, permission, scope, scopeRef } = this.props;
    const { hasAccess } = this.context;
    if (!hasAccess({ endpoint, permission, scope, scopeRef })) {
      return null;
    }
    return children;
  }
}

Protected.propTypes = {
  endpoint: PropTypes.string.isRequired,
  permission: PropTypes.string,
  scope: PropTypes.string,
  scopeRef: PropTypes.string,
};

Protected.defaultProps = {
  permission: 'read',
  scope: 'global',
};
