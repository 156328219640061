import React from 'react';
import { Segment, Divider, Statistic } from 'semantic';

import { AggregateStats, AggregateCardinality, AggregateTerms, Table, DonutChart } from 'react-tectonic';

import { numberWithCommas, formatUsd } from 'utils/formatting';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';
import { Link } from 'react-router-dom';

export default class InsightsTracking extends React.Component {
  render() {
    return (
      <>
        <Segment basic>
          <Divider hidden />

          <Statistic.Group widths={3}>
            <AggregateCardinality
              fields={['eventId']}
              filter={{
                terms: [...this.props.terms, { type: { value: 'event-entered' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>{status.success ? numberWithCommas(data['eventId']) : '...'}</Statistic.Value>
                  <Statistic.Label>Events</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>

            <AggregateStats
              fields={['occurredAt']}
              filter={{
                terms: [...this.props.terms, { type: { value: 'event-entered' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(data['occurredAt'].count) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Event Entered</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>

            <AggregateCardinality
              fields={['clientSessionId']}
              filter={{
                terms: [...this.props.terms, { type: { value: 'event-entered' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(data['clientSessionId']) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Unique Event Entered</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>
            {/*
            <AggregateStats
              fields={['secondsSinceEntry']}
              filter={{
                terms: [...this.props.terms, { type: { value: 'event-exited' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(Math.floor(data['secondsSinceEntry'].avg)) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Avg Seconds</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>
            */}
          </Statistic.Group>

          <Divider hidden />
        </Segment>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: [...this.props.terms, { type: { value: 'product-purchased' } }],
              }}
              aggField="event.name"
              operation="sum"
              field="product.price"
              termsSize={8}>
              <DonutChart title="Top Events by Sales" titleAlign="center" limit={5} percent />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="event.name"
              operation="sum"
              field="product.price"
              filter={{
                terms: [...this.props.terms, { type: { value: 'product-purchased' } }],
              }}
              termsSize={8}>
              <Table
                title="Events by Sales"
                titleAlign="center"
                valueFieldName="Sales"
                valueField="value"
                valueFormatter={formatUsd}
                rowFormatter={(item, label, value) => {
                  const { event, eventId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${eventId}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="event.name"
              operation="count"
              filter={{
                terms: [...this.props.terms, { type: { value: 'product-added-to-cart' } }],
              }}
              termsSize={8}>
              <Table
                title="Events by Product Added"
                titleAlign="center"
                valueFieldName="Count"
                valueField="count"
                valueFormatter={(value) => numberWithCommas(Math.round(value))}
                rowFormatter={(item, label, value) => {
                  const { event, eventId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${eventId}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="event.name"
              operation="count"
              filter={{
                terms: [...this.props.terms, { type: { value: 'event-entered' } }],
              }}
              termsSize={8}>
              <Table
                titleAlign="center"
                title="Events by Views"
                valueFieldName="Views"
                valueField="count"
                valueFormatter={(value) => numberWithCommas(Math.round(value))}
                rowFormatter={(item, label, value) => {
                  const { event, eventId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${eventId}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="event.name"
              operation="count"
              filter={{
                terms: [...this.props.terms, { type: { value: 'product-purchased' } }],
              }}
              termsSize={8}>
              <Table
                title="Events by Purchases"
                titleAlign="center"
                valueFieldName="Purchases"
                valueField="count"
                valueFormatter={(value) => numberWithCommas(Math.round(value))}
                rowFormatter={(item, label, value) => {
                  const { event, eventId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${eventId}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="event.name"
              operation="count"
              filter={{
                terms: [...this.props.terms, { type: { value: 'buyflow-entered' } }],
              }}
              termsSize={8}>
              <Table
                title="Events by Purchase Intent"
                titleAlign="center"
                valueFieldName="# Checkouts started"
                valueField="count"
                valueFormatter={(value) => numberWithCommas(Math.round(value))}
                rowFormatter={(item, label, value) => {
                  const { event, eventId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${eventId}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
      </>
    );
  }
}
