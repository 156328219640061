import React from 'react';
import { mean, round } from 'lodash';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import * as UpChunk from '@mux/upchunk';
import { Progress } from 'semantic';
import { request } from 'utils/api';
import { UPLOADS_STORE } from 'utils/env';
import { Component } from 'common/helpers';

import './uploads.less';

const MIME_TYPES = {
  image: 'image/*',
  video: 'video/*',
  audio: 'audio/*',
  text: 'text/*',
  pdf: 'application/pdf',
  csv: 'text/csv,application/vnd.ms-excel',
  zip: 'application/zip,application/octet-stream',
  model: 'model/usd,model/vnd.pixar.usd,model/vnd.usdz+zip',
};

const RESTRICTED_MIME_TYPES = {
  image: 'image/jpeg,image/png',
};

// Max upload size of 100MB
const CHUNKED_FILESIZE_THRESHOLD = 100 * Math.pow(1024, 2);

// 1GB for videos or models for now
const MAX_SIZE_LARGE = Math.pow(1024, 3);

// 10MB for anything else
const MAX_SIZE_DEFAULT = 10 * Math.pow(1024, 2);

export default class Uploads extends Component {
  static TYPES = Object.keys(MIME_TYPES);

  constructor(props) {
    super(props);
    this.state = {
      progress: {},
      loading: false,
      error: null,
    };
  }

  // Component

  getModifiers(isDragActive) {
    const { size } = this.props;
    const { error } = this.state;
    return [error ? 'error' : null, isDragActive ? 'dragging' : null, size];
  }

  // Events

  onDrop = async (acceptedFiles, rejectedFiles) => {
    const { single, types, skipUpload } = this.props;
    try {
      if (rejectedFiles.length) {
        const err = rejectedFiles[0].errors?.[0] || new Error(`File must be of ${types.join(', ')} type.`);
        throw err;
      }
      if (skipUpload) {
        this.props.onDrop(single ? acceptedFiles[0] : acceptedFiles);
      } else {
        this.setState({
          loading: true,
          error: null,
        });
        const uploads = await this.uploadFiles(acceptedFiles);

        // Small timeout to allow progress to reach 100%
        await new Promise((resolve) => {
          setTimeout(resolve, 200);
        });

        this.setState({
          loading: false,
        });

        this.props.onUpload(single ? uploads[0] : uploads);
      }
    } catch (error) {
      console.error(error);
      this.setState({
        error,
        loading: false,
      });
      this.props.onError(error);
    }
  };

  // Upload helpers

  uploadFiles = async (files) => {
    this.setState({
      progress: {},
    });
    const normalFiles = files.filter((file) => {
      return !this.needsChunkedUpload(file);
    });
    const chunkedFiles = files.filter((file) => {
      return this.needsChunkedUpload(file);
    });
    return [...(await this.uploadFilesDefault(normalFiles)), ...(await this.uploadFilesChunked(chunkedFiles))];
  };

  needsChunkedUpload = (file) => {
    return UPLOADS_STORE === 'gcs' && file.size > CHUNKED_FILESIZE_THRESHOLD;
  };

  uploadFilesDefault = async (files) => {
    if (files.length) {
      this.updateProgress(files, 0);
      const { data } = await request({
        method: 'POST',
        path: '/1/uploads',
        files,
      });
      this.updateProgress(files, 1);
      return Array.isArray(data) ? data : [data];
    } else {
      return [];
    }
  };

  uploadFilesChunked = (files) => {
    return Promise.all(files.map(this.uploadFileChunked));
  };

  uploadFileChunked = async (file) => {
    this.updateProgress([file], 0);
    const { data } = await request({
      method: 'POST',
      path: '/1/uploads/upload-uri',
      body: {
        mimeType: file.type,
        fileName: file.name,
      },
    });

    return new Promise((resolve, reject) => {
      const upload = UpChunk.createUpload({
        file,
        endpoint: data.uploadUrl,
        chunkSize: 20480,
      });

      upload.on('error', (err) => {
        this.updateProgress([file], 0);
        reject(err);
      });

      upload.on('progress', (progress) => {
        this.updateProgress([file], progress.detail / 100);
      });

      upload.on('success', async () => {
        const { data: finalizedUpload } = await request({
          method: 'POST',
          path: `/1/uploads/${data.id}/finish`,
        });
        resolve(finalizedUpload);
      });
    });
  };

  // Other helpers

  getProgress() {
    const { progress } = this.state;
    const amt = mean(Object.values(progress)) || 0;
    return Math.round(amt * 100);
  }

  getMaxSize() {
    const { types } = this.props;
    if (types.includes('video') || types.includes('model')) {
      // 1GB for videos or models for now
      return MAX_SIZE_LARGE;
    } else {
      // 1MB for anything else
      return MAX_SIZE_DEFAULT;
    }
  }

  getAcceptedTypes() {
    const { types, restricted } = this.props;
    return types
      .map((type) => {
        let mime;
        if (restricted) {
          mime = RESTRICTED_MIME_TYPES[type];
        }
        if (!mime) {
          mime = MIME_TYPES[type];
        }
        return mime;
      })
      .join(',');
  }

  updateProgress(files, amt) {
    const progress = {};
    for (let file of files) {
      // Fix until this lands:
      // https://github.com/muxinc/upchunk/pull/43
      const current = this.state.progress[file.name] || 0;
      progress[file.name] = Math.max(amt, current);
    }
    this.setState({
      progress: {
        ...this.state.progress,
        ...progress,
      },
    });
  }

  render() {
    return (
      <Dropzone
        accept={this.getAcceptedTypes()}
        maxSize={this.getMaxSize()}
        onDrop={this.onDrop}
        multiple={!this.props.single}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div className={this.getComponentClass(isDragActive)} {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={this.getElementClass('content')}>{this.renderStage(isDragActive)}</div>
            </div>
          );
        }}
      </Dropzone>
    );
  }

  renderStage(isDragActive) {
    const { error, loading } = this.state;
    if (loading) {
      return this.renderUploading();
    } else if (error) {
      return error.message;
    } else {
      return this.renderContent(isDragActive);
    }
  }

  renderContent(isDragActive) {
    const { children } = this.props;
    const dragMessage = this.renderDragMessage(isDragActive);
    const acceptedMessage = this.renderAcceptedMessage();
    if (typeof children === 'function') {
      return children({ dragMessage, acceptedMessage });
    } else {
      return (
        <React.Fragment>
          {dragMessage}
          {acceptedMessage}
          {children}
        </React.Fragment>
      );
    }
  }

  renderDragMessage(isDragActive) {
    const { single } = this.props;
    let message;
    if (isDragActive) {
      message = 'Drop files here...';
    } else if (single) {
      message = 'Drag a file here, or click to select.';
    } else {
      message = 'Drag files here, or click to select.';
    }
    return <div className={this.getElementClass('drag-message')}>{message}</div>;
  }

  renderAcceptedMessage() {
    const { types } = this.props;
    const maxSize = this.getMaxSize();
    let typeStr;
    if (types.length === 1) {
      typeStr = `${types[0]} files`;
    } else if (types.length === 2) {
      typeStr = `${types.join(' or ')} files`;
    } else {
      typeStr = 'files';
    }
    let sizeStr;
    if (maxSize > 1e9) {
      sizeStr = `${round(maxSize / 1e9)}gb`;
    } else {
      sizeStr = `${round(maxSize / 1e6, maxSize < 1e6 ? 1 : 0)}mb`;
    }
    return (
      <span className={this.getElementClass('accepted-message')}>
        Accepts {typeStr} up to {sizeStr}.
      </span>
    );
  }

  renderUploading() {
    const progress = this.getProgress();
    return (
      <React.Fragment>
        <div className={this.getElementClass('progress-text')}>{progress}% upload complete</div>
        <Progress size="tiny" color="blue" percent={progress} className={this.getElementClass('progress-bar')} />
      </React.Fragment>
    );
  }
}

Uploads.propTypes = {
  types: PropTypes.arrayOf(PropTypes.oneOf(Uploads.TYPES)),
  size: PropTypes.oneOf(['small', 'large']),
  single: PropTypes.bool,
  skipUpload: PropTypes.bool,
  onUpload: PropTypes.func,
  onError: PropTypes.func,
  onDrop: PropTypes.func,
};

Uploads.defaultProps = {
  types: ['image'],
  size: 'small',
  single: false,
  skipUpload: false,
  onError: () => {},
};
