import React from 'react';
import { BrowserRouter, withRouter } from 'react-router-dom';

@withRouter
class ScrollRouter extends React.Component {
  componentDidMount() {
    this.onRouteChange();
  }

  componentDidUpdate(lastProps) {
    const { action } = this.props.history;
    if (this.routeChanged(lastProps)) {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
      this.onRouteChange();
    }
  }

  routeChanged(lastProps) {
    const { location } = this.props;
    const { location: lastLocation } = lastProps;
    return location.pathname !== lastLocation.pathname || location.search !== lastLocation.search;
  }

  onRouteChange() {
    if (this.props.onRouteChange) {
      this.props.onRouteChange(this.props.location);
    }
  }

  render() {
    return null;
  }
}

export default function Router(props) {
  const { children, onRouteChange, ...rest } = props;
  return (
    <BrowserRouter {...rest}>
      <ScrollRouter onRouteChange={onRouteChange} />
      {children}
    </BrowserRouter>
  );
}
