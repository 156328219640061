import React from 'react';
import { request } from 'utils/api';
import { Form, Loader, Popup, Icon, Message, Modal, Button, Divider } from 'semantic';
import SearchDropdown from 'common/components/SearchDropdown';
import FetchObject from 'common/components/FetchObject';
import { union, uniq } from 'lodash';

export default class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allRoles: null,
      error: null,
      loading: true,
      roles: props.value,
      scopedCreatorAccountIds: this.getScopeRefs(props.value, 'creatorAccount'),
      showAddCreatorAccountModal: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/users/roles`,
      });
      const allRoles = data;
      this.setState({ allRoles, loading: false });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  setGlobalValues(values) {
    const roles = [
      ...this.state.roles.filter((role) => role.scope !== 'global'),
      ...values.map((value) => {
        return { scope: 'global', role: value };
      }),
    ];
    this.setState({
      roles,
    });
    this.props.onChange(roles);
  }

  getGlobalValues() {
    return this.state.roles.filter((role) => role.scope === 'global').map((role) => role.role);
  }

  getScopedValues(scope, scopeRef) {
    return this.state.roles
      .filter((role) => role.scope === scope && role.scopeRef === scopeRef)
      .map((role) => role.role);
  }

  setScopedValues(scope, scopeRef, values) {
    const otherRoles = this.state.roles.filter((role) => !(role.scope === scope && role.scopeRef === scopeRef));
    const roles = otherRoles.concat(
      values.map((value) => {
        return {
          scope,
          scopeRef,
          role: value,
        };
      })
    );
    this.setState({
      roles,
    });
    this.props.onChange(roles);
  }

  getScopeRefs(roles, scope) {
    return union(roles.filter((role) => role.scope === scope).map((role) => role.scopeRef));
  }

  fetchCreatorAccounts = (keyword) => {
    return request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    }).then(({ data }) => data);
  };

  addCreatorAccountScope() {
    const { currentCreatorAccount, scopedCreatorAccountIds } = this.state;
    scopedCreatorAccountIds.push(currentCreatorAccount.id);
    this.setState({ scopedCreatorAccountIds: uniq(scopedCreatorAccountIds) });
  }

  getOptionsForScope(scope) {
    const { allRoles } = this.state;

    return Object.keys(allRoles)
      .map((key) => {
        return {
          ...allRoles[key],
          id: key,
        };
      })
      .filter((role) => role.allowScopes.includes(scope))
      .map((role) => {
        return {
          value: role.id,
          key: role.id,
          text: role.name,
        };
      });
  }

  render() {
    const { error, loading, scopedCreatorAccountIds, showAddCreatorAccountModal } = this.state;
    if (loading) return <Loader />;
    if (error) return <Message error content={error.message} />;

    return (
      <>
        <Form.Dropdown
          name="globalRoles"
          label={
            <>
              Global Roles
              <Popup
                content="Global scoped roles give a user permissions across all organizations."
                on="click"
                trigger={
                  <Icon
                    style={{
                      marginLeft: '5px',
                      cursor: 'pointer',
                      color: '#cccccc',
                    }}
                    name="help circle"
                  />
                }
              />
            </>
          }
          fluid
          selection
          multiple
          value={this.getGlobalValues()}
          options={this.getOptionsForScope('global')}
          onChange={(e, { value }) => this.setGlobalValues(value)}
        />
        {scopedCreatorAccountIds.length > 0 && (
          <>
            <Divider />
            <p style={{ fontWeight: 'bold' }}>Creator Accounts specify roles:</p>
          </>
        )}
        {scopedCreatorAccountIds.map((creatorAccountId) => {
          const name = `creator-account-${creatorAccountId}`;
          return (
            <FetchObject key={name} id={creatorAccountId} endpoint="creator-accounts">
              {(creatorAccount) => {
                return (
                  <Form.Dropdown
                    name={name}
                    label={
                      <>
                        <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{creatorAccount.name}</span>{' '}
                        Roles
                        <Popup
                          content="Creator Account scoped roles give a user permissions for a single Creator Account."
                          on="click"
                          trigger={
                            <Icon
                              style={{
                                marginLeft: '5px',
                                cursor: 'pointer',
                                color: '#cccccc',
                              }}
                              name="help circle"
                            />
                          }
                        />
                      </>
                    }
                    fluid
                    selection
                    multiple
                    value={this.getScopedValues('creatorAccount', creatorAccountId)}
                    options={this.getOptionsForScope('creatorAccount')}
                    onChange={(e, { value }) => this.setScopedValues('creatorAccount', creatorAccountId, value)}
                  />
                );
              }}
            </FetchObject>
          );
        })}
        <Modal
          open={showAddCreatorAccountModal}
          onClose={() => this.setState({ showAddCreatorAccountModal: false })}
          content={
            <Modal.Content>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.addCreatorAccountScope();
                  this.setState({ showAddCreatorAccountModal: false });
                }}>
                <Form.Field>
                  <label>Select Creator Account</label>
                  <SearchDropdown
                    value={this.state.currentCreatorAccount}
                    onChange={(e, { value }) => {
                      this.setState({ currentCreatorAccount: value });
                    }}
                    onDataNeeded={this.fetchCreatorAccounts}
                    fluid
                    search={false}
                  />
                </Form.Field>
                <Button
                  primary
                  content="Add"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addCreatorAccountScope();
                    this.setState({ showAddCreatorAccountModal: false });
                  }}
                />
              </Form>
            </Modal.Content>
          }
          size="tiny"
          on="click"
        />
        <div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.setState({ showAddCreatorAccountModal: true });
            }}
            basic
            icon="plus"
            content="Add Creator Account Role"
            size="tiny"
          />
        </div>
      </>
    );
  }
}
