import React from 'react';
import PropTypes from 'prop-types';

import { Form, Label, Input } from 'semantic';
import { Component } from 'common/helpers';
import { Layout } from 'common/components/Layout';

import DateInput from './Input';

import './date.less';

export default class DateField extends Component {
  setDate(evt, date) {
    this.props.onChange(evt, {
      ...this.props,
      value: date,
    });
  }

  onDayChange = (date) => {
    this.setDate(null, date);
  };

  onHoursChange = (evt, { value }) => {
    let { value: date } = this.props;
    if (date) {
      date = new Date(date);
      date.setHours(value);
      this.setDate(evt, date);
    }
  };

  onMinutesChange = (evt, { value }) => {
    let { value: date } = this.props;
    if (date) {
      date = new Date(date);
      date.setMinutes(value);
      this.setDate(evt, date);
    }
  };

  render() {
    const { time, required, label, placeholder, clearable, id, disabled, includeTimezone } = this.props;
    let { value: date } = this.props;
    if (date && typeof date === 'string') {
      date = new Date(date);
    }

    return (
      <Form.Field className={this.getComponentClass()} required={required}>
        {label && (
          <label htmlFor={id}>
            {label}
            {includeTimezone && date && time && (
              <span style={{ float: 'right', marginRight: '-15px' }}>
                Timezone: {Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}
              </span>
            )}
          </label>
        )}
        <Layout horizontal center>
          <Layout.Group style={{ position: 'relative' }}>
            <DateInput value={date} placeholder={placeholder} onChange={this.onDayChange} />
            {clearable && date && (
              <div className={this.getElementClass('clear-button')} onClick={() => this.setDate(null)}>
                &times;
              </div>
            )}
          </Layout.Group>
          {time && date && (
            <React.Fragment>
              <Layout.Spacer size="tiny" />
              <Layout.Group>
                <Input
                  disabled={disabled}
                  type="number"
                  labelPosition="right"
                  value={date ? date.getHours().toString() : ''}
                  onChange={this.onHoursChange}>
                  <input className={this.getElementClass('time-input')} />
                  <Label as="span">h</Label>
                </Input>
              </Layout.Group>
              <Layout.Spacer size="tiny" />
              <Layout.Group>
                <Input
                  type="number"
                  step="5"
                  disabled={disabled}
                  labelPosition="right"
                  value={date ? date.getMinutes().toString().padStart(2, 0) : ''}
                  onChange={this.onMinutesChange}>
                  <input className={this.getElementClass('time-input')} />
                  <Label as="span">m</Label>
                </Input>
              </Layout.Group>
            </React.Fragment>
          )}
        </Layout>
      </Form.Field>
    );
  }
}

DateField.propTypes = {
  time: PropTypes.bool,
  label: PropTypes.node,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateField.defaultProps = {
  time: false,
  required: false,
  clearable: false,
  placeholder: 'Select Date',
};
