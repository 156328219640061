import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';

import './draggable.less';

const DRAG_THRESHOLD = 5;

export default class Draggable extends Component {
  getDrag(evt) {
    return {
      ...this.props,
      x: evt.clientX - this.origin.x,
      y: evt.clientY - this.origin.y,
    };
  }

  onMouseDown = (evt) => {
    // Ignore right clicks
    if (evt.button !== 0) {
      return;
    }
    this.origin = {
      x: evt.clientX,
      y: evt.clientY,
    };
    this.started = false;
    evt.preventDefault();
    document.documentElement.classList.add('dragging');
    document.documentElement.addEventListener('mousemove', this.onDocMouseMove);
    document.documentElement.addEventListener('mouseup', this.onDocMouseUp);
  };

  onDocMouseMove = (evt) => {
    const drag = this.getDrag(evt);
    const delta = Math.max(Math.abs(drag.x), Math.abs(drag.y));
    if (!this.started && delta > DRAG_THRESHOLD) {
      this.props.onDragStart(drag);
      this.started = true;
    }
    if (this.started) {
      this.props.onDragMove(drag);
    }
  };

  onDocMouseUp = (evt) => {
    const drag = this.getDrag(evt);
    document.documentElement.classList.remove('dragging');
    document.documentElement.removeEventListener('mousemove', this.onDocMouseMove);
    document.documentElement.removeEventListener('mouseup', this.onDocMouseUp);
    if (this.started) {
      this.props.onDragEnd(drag);
    } else if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div onMouseDown={this.onMouseDown} {...this.getProps()}>
        {this.props.children}
      </div>
    );
  }
}

Draggable.propTypes = {
  name: PropTypes.string,
  onDragStart: PropTypes.func,
  onDragMove: PropTypes.func,
  onDragEnd: PropTypes.func,
  onClick: PropTypes.func,
};

Draggable.defaultProps = {
  onDragStart: () => {},
  onDragMove: () => {},
  onDragEnd: () => {},
};
