import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Container } from 'semantic';
import { Layout } from 'components';
import Sidebar from './Sidebar';
import { withSession } from 'stores';
import { truncate } from 'lodash';

import logo from 'assets/logo-black.svg';
import favicon from 'assets/logo-black.svg';
import Protected from 'common/components/Protected';
import SwitchAccount from 'common/modals/SwitchAccount';
import Notifications from 'admin/components/Notifications';

@withSession
export default class DashboardLayout extends React.Component {
  render() {
    const { creatorAccount, canSwitchAccounts } = this.context;
    return (
      <Sidebar>
        <Sidebar.Menu>
          <Notifications />
          <Layout style={{ height: '100%' }}>
            <NavLink style={{ margin: '5px 25px 15px 25px' }} to="/">
              <img height="30" src={logo} />
            </NavLink>
            <Layout vertical spread>
              {canSwitchAccounts() ? (
                <Sidebar.Item>
                  <SwitchAccount
                    trigger={
                      <Layout horizontal spread>
                        {truncate(creatorAccount?.name, { length: 26 }) || 'All Creators'}{' '}
                        <Icon name="caret-down" className="right" />
                      </Layout>
                    }
                    size="tiny"
                  />
                </Sidebar.Item>
              ) : (
                <Sidebar.Item>{creatorAccount?.name}</Sidebar.Item>
              )}

              <Layout.Group>
                <Sidebar.Header>Main Menu</Sidebar.Header>
              </Layout.Group>
              <Layout.Group grow overflow>
                <Protected endpoint="stacks" scope="global">
                  <Sidebar.Link to="/event-stacks">Stacks</Sidebar.Link>
                </Protected>
                <Sidebar.Link to="/events">Events</Sidebar.Link>

                <Sidebar.Link to="/products">Products</Sidebar.Link>
                <Protected endpoint="productOptions" scope="global">
                  <Sidebar.Link to="/product-options">Product Options</Sidebar.Link>
                </Protected>
                {!creatorAccount && (
                  <Protected endpoint="creatorAccounts" scope="global">
                    <Sidebar.Link to="/creator-accounts">Creators</Sidebar.Link>
                  </Protected>
                )}
                {(!creatorAccount || creatorAccount.discountsEnabled) && (
                  <Protected endpoint="discounts" scope="creatorAccount">
                    <Sidebar.Link to="/discounts">Discounts</Sidebar.Link>
                  </Protected>
                )}
                <Protected endpoint="links" scope="creatorAccount">
                  <Sidebar.Link to="/links">Links</Sidebar.Link>
                </Protected>
                <Protected endpoint="assets" scope="creatorAccount">
                  <Sidebar.Link to="/assets">Assets</Sidebar.Link>
                </Protected>
                <Protected endpoint="orders" scope="creatorAccount">
                  <Sidebar.Link to="/orders">Orders</Sidebar.Link>
                </Protected>

                <Protected endpoint="orders" scope="creatorAccount">
                  <Sidebar.Link to="/insights">Insights</Sidebar.Link>
                </Protected>

                <Protected endpoint="users" scope="creatorAccount">
                  <Sidebar.Link to="/users">Users</Sidebar.Link>
                </Protected>

                <Protected endpoint="invites" scope="creatorAccount">
                  <Sidebar.Link to="/invites">Invites</Sidebar.Link>
                </Protected>

                {/*
                <Protected endpoint="causes" scope="global">
                  <Sidebar.Link to="/causes">Causes</Sidebar.Link>
                </Protected>
                */}
              </Layout.Group>
              <Layout.Group>
                <Sidebar.Divider />
                <Sidebar.Link to="/settings">
                  <Icon name="cog" />
                  Settings
                </Sidebar.Link>
                <Protected endpoint="docs" scope="global">
                  <Sidebar.Link to="/docs/getting-started">
                    <Icon name="terminal" />
                    Docs
                  </Sidebar.Link>
                </Protected>
                <Sidebar.Link to="/logout">
                  <Icon name="sign-out-alt" />
                  Log Out
                </Sidebar.Link>
              </Layout.Group>
            </Layout>
          </Layout>
        </Sidebar.Menu>
        <Sidebar.Content>
          <Sidebar.Mobile>
            <Layout horizontal spread center>
              <Layout.Group>
                <NavLink to="/">
                  <img src={favicon} height="15" />
                </NavLink>
              </Layout.Group>
              <Layout.Group>
                <Sidebar.Trigger>
                  <Icon name="bars" fitted />
                </Sidebar.Trigger>
              </Layout.Group>
            </Layout>
          </Sidebar.Mobile>
          <Container>
            <main>{this.props.children}</main>
          </Container>
        </Sidebar.Content>
      </Sidebar>
    );
  }
}
