import React from 'react';
import { Header, Table, Image, Button } from 'semantic';
import { Link } from 'react-router-dom';
import { urlForImage } from 'utils/uploads';
import { formatDateTime } from 'utils/date';
import { ExternalLink } from 'components/Link';
import EditEventStackImage from 'admin/modals/EditEventStackImage';
import { AdaptiveVideo } from 'common/components/Video';

export default class EventOverview extends React.Component {
  render() {
    const { eventStack } = this.props;
    return (
      <div>
        <Header as="h1">{eventStack.name}</Header>
        <p>{eventStack.description}</p>
        <Header as="h3">Images</Header>
        {eventStack.coverImage && (
          <Image.Group size="large">
            <EditEventStackImage eventStack={eventStack} trigger={<Image src={urlForImage(eventStack.coverImage)} />} />
          </Image.Group>
        )}
        {eventStack.previewVideo && (
          <React.Fragment>
            <Header as="h3">Preview Video</Header>
            <AdaptiveVideo controls video={eventStack.previewVideo} />
          </React.Fragment>
        )}
        <Header as="h3">Details</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Creator Account</Table.Cell>
              <Table.Cell>
                <Link to={`/creator-accounts/${eventStack.creatorAccount?.id}`}>{eventStack.creatorAccount.name}</Link>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sharable Link</Table.Cell>
              <Table.Cell>
                {eventStack.shareableLink ? (
                  <>
                    <ExternalLink href={eventStack.shareableLink}>
                      {eventStack.shareableLink.replace('https://', '')}
                    </ExternalLink>
                    <Button
                      basic
                      style={{ boxShadow: 'none' }}
                      title="Copy Shareable Link"
                      onClick={() =>
                        //only works on https
                        navigator.clipboard.writeText(eventStack.shareableLink)
                      }
                      icon="clipboard"
                    />
                  </>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Starts At</Table.Cell>
              <Table.Cell>{formatDateTime(eventStack.startsAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Ends At</Table.Cell>
              <Table.Cell>{formatDateTime(eventStack.endsAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>{eventStack.status}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(eventStack.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(eventStack.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
