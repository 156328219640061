import React from 'react';
import { Table, Button, Image, Confirm, Segment } from 'semantic';
import { request } from 'utils/api';

import { urlForImage } from 'utils/uploads';
import { Layout, Breadcrumbs } from 'components';
import EditProductOptions from 'admin/modals/EditProductOption';
import { screen } from 'helpers';
import { SearchProvider, Filters, Pagination, Status } from 'components/search';
import Protected from 'components/Protected';
import { Link } from 'react-router-dom';

@screen
export default class Productoptions extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/product-options/search',
      body: params,
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({ items, getSorted, setSort, filters, setFilters, reload }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Product Options" />
              <Layout horizontal center spread>
                <h1>Product Options</h1>

                <Layout.Group>
                  <EditProductOptions
                    trigger={<Button primary content="New Product Option" icon="plus" />}
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {/*
              <Segment>
                  <Layout horizontal center spread stackable>
                    <Layout horizontal></Layout>
                    <Layout.Group>
                      <Filters.Search name="keyword" placeholder="Enter name" />
                    </Layout.Group>
                  </Layout>
                </Segment>
              */}
              <Status />

              {items.length !== 0 && (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2} onClick={() => setSort('name')} sorted={getSorted('name')}>
                        Category
                      </Table.HeaderCell>
                      <Protected endpoint="creatorAccounts" scope="global">
                        <Table.HeaderCell width={3}>Creator</Table.HeaderCell>
                      </Protected>
                      <Table.HeaderCell onClick={() => setSort('value')} sorted={getSorted('value')}>
                        Value
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1}>Image</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => {
                      return (
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Protected endpoint="creatorAccounts" scope="global">
                            <Table.Cell>
                              {item.creatorAccount ? (
                                <Link to={`/creator-accounts/${item.creatorAccount.id}`}>
                                  {item.creatorAccount.name}
                                </Link>
                              ) : (
                                '-'
                              )}
                            </Table.Cell>
                          </Protected>
                          <Table.Cell>{item.value}</Table.Cell>
                          <Table.Cell>
                            {item.image && <Image size="tiny" src={urlForImage(item.image, { width: 150 })} />}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <EditProductOptions item={item} onSave={reload} trigger={<Button basic icon="edit" />} />
                            <Confirm
                              negative
                              confirmText="Delete"
                              header={`Are you sure you want to delete "${item.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/product-options/${item.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
              <Layout center>
                <Pagination />
              </Layout>
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
