import React, { useContext } from 'react';

import { SessionContext, NotificationContext } from 'stores';
import EditAdminEvent from './EditAdminEvent';
import EditMyEvent from './EditMyEvent';

export default function EditEvent(props) {
  const { user } = useContext(SessionContext);
  const notification = useContext(NotificationContext);

  const isSuperAdminOrManager = user.roles?.some((c) => ['superAdmin'].includes(c.role));

  function triggerNotification(props) {
    // disabled for now for production;
    return;
    // notification.show(props);
  }

  return isSuperAdminOrManager ? (
    <EditAdminEvent triggerNotification={triggerNotification} {...props} />
  ) : (
    <EditMyEvent triggerNotification={triggerNotification} {...props} />
  );
}
