import React from 'react';
import { sortBy } from 'lodash';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import SearchDropDown from 'common/components/SearchDropdown';
import CurrencyField from 'components/form-fields/Currency';
import UploadsField from 'admin/components/form-fields/Uploads';

import { modal } from 'common/helpers';
import FetchObject from 'common/components/FetchObject';
import { withSession } from '../../stores';

@modal
@withSession
export default class EditProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      hasError: false,
      product: {
        ...props.product,
      },
    };
  }

  isUpdate() {
    return !!this.props.product?.id;
  }

  setField(name, value) {
    this.setState({
      product: {
        ...this.state.product,
        [name]: value,
      },
    });
  }

  fetchCategories = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/categories/search',
      body: {
        name: keyword,
      },
    });
    return data;
  };

  fetchBundledProducts = async ({ name }) => {
    const creatorAccount = this.state.product?.creatorAccount;
    let { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        creatorAccount: creatorAccount.id,
        types: ['bundled'],
        keyword: name,
      },
    });

    const { product } = this.props;
    if (product) {
      data = data.filter((p) => {
        return p.id !== product.id;
      });
    }

    return data;
  };

  fetchCreatorAccounts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  fetchParent = async (keyword) => {
    const parentType = this.props.product.type === 'bundled' ? 'bundle' : 'base';
    const { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        externalId: null,
        types: [parentType],
        keyword,
      },
    });
    return data;
  };

  fetchOptionsNames = async () => {
    const { data } = await request({
      method: 'POST',
      path: '/1/product-options/search/names',
    });

    return data.map((item) => {
      return {
        id: item,
        name: item,
      };
    });
  };

  fetchOptions = async (filters) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/product-options/search',
      body: filters,
    });

    return data;
  };

  convertToBundle = async () => {
    const { product } = this.state;
    this.setState({
      loading: true,
    });
    try {
      await request({
        method: 'POST',
        path: `/1/products/${product.id}/convert-to/bundle`,
      });
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
        touched: true,
      });
      const { product } = this.state;
      const productOptions = (product.productOptions || [])
        .filter((item) => item.item)
        .map((productOption) => {
          return {
            weight: productOption.weight,
            item: productOption.item.id,
          };
        });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/products/${product.id}`,
          body: {
            ...product,
            parent: product.parent,
            creatorAccount: product.creatorAccount?.id,
            assets: (product.assets || []).map((pi) => {
              return pi;
            }),
            subProducts: (product.subProducts || []).map((product) => {
              return product.id || product;
            }),
            categories: (product.categories || []).map((category) => category.id),
            productOptions: (product.productOptions || []).map((productOption) => {
              const { item, weight } = productOption;
              return {
                weight,
                item: item.id,
              };
            }),
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/products',
          body: {
            ...product,
            parent: product.parent,
            creatorAccount: product.creatorAccount?.id,
            assets: (product.assets || []).map((pi) => {
              return pi;
            }),
            subProducts: (product.subProducts || []).map((product) => {
              return product.id || product;
            }),
            productOptions,
            categories: (product.categories || []).map((category) => category.id),
          },
        });
        this.setState({
          product: {},
          touched: false,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  };

  render() {
    const { product, touched, loading, error, hasError } = this.state;
    return (
      <>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${product.name}" - ${product.type}` : `New Product (${product.type})`}
        </Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            {error && <Message error content={error.message} />}
            <Form error={touched && (!!error || hasError)}>
              <Form.Input
                required
                name="name"
                label="Name"
                type="text"
                value={product.name || ''}
                onChange={(e, { value }) => this.setField('name', value)}
              />
              <Form.TextArea
                name="description"
                label="Description"
                value={product.description || ''}
                onChange={(e, { value }) => this.setField('description', value)}
              />
              {!this.isUpdate() && ['variant', 'bundled'].includes(product.type) && (
                <Form.Field required>
                  <label>Parent</label>
                  <FetchObject key={product.parent} id={product.parent} endpoint="products">
                    {(parent) => (
                      <SearchDropDown
                        fluid
                        value={parent}
                        onChange={(e, { value }) => {
                          this.setState({
                            product: {
                              ...product,
                              parent: value.id,
                              creatorAccount: value.creatorAccount,
                              productOptions: [],
                            },
                          });
                        }}
                        onDataNeeded={this.fetchParent}
                      />
                    )}
                  </FetchObject>
                </Form.Field>
              )}
              {this.context.canManageAccounts() &&
                !this.context.creatorAccount?.id &&
                !this.isUpdate() &&
                ['base', 'bundle', 'individual'].includes(product.type) && (
                  <Form.Field required>
                    <label>Creator Account</label>
                    <SearchDropDown
                      fluid
                      value={product.creatorAccount}
                      onChange={(e, { value }) => {
                        this.setState({
                          product: {
                            ...product,
                            creatorAccount: value,
                            subProducts: [],
                          },
                        });
                      }}
                      onDataNeeded={this.fetchCreatorAccounts}
                    />
                  </Form.Field>
                )}
              {product.type === 'base' && (
                <Form.Field>
                  <label>Options</label>
                  <SearchDropDown
                    fluid
                    multiple
                    value={(product.options || []).map((option) => {
                      return {
                        id: option,
                        value: option,
                      };
                    })}
                    onChange={(e, { value }) => {
                      this.setField(
                        'options',
                        value.map((item) => item.id)
                      );
                    }}
                    onDataNeeded={this.fetchOptionsNames}
                  />
                </Form.Field>
              )}
              {product.parent && product.type === 'variant' && (
                <FetchObject key={product.parent} id={product.parent} endpoint="products">
                  {(parent) => {
                    return (
                      <>
                        {(parent.options || []).map((option) => (
                          <Form.Field key={option}>
                            <label
                              style={{
                                textTransform: 'capitalize',
                              }}>
                              {option} Options
                            </label>

                            <SearchDropDown
                              fluid
                              multiple
                              value={sortBy(
                                (product.productOptions || [])
                                  .map((item) => item.item)
                                  .filter((item) => item?.name === option),
                                'weight'
                              )}
                              getOptionLabel={(item) => item.value}
                              onChange={(e, { value: newItems }) => {
                                this.setField('productOptions', [
                                  ...(product.productOptions || []).filter(
                                    (productOption) => productOption.item?.name !== option
                                  ),
                                  ...newItems.map((newItem) => {
                                    // if there is a weight it will be on the original productOptions
                                    // so here i am restoring what i can
                                    const existing = (this.props.product.productOptions || []).find(
                                      (productOption) => productOption.item?.id === newItem.id
                                    );

                                    return existing
                                      ? existing
                                      : {
                                          item: newItem,
                                        };
                                  }),
                                ]);
                              }}
                              onDataNeeded={(keyword) =>
                                this.fetchOptions({
                                  keyword: keyword,
                                  name: option,
                                })
                              }
                            />
                          </Form.Field>
                        ))}
                      </>
                    );
                  }}
                </FetchObject>
              )}
              {['bundled', 'variant', 'individual'].includes(product.type) && (
                <Form.Group widths="equal">
                  <Form.Input
                    name="sku"
                    label="SKU"
                    type="text"
                    required
                    value={product.sku || ''}
                    onChange={(e, { value }) => this.setField('sku', value)}
                  />

                  <CurrencyField
                    placeholder="Amount"
                    name="price"
                    type="number"
                    label="Price ($)"
                    required
                    onChange={(e, { value }) => this.setField('price', value)}
                    value={product.price || ''}
                  />

                  <Form.Input
                    label="Inventory"
                    name="inventory"
                    type="number"
                    onChange={(e, { value }) => this.setField('inventory', value)}
                    value={product.inventory || ''}
                  />
                  <Form.Input
                    label="Purchase Limit"
                    name="purchaseLimit"
                    type="number"
                    onChange={(e, { value }) => this.setField('purchaseLimit', value ? Number(value) : null)}
                    value={product.purchaseLimit}
                  />
                </Form.Group>
              )}

              <Form.Input
                label="Pre Order Message"
                name="preOrder"
                type="text"
                onChange={(e, { value }) => this.setField('preOrder', value.length ? { message: value } : null)}
                value={product.preOrder?.message || ''}
              />

              <Form.Field>
                <label>Categories</label>
                <SearchDropDown
                  fluid
                  multiple
                  value={product.categories}
                  onChange={(e, { value }) => this.setField('categories', value)}
                  onDataNeeded={this.fetchCategories}
                />
              </Form.Field>
              <Form.TextArea
                name="details"
                label="Details"
                value={product.details || ''}
                onChange={(e, { value }) => this.setField('details', value)}
                rows={15}
              />
              <Form.Checkbox
                label="Is Featured?"
                name="isFeatured"
                checked={product.isFeatured || false}
                onChange={(e, { checked }) => this.setField('isFeatured', checked)}
              />
              {/*
              <DateField
                time
                name="expiresAt"
                value={product.expiresAt || new Date()}
                label="Expiration Date and Time"
                onChange={(e, { value }) => this.setField('expiresAt', value)}
              />
              */}
              <UploadsField
                label="Assets"
                name="assets"
                types={['image', 'model']}
                value={product.assets || []}
                onChange={(uploads) => {
                  this.setField(
                    'assets',
                    uploads.map((upload) => {
                      let { id, type } = upload;
                      // Hacking this as product assets are not
                      // aligned with the rest of our assets
                      if (!type && upload.mimeType) {
                        type = upload.mimeType.split('/')[0];
                      }
                      return {
                        id,
                        type,
                      };
                    })
                  );
                }}
                onError={() => this.setState({ touched: true, hasError: true })}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          {this.isUpdate() && product.type === 'individual' && (
            <Button
              floated="left"
              loading={loading}
              disabled={loading}
              content={'Convert To Bundle'}
              onClick={this.convertToBundle}
            />
          )}
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
