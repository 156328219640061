import React, { useState } from 'react';

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState();

  return (
    <NotificationContext.Provider
      value={{
        notification,
        show: (props) => {
          setNotification(props);
        },
        clear: () => setNotification(null),
      }}>
      {children}
    </NotificationContext.Provider>
  );
};
