import React from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Loader, Header } from 'semantic';
import Breadcrumbs from 'admin/components/Breadcrumbs';
import { request } from 'utils/api';

import Overview from './Overview';
import Members from './Members';
import Invites from './Invites';
import Sales from './Sales';

import { screen } from 'helpers';

@screen
export default class CreatorAccountDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorAccount: null,
      error: null,
      loading: true,
      onSave: this.onSave,
    };
  }

  componentDidMount() {
    this.fetchCreatorAccount();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchCreatorAccount();
    }
  }

  onSave = () => {
    this.fetchCreatorAccount();
  };

  async fetchCreatorAccount() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/creator-accounts/${id}`,
      });
      this.setState({
        creatorAccount: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <>
          <Breadcrumbs link={<Link to="/creator-accounts">Creator Accounts</Link>} active="Not Found" />
          <Header content="Sorry that user wasn't found." />
        </>
      );
    }
    return (
      <>
        <Switch>
          <Route
            exact
            path="/creator-accounts/:id/members"
            render={(props) => <Members {...props} {...this.state} />}
          />
          <Route
            exact
            path="/creator-accounts/:id/invites"
            render={(props) => <Invites {...props} {...this.state} />}
          />
          <Route exact path="/creator-accounts/:id/sales" render={(props) => <Sales {...props} {...this.state} />} />

          <Route exact path="/creator-accounts/:id" render={(props) => <Overview {...props} {...this.state} />} />
        </Switch>
      </>
    );
  }
}
