import React from 'react';
import PropTypes from 'prop-types';
import { Form, Label } from 'semantic';

export default class CurrencyField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: null,
    };
  }

  onChange = (evt, { value }) => {
    this.setState({
      input: value,
    });
  };

  onBlur = (evt) => {
    const { input } = this.state;

    if (input != null) {
      const { name, cents } = this.props;
      let value = Math.round(parseFloat(input) * (cents ? 100 : 1));
      if (isNaN(value)) {
        value = null;
      }

      this.setState({
        input: null,
      });

      this.props.onChange(evt, { name, value });
    }
  };

  formatCurrency(value) {
    if (this.props.cents) {
      value /= 100;
    }
    if (!value) {
      return '';
    }

    return new Intl.NumberFormat('en', {
      minimumFractionDigits: 2,
    }).format(value);
  }

  render() {
    const { input } = this.state;
    const { value, cents, currency, onChange, ...rest } = this.props;

    return (
      <Form.Input
        {...rest}
        type="text"
        value={input != null ? input : this.formatCurrency(value)}
        onBlur={this.onBlur}
        onChange={this.onChange}
      />
    );
  }
}

CurrencyField.propTypes = {
  cents: PropTypes.bool,
  currency: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CurrencyField.defaultProps = {
  currency: 'USD',
  cents: true,
};
