import React from 'react';
import { Table, Message, Divider, Loader, Button } from 'semantic';
import { request } from 'utils/api';

import { Confirm } from 'common/components/Semantic';
import SearchProvider from 'common/components/SearchProvider';

import Menu from './Menu';
import { Link } from 'react-router-dom';

export default class CreatorAccountMembers extends React.Component {
  onDataNeeded = async (params) => {
    const { creatorAccount } = this.props;
    return await request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...params,
        creatorAccount: creatorAccount.id,
      },
    });
  };

  render() {
    const { creatorAccount } = this.props;

    return (
      <React.Fragment>
        <Menu {...this.props} showAddMember />
        {creatorAccount ? (
          <SearchProvider onDataNeeded={this.onDataNeeded}>
            {({ items, reload }) => {
              return (
                <React.Fragment>
                  <Divider hidden />
                  {items.length === 0 ? (
                    <Message>No Members added yet</Message>
                  ) : (
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Email</Table.HeaderCell>
                          <Table.HeaderCell>Roles</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {items.map((user) => {
                          return (
                            <Table.Row key={user.id}>
                              <Table.Cell>
                                <Link to={`/users/${user.id}`}>
                                  {user.firstName} {user.lastName}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>{user.email}</Table.Cell>
                              <Table.Cell>
                                {user.roles
                                  .filter((r) => r.scopeRef === creatorAccount.id)
                                  .map((r) => r.roleDefinition?.name)
                                  .join(', ')}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Confirm
                                  negative
                                  confirmText="Revoke"
                                  header={`Revoke access for ${user.firstName} ${user.lastName}?`}
                                  content={`The User will no longer have access to ${creatorAccount.name}`}
                                  trigger={<Button basic icon="trash" />}
                                  onConfirm={async () => {
                                    await request({
                                      method: 'DELETE',
                                      path: `/1/creator-accounts/${creatorAccount.id}/users/${user.id}`,
                                    });
                                    reload();
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  )}
                </React.Fragment>
              );
            }}
          </SearchProvider>
        ) : (
          <Loader active>Loading</Loader>
        )}
      </React.Fragment>
    );
  }
}
