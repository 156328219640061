import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import Protected from 'common/components/Protected';

export default () => (
  <Container>
    <Menu pointing secondary>
      <Menu.Item exact name="Overview" to={`/insights`} as={NavLink} />
      <Menu.Item exact name="Events" to={`/insights/events`} as={NavLink} />
      <Menu.Item exact name="Funnel" to={`/insights/funnel`} as={NavLink} />
      <Menu.Item exact name="Sales" to={`/insights/sales`} as={NavLink} />
      <Menu.Item exact name="Tracking" to={`/insights/tracking`} as={NavLink} />
      <Protected endpoint="creatorAccounts" scope="global">
        <Menu.Item exact name="Tracking Details" to={`/insights/tracking-details`} as={NavLink} />
      </Protected>
      <Menu.Item
        position="right"
        style={{
          right: '-22px',
        }}></Menu.Item>
    </Menu>
  </Container>
);
