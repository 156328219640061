import React from 'react';
import { Segment, Divider, Statistic, Header } from 'semantic';

import {
  AggregateTimeSeries,
  SeriesChart,
  AggregateStats,
  AggregateCardinality,
  AggregateTerms,
  DonutChart,
  Table,
} from 'react-tectonic';

import { numberWithCommas, formatUsd } from 'utils/formatting';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';
import { Link } from 'react-router-dom';
import LookupUserNames from 'admin/components/LookupUserNames';
import { useSession } from 'stores';

export default function InsightSales(props) {
  const { hasAccess } = useSession();

  const canAccessGlobalUsers = hasAccess({
    endpoint: 'users',
    permission: 'read',
    scope: 'global',
  });

  return (
    <>
      <Segment basic>
        <Divider hidden />

        <Statistic.Group widths="6">
          <AggregateStats
            fields={['occurredAt']}
            filter={{ terms: [...props.terms, { type: { value: 'buyflow-entered' } }] }}>
            {({ status, data }) => (
              <Statistic>
                <Statistic.Value>{status.success ? numberWithCommas(data['occurredAt'].count) : '...'}</Statistic.Value>
                <Statistic.Label>Checkouts started</Statistic.Label>
              </Statistic>
            )}
          </AggregateStats>

          <AggregateStats
            fields={['occurredAt']}
            filter={{ terms: [...props.terms, { type: { value: 'product-added-to-cart' } }] }}>
            {({ status, data }) => (
              <Statistic>
                <Statistic.Value>{status.success ? numberWithCommas(data['occurredAt'].count) : '...'}</Statistic.Value>
                <Statistic.Label>Added to Cart</Statistic.Label>
              </Statistic>
            )}
          </AggregateStats>

          <AggregateStats
            fields={['occurredAt']}
            filter={{ terms: [...props.terms, { type: { value: 'product-purchased' } }] }}>
            {({ status, data }) => (
              <Statistic>
                <Statistic.Value>{status.success ? numberWithCommas(data['occurredAt'].count) : '...'}</Statistic.Value>
                <Statistic.Label>Purchases</Statistic.Label>
              </Statistic>
            )}
          </AggregateStats>

          <AggregateCardinality
            fields={['pipelineMeta.userId']}
            filter={{ terms: [...props.terms, { type: { value: 'product-purchased' } }] }}>
            {({ status, data }) => (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(data['pipelineMeta.userId']) : '...'}
                </Statistic.Value>
                <Statistic.Label>Buyers</Statistic.Label>
              </Statistic>
            )}
          </AggregateCardinality>

          <AggregateStats
            fields={['product.price']}
            filter={{ terms: [...props.terms, { type: { value: 'product-purchased' } }] }}>
            {({ status, data }) => (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(Math.round(data['product.price'].sum / 100)) : '...'}
                </Statistic.Value>
                <Statistic.Label>Total Sales</Statistic.Label>
              </Statistic>
            )}
          </AggregateStats>
        </Statistic.Group>

        <Divider hidden />
      </Segment>

      <AnalyticsBlock>
        <AggregateTimeSeries
          filter={{
            terms: [...props.terms, { type: { value: 'product-purchased' } }],
          }}
          operation="count"
          dateField="occurredAt">
          <SeriesChart
            height={240}
            title="Purchases over Time"
            titleAlign="center"
            chartType="area"
            valueField="count"
          />
        </AggregateTimeSeries>
      </AnalyticsBlock>

      <AnalyticsBlock columns={2}>
        <React.Fragment>
          <AggregateTerms
            filter={{
              terms: [...props.terms, { type: { value: 'product-purchased' } }],
            }}
            aggField="product.name"
            termsSize={5}>
            <DonutChart title="Top Products by Purchase" titleAlign="center" limit={5} percent />
          </AggregateTerms>
        </React.Fragment>
        <React.Fragment>
          <Header as="h4" content="Top Products by Sales" textAlign="center" />
          <Divider hidden />
          <AggregateTerms
            includeTopHit
            aggField="product.name"
            operation="sum"
            field="product.price"
            filter={{
              terms: [{ type: { value: 'product-purchased' } }],
            }}
            termsSize={8}>
            <Table
              valueFieldName="Sales"
              valueField="value"
              valueFormatter={formatUsd}
              rowFormatter={(item, label, value) => {
                const { productId } = item.topHit._source;
                return (
                  <>
                    <td>
                      <Link to={`/products/${productId}`}>{item.key}</Link>
                    </td>
                    <td>{value}</td>
                  </>
                );
              }}
            />
          </AggregateTerms>
        </React.Fragment>
      </AnalyticsBlock>

      <AnalyticsBlock columns={2}>
        <React.Fragment>
          <AggregateTerms
            includeTopHit
            aggField="product.name"
            operation="count"
            filter={{
              terms: [...props.terms, { type: { value: 'product-viewed' } }],
            }}
            termsSize={8}>
            <Table
              title="Most Viewed Products"
              titleAlign="center"
              valueFieldName="Views"
              valueField="count"
              rowFormatter={(item, label, value) => {
                const { productId } = item.topHit._source;
                return (
                  <>
                    <td>
                      <Link to={`/products/${productId}`}>{item.key}</Link>
                    </td>
                    <td>{value}</td>
                  </>
                );
              }}
            />
          </AggregateTerms>
        </React.Fragment>
        <React.Fragment>
          <AggregateTerms
            includeTopHit
            aggField="product.name"
            operation="count"
            filter={{
              terms: [...props.terms, { type: { value: 'buyflow-entered' } }],
            }}
            termsSize={8}>
            <Table
              title="Top Products by Purchase Intent"
              titleAlign="center"
              valueFieldName="# Add to Cart Actions"
              valueField="count"
              rowFormatter={(item, label, value) => {
                const { productId } = item.topHit._source;
                return (
                  <>
                    <td>
                      <Link to={`/products/${productId}`}>{item.key}</Link>
                    </td>
                    <td>{value}</td>
                  </>
                );
              }}
            />
          </AggregateTerms>
        </React.Fragment>
      </AnalyticsBlock>

      {/*
        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="product.name"
              aggFieldOrder="asc"
              field="secondsSinceViewProduct"
              operation="avg"
              filter={{
                terms: [{ type: { value: 'product-purchased' } }],
              }}
              termsSize={8}>
              <Table
                title="Shortest Time-to-Purchase"
                titleAlign="center"
                valueFieldName="Time"
                valueField="value"
                rowFormatter={(item, label, value) => {
                  const { productId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/products/${productId}`}>{item.key}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
                valueFormatter={(item) => {
                  return `${Math.round(item)} seconds`;
                }}
              />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="product.name"
              aggFieldOrder="desc"
              field="secondsSinceViewProduct"
              operation="avg"
              filter={{
                terms: [{ type: { value: 'product-purchased' } }],
              }}
              termsSize={8}>
              <Table
                title="Longest Time-to-Purchase"
                titleAlign="center"
                valueFieldName="Time"
                valueField="value"
                rowFormatter={(item, label, value) => {
                  const { productId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/products/${productId}`}>{item.key}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
                valueFormatter={(item) => {
                  return `${Math.round(item)} seconds`;
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
        */}

      <AnalyticsBlock columns={2}>
        <React.Fragment>
          <AggregateTerms
            includeTopHit
            aggField="pipelineMeta.userId"
            operation="sum"
            field="product.price"
            filter={{
              terms: [...props.terms, { type: { value: 'product-purchased' } }],
            }}
            termsSize={8}>
            <LookupUserNames>
              <Table
                title="Consumers by Sales"
                titleAlign="center"
                valueFieldName="Sales"
                valueField="value"
                valueFormatter={formatUsd}
                rowFormatter={(item, label, value) => {
                  const { pipelineMeta } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/users/${pipelineMeta.userId}`}>{item.userName}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </LookupUserNames>
          </AggregateTerms>
        </React.Fragment>
        <React.Fragment>
          <AggregateTerms
            filter={{
              terms: [...props.terms, { type: { value: 'product-purchased' } }],
            }}
            aggField="pipelineMeta.userId"
            termsSize={7}>
            <LookupUserNames>
              <DonutChart title="Top Consumers" titleAlign="center" limit={7} percent labelField="userName" />
            </LookupUserNames>
          </AggregateTerms>
        </React.Fragment>
      </AnalyticsBlock>
    </>
  );
}
