import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'semantic';
import { Component } from 'common/helpers';

import './number.less';

export default class NumberField extends Component {
  onChange = (evt) => {
    let { value } = evt.target;
    if (this.props.currency) {
      value = parseFloat(value);
    } else {
      value = parseInt(value, 10);
    }
    this.props.onChange(evt, {
      ...this.props,
      type: 'number',
      value,
    });
  };

  getPrefix() {
    const { currency } = this.props;
    if (currency === true || currency === 'USD') {
      return '$';
    }
  }

  render() {
    const { label } = this.props;
    return (
      <Form.Input label={label}>
        <div className={this.getElementClass('input')}>
          {this.renderPrefix()}
          <input
            {...this.getProps()}
            type="number"
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            onChange={this.onChange}
          />
        </div>
      </Form.Input>
    );
  }

  renderPrefix() {
    const prefix = this.getPrefix();
    if (prefix) {
      const empty = !this.props.value;
      return <span className={this.getElementClass('prefix', empty ? 'placeholder' : null)}>{prefix}</span>;
    }
  }
}

NumberField.propTypes = {
  currency: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  step: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

NumberField.defaultProps = {
  currency: false,
  step: 'any',
  min: 0,
};
