import React from 'react';
import { request } from 'utils/api';
import { Message } from 'semantic';

const cache = new Map();

function fetchData(url) {
  let promise = cache.get(url);
  if (promise) {
    return promise;
  }

  promise = new Promise((resolve, reject) => {
    return request({
      method: 'GET',
      path: url,
    })
      .then(resolve)
      .catch(reject);
  });
  cache.set(url, promise);
  return promise;
}

const clearCache = () => {
  cache.clear();
};

export default class FetchObject extends React.Component {
  state = {
    data: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetch(this.props);
    window.requestAnimationFrame(clearCache);
  }

  async fetch() {
    const { url, endpoint, id } = this.props;

    if (!id && !url) {
      return;
    }

    try {
      const { data } = await fetchData(url ? url : `/1/${endpoint}/${id}`);
      this.setState({ data, error: null, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const { loading, error, data } = this.state;
    if (!this.props.id && !this.props.url) return this.props?.notFound ? this.props?.notFound() : '-';
    if (loading) return <p>loading</p>;
    if (error) return <Message error content={error.message} />;
    return this.props.children(data);
  }
}
