import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Segment } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { formatUsd } from 'utils/formatting';
import { screen } from 'helpers';
import { withSession } from 'stores';

import ExportButton from 'admin/components/ExportButton';
import { HelpTip, Layout, Breadcrumbs } from 'components';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';

import FulfillmentLabel from '../FulfillmentLabel';
import UserLink from 'admin/components/UserLink';

@screen
@withSession
export default class Orders extends React.Component {
  fetchOrders = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/orders/search',
      body: {
        limit: 50,
        ...params,
      },
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.fetchOrders}>
        {({ items: orders, getSorted, setSort, filters, sort }) => {
          return (
            <React.Fragment>
              <Breadcrumbs link={<Link to="/orders">Orders</Link>} />

              <Layout horizontal center spread>
                <h1>Orders</h1>

                <ExportButton
                  content="Export All"
                  filename="orders.csv"
                  onDataNeeded={async (params) => {
                    return this.fetchOrders({
                      ...filters,
                      ...params,
                      sort,
                    });
                  }}
                />
              </Layout>

              <Segment>
                <Layout horizontal center spread stackable>
                  <Layout horizontal></Layout>
                  <Layout.Group>
                    <Filters.Search name="keyword" placeholder="Enter Reference/ProductId/UserId " />
                  </Layout.Group>
                </Layout>
              </Segment>
              <Status />

              {orders.length !== 0 && (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Order</Table.HeaderCell>
                      <Table.HeaderCell width={3}>User</Table.HeaderCell>
                      <Table.HeaderCell width={2} sorted={getSorted('amount')} onClick={() => setSort('amount')}>
                        Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>Shipping</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Provider</Table.HeaderCell>
                      <Table.HeaderCell width={2}>External Id</Table.HeaderCell>
                      <Table.HeaderCell width={4} sorted={getSorted('createdAt')} onClick={() => setSort('createdAt')}>
                        Created
                        <HelpTip title="Created" text="This is the date and time the order was created." />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orders.map((order) => {
                      return (
                        <Table.Row key={order.id}>
                          <Table.Cell>
                            <Link to={`/orders/${order.id}`}>{order.reference}</Link>
                          </Table.Cell>
                          <Table.Cell>
                            <UserLink user={order.userId} />
                          </Table.Cell>
                          <Table.Cell>{formatUsd(order.amount)}</Table.Cell>
                          <Table.Cell>
                            <FulfillmentLabel order={order} style={{ float: 'right', marginTop: '8px' }} />
                          </Table.Cell>
                          <Table.Cell>{order.fulfillmentProvider || 'N / A'}</Table.Cell>
                          <Table.Cell>{order.fulfillmentOrderRef || 'N / A'}</Table.Cell>
                          <Table.Cell>{formatDateTime(order.createdAt)}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
              <Layout center>
                <Pagination />
              </Layout>
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
