import React from 'react';
import { request } from 'utils/api';

import Menu from './Menu';

import { Table } from 'react-tectonic';
import { formatUsd } from 'utils/formatting';

export default class UserSales extends React.Component {
  state = {};

  componentDidMount() {
    Promise.all([this.fetchSalesbyLink()]);
  }

  fetchSalesbyLink = async () => {
    this.setState({
      salesByLinkStatus: { loading: true },
    });

    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/attributions/sales/links',
        params: {
          user: this.props.user.id,
        },
      });
      this.setState({
        salesByLinkStatus: { success: true },
        salesByLinkData: data,
      });
    } catch (e) {
      this.setState({
        salesByLinkStatus: { error: e },
      });
    }
  };

  render() {
    return (
      <>
        <Menu {...this.props} />

        <Table
          status={this.state.salesByLinkStatus}
          data={this.state.salesByLinkData}
          title="Sales by Link"
          titleAlign="center"
          valueFieldName="Sales"
          valueField="value"
          labelFieldName="Link"
          labelFormatter={(item) => item}
          rowFormatter={(item) => {
            const { object: link } = item;

            return (
              <>
                <td>
                  <a target="_blank" href={link.longURL} rel="noreferrer">
                    {link.longURL}
                  </a>
                </td>
                <td>{formatUsd(item.value)}</td>
              </>
            );
          }}
        />
      </>
    );
  }
}
