import React from 'react';
import { Header, Table, Image } from 'semantic';
import { Link } from 'react-router-dom';
import { urlForImage } from 'utils/uploads';
import { formatDateTime } from 'utils/date';
import EditEventImage from 'admin/modals/EditEventImage';
import FetchObject from 'components/FetchObject';

export default class EventOverview extends React.Component {
  render() {
    const { event } = this.props;
    return (
      <div>
        <Header as="h1">{event.name}</Header>
        <p>{event.description}</p>
        <Header as="h3">Images</Header>
        {event.coverImage && (
          <EditEventImage
            event={event}
            trigger={
              <Image.Group size="large">
                <Image src={urlForImage(event.coverImage)} />
              </Image.Group>
            }
          />
        )}
        <Header as="h3">Details</Header>
        <Table definition>
          <Table.Body>
            {event.parent && (
              <Table.Row>
                <Table.Cell>Parent</Table.Cell>
                <Table.Cell>
                  <FetchObject id={event.parent} endpoint="events">
                    {(parent) => <Link to={`/events/${event.parent}`}>{parent.name}</Link>}
                  </FetchObject>
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Creator Account</Table.Cell>
              <Table.Cell>
                <Link to={`/creator-accounts/${event.creatorAccount?.id}`}>{event.creatorAccount.name}</Link>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>User</Table.Cell>
              <Table.Cell>
                {event.user ? (
                  <Link to={`/users/${event.user}`}>
                    <FetchObject id={event.user} endpoint="users">
                      {(user) => (
                        <Link to={`/users/${user.id}`}>
                          {user?.firstName} {user?.lastName}
                        </Link>
                      )}
                    </FetchObject>
                  </Link>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>
            {event.startsAt && (
              <Table.Row>
                <Table.Cell>Starts At</Table.Cell>
                <Table.Cell>{formatDateTime(event.startsAt)}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{event.type}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>State</Table.Cell>
              <Table.Cell>{event.state}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>{event.status}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(event.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(event.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
