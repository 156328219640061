import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Confirm, Segment } from 'semantic';
import { formatDateTime } from 'utils/date';
import { formatProfileImage } from 'utils/formatting';
import { request } from 'utils/api';
import { Layout, Breadcrumbs, HelpTip } from 'components';
import EditUser from 'admin/modals/EditUser';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';

import { withSession } from 'stores';

import { screen } from 'helpers';
import Protected from 'components/Protected';

import Menu from './Menu';

@screen
@withSession
export default class UserList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...params,
        hasRoles: false,
      },
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({ items: users, getSorted, setSort, reload }) => {
          return (
            <React.Fragment>
              <Breadcrumbs
                link={[
                  <Link key="users" to="/users">
                    Users
                  </Link>,
                ]}
                active="Consumer"
              />
              <Layout horizontal spread center>
                <h1>Consumers</h1>
                <Layout.Group>
                  <Protected endpoint="users" scope="global">
                    <EditUser trigger={<Button primary content="New User" icon="plus" />} onSave={reload} />
                  </Protected>
                </Layout.Group>
              </Layout>
              <Menu />
              <Segment>
                <Layout horizontal center spread stackable>
                  <Layout horizontal></Layout>
                  <Layout.Group>
                    <Filters.Search name="keyword" placeholder="Enter email" />
                  </Layout.Group>
                </Layout>
              </Segment>

              <Status />

              {users.length !== 0 && (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={3} onClick={() => setSort('lastName')} sorted={getSorted('lastName')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={6}>Email</Table.HeaderCell>
                      <Table.HeaderCell onClick={() => setSort('createdAt')} sorted={getSorted('createdAt')}>
                        Joined
                        <HelpTip title="Joined" text="This is the date and time the user was created." />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {users.map((user) => {
                      return (
                        <Table.Row key={user.id}>
                          <Table.Cell>
                            {formatProfileImage(user.profileImage)}{' '}
                            <Link to={`/users/${user.id}`}>
                              {user.firstName} {user.lastName}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>{user.email || '-'}</Table.Cell>
                          <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
                          <Table.Cell textAlign="center">
                            <EditUser
                              user={user}
                              trigger={<Button style={{ marginLeft: '20px' }} basic icon="edit" />}
                              onSave={reload}
                            />

                            <Confirm
                              negative
                              confirmText="Delete"
                              header={`Are you sure you want to remove "${user.firstName} ${user.lastName}"?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request(
                                  this.context.creatorAccount
                                    ? {
                                        method: 'POST',
                                        path: `/1/users/${user.id}/remove`,
                                      }
                                    : {
                                        method: 'DELETE',
                                        path: `/1/users/${user.id}`,
                                      }
                                );
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
              <Layout center>
                <Pagination />
              </Layout>
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
