import React from 'react';
import PropTypes from 'prop-types';
import { Form, Image, Icon, Label, Card } from 'semantic';
import { urlForUpload, urlForImage } from 'utils/uploads';
import Uploads from 'common/components/Uploads';
import './uploads.less';

const ALTERNATE_ICONS = {
  csv: 'excel',
  zip: 'archive',
  model: 'unity brand',
};

export default class UploadsField extends React.Component {
  delete(upload) {
    const { value, onChange } = this.props;
    if (Array.isArray(value)) {
      onChange(
        value.filter((u) => {
          return u !== upload;
        })
      );
    } else {
      onChange(null);
    }
  }

  makePrimary(upload) {
    const { value, onChange } = this.props;
    if (Array.isArray(value)) {
      onChange([
        upload,
        ...value.filter((u) => {
          return u !== upload;
        }),
      ]);
    }
  }

  getTypes() {
    const { types, type } = this.props;
    return types || [type];
  }

  onUpload = (data) => {
    const { value, onChange } = this.props;
    if (Array.isArray(data)) {
      onChange([...value, ...data]);
    } else {
      onChange(data);
    }
  };

  render() {
    const { required, label, value, onError } = this.props;
    return (
      <Form.Field className="formFieldUploads" required={required}>
        {label && <label>{label}</label>}
        {this.renderUploads()}
        <Uploads types={this.getTypes()} single={!Array.isArray(value)} onUpload={this.onUpload} onError={onError} />
      </Form.Field>
    );
  }

  renderUploads() {
    const { type, value } = this.props;
    let uploads = [];
    if (Array.isArray(value)) {
      uploads = value;
    } else if (value) {
      uploads = [value];
    }

    if (uploads.length) {
      return (
        <div style={{ marginBottom: '10px' }}>
          {['image', 'video', 'audio'].includes(type) ? (
            <Card.Group itemsPerRow={4}>
              {uploads.map((upload) => (
                <Card className="formFieldUploads--card" key={upload.id}>
                  {this.renderUpload(upload)}

                  <Icon
                    name="delete"
                    color="blue"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '5px',
                      top: '5px',
                      zIndex: 1,
                    }}
                    onClick={() => this.delete(upload)}
                  />
                  <Icon
                    name="pin"
                    color="blue"
                    className="hoverable"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      left: '5px',
                      top: '5px',
                      zIndex: 1,
                    }}
                    onClick={() => this.makePrimary(upload)}
                  />
                </Card>
              ))}
            </Card.Group>
          ) : (
            <Label.Group color="blue">
              {uploads.map((upload) => (
                <Label key={upload.id}>
                  {this.renderIconForType()}
                  {upload.filename}
                  <Icon name="delete" style={{ cursor: 'pointer' }} onClick={() => this.delete(upload)} />
                </Label>
              ))}
            </Label.Group>
          )}
        </div>
      );
    }
  }

  renderUpload(upload) {
    let type = upload.type;

    if (!type && upload.mimeType) {
      [type] = upload.mimeType.split('/');
    } else {
      type = 'image';
    }

    if (type === 'image') {
      return <Image key={upload.id || upload} src={urlForImage(upload)} />;
    } else if (type === 'video') {
      return <video style={{ width: '100%' }} src={urlForUpload(upload, true)} controls />;
    } else if (type === 'audio') {
      return <audio src={urlForUpload(upload, true)} controls />;
    } else if (type === 'model') {
      return (
        <div
          title="ARKit model file cannot be displayed in the browser"
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon
            name="unity brand"
            size="large"
            style={{ display: 'block', position: 'static', width: '80px', height: '80px', color: 'rgba(0,0,0,.2)' }}
            fitted
          />
        </div>
      );
    }
  }

  renderIconForType() {
    const { type } = this.props;
    return <Icon name={`${ALTERNATE_ICONS[type] || type || ''} file outline`} />;
  }
}

UploadsField.propTypes = {
  type: PropTypes.oneOf(Uploads.TYPES),
  types: PropTypes.arrayOf(PropTypes.oneOf(Uploads.TYPES)),
  onChange: PropTypes.func.isRequired,
};

UploadsField.defaultProps = {
  type: 'image',
};
