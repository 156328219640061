import React from 'react';
import { Dimmer, Loader, Message } from 'semantic';

import AssetsField from './Assets';
import { AdaptiveVideo } from 'common/components/Video';
import { createVodForUpload } from 'utils/api';
import { Spacer } from 'common/components/Spacer';

export default class VideoField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      upload: null,
      loading: false,
    };
  }

  onUpload = async (upload) => {
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const video = await createVodForUpload(upload);
      this.setState({
        loading: false,
      });
      this.props.onChange(video);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };
  render() {
    const { creatorAccount } = this.props;
    const { upload } = this.state;
    return (
      <React.Fragment>
        <AssetsField
          single
          type="video"
          label="Video Preview"
          value={upload}
          renderUploaded={this.renderUploaded}
          onChange={this.onUpload}
          creatorAccount={creatorAccount}
        />
      </React.Fragment>
    );
  }

  renderUploaded = () => {
    const { value } = this.props;
    const { error, loading } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        {loading && (
          <Dimmer inverted active>
            <Loader inverted />
          </Dimmer>
        )}
        {error && <Message error content={error.message} />}
        <AdaptiveVideo controls video={value} style={{ width: '300px' }} />
        <Spacer size="xs" />
      </div>
    );
  };
}
