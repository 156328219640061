import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import SearchDropdown from 'admin/components/SearchDropdown';
import { modal } from 'common/helpers';
import { withSession } from '../../stores';

@modal
@withSession
export default class ApplyDiscounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      events: this.props.event || {},
      discounts: this.props.event.discounts || [],
    };
  }

  fetchDiscounts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/discounts/search',
      body: {
        ...keyword,
        creatorAccountId: this.props.event.creatorAccount.id,
      },
    });
    return data;
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { close } = this.props;

      await request({
        method: 'PATCH',
        path: `/1/events/${this.props.event.id}`,
        body: {
          discounts: this.state.discounts.map((c) => {
            return {
              discountId: c.discountId,
            };
          }),
        },
      });

      close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { loading, error } = this.state;

    return (
      <>
        <Modal.Header>Apply Discount to "{this.props.event.name}"</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form noValidate id="apply-discounts" error={!!error} onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}

              <Form.Field>
                <label>Discounts</label>

                <SearchDropdown
                  fluid
                  multiple
                  getOptionLabel={(item) => {
                    return `${item.name}`;
                  }}
                  value={this.state.discounts.map((c) => c.discountId)}
                  placeholder="Search by name"
                  objectMode={false}
                  onDataNeeded={this.fetchDiscounts}
                  onChange={(e, { value }) => {
                    this.setState({
                      discounts: value.map((discountId) => {
                        return {
                          discountId,
                        };
                      }),
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button primary form="apply-discounts" loading={loading} disabled={loading} content={'Save'} />
        </Modal.Actions>
      </>
    );
  }
}
