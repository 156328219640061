import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Divider, Button, Header, Loader, Message, Dropdown } from 'semantic';
import { request } from 'utils/api';

import { Layout, PageLoader, Breadcrumbs } from 'components';
import EditProduct from 'admin/modals/EditProduct';
import Overview from './Overview';
import Insights from './Insights';
import Details from './Details';
import Images from './Images';
import Events from './Events';
import Variants from './Variants';
import Bundled from './Bundled';
import Menu from './Menu';
import { screen } from 'helpers';
import Actions from '../Actions';

@screen
export default class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      error: null,
      loading: true,
    };
  }

  onSave = () => {
    this.fetchProduct();
  };

  componentDidMount() {
    this.fetchProduct();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchProduct();
    }
  }

  async fetchProduct() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/products/${id}`,
      });
      this.setState({
        product: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, product } = this.state;

    if (loading) {
      return (
        <>
          <Loader active>Loading</Loader>
        </>
      );
    } else if (!product) {
      return (
        <>
          <Header content="Sorry that product wasn't found." />
        </>
      );
    }

    return (
      <>
        <Breadcrumbs link={<Link to="/products">Products</Link>} active={product.name} />
        <Layout horizontal center spread>
          <h1>Product: {product.name}</h1>
          <Layout.Group>
            <Dropdown button basic disabled={!product} text="Actions" style={{ marginTop: '-5px' }}>
              <Dropdown.Menu direction="left">
                {product && <Actions item={product} onReload={this.onSave} />}
              </Dropdown.Menu>
            </Dropdown>

            {product && (
              <EditProduct
                product={product}
                onSave={() => this.fetchProduct()}
                trigger={<Button primary icon="setting" content="Settings" />}
              />
            )}
          </Layout.Group>
        </Layout>

        <Divider hidden />
        {!product ? (
          <PageLoader />
        ) : (
          <React.Fragment>
            <Menu product={product} />
            {product.deletedAt && <Message>This Product has been deleted</Message>}
            <Divider hidden />
            <Switch>
              <Route path="/products/:id/insights" render={(props) => <Insights {...props} product={product} />} />
              <Route exact path="/products/:id/details" render={(props) => <Details {...props} product={product} />} />
              <Route exact path="/products/:id/images" render={(props) => <Images {...props} product={product} />} />
              <Route exact path="/products/:id/events" render={(props) => <Events {...props} product={product} />} />
              <Route
                exact
                path="/products/:id/variants"
                render={(props) => <Variants {...props} onReload={() => this.fetchProduct()} product={product} />}
              />
              <Route
                exact
                path="/products/:id/bundled"
                render={(props) => <Bundled {...props} onReload={() => this.fetchProduct()} product={product} />}
              />
              <Route exact path="/products/:id" render={(props) => <Overview {...props} product={product} />} />
            </Switch>
          </React.Fragment>
        )}
      </>
    );
  }
}
