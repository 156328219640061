import React from 'react';
import { request } from 'utils/api';

import Menu from './Menu';

import { Table, Button, Segment, Loader, Message } from 'semantic';
import { formatDateTime } from 'utils/date';
import { Link } from 'react-router-dom';

const searchParams = new URLSearchParams(window.location.search);

export default class Feed extends React.Component {
  state = {
    items: [],
    hasMore: false,
  };

  componentDidMount() {
    this.fetchFeed();
  }

  fetchFeed = async ({ fromId } = {}) => {
    this.setState({
      loading: true,
    });
    const params = {
      limit: searchParams.get('limit') || 10,
    };
    if (fromId || searchParams.get('fromId')) params.fromId = searchParams.get('fromId') || fromId;
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/users/${this.props.user.id}/feed`,
        params,
      });

      this.setState({
        loading: false,
        hasMore: data.length > 0,
        items: [...this.state.items, ...data],
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  };

  render() {
    return (
      <>
        <Menu {...this.props} />

        {this.state.error && <Message error content={this.state.error.message} />}

        {this.state.loading && (
          <Segment style={{ height: '100px' }}>
            <Loader active>Loading</Loader>
          </Segment>
        )}

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.items.map((c) => {
              return (
                <Table.Row key={c.id}>
                  <Table.Cell>{c.id}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/events/${c.id}`}>{c.name}</Link>
                  </Table.Cell>
                  <Table.Cell>{c.status}</Table.Cell>
                  <Table.Cell>{formatDateTime(c.createdAt)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {this.state.hasMore && this.state.items.length > 0 && (
          <Button
            disabled={this.state.loading}
            loading={this.state.loading}
            onClick={() => this.fetchFeed({ fromId: this.state.items[this.state.items.length - 1].id })}>
            Load more
          </Button>
        )}
      </>
    );
  }
}
