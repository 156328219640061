import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withSession } from 'stores';
import AuthSwitch from './AuthSwitch';

@withSession
export default class Protected extends React.Component {
  hasAccess() {
    const { endpoint, permission, scope, scopeRef } = this.props;
    const { hasAccess } = this.context;
    return hasAccess({
      endpoint,
      permission,
      scope,
      scopeRef,
    });
  }

  render() {
    return <AuthSwitch {...this.props} loggedIn={this.renderLoggedIn} loggedOut={this.renderDenied} />;
  }

  renderLoggedIn = (props) => {
    if (this.hasAccess()) {
      return this.renderAllowed(props);
    } else {
      return this.renderDenied(props);
    }
  };

  renderAllowed = (props) => {
    const { allowed: AllowedComponent } = this.props;
    return <AllowedComponent {...props} />;
  };

  renderDenied = (props) => {
    const { denied: DeniedComponent } = this.props;
    return <DeniedComponent {...props} />;
  };
}

Protected.propTypes = {
  allowed: PropTypes.elementType.isRequired,
  denied: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  permission: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  scopeRef: PropTypes.string,
  ...Route.propTypes,
};

Protected.defaultProps = {
  permission: 'read',
  scope: 'creatorAccount',
  denied: () => <Redirect to="/" />,
};
