import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Table, Item, Loader, Label } from 'semantic';

import NotFound from 'admin/components/NotFound';

import { urlForImage } from 'utils/uploads';
import { formatDateTime } from 'utils/date';
import { formatCurrency } from 'utils/formatting';

import FulfillmentLabel from '../FulfillmentLabel';
import UserLink from 'admin/components/UserLink';

export default class OrderOverview extends React.Component {
  render() {
    const { order, error } = this.props;
    return (
      <React.Fragment>
        {error ? (
          <NotFound message="Sorry that order wasn't found." />
        ) : !order ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <h1>{order.reference}</h1>
            <Header as="h3">
              Consumer <UserLink user={order.userId?.id || order.userId} />
            </Header>
            <Header as="h3">Products</Header>
            <Item.Group divided>
              {order.productItems.map(({ productOrdered, quantity }) => {
                return (
                  <Item key={productOrdered.id}>
                    {productOrdered.coverImage && (
                      <Item.Image size="tiny" src={urlForImage(productOrdered.coverImage, { width: 150 })} />
                    )}
                    <Item.Content>
                      <Item.Header>
                        <Link to={`/products/${productOrdered.id}`}>{productOrdered.name}</Link>
                      </Item.Header>
                      <Item.Meta>
                        {productOrdered.discount && <Label>Discount: {productOrdered.discount.name}</Label>}
                        {formatCurrency(productOrdered.price)}{' '}
                        {productOrdered.originalPrice && (
                          <span style={{ textDecoration: 'line-through' }}>
                            {formatCurrency(productOrdered.originalPrice)}
                          </span>
                        )}
                      </Item.Meta>
                      <Item.Meta>Quantity: {quantity}</Item.Meta>
                    </Item.Content>
                  </Item>
                );
              })}
            </Item.Group>
            <Header as="h3">Shipping Status</Header>
            <FulfillmentLabel order={order} />
            <Header as="h3">Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Consumer</Table.Cell>
                  <Table.Cell>
                    <UserLink user={order.userId?.id || order.userId} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Provider</Table.Cell>
                  <Table.Cell>{order.fulfillmentProvider || 'N / A'}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>External Reference</Table.Cell>
                  <Table.Cell>{order.fulfillmentOrderRef ? `#${order.fulfillmentOrderRef}` : 'N / A'}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>External Id</Table.Cell>
                  <Table.Cell>{order.fulfillmentOrderId || 'N / A'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Status</Table.Cell>
                  <Table.Cell>{order.status}</Table.Cell>
                </Table.Row>
                {order.discountAmount > 0 && (
                  <Table.Row>
                    <Table.Cell>Discount Amount</Table.Cell>
                    <Table.Cell>{formatCurrency(order.discountAmount)}</Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Amount</Table.Cell>
                  <Table.Cell>{formatCurrency(order.amount)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Created At</Table.Cell>
                  <Table.Cell>{formatDateTime(order.createdAt)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Updated At</Table.Cell>
                  <Table.Cell>{formatDateTime(order.updatedAt)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
