import React from 'react';
import { Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'common/helpers';

@modal
export default class ImportProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      isReady: false,
      loading: false,
      error: null,
      countDown: 5,
      hasError: false,
      triggeredImport: false,
    };
  }

  fetchStatus = async (initial) => {
    this.setState({ loading: true });
    try {
      const { data: job } = await request({
        method: 'GET',
        path: `/1/creator-accounts/shopify/import-status`,
        params: { creatorAccount: this.props.creatorAccountId },
      });

      const isOldJob = initial && ['completed', 'failed'].includes(job?.status);
      this.setState({
        job: isOldJob ? null : job,
        loading: false,
      });

      if (['completed', 'failed'].includes(job?.status)) {
        clearInterval(this.interval);
      }

      if (initial && job) {
        this.setStartPulling();
      }
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  componentDidMount() {
    this.fetchStatus(true);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setStartPulling() {
    this.interval = setInterval(() => {
      const countDown = this.state.countDown - 1;
      if (countDown === 0) {
        this.fetchStatus();
        this.setState({ countDown: 5 });
      } else {
        this.setState({ countDown });
      }
    }, 1000);
  }

  onImport = async () => {
    this.setState({ loading: true, error: null });
    try {
      this.interval && clearInterval(this.interval);
      const { data } = await request({
        method: 'POST',
        path: `/1/creator-accounts/shopify/import`,
        body: {
          creatorAccountId: this.props.creatorAccountId,
        },
      });
      this.setState({
        job: data,
        loading: false,
      });
      this.setStartPulling();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { loading, error, job, countDown } = this.state;
    const isRunning = ['queued', 'running'].includes(job?.status);

    return (
      <>
        <Modal.Header>Manage Shopify Account</Modal.Header>
        <Modal.Content>
          <>
            {error && <Message error content={error.message} />}
            {!job && <p>No activate import job was found, click import to trigger a import of products from shopify</p>}
            {isRunning && (
              <Message>
                <Message.Header style={{ marginBottom: '0.5em' }}>
                  {' '}
                  Job status is <code>{this.state.loading ? '...' : job.status}</code> - refreshing status {countDown}{' '}
                  seconds
                </Message.Header>
                <p>
                  Importing normally takes ~2 minute to complete, if you have a lot of products it might take longer.
                </p>
              </Message>
            )}
            {job && job.status === 'completed' && (
              <Message>Job is completed, you can now go to the products page and see the imported products.</Message>
            )}
            {job && job.status === 'failed' && (
              <Message error>
                <Message.Header style={{ marginBottom: '0.5em' }}>Job failed</Message.Header>
                {job.error && <p>{job.error.message}</p>}
              </Message>
            )}
          </>
        </Modal.Content>
        <Modal.Actions>
          {job?.status == 'completed' ? (
            <Button primary content={'Close'} onClick={this.props.close} />
          ) : (
            <Button primary loading={loading} disabled={isRunning} content={'Import'} onClick={this.onImport} />
          )}
        </Modal.Actions>
      </>
    );
  }
}
