import React from 'react';

import FetchObject from 'components/FetchObject';
import { Label } from 'semantic';
import { groupBy } from 'lodash';

export default function Roles({ roles, filterCreatorAccount }) {
  let filteredRoles = [...roles].filter((r) => r.scopeRef);
  const isSuperAdmin = [...roles].find((r) => r.scope == 'global' && r.role == 'superAdmin');

  if (filterCreatorAccount) {
    filteredRoles = roles.filter((r) => r.scopeRef === filterCreatorAccount);
  }

  const creatorAccountsGrouped = groupBy(filteredRoles, 'scopeRef');

  return (
    <div>
      {!filterCreatorAccount && isSuperAdmin && <Label>Super Admin</Label>}
      {Object.keys(creatorAccountsGrouped)
        .filter(Boolean)
        .map((creatorAccountId) => {
          const isOwner =
            creatorAccountsGrouped[creatorAccountId].find((c) => c.role === 'creatorAccountAdmin') &&
            creatorAccountsGrouped[creatorAccountId].find((c) => c.role === 'manager');

          return (
            <div key={creatorAccountId}>
              {!filterCreatorAccount && (
                <FetchObject id={creatorAccountId} endpoint="creator-accounts">
                  {(data) => {
                    return (
                      <Label>
                        "{data?.name}" {isOwner ? 'Owner' : 'Member'}
                      </Label>
                    );
                  }}
                </FetchObject>
              )}

              {filterCreatorAccount && (
                <Label>{filterCreatorAccount && <span>{isOwner ? 'Owner' : 'Member'}</span>}</Label>
              )}
            </div>
          );
        })}
    </div>
  );
}
