import React from 'react';
import { API_URL } from 'utils/env';
import { Segment, Divider, Statistic, Header, Menu } from 'semantic';

import { withSession } from 'stores';
import {
  AggregateTerms,
  Aggregate,
  AggregateTimeSeries,
  SeriesChart,
  MultiSeriesChart,
  DonutChart,
  Table,
  TectonicProvider,
  AggregateStats,
  AggregateCardinality,
} from 'react-tectonic';

import { numberWithCommas, formatUsd, formatCurrency } from 'utils/formatting';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';
import { Link } from 'react-router-dom';
import LookupUserNames from 'admin/components/LookupUserNames';

@withSession
export default class EventInsights extends React.Component {
  state = {
    view: 'all',
    platform: 'all',
  };
  handleTimeRange = (stats) => {
    const { view } = this.state;
    const liveStream = this.props.event.streams.find((c) => c.type === 'live');

    this.setState({ stats });

    if (view === 'all') {
      return {
        from: stats.from,
        to: stats.to,
      };
    } else if (view === 'live') {
      return {
        from: liveStream.startedAt,
        to: liveStream.endedAt,
      };
    } else if (view === 'vod') {
      return {
        from: liveStream.endedAt,
        to: stats.to,
      };
    }
  };
  render() {
    const { event } = this.props;
    const termFilter = [
      this.state.platform !== 'all' && { 'pipelineMeta.origin': this.state.platform },
      {
        eventId: event.id,
      },
    ].filter(Boolean);

    const liveStream = event.streams.find((c) => c.type === 'live') || {};

    function getChapterName(chapterNumber) {
      const [stream] = event.streams;
      if (!stream || !stream.chapters) return `Chapter ${chapterNumber}`;
      const chapter = stream.chapters[parseInt(chapterNumber, 10)];
      if (!chapter) return `Chapter ${chapterNumber}`;
      return chapter.name;
    }

    const canAccessGlobalUsers = this.context.hasAccess({
      endpoint: 'users',
      permission: 'read',
      scope: 'global',
    });

    return (
      <TectonicProvider
        key={this.state.view}
        collection="enriched-events"
        dateField="occurredAt"
        baseUrl={API_URL}
        token={this.context.token}
        primaryColor="#000000"
        getTimeRangeFromCollectionStats={this.handleTimeRange}
        statsFilter={{ terms: [...termFilter] }}
        onRequest={(url, options) => {
          if (this.context.creatorAccount?.id) {
            options.headers['creator-account-id'] = this.context.creatorAccount?.id;
          }
          return fetch(url, options);
        }}>
        <Menu borderless compact>
          <Menu.Item header>Platform</Menu.Item>
          <Menu.Item active={this.state.platform === 'all'} onClick={() => this.setState({ platform: 'all' })}>
            All
          </Menu.Item>
          <Menu.Item active={this.state.platform === 'web'} onClick={() => this.setState({ platform: 'web' })}>
            Web
          </Menu.Item>
          <Menu.Item active={this.state.platform === 'native'} onClick={() => this.setState({ platform: 'native' })}>
            Native (IOS)
          </Menu.Item>
        </Menu>

        {event.type === 'live' && (
          <Menu borderless compact style={{ margin: '1em' }}>
            <Menu.Item header>Event Life Cycle</Menu.Item>
            <Menu.Item active={this.state.view === 'all'} onClick={() => this.setState({ view: 'all' })}>
              All Data
            </Menu.Item>
            <Menu.Item
              disabled={!liveStream.endedAt}
              active={this.state.view === 'vod'}
              onClick={() => this.setState({ view: 'vod' })}>
              VOD Period
            </Menu.Item>
            <Menu.Item
              disabled={!liveStream.endedAt}
              active={this.state.view === 'live'}
              onClick={() => this.setState({ view: 'live' })}>
              Live Period
            </Menu.Item>
            {/*
            <Menu.Item position="right">
              <TimeRangePicker
                align="right"
                renderButton={(label, onClick) => (
                  <Button compact onClick={onClick}>
                    <Icon name="calendar" /> {label}
                  </Button>
                )}
              />
            </Menu.Item>
            */}
          </Menu>
        )}

        <Segment basic>
          <Divider hidden />

          <Statistic.Group widths={4}>
            <AggregateCardinality
              fields={['clientSessionId']}
              filter={{
                terms: [...termFilter, { type: { value: 'event-entered' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(data['clientSessionId']) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Viewers</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>

            <AggregateStats
              fields={['product.price']}
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? `${formatCurrency(data['product.price'].sum)}` : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Total Sales</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>
            {/*
            <AggregateStats
              fields={['secondsSinceEntry']}
              filter={{
                terms: [...termFilter, { type: { value: 'event-exited' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(Math.round(data.secondsSinceEntry.avg)) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Avg. Seconds</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>
            */}
            <AggregateStats
              fields={['occurredAt']}
              filter={{
                terms: [...termFilter, { type: { value: 'event-entered' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>{status.success ? numberWithCommas(data.occurredAt.count) : '...'}</Statistic.Value>
                  <Statistic.Label>Playbacks</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>

            <AggregateStats
              fields={['occurredAt']}
              filter={{
                terms: [...termFilter, { type: { value: 'product-added-to-cart' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>{status.success ? numberWithCommas(data.occurredAt.count) : '...'}</Statistic.Value>
                  <Statistic.Label>Added to cart</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>
          </Statistic.Group>
          <Divider hidden />
        </Segment>
        <AnalyticsBlock>
          <Header as="h4" content="Playbacks Over Time" textAlign="center" />
          <AggregateTimeSeries
            operation="count"
            dateField="occurredAt"
            filter={{
              terms: [...termFilter, { type: { value: 'event-entered' } }],
            }}>
            <SeriesChart
              enabledControls={[]}
              height={350}
              chartType="area"
              valueField="count"
              valueFieldLabel={'Number of Playbacks'}
            />
          </AggregateTimeSeries>
        </AnalyticsBlock>
        {/*
        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <Header as="h4" content="Most Exited Chapters" textAlign="center" />
            <Divider hidden />
            <AggregateTerms
              includeTopHit
              aggField="eventMeta.chapter"
              filter={{
                terms: [...termFilter, { type: { value: 'event-exited' } }],
              }}
              termsSize={10}>
              <Table
                labelFormatter={(value) => getChapterName(value)}
                valueFieldName="Occurrences"
                valueField="count"
              />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <Header as="h4" content="Most Entered Chapters" textAlign="center" />
            <Divider hidden />
            <AggregateTerms
              includeTopHit
              aggField="eventMeta.chapter"
              filter={{
                terms: [...termFilter, { type: { value: 'event-entered' } }],
              }}
              termsSize={10}>
              <Table
                labelFormatter={(value) => getChapterName(value)}
                valueFieldName="Occurrences"
                valueField="count"
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
        */}
        <AnalyticsBlock>
          <Header as="h4" content="Event View, Buy Action and Purchase Activity" textAlign="center" />
          <Aggregate
            type="time-series"
            requests={[
              {
                operation: 'count',
                dateField: 'occurredAt',
                filter: {
                  terms: [...termFilter, { type: { value: 'product-viewed' } }],
                },
              },
              {
                operation: 'count',
                dateField: 'occurredAt',
                filter: {
                  terms: [...termFilter, { type: { value: 'buyflow-entered' } }],
                },
              },
              {
                operation: 'count',
                dateField: 'occurredAt',
                filter: {
                  terms: [...termFilter, { type: { value: 'product-purchased' } }],
                },
              },
            ]}>
            <MultiSeriesChart
              enabledControls={[]}
              height={250}
              chartType="area"
              stacked
              valueField="count"
              labels={['Viewed Product', 'Buy Action', 'Purchased Product']}
            />
          </Aggregate>
        </AnalyticsBlock>
        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <Header as="h4" content="Products by Purchase" textAlign="center" />
            <AggregateTerms
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}
              aggField="product.name"
              termsSize={10}>
              <DonutChart limit={5} percent enabledControls={[]} />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="product.name"
              operation="sum"
              field="product.price"
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}
              termsSize={10}>
              <Table
                title="Products by Sales"
                titleAlign="center"
                enabledControls={[]}
                valueFieldName="Sales"
                valueField="value"
                valueFormatter={formatUsd}
                rowFormatter={(item, label, value) => {
                  const { productId } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/products/${productId}`}>{label}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}
              aggField="eventMeta.chapter"
              termsSize={10}>
              <DonutChart
                labelFormatter={(value) => getChapterName(value)}
                titleAlign="center"
                title="Chapters by Purchase"
                limit={5}
                percent
              />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="eventMeta.chapter"
              operation="sum"
              field="product.price"
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}
              termsSize={10}>
              <Table
                title="Chapters by Revenue"
                titleAlign="center"
                labelFormatter={(value) => getChapterName(value)}
                valueFieldName="Revenue"
                valueField="value"
                valueFormatter={formatUsd}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>

        <React.Fragment>
          <AggregateTerms
            includeTopHit
            aggField="eventMeta.chapter"
            operation="count"
            filter={{
              terms: [...termFilter, { type: { value: 'buyflow-entered' } }],
            }}
            termsSize={10}>
            <Table
              title="Chapters by Checkouts started"
              labelFormatter={(value) => getChapterName(value)}
              titleAlign="center"
              valueField="count"
              valueFieldName="# Checkout started"
            />
          </AggregateTerms>
        </React.Fragment>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="pipelineMeta.userId"
              operation="sum"
              field="product.price"
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}
              termsSize={10}>
              <LookupUserNames>
                <Table
                  title="Consumers by Sales"
                  titleAlign="center"
                  valueFieldName="Sales"
                  valueField="value"
                  valueFormatter={formatUsd}
                  rowFormatter={(item, label, value) => {
                    const { pipelineMeta } = item.topHit._source;
                    return (
                      <>
                        <td>
                          <Link to={`/users/${pipelineMeta.userId}`}>{item.userName}</Link>
                        </td>
                        <td>{value}</td>
                      </>
                    );
                  }}
                />
              </LookupUserNames>
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: [...termFilter, { type: { value: 'product-purchased' } }],
              }}
              aggField="pipelineMeta.userId"
              termsSize={10}>
              <LookupUserNames>
                <DonutChart title="Top Consumers" titleAlign="center" limit={7} percent labelField="userName" />
              </LookupUserNames>
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
      </TectonicProvider>
    );
  }
}
