import React from 'react';
import { Image, Form, Modal, Message, Menu, Divider, Button, Icon, Header, Label } from 'semantic';
import { request } from 'utils/api';
import { Component } from 'common/helpers';
import { urlForImage } from 'utils/uploads';
import { AdaptiveVideo } from 'common/components/Video';
import { Layout } from 'common/components/Layout';
import { Confirm } from 'common/components/Semantic';
import { Track } from 'admin/components/Track';
import { TrackRuler } from 'admin/components/TrackRuler';
import SearchDropdown from 'common/components/SearchDropdown';
import EditStream from 'admin/modals/EditStream';
import UploadsField from 'admin/components/form-fields/Uploads';
import { getEventProduct } from 'utils/event';
import { getTimelineTrackForTime } from 'utils/timeline';
import { PhoneFrame } from 'common/components/PhoneFrame';
import VideoRefresh from './VideoRefresh';
import FetchObject from 'components/FetchObject';

import './videos.less';

const ZOOM_MAX = 10;
const ZOOM_MIN = 1;

export default class EventVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 1,
      products: [],
      duration: null,
      currentTime: 0,
      loading: false,
      error: null,
      message: null,
      showProductModal: false,
      playheadDragging: false,
      newProduct: null,
      newVariant: null,
      newProductOptions: null,
      timelineType: 'products',
      focusedTrack: null,
      focusedIndex: 0,
      chapterName: '',
      chapterImage: '',
      streams: props.event.streams || [],
      editStreamVisible: false,
    };
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.getProducts();
  }

  componentDidUpdate(lastProps) {
    const { event } = this.props;
    if (event !== lastProps.event) {
      this.setState({
        streams: event.streams || [],
      });
    }
  }

  fetchVariants = async (filter) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        ...filter,
        parent: this.state.newProduct.id,
      },
    });

    return data;
  };

  fetchProductOptions = async (filter) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/product-options/search',
      body: {
        ...filter,
      },
    });

    return data;
  };

  getProductOptions(variant) {
    if (!variant) return [];
    return variant.productOptions
      .map((c) => c.item)
      .map((c) => {
        return {
          text: `${c.name} - ${c.value}`,
          value: c.id,
        };
      });
  }

  // stream/timeline helpers

  getFilteredStreams() {
    return this.state.streams.filter((s) => s.type !== 'live');
  }

  getCurrentStream() {
    return this.getFilteredStreams()[this.state.focusedIndex];
  }

  getTimeline(timelineType) {
    return this.getCurrentStream()?.[timelineType];
  }

  getCurrentTimeline() {
    return this.getTimeline(this.state.timelineType);
  }

  getChapterTimeline() {
    return this.getTimeline('chapters');
  }

  getProductTimeline() {
    return this.getTimeline('products');
  }

  getCurrentTrack(timeline) {
    const { currentTime } = this.state;
    return getTimelineTrackForTime(timeline, currentTime);
  }

  getTimelineDuration(videoDuration) {
    const productTimeline = this.getProductTimeline();
    return Math.max(videoDuration, ...productTimeline.map((track) => track.end));
  }

  addProductToTimeline(product, productOptionsIds) {
    const stream = this.getCurrentStream();

    stream.products = stream.products.concat({
      productId: product.id,
      ...this.getNextProductTime(stream),
      productOptionIds: productOptionsIds,
    });
    this.setState({
      streams: this.state.streams,
    });
  }

  getProducts = () => {
    const { event } = this.props;
    if (event.isBundle) {
      return event.products[0].subProducts;
    } else {
      return event.products;
    }
  };

  onDurationChange = (evt) => {
    const { duration } = evt.target;
    this.setState({
      duration,
    });
  };

  onSeeking = (evt) => {
    const { currentTime } = evt.target;
    this.setState({
      currentTime,
    });
  };

  onTimeUpdate = (evt) => {
    // TODO: rewire this to have less state
    const { playheadDragging } = this.state;
    if (!playheadDragging) {
      const { currentTime } = evt.target;
      this.setState({
        currentTime,
      });
    }
  };

  onZoomOutClick = () => {
    this.setZoom(this.state.zoom / 2);
  };

  onZoomInClick = () => {
    this.setZoom(this.state.zoom * 2);
  };

  onZoomResetClick = () => {
    this.setZoom(1);
  };

  onKeyDown = (evt) => {
    const { showProductModal, editStreamVisible, focusedTrack } = this.state;
    // Bail if modal is showing
    if (showProductModal || editStreamVisible || focusedTrack) {
      return;
    }
    if (evt.key === ' ' || evt.key === 'k') {
      this.videoRef.current.toggle();
      evt.preventDefault();
    } else if (evt.key === 'ArrowLeft' || evt.key === 'j') {
      this.videoRef.current.jump(-10);
      evt.preventDefault();
    } else if (evt.key === 'ArrowRight' || evt.key === 'l') {
      this.videoRef.current.jump(10);
      evt.preventDefault();
    }
  };

  onPlayheadDragStart = () => {
    this.setState({
      playheadDragging: true,
    });
  };

  onPlayheadDragMove = (time) => {
    this.videoRef.current.setTime(time);
    this.setState({
      currentTime: time,
    });
  };

  onPlayheadDragEnd = () => {
    this.setState({
      playheadDragging: false,
    });
  };

  onAddChapterClick = () => {
    const stream = this.getCurrentStream();
    const track = {
      name: 'New Chapter',
      start: 0,
      end: Math.min(this.state.duration, 10),
    };
    stream.chapters = stream.chapters.concat(track);
    this.setState({
      streams: this.state.streams,
    });
  };

  onAddProductSubmit = () => {
    const { newProduct, newProductOptions } = this.state;
    if (newProduct) {
      this.addProductToTimeline(newProduct, newProductOptions);
    }
    this.setState({
      showProductModal: false,
      newProduct: null,
      newProductOptions: null,
      newVariant: null,
    });
  };

  onEditStreamOpen = () => {
    this.setState({
      editStreamVisible: true,
    });
  };

  onEditStreamClose = () => {
    this.setState({
      editStreamVisible: false,
    });
  };

  onSaveClick = () => {
    this.saveStreams(this.state.streams);
  };

  async saveStreams(streams) {
    try {
      this.setState({
        message: null,
        loading: true,
      });
      const { event } = this.props;

      await this.props.saveStreams(event, streams);
      this.props.fetchEvent();
      this.setState({
        message: 'Saved!',
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  setZoom(zoom) {
    zoom = Math.min(zoom, ZOOM_MAX);
    zoom = Math.max(zoom, ZOOM_MIN);
    this.setState({
      zoom,
    });
    // TODO: do this smarter
    setTimeout(() => {
      this.forceUpdate();
    });
  }

  setTrackTime(track, start, end) {
    track.start = start;
    track.end = end;
    this.setState({
      streams: this.state.streams,
    });
  }

  // Modals

  onTrackModalClose = () => {
    this.setState({
      focusedTrack: null,
      chapterImage: '',
    });
  };

  onChapterSubmit = () => {
    const { focusedTrack, chapterName, chapterImage } = this.state;
    focusedTrack.name = chapterName;
    focusedTrack.image = chapterImage;
    this.setState({
      focusedTrack: null,
      chapterName: '',
      chapterImage: '',
    });
  };

  removeProductTrack = (track) => {
    const stream = this.getCurrentStream();
    const products = this.getProductTimeline();
    stream.products = products.filter((t) => t !== track);
    this.setState({
      focusedTrack: null,
    });
  };

  removeChapterTrack = (track) => {
    const stream = this.getCurrentStream();
    const chapters = this.getChapterTimeline();
    stream.chapters = chapters.filter((t) => t !== track);
    this.setState({
      focusedTrack: null,
    });
  };

  removeStream = async (stream) => {
    let { streams, focusedIndex } = this.state;
    streams = streams.filter((v) => {
      return v !== stream;
    });
    focusedIndex = Math.min(focusedIndex, streams.length - 1);
    await this.saveStreams(streams);
    this.props.fetchEvent();
    this.setState({
      streams,
      focusedIndex,
    });
  };

  updateStream = async (data) => {
    let { streams, focusedIndex } = this.state;
    streams = [...streams];
    streams[focusedIndex] = data;
    await this.saveStreams(streams);
    this.setState({
      streams,
    });
  };

  addStream = async (data) => {
    let { streams, focusedIndex } = this.state;
    const newStream = {
      chapters: [],
      products: [],
      ...data,
    };
    streams = [...streams, newStream];
    focusedIndex = streams.filter((stream) => stream.type !== 'live').length - 1;
    await this.saveStreams(streams);
    this.setState({
      streams,
      focusedIndex,
    });
  };

  // Unadded product helpers

  hasUnaddedProducts(stream) {
    const { products } = this.props.event;
    return products.some((product) => {
      return !this.productIsOnTimeline(product, stream);
    });
  }

  addUnaddedProducts(stream) {
    const { products } = this.props.event;
    const filteredProducts = products.filter((product) => {
      return !this.productIsOnTimeline(product, stream);
    });
    for (let product of filteredProducts) {
      stream.products.push({
        productId: product.id,
        ...this.getNextProductTime(stream),
      });
    }
    this.setState({
      timelineType: 'products',
    });
  }

  productIsOnTimeline(product, stream) {
    return stream.products.some(({ productId }) => {
      return productId === product.id;
    });
  }

  // Duration helpers

  getNextProductTime(stream) {
    const duration = this.getTimelineDuration(this.state.duration);
    const t = this.getAverageProductDuration(duration);
    let start = stream.products.reduce((t, track) => {
      return Math.max(t, track.end);
    }, 0);
    // Clamp start time from 0 to 4s minus end of timeline to ensure
    // it isn't generated to be a tiny interval.
    start = Math.max(0, Math.min(start, duration - 4));
    const end = Math.min(duration, start + t);
    return { start, end };
  }

  getAverageProductDuration(duration) {
    const { products } = this.props.event;
    return duration / products.length;
  }

  // Chapter generation helpers

  generateChapters(stream) {
    const { products = [], chapters = [] } = stream;
    const newChapters = [...chapters];
    for (let productTrack of products) {
      const product = getEventProduct(this.props.event, productTrack.productId);
      if (!this.hasProductChapter(stream, product, productTrack)) {
        newChapters.push({
          name: product.name,
          image: product.coverImage,
          start: productTrack.start,
          end: productTrack.end,
        });
      }
    }
    newChapters.sort((a, b) => {
      return a.start - b.start;
    });
    stream.chapters = newChapters;
    this.setState({
      timelineType: 'chapters',
    });
  }

  hasProductChapter(stream, product, productTrack) {
    const { start: pStart, end: pEnd } = productTrack;
    return stream.chapters.some(({ name, start: cStart, end: cEnd }) => {
      return name === product.name && cStart === pStart && cEnd === pEnd;
    });
  }

  render() {
    const streams = this.getFilteredStreams();

    const { timelineType, loading, error, message } = this.state;
    const stream = this.getCurrentStream();

    return (
      <div tabIndex="0" onKeyDown={this.onKeyDown} className="event-videos">
        {error && <Message negative content={error.message} />}
        {message && <Message positive content={message} />}
        {this.renderMenu(streams)}
        <div className={'event-videos__stage'}>
          {this.renderCurrentChapter()}
          {this.renderCurrentProduct()}
          <PhoneFrame>
            <VideoRefresh video={stream.video} event={this.props.event} reload={() => this.props.fetchEvent()}>
              <AdaptiveVideo
                controls
                ref={this.videoRef}
                video={stream.video}
                onSeeking={this.onSeeking}
                onTimeUpdate={this.onTimeUpdate}
                onDurationChange={this.onDurationChange}
              />
            </VideoRefresh>
          </PhoneFrame>
        </div>
        <Divider hidden />
        <Layout horizontal center spread>
          <Layout.Group>
            <Menu secondary>
              <Menu.Item
                name="Products"
                active={timelineType === 'products'}
                onClick={() => this.setState({ timelineType: 'products' })}
              />
              <Menu.Item
                name="Chapters"
                active={timelineType === 'chapters'}
                onClick={() => this.setState({ timelineType: 'chapters' })}
              />
            </Menu>
          </Layout.Group>
          <Layout.Group>{this.renderZoomButtons()}</Layout.Group>
        </Layout>
        <Divider hidden />
        {this.renderTimeline()}
        <div>
          {timelineType === 'products' ? (
            <React.Fragment>
              <Button primary content="Add Product" onClick={() => this.setState({ showProductModal: true })} />
              {this.hasUnaddedProducts(stream) && (
                <Confirm
                  negative
                  confirmText="Add"
                  header="Add Product Tracks"
                  content={
                    <React.Fragment>
                      <p>
                        This will add tracks for products that have not been added to the timeline yet. After adding,
                        you can go over the tracks and adjust to the video.
                      </p>
                    </React.Fragment>
                  }
                  trigger={<Button primary content="Add Multiple" />}
                  onConfirm={() => this.addUnaddedProducts(stream)}
                />
              )}
            </React.Fragment>
          ) : (
            <Button primary onClick={this.onAddChapterClick} content="Add Chapter" />
          )}
        </div>
        <Divider hidden />
        <Layout horizontal center right>
          <Confirm
            negative
            confirmText="Delete"
            header="Delete this video?"
            content="References to chapters, products and the video file will be deleted."
            trigger={<Icon color="red" name="trash" title="Remove Video" className={'event-videos__remove-button'} />}
            onConfirm={() => this.removeStream(stream)}
          />
          <Layout.Spacer size="small" />
          {stream.products.length > 0 && (
            <React.Fragment>
              <Confirm
                negative
                confirmText="Generate"
                header="Generate Chapters"
                content={
                  <React.Fragment>
                    <p>
                      This will generate chapters for all products if they don't exist. It will not remove manually
                      added chapters. Chapter images will be taken from product images but can be manually overwritten.
                    </p>
                    <p>After generating, confirm that all chapters look ok and are not overlapping and save.</p>
                  </React.Fragment>
                }
                trigger={
                  <Icon
                    color="grey"
                    name="plug"
                    title="Generate Chapters"
                    className={'event-videos__generate-button'}
                  />
                }
                onConfirm={() => this.generateChapters(stream)}
              />
              <Layout.Spacer size="small" />
            </React.Fragment>
          )}
          {stream.video.originalVideoURL && (
            <>
              <a style={{ display: 'flex' }} href={stream.video.originalVideoURL} target="_blank" rel="noreferrer">
                <Icon basic name="download" />
              </a>
              <Layout.Spacer size="small" />
            </>
          )}

          <EditStream
            stream={stream}
            onOpen={this.onEditStreamOpen}
            onClose={this.onEditStreamClose}
            onComplete={this.updateStream}
            trigger={<Icon color="grey" name="pencil-alt" title="Edit Video" className={'event-videos__edit-button'} />}
            creatorAccount={this.props.event.creatorAccount?.id}
            eventType={this.props.event.type}
          />
          <Layout.Spacer size="small" />
          <Button primary loading={loading} disabled={loading} onClick={this.onSaveClick} content="Save" />
        </Layout>
        {this.renderProductModal()}
        {this.renderTrackModal()}
      </div>
    );
  }

  renderMenu(streams) {
    const { focusedIndex } = this.state;
    return (
      <React.Fragment>
        <Menu secondary>
          {streams.map((stream, i) => {
            let label = stream.name || stream.type;
            if (this.props.event.type === 'live' && stream.type === 'main') {
              label = 'Scheduled Live';
            }

            return (
              <Menu.Item key={i} active={i === focusedIndex} onClick={() => this.setState({ focusedIndex: i })}>
                {label}
              </Menu.Item>
            );
          })}
          <Menu.Menu position="right">
            <Menu.Item>
              <EditStream
                onOpen={this.onEditStreamOpen}
                onClose={this.onEditStreamClose}
                onComplete={this.addStream}
                trigger={<Button primary icon="plus" content="Add Video" />}
                creatorAccount={this.props.event.creatorAccount?.id}
                eventType={this.props.event.type}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </React.Fragment>
    );
  }

  renderCurrentChapter() {
    const track = this.getCurrentTrack(this.getChapterTimeline());
    if (track) {
      return <div className={'event-videos__current-chapter'}>{track.name}</div>;
    }
  }

  renderCurrentProduct() {
    const track = this.getCurrentTrack(this.getProductTimeline());
    if (track) {
      let product = getEventProduct(this.props.event, track.productId);
      let image = product?.coverImage;
      if (product.subProducts && track.productOptionIds) {
        product = product.subProducts.find((subProduct) =>
          track.productOptionIds.every((id) => subProduct.productOptions.find((obj) => obj.item.id === id))
        );
        image = product?.coverImage;
      }

      if (image) {
        return (
          <div className={'event-videos__current-product'}>
            <Image className={'event-videos__current-product-image'} src={urlForImage(image)} />
          </div>
        );
      }
    }
  }

  renderZoomButtons() {
    const timeline = this.getCurrentTimeline();
    if (timeline.length) {
      return (
        <Button.Group icon>
          <Button onClick={this.onZoomOutClick}>
            <Icon name="search-minus" />
          </Button>
          <Button onClick={this.onZoomResetClick}>
            <Icon name="search" />
          </Button>
          <Button onClick={this.onZoomInClick}>
            <Icon name="search-plus" />
          </Button>
        </Button.Group>
      );
    }
  }

  renderTimeline() {
    const { zoom } = this.state;
    const timeline = this.getCurrentTimeline();
    if (timeline.length) {
      return (
        <React.Fragment>
          <div className={'event-videos__timeline-container'}>
            <div style={{ width: `${zoom * 100}%` }} className={'event-videos__timeline'}>
              {this.renderRuler()}
              {this.renderTracks()}
            </div>
          </div>
          <Divider hidden />
        </React.Fragment>
      );
    }
  }

  renderRuler() {
    const { duration, currentTime } = this.state;
    if (duration != null) {
      return (
        <TrackRuler
          duration={this.getTimelineDuration(duration)}
          currentTime={currentTime}
          onPlayheadDragStart={this.onPlayheadDragStart}
          onPlayheadDragMove={this.onPlayheadDragMove}
          onPlayheadDragEnd={this.onPlayheadDragEnd}
        />
      );
    }
  }

  renderTracks() {
    const { duration } = this.state;
    const timeline = this.getCurrentTimeline();
    if (duration != null) {
      const timelineDuration = this.getTimelineDuration(duration);
      return timeline.map((track, i) => {
        let { start, end, name, image, productId } = track;
        const product = getEventProduct(this.props.event, productId);
        name = product?.name || name;
        const key = [name, start, end, i].join('-');
        return (
          <Track
            key={key}
            name={product ? product.name : name}
            duration={timelineDuration}
            onNameClick={() => {
              this.setState({
                focusedTrack: track,
                chapterName: name,
                chapterImage: image,
              });
            }}
            onChange={({ start, end }) => this.setTrackTime(track, start, end)}
            start={start}
            end={end}
          />
        );
      });
    }
  }

  renderProductModal() {
    const { showProductModal, newVariant, newProduct, newProductOptions } = this.state;
    return (
      <Modal closeIcon open={!!showProductModal} onClose={() => this.setState({ showProductModal: false })}>
        <Modal.Header>Add Product</Modal.Header>
        <Modal.Content>
          <Form id="products" onSubmit={this.onAddProductSubmit}>
            <Form.Field>
              <label>Product</label>
              <SearchDropdown
                fluid
                value={newProduct}
                onChange={(evt, { value }) => {
                  this.setState({
                    newProduct: value,
                  });
                }}
                onDataNeeded={this.getProducts}
              />
            </Form.Field>

            {newProduct?.type === 'base' && (
              <>
                <Header size="small">Prefill Options - Optional</Header>
                <Form.Field>
                  <label>Select Variant</label>
                  <SearchDropdown
                    key={newProduct?.id}
                    fluid
                    value={newVariant}
                    onChange={(evt, { value }) => {
                      this.setState({
                        newVariant: value,
                      });
                    }}
                    onDataNeeded={this.fetchVariants}
                  />
                </Form.Field>

                <Form.Select
                  label="Select Prefilled Options"
                  fluid
                  multiple
                  disabled={!newVariant}
                  value={newProductOptions || []}
                  onChange={(evt, { value }) => {
                    this.setState({
                      newProductOptions: value,
                    });
                  }}
                  options={this.getProductOptions(newVariant)}
                />
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary form="products" content="Add" />
        </Modal.Actions>
      </Modal>
    );
  }

  renderTrackModal() {
    const { focusedTrack: track } = this.state;
    return (
      <Modal closeIcon open={!!track} onClose={this.onTrackModalClose}>
        {this.renderTrackModalContent(track)}
      </Modal>
    );
  }

  renderTrackModalContent(track) {
    if (!track) {
      return null;
    }
    const { productId, productOptionIds = [] } = track;
    if (productId) {
      const product = getEventProduct(this.props.event, productId);
      return (
        <React.Fragment>
          <Modal.Header>{product.name}</Modal.Header>
          <Modal.Content>
            {product.coverImage && (
              <Image
                style={{
                  height: '80px',
                  margin: '0 auto',
                  display: 'block',
                  objectFit: 'contain',
                }}
                src={urlForImage(product.coverImage)}
              />
            )}
            {productOptionIds.map((id) => (
              <FetchObject key={id} id={id} endpoint="product-options">
                {(data) => {
                  return (
                    <Label>
                      {data.name} - {data.value}
                    </Label>
                  );
                }}
              </FetchObject>
            ))}
          </Modal.Content>
          <Modal.Actions>
            <Button negative content="Remove from Timeline" onClick={() => this.removeProductTrack(track)} />
          </Modal.Actions>
        </React.Fragment>
      );
    } else {
      const { chapterName, chapterImage } = this.state;
      return (
        <React.Fragment>
          <Modal.Header>{track.name}</Modal.Header>
          <Modal.Content>
            <Form id="chapter" onSubmit={this.onChapterSubmit}>
              <Form.Input
                label="Chapter Name"
                value={chapterName}
                onChange={(evt, { value }) => this.setState({ chapterName: value })}
              />
              <UploadsField
                single
                label="Image"
                value={chapterImage || null}
                onChange={(upload) => this.setState({ chapterImage: upload })}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Layout horizontal center spread>
              <Button negative onClick={() => this.removeChapterTrack(track)} content="Remove from Timeline" />
              <Button primary form="chapter" content="Save" />
            </Layout>
          </Modal.Actions>
        </React.Fragment>
      );
    }
  }
}
