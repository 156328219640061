import React from 'react';
import { Menu, Button } from 'semantic';
import { NavLink, Link } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import EditEventStack from 'admin/modals/EditEventStack';

export default ({ eventStack, updateEventStack }) => {
  return (
    <>
      <Breadcrumbs link={<Link to="/event-stacks">Stacks</Link>} active={eventStack?.name} />
      <Layout horizontal center spread>
        <h1>{eventStack?.name}</h1>

        <Layout.Group>
          {eventStack && (
            <EditEventStack
              eventStack={eventStack}
              onSave={updateEventStack}
              trigger={<Button primary icon="setting" content="Settings" />}
            />
          )}
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item exact name="Overview" to={`/event-stacks/${eventStack?.id}`} as={NavLink} />
        <Menu.Item exact name="Events" to={`/event-stacks/${eventStack?.id}/events`} as={NavLink} />
      </Menu>
    </>
  );
};
