import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic';
import { DraggableBox } from 'common/components/Draggable';
import { Component } from 'common/helpers';
import { urlForImage } from 'utils/uploads';
import { request } from 'utils/api';
import { Rect } from 'utils/math';
import AssetPicker from 'common/components/AssetPicker';
import Uploads from 'common/components/Uploads';

import './cropped-image.less';

export default class CroppedImageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props.image ? 'preview' : 'upload',
      img: null,
      imgRect: null,
      cropRect: null,
      hint: null,
    };
    this.stageRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  // Lifecycle

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(lastProps = {}, lastState = {}) {
    const { image } = this.props;
    const { mode, img, cropRect, imgRect } = this.state;
    if (image !== lastProps.image) {
      if (image) {
        this.loadImage(urlForImage(image));
      } else {
        this.unloadImage();
      }
    }
    if (mode !== lastState.mode) {
      this.setState({
        hint: '',
      });
      if (mode === 'crop' && !cropRect) {
        this.setState({
          cropRect: this.getDefaultCropRect(),
        });
      }
    }
    if (img !== lastState.img || mode !== lastState.mode || imgRect !== lastState.imgRect) {
      this.drawImage(img, mode === 'crop' ? null : imgRect);
    }
  }

  // Events

  onCropClick = () => {
    this.lastCropRect = this.state.cropRect;
    this.setState({
      mode: 'crop',
    });
  };

  onUploadClick = () => {
    this.setState({
      mode: 'upload',
    });
  };

  onPreviewClick = () => {
    this.setState({
      mode: 'preview',
    });
  };

  onChooseClick = (evt) => {
    evt.stopPropagation();
    this.setState({
      mode: 'select',
    });
  };

  onAcceptClick = () => {
    const { cropRect } = this.state;
    this.setState({
      mode: 'preview',
      imgRect: this.getImgRect(cropRect),
    });
    this.lastCropRect = null;
  };

  onCancelClick = () => {
    this.setState({
      mode: 'preview',
      cropRect: this.lastCropRect,
    });
    this.lastCropRect = null;
  };

  onBoxChange = (rect) => {
    this.setState({
      cropRect: rect,
    });
  };

  onDrop = async (file) => {
    this.filename = file.name;
    await this.setMode('preview');
    const [base] = file.type.split('/');
    if (base !== 'image') {
      this.props.onError(new IncompatibleFileError(file));
    } else {
      await this.loadImage(URL.createObjectURL(file));
    }
  };

  onAssetClick = async (asset) => {
    this.filename = asset.name;
    await this.setMode('preview');
    await this.loadImage(urlForImage(asset.upload));
  };

  onUploadClick = () => {
    this.setMode('upload');
  };

  // Handling upload objects

  async createUpload(file) {
    const { data } = await request({
      method: 'POST',
      path: '/1/uploads',
      files: [file],
    });
    return data;
  }

  async createImageUpload() {
    const blob = await this.exportImage();
    return await this.createUpload(blob);
  }

  getImage() {
    return this.isTouched() ? this.createImageUpload() : this.props.image;
  }

  isTouched() {
    const { image } = this.props;
    const { img, imgRect } = this.state;
    if (!img) {
      // No image loaded.
      return false;
    } else if (imgRect) {
      // Crop area is defined.
      return true;
    } else {
      // Touched if no original upload or new image was uploaded.
      return !image || img.src !== urlForImage(image);
    }
  }

  setHint(hint) {
    this.setState({
      hint,
    });
  }

  // Dimensions

  getCanvasRect() {
    const canvas = this.getCanvas();
    return new Rect(canvas.offsetTop, canvas.offsetLeft, canvas.offsetWidth, canvas.offsetHeight);
  }

  getDefaultCropRect() {
    const { targetWidth, targetHeight } = this.props;
    const rect = this.getCanvasRect();
    rect.constrain(targetWidth / targetHeight);
    return rect;
  }

  getImgRect(cropRect) {
    if (cropRect) {
      const imgRect = cropRect.clone();
      imgRect.offset(this.getCanvasRect());
      imgRect.scale(this.getImgScale());
      return imgRect;
    }
    return null;
  }

  getImgScale() {
    const canvas = this.getCanvas();
    return canvas.height / canvas.clientHeight;
  }

  getStageBounds() {
    const stage = this.stageRef.current;
    if (stage) {
      return new Rect(0, 0, stage.clientWidth, stage.clientHeight);
    }
  }

  // Image loading

  async loadImage(url) {
    try {
      const img = await this.loadImageUrl(url);
      const canvas = this.getCanvas();
      canvas.width = img.width;
      canvas.height = img.height;
      this.setState({
        img,
        mode: 'preview',
        cropRect: null,
        imgRect: null,
      });
    } catch (error) {
      this.props.onError(error);
    }
  }

  unloadImage() {
    this.setState({
      img: null,
      imgRect: null,
      cropRect: null,
      mode: 'upload',
    });
  }

  loadImageUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(new Error(`URL ${url} is invalid.`));
      };
      img.src = url;
    });
  }

  // Canvas

  getCanvas() {
    return this.canvasRef.current;
  }

  drawImage(img, imgRect) {
    const canvas = this.getCanvas();
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (img) {
      let sx, sy, width, height;
      if (imgRect) {
        sx = imgRect.left;
        sy = imgRect.top;
        width = imgRect.width;
        height = imgRect.height;
      } else {
        sx = 0;
        sy = 0;
        width = img.width;
        height = img.height;
      }
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, sx, sy, width, height, 0, 0, width, height);
    }
    this.props.onDraw({ img, imgRect });
  }

  async exportImage() {
    await this.setMode('preview');
    const [jpg, png] = await Promise.all([
      await this.exportImageForType('image/jpeg'),
      await this.exportImageForType('image/png'),
    ]);
    return png.size < jpg.size ? png : jpg;
  }

  // Ensure editing mode is off so canvas has been updated.
  setMode(mode) {
    return new Promise((resolve) => {
      this.setState(
        {
          mode,
        },
        resolve
      );
    });
  }

  exportImageForType(type) {
    return new Promise((resolve, reject) => {
      try {
        this.getCanvas().toBlob((blob) => {
          blob.name = this.filename || this.props.image?.filename || 'Cropped Image';
          resolve(blob);
        }, type);
      } catch (err) {
        reject(err);
      }
    });
  }

  getDimensions() {
    return this.state.imgRect || this.getCanvas();
  }

  render() {
    return <div {...this.getProps()}>{this.renderBody()}</div>;
  }

  renderBody() {
    const { targetWidth, targetHeight, creatorAccount } = this.props;
    const { mode, cropRect } = this.state;
    if (mode === 'crop' || mode === 'preview') {
      return (
        <div ref={this.stageRef} className={this.getElementClass('stage')}>
          {mode === 'crop' && (
            <React.Fragment>
              <DraggableBox
                rect={cropRect}
                bounds={this.getStageBounds()}
                onChange={this.onBoxChange}
                className={this.getElementClass('box')}
                constrain
              />
              <div className={this.getElementClass('dimmer')}>
                <div className={this.getElementClass('dimmer-inner')} style={{ ...cropRect }} />
              </div>
            </React.Fragment>
          )}
          <canvas width={targetWidth} height={targetHeight} ref={this.canvasRef} />
          {this.renderActions()}
        </div>
      );
    } else if (mode === 'upload') {
      const { types } = this.props;
      return (
        <React.Fragment>
          <Uploads single skipUpload onDrop={this.onDrop} types={types}>
            {({ acceptedMessage }) => {
              return (
                <React.Fragment>
                  <span>
                    Drag an image here, click to select, or{' '}
                    <a className="link" onClick={this.onChooseClick}>
                      choose from assets
                    </a>
                    .
                  </span>{' '}
                  {acceptedMessage}
                </React.Fragment>
              );
            }}
          </Uploads>
          <p style={{ marginTop: '1em' }}>
            <a style={{ cursor: 'pointer' }} onClick={this.onPreviewClick}>
              Back
            </a>
          </p>
        </React.Fragment>
      );
    } else if (mode === 'select') {
      return (
        <AssetPicker
          creatorAccount={creatorAccount}
          onAssetClick={this.onAssetClick}
          errorAction={
            <p>
              <a style={{ cursor: 'pointer' }} onClick={this.onUploadClick}>
                Back
              </a>
            </p>
          }
        />
      );
    }
  }

  renderActions() {
    const { mode, img } = this.state;
    return (
      <div onMouseLeave={() => this.setHint(null)} className={this.getElementClass('actions')}>
        {this.renderHint()}
        {mode === 'crop' ? (
          <React.Fragment>
            {this.renderAction(this.onCancelClick, 'ban', 'Cancel')}
            {this.renderAction(this.onAcceptClick, 'check', 'Accept')}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {img && this.renderWarning()}
            {this.renderAction(this.onUploadClick, 'upload', 'Load Image')}
            {img && this.renderAction(this.onCropClick, 'crop', 'Crop Image')}
          </React.Fragment>
        )}
      </div>
    );
  }

  renderHint() {
    const { hint } = this.state;
    if (hint) {
      return <div className={this.getElementClass('hint')}>{hint}</div>;
    }
  }

  renderWarning() {
    const dim = this.getDimensions();
    if (dim) {
      const { width, height } = dim;
      const { targetWidth, targetHeight } = this.props;
      if (width < targetWidth || height < targetHeight) {
        const title = `Image less than ${targetWidth}x${targetHeight}`;
        return this.renderAction(null, 'exclamation-triangle', title, 'warning');
      }
    }
  }

  renderAction(handler, icon, hint, mod) {
    return (
      <div onClick={handler} onMouseOver={() => this.setHint(hint)} className={this.getElementClass('action', mod)}>
        <Icon name={icon} width={18} height={18} />
      </div>
    );
  }
}

CroppedImageField.propTypes = {
  types: PropTypes.array,
  targetWidth: PropTypes.number.isRequired,
  targetHeight: PropTypes.number.isRequired,
  creatorAccount: PropTypes.string,
  image: PropTypes.object,
  onError: PropTypes.func,
  onDraw: PropTypes.func,
};

CroppedImageField.defaultProps = {
  onError: () => {},
  onDraw: () => {},
};

export class IncompatibleFileError extends Error {
  constructor(file) {
    super(`File type ${file.type} cannot be rendered.`);
    this.file = file;
  }
}
