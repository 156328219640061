import { urlForUpload } from '../uploads';
import request from './request';

export async function createVodForUpload(upload) {
  // Prefer rawUrl to allow local testing of VOD streams.
  const videoURL = upload.storageType === 'local' ? urlForUpload(upload) : upload.rawUrl;
  const { data } = await request({
    method: 'POST',
    path: '/1/videos/vod',
    body: {
      videoURL,
    },
  });
  return data;
}
