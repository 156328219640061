import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

export default ({ product }) => (
  <Container>
    <Menu pointing secondary>
      <Menu.Item exact name="Overview" to={`/products/${product.id}`} as={NavLink} />
      <Menu.Item exact name="Insights" to={`/products/${product.id}/insights`} as={NavLink} />
      <Menu.Item exact name="Details" to={`/products/${product.id}/details`} as={NavLink} />
      <Menu.Item exact name="Images" to={`/products/${product.id}/images`} as={NavLink} />
      <Menu.Item exact name="Events" to={`/products/${product.id}/events`} as={NavLink} />
      {product.type === 'base' && (
        <Menu.Item exact name="Variants" to={`/products/${product.id}/variants`} as={NavLink} />
      )}
      {product.type === 'bundle' && (
        <Menu.Item exact name="Bundled" to={`/products/${product.id}/bundled`} as={NavLink} />
      )}
    </Menu>
  </Container>
);
