import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Divider, Message } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';

import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import Broadcast from './Broadcast';
import Videos from './Videos';
import Insights from './Insights';
import Questions from './Questions';
import VideoEditor from './VideoEditor';
import Funnel from './Funnel';
import Invites from './Invites';
import Links from './Links';
import Chat from './Chat';
import Menu from './Menu';
import Products from './Products';
import Subscribers from './Subscribers';

@screen
export default class Event extends React.Component {
  state = {
    event: null,
  };

  componentDidMount() {
    this.fetchEvent();
  }

  componentDidUpdate(lastProps = {}) {
    const { match } = this.props;

    if (lastProps.match.params.id !== match.params.id) {
      this.setState({
        event: null,
      });
      this.fetchEvent();
    }
  }

  fetchEvent = async () => {
    this.setState({
      loading: true,
    });
    const { id } = this.props.match.params;
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/events/${id}`,
      });
      this.setState({
        event: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  updateEvent = (event) => {
    this.setState({
      event,
    });
  };

  render() {
    const { event, loading } = this.state;
    return (
      <>
        {!event || loading ? (
          <PageLoader />
        ) : (
          <React.Fragment>
            <Menu event={event} reload={this.fetchEvent} updateEvent={this.fetchEvent} />
            {event.deletedAt && <Message>This Event has been deleted</Message>}
            <Divider hidden />
            <Switch>
              <Route exact path="/events/:id" render={(props) => <Overview {...props} event={event} />} />
              <Route exact path="/events/:id/insights" render={(props) => <Insights {...props} event={event} />} />
              <Route exact path="/events/:id/funnel" render={(props) => <Funnel {...props} event={event} />} />
              <Route
                exact
                path="/events/:id/broadcast"
                render={(props) => <Broadcast {...props} event={event} fetchEvent={this.fetchEvent} />}
              />
              <Route
                exact
                path="/events/:id/subscribers"
                render={(props) => <Subscribers {...props} event={event} fetchEvent={this.fetchEvent} />}
              />
              <Route
                path="/events/:id/videos"
                render={(props) => (
                  <Videos {...props} event={event} fetchEvent={this.fetchEvent} updateEvent={this.updateEvent} />
                )}
              />
              <Route
                path="/events/:id/video-editor"
                render={(props) => (
                  <VideoEditor {...props} event={event} fetchEvent={this.fetchEvent} updateEvent={this.updateEvent} />
                )}
              />

              <Route exact path="/events/:id/questions" render={(props) => <Questions {...props} event={event} />} />
              <Route exact path="/events/:id/invites" render={(props) => <Invites {...props} event={event} />} />
              <Route exact path="/events/:id/chat" render={(props) => <Chat {...props} event={event} />} />
              <Route exact path="/events/:id/links" render={(props) => <Links {...props} event={event} />} />
              <Route
                exact
                path="/events/:id/products"
                render={(props) => <Products {...props} products={event.products} event={event} />}
              />
            </Switch>
          </React.Fragment>
        )}
      </>
    );
  }
}
