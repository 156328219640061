import React from 'react';
import { Form, Modal, Message, Button, Loader, Segment } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import { DateField } from 'common/components/form-fields';
import SearchDropdown from 'admin/components/SearchDropdown';
import AssetsField from 'admin/components/form-fields/Assets';
import { modal } from 'common/helpers';
import { withRouter } from 'react-router-dom';
import { withSession } from 'stores';
import Protected from 'components/Protected';

@modal
@withRouter
@withSession
export default class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !!this.props.event?.id,
      error: null,
      hasError: false,
      enableChat: true,
      event: {
        status: 'draft',
        access: 'public',
        userOnly: false,
        isBundle: false,
        subType: 'session',
        type: 'vod',
      },
    };
  }

  componentDidMount() {
    if (this.props.event?.id) {
      this.fetchEvent();
    }
  }

  isUpdate() {
    return !!this.props.event;
  }

  setEventField(name, value) {
    this.setState({
      event: {
        ...this.state.event,
        [name]: value,
      },
    });
  }

  setEventBundle(isBundle) {
    if (isBundle != this.state.event.isBundle) {
      this.setState({
        event: {
          ...this.state.event,
          products: [],
          isBundle,
        },
      });
    }
  }

  mapEventRefFields(event, fallback) {
    return {
      ...event,
      creatorAccount: event.creatorAccount?.id,
      cause: event.cause?.id || fallback,
      coverImage: event.coverImage?.id || event.coverImage || fallback,
      appClipCardImage: event.appClipCardImage?.id || event.appClipCardImage || fallback,
      products: (event.products || []).map((p) => p.id),
      streams: this.cleanStreamProducts(event),
    };
  }

  cleanStreamProducts(event) {
    const { products, streams = [] } = event;
    return streams.map((stream) => {
      return {
        ...stream,
        video: stream.video.id,
        chapters: stream.chapters.map((chapter) => {
          const { image, ...rest } = chapter;
          return {
            ...rest,
            ...(image && {
              image: image.id || image,
            }),
          };
        }),
        products: stream.products.filter((streamProduct) => {
          return products.some((product) => {
            return product.id === streamProduct.productId;
          });
        }),
      };
    });
  }

  onSubmit = async () => {
    try {
      const { event } = this.state;
      this.setState({
        loading: true,
      });
      let updatedEvent;
      if (this.isUpdate()) {
        const { data } = await request({
          method: 'PATCH',
          path: `/1/events/${event.id}`,
          body: {
            ...this.mapEventRefFields(event, null),
          },
        });
        updatedEvent = data;
      } else {
        const { data } = await request({
          method: 'POST',
          path: '/1/events',
          body: {
            ...this.mapEventRefFields(event),
          },
        });
        updatedEvent = data;

        if (this.state.enableChat) {
          await request({
            method: 'POST',
            path: `/1/events/${updatedEvent.id}/chat/enable`,
          });
        }
      }

      if (updatedEvent.type === 'live' && !updatedEvent.liveVideo) {
        // TODO: not sure why the model isn't taking care of this automatically
        const { data: liveVideo } = await request({
          method: 'POST',
          path: '/1/videos/live',
        });
        updatedEvent.streams.push({
          type: 'live',
          video: liveVideo,
        });

        const { data } = await request({
          method: 'PATCH',
          path: `/1/events/${updatedEvent.id}`,
          body: {
            streams: updatedEvent.streams.map((stream) => {
              return {
                ...stream,
                video: stream.video?.id,
                chapters: (stream.chapters || []).map((chapter) => {
                  return {
                    ...chapter,
                    image: chapter.image?.id || chapter.image,
                  };
                }),
              };
            }),
          },
        });
        updatedEvent = data;
      }

      this.triggerNofications(updatedEvent);

      if (this.isUpdate()) {
        this.props.close();
        this.props.onSave(updatedEvent);
      } else {
        this.props.history.push(`/events/${updatedEvent.id}`);
      }
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  triggerNofications = (updatedEvent) => {
    const { event, originalEvent } = this.state;

    if (event.type !== 'vod' || event.subType !== 'session' || event.status !== 'active' || event.access !== 'public') {
      return;
    }

    if (originalEvent && originalEvent.status === 'active' && originalEvent.access === 'public') {
      return;
    }

    this.props.triggerNotification({
      header: 'Send nofications to clients',
      content: <p>Your clients will immediately be notified about the session {event.name}</p>,
      confirmButton: 'Notify Now',
      cancelButton: 'Don’t Notify',
      onConfirm: () =>
        request({
          method: 'POST',
          path: `/1/notifications/event/${updatedEvent.id}/published`,
        }),
    });
  };

  fetchDiscounts = (props) => {
    const { event } = this.state;
    return request({
      method: 'POST',
      path: '/1/discounts/search',
      body: {
        ...props,
        creatorAccountId: event.creatorAccount?.id,
      },
    });
  };

  fetchUsers = (props) => {
    const { event } = this.state;
    return request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...props,
        creatorAccount: event.creatorAccount?.id,
      },
    });
  };

  fetchProducts = async (body) => {
    const { event } = this.state;
    const { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        ...body,
        creatorAccount: event.creatorAccount?.id,
        types: event.isBundle ? ['bundled'] : ['base', 'variant', 'individual'],
      },
    });
    return data;
  };

  fetchCreatorAccounts = async (body) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body,
    });
    return data;
  };

  fetchEvent = async () => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/events/${this.props.event.id}`,
      });

      this.setState({
        loading: false,
        event: { ...data },
        originalEvent: { ...data },
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  render() {
    const { event, loading, error, hasError } = this.state;
    const creatorAccountId = this.context.creatorAccount?.id || event?.creatorAccount?.id;
    const isUpdate = this.isUpdate();
    const isLoadingEvent = isUpdate && this.state.loading && !this.state.event?.id;

    const isSuperAdminOrManager = this.context.user.roles?.some((c) => ['superAdmin', 'manager'].includes(c.role));

    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit "${this.props.event?.name}"` : 'New Event'}</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          {isLoadingEvent && (
            <Segment basic loading style={{ height: '300px' }}>
              <Loader active />
            </Segment>
          )}
          {!isLoadingEvent && (
            <AutoFocus>
              <Form error={!!error || hasError}>
                {!isUpdate && (
                  <Protected endpoint="users" scope="creatorAccount">
                    <Segment>
                      <Form.Group widths="equal">
                        {this.context.canManageAccounts() && !this.context.creatorAccount?.id && !this.isUpdate() && (
                          <>
                            <Form.Field required>
                              <label>Creator Account</label>
                              <SearchDropdown
                                fluid
                                value={event.creatorAccount}
                                onChange={(e, { value }) => {
                                  this.setState({
                                    event: {
                                      ...event,
                                      creatorAccount: value,
                                      products: [],
                                      user: undefined,
                                    },
                                  });
                                }}
                                onDataNeeded={this.fetchCreatorAccounts}
                              />
                            </Form.Field>
                          </>
                        )}

                        <Form.Field>
                          <label>User</label>
                          <SearchDropdown
                            fluid
                            key={creatorAccountId}
                            disabled={!creatorAccountId}
                            objectMode={false}
                            value={event.user}
                            onChange={(e, { value }) => {
                              this.setEventField('user', value?.length ? value : undefined);
                            }}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            onDataNeeded={this.fetchUsers}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Checkbox
                        label="Only visible to owner"
                        checked={event.userOnly}
                        onChange={(e, { checked }) => {
                          this.setState({
                            event: {
                              ...this.state.event,
                              userOnly: checked,
                              status: checked ? 'unlisted' : this.state.event.status,
                            },
                          });
                        }}
                      />
                    </Segment>
                  </Protected>
                )}

                <Form.Input
                  required
                  name="name"
                  label="Name"
                  type="text"
                  value={event.name || ''}
                  onChange={(e, { value }) => this.setEventField('name', value)}
                />

                <Form.TextArea
                  name="description"
                  label="Description"
                  type="text"
                  value={event.description || ''}
                  onChange={(e, { value }) => this.setEventField('description', value)}
                />

                {isSuperAdminOrManager && (
                  <Form.Dropdown
                    selection
                    clearable
                    name="publishScope"
                    label="PublishScope"
                    value={event.publishScope || ''}
                    options={[
                      {
                        text: 'Brand',
                        value: 'brand',
                      },
                    ]}
                    onChange={(e, { value }) => this.setEventField('publishScope', value ? value : null)}
                  />
                )}

                <Form.Checkbox
                  name="subType"
                  label="Is Event Session"
                  checked={event.subType === 'session'}
                  onClick={(e, { checked }) => {
                    let fields = {
                      subType: checked ? 'session' : 'event',
                    };
                    if (checked) {
                      fields.type = 'vod';
                      fields.startsAt = undefined;
                    }

                    this.setState({
                      event: {
                        ...event,
                        ...fields,
                      },
                    });
                  }}
                />

                {!isUpdate && (
                  <Form.Dropdown
                    required
                    selection
                    disabled={isUpdate || event.subType === 'session'}
                    name="type"
                    label="Type"
                    value={event.type || ''}
                    options={[
                      {
                        text: 'Live',
                        value: 'live',
                      },
                      {
                        text: 'VOD',
                        value: 'vod',
                      },
                    ]}
                    onChange={(e, { value }) => this.setEventField('type', value)}
                  />
                )}

                <Form.Field>
                  <label>Products</label>

                  <SearchDropdown
                    disabled={!creatorAccountId}
                    key={`${creatorAccountId}`}
                    fluid
                    multiple
                    getOptionLabel={(item) => {
                      return [`${item.name} [${item.type}]`, item.sku].filter(Boolean).join(' - ');
                    }}
                    placeholder="Search by name or sku"
                    onDataNeeded={this.fetchProducts}
                    onChange={(e, { value }) => {
                      this.setEventField('products', event.isBundle ? [value] : value);
                    }}
                    value={event?.products}
                  />
                </Form.Field>

                {!this.isUpdate() && (
                  <Form.Checkbox
                    disabled={event.subType === 'session'}
                    label="Enable Event Chat"
                    checked={this.state.enableChat}
                    onChange={() => {
                      this.setState({
                        enableChat: !this.state.enableChat,
                      });
                    }}
                  />
                )}
                <Form.Group>
                  <Form.Dropdown
                    selection
                    name="access"
                    label="Access"
                    value={event.access || 'public'}
                    options={[
                      {
                        text: 'Public',
                        value: 'public',
                      },
                      {
                        text: 'Private',
                        value: 'private',
                      },
                    ]}
                    onChange={(e, { value }) => this.setEventField('access', value)}
                  />
                  <Form.Dropdown
                    selection
                    name="status"
                    label="Status"
                    value={event.status || ''}
                    options={[
                      {
                        text: 'Active',
                        value: 'active',
                      },
                      {
                        text: 'Draft',
                        value: 'draft',
                      },
                      {
                        text: 'Unlisted',
                        value: 'unlisted',
                      },
                    ]}
                    onChange={(e, { value }) => this.setEventField('status', value)}
                  />
                  {event.subType === 'event' && (
                    <DateField
                      time
                      clearable
                      required
                      name="startsAt"
                      value={event.startsAt}
                      disabled={event.subType === 'session' || event.parent}
                      label="Start Date"
                      includeTimezone
                      onChange={(e, { value }) => this.setEventField('startsAt', value)}
                    />
                  )}
                </Form.Group>

                <AssetsField
                  label="Cover Image"
                  required
                  name="coverImage"
                  value={event.coverImage || null}
                  onChange={(upload) => this.setEventField('coverImage', upload)}
                  onError={() => this.setState({ hasError: true })}
                  creatorAccount={event.creatorAccount?.id}
                />

                <AssetsField
                  label="AppClipCard Image"
                  name="appClipCardImage"
                  value={event.appClipCardImage || null}
                  onChange={(upload) => this.setEventField('appClipCardImage', upload)}
                  onError={() => this.setState({ hasError: true })}
                  creatorAccount={event.creatorAccount?.id}
                />
              </Form>
            </AutoFocus>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
