import React from 'react';
import { Table, Button, Image, Icon } from 'semantic';
import { urlForImage } from 'utils/uploads';
import EditProduct from 'admin/modals/EditProduct';
import { Link } from 'react-router-dom';
import { request } from 'utils/api';
import { SearchProvider, Status } from 'components/search';

const statusToName = {
  active: 'Active',
  draft: 'Draft',
};

export default class ProductEvents extends React.Component {
  onDataNeeded = () => {
    return request({
      method: 'GET',
      path: `/1/products/${this.props.product.id}/events`,
    });
  };
  render() {
    const { product } = this.props;

    return (
      <>
        {!product.externalId && (
          <EditProduct
            product={{ type: 'variant', parent: product.id, creatorAccount: product.creatorAccount }}
            onSave={this.props.onReload}
            trigger={<Button primary>Add A Variant</Button>}
          />
        )}

        <SearchProvider
          sort={{
            field: 'startsAt',
            order: 'desc',
          }}
          onDataNeeded={this.onDataNeeded}>
          {({ items }) => {
            return (
              <>
                <Status />

                {items.length !== 0 && (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={3}>
                          Cover
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>State</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Public?</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <Link to={`/events/${item.id}`}>{item.name}</Link>
                              {item.shareableLink && (
                                <Button
                                  basic
                                  style={{ boxShadow: 'none' }}
                                  title="Copy Shareable Link"
                                  onClick={() =>
                                    //only works on https
                                    navigator.clipboard.writeText(item.shareableLink)
                                  }
                                  icon="clipboard"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {item.coverImage && (
                                <Image
                                  style={{ display: 'inline' }}
                                  size="small"
                                  src={urlForImage(item.coverImage, { width: 150 })}
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">{item.state}</Table.Cell>
                            <Table.Cell>{statusToName[item.status]}</Table.Cell>
                            <Table.Cell textAlign="center">
                              {item.access === 'public' ? (
                                <Icon title="Public" name="check-circle" />
                              ) : (
                                <Icon title="Private" name="circle regular" />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
