import React from 'react';
import { Form, Button, Modal, Grid, Message } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'common/helpers';
import SearchDropdown from 'common/components/SearchDropdown';
import Asset from 'common/components/Asset';
@modal
export default class AssignAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      product: null,
    };
  }

  fetchProducts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    const { product } = this.state;
    const body = {
      ...product,
      assets: [
        ...product.assets,
        ...this.props.assets.map((c) => {
          return {
            id: c.upload.id,
            type: 'image',
          };
        }),
      ],
    };

    try {
      await request({
        method: 'PATCH',
        path: `/1/products/${this.state.product.id}`,
        body,
      });
      this.setState({ loading: false });
      this.props.close();
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  render() {
    const { assets = [] } = this.props;
    const { loading, error, product } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>Add Assets to Product</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}

          <Form>
            <Form.Field>
              <label>Product</label>
              <SearchDropdown
                fluid
                getOptionLabel={(item) => {
                  return [`${item.name} [${item.type}]`, item.sku].filter(Boolean).join(' - ');
                }}
                placeholder="Search by name or sku"
                onDataNeeded={this.fetchProducts}
                onChange={(e, { value }) => {
                  this.setState({ product: value });
                }}
                value={this.state.product?.id}
              />
            </Form.Field>
            <Form.Field>
              <label>Images to be added</label>

              <Grid columns={4}>
                {assets.map((asset) => {
                  return (
                    <Grid.Column verticalAlign="middle" key={asset.id}>
                      <Asset asset={asset} />
                    </Grid.Column>
                  );
                })}
              </Grid>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary loading={loading} disabled={loading || !product} onClick={this.onSubmit}>
            Add to product
          </Button>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
