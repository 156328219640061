import React from 'react';
import PropTypes from 'prop-types';
import VideoStream from '../Stream';
import { Component } from 'common/helpers';

import './adaptive.less';

export default class AdaptiveVideo extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const el = this.getVideoElement();
    el.addEventListener('durationchange', this.props.onDurationChange);
    el.addEventListener('timeupdate', this.props.onTimeUpdate);
    el.addEventListener('seeking', this.props.onSeeking);
  }

  componentWillUnmount() {
    const el = this.getVideoElement();
    el.removeEventListener('durationchange', this.props.onDurationChange);
    el.removeEventListener('timeupdate', this.props.onTimeUpdate);
    el.removeEventListener('seeking', this.props.onSeeking);
  }

  isStream() {
    const { video } = this.props;
    return video?.source === 'mux' || video?.source === 'shotstack';
  }

  getSrc() {
    const { video, src } = this.props;
    return video?.playbackUrl || src;
  }

  getVideoElement() {
    let el = this.ref.current;
    if (el.ref) {
      el = el.ref.current;
    }
    return el;
  }

  play() {
    this.getVideoElement()?.play();
  }

  pause() {
    this.getVideoElement()?.pause();
  }

  isPlaying() {
    return !this.getVideoElement()?.paused;
  }

  getCurrentTime() {
    return this.getVideoElement()?.currentTime;
  }

  getDuration() {
    return this.getVideoElement()?.duration;
  }

  setTime(time) {
    const el = this.getVideoElement();
    if (el) {
      if (!isNaN(el.duration)) {
        // Small offset to prevent the video from unloading
        time = Math.max(0, Math.min(time, el.duration - 0.001));
        el.currentTime = time;
      }
    }
  }

  jump(t) {
    const el = this.getVideoElement();
    if (el) {
      this.setTime(el.currentTime + t);
    }
  }

  toggle() {
    if (this.isPlaying()) {
      this.pause();
    } else {
      this.play();
    }
  }

  render() {
    return <div {...this.getProps()}>{this.renderVideo()}</div>;
  }

  renderVideo() {
    const { video } = this.props;
    if (this.isStream()) {
      return <VideoStream ref={this.ref} {...this.props} />;
    } else {
      const { controls, autoPlay } = this.props;
      return (
        <React.Fragment>
          <video ref={this.ref} src={this.getSrc()} controls={controls} autoPlay={autoPlay} />
        </React.Fragment>
      );
    }
  }
}

AdaptiveVideo.propTypes = {
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  src: PropTypes.string,
  video: PropTypes.object,
  onFragChanged: PropTypes.func,
  onLiveChanged: PropTypes.func,
};

AdaptiveVideo.defaultProps = {
  controls: false,
  autoPlay: false,
  onSeeking: () => {},
  onTimeUpdate: () => {},
  onDurationChange: () => {},
};
