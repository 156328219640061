import React from 'react';
import { Modal, Form, Message, Button } from 'semantic';
import { ExternalLink } from 'common/components/Link';
import AssetsField from 'admin/components/form-fields/Assets';
import { createVodForUpload } from 'utils/api';
import { ENV_NAME } from 'utils/env';

export default class EditStream extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      upload: null,
      stream: null,
      loading: false,
    };
  }

  componentDidUpdate(lastProps, lastState) {
    const { open } = this.state;
    if (open !== lastState.open) {
      if (open) {
        this.setState({
          error: null,
          upload: null,
          stream: this.props.stream || {
            type: 'main',
          },
        });
        if (this.props.onOpen) {
          this.props.onOpen();
        }
      } else {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    }
  }

  setStreamField(name, value) {
    this.setState({
      stream: {
        ...this.state.stream,
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    this.setState({ loading: true });
    try {
      const { stream, upload } = this.state;
      if (upload) {
        stream.video = await createVodForUpload(upload);
      } else if (!stream.video) {
        throw new Error('Video upload is required');
      }
      this.setState({
        open: false,
        loading: false,
      });
      this.props.onComplete(stream);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { open, error, upload, stream } = this.state;
    return (
      <Modal
        closeIcon
        open={open}
        trigger={this.props.trigger}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}>
        {stream && (
          <React.Fragment>
            <Modal.Header>{stream.id ? 'Edit Video' : 'Add Video'}</Modal.Header>
            <Modal.Content>
              <Form id="upload" error={!!error} onSubmit={this.onSubmit}>
                {error && <Message error content={error.message} />}
                <Form.Input
                  label="Name (optional)"
                  value={stream.name || ''}
                  onChange={(evt, { value }) => this.setStreamField('name', value)}
                />
                <Form.Dropdown
                  selection
                  label="Type"
                  value={stream.type}
                  options={[
                    {
                      text: this.props.eventType === 'live' ? 'Scheduled Live' : 'Main',
                      value: 'main',
                    },
                    {
                      text: 'Preview',
                      value: 'preview',
                    },
                    {
                      text: 'Replay',
                      value: 'replay',
                    },
                  ].filter(Boolean)}
                  onChange={(evt, { value }) => this.setStreamField('type', value)}
                />
                {this.props.eventType === 'live' && (
                  <Message>
                    The system is currently configured to support {ENV_NAME === 'staging' ? 2 : 6} "scheduled live"
                    streams concurrently. <br />
                    Going above the limit could cause the streams to hang. <br />
                    <b>Once started the stream will run to its end, regardless of the event</b>
                  </Message>
                )}
                <AssetsField
                  single
                  type="video"
                  label={stream.video ? 'Upload New Video File' : 'Add Video File'}
                  value={upload}
                  onChange={(upload) => {
                    this.setState({ upload });
                  }}
                  creatorAccount={this.props.creatorAccount}
                />
                <ExternalLink to="/docs/uploads#video">Video file recommendations</ExternalLink>
              </Form>
            </Modal.Content>
          </React.Fragment>
        )}
        <Modal.Actions>
          <Button loading={this.state.loading} disabled={this.state.loading} primary form="upload" content="Save" />
        </Modal.Actions>
      </Modal>
    );
  }
}
