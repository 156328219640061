import React from 'react';
import { Message, Loader, Image, Icon } from 'semantic';
import { urlForImage } from 'utils/uploads';
import { request } from 'utils/api';

import EditProductImage from 'admin/modals/EditProductImage';

export default class ProductImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      focusedImage: null,
      error: null,
    };
  }

  onSave = (updatedImage) => {
    const { product } = this.props;
    const { focusedImage } = this.state;
    this.setState({
      open: false,
      focusedImage: null,
    });
    if (updatedImage && updatedImage !== focusedImage) {
      if (focusedImage) {
        const asset = product.assets.find((pi) => {
          return pi === focusedImage;
        });
        asset.id = updatedImage.id;
      } else {
        product.assets.push({
          id: updatedImage.id,
          type: this.getImageTypeFromUpload(updatedImage),
        });
      }
      this.saveProductImages(product);
    }
  };

  onDelete = () => {
    const { product } = this.props;
    const { focusedImage } = this.state;
    product.assets = product.assets.filter((pi) => {
      return pi !== focusedImage;
    });
    this.setState({
      open: false,
      focusedImage: null,
    });
    this.saveProductImages(product);
  };

  onMakePrimary = () => {
    const { product } = this.props;
    const { focusedImage } = this.state;
    product.assets = [
      focusedImage,
      ...product.assets.filter((pi) => {
        return pi !== focusedImage;
      }),
    ];
    this.setState({
      open: false,
      focusedImage: null,
    });
    this.saveProductImages(product);
  };

  getImageTypeFromUpload(upload) {
    const [type] = upload.mimeType.split('/');
    return type;
  }

  async saveProductImages(product) {
    try {
      this.setState({
        error: null,
        loading: true,
      });
      await request({
        method: 'PATCH',
        path: `/1/products/${product.id}`,
        body: {
          assets: product.assets.map((pi) => {
            return {
              id: pi.id,
              type: pi.type,
            };
          }),
        },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  onClose = () => {
    this.setState({
      open: false,
      focusedImage: null,
    });
  };

  render() {
    const { product } = this.props;
    const { open, focusedImage, loading, error } = this.state;
    const { assets = [] } = product;
    return (
      <div>
        {loading ? (
          <Loader active />
        ) : (
          <React.Fragment>
            {error && <Message error content={error.message} />}
            <Image.Group size="small">
              {assets.map((asset) => {
                const onClick = () => {
                  this.setState({
                    open: true,
                    focusedImage: asset,
                  });
                };
                return (
                  <React.Fragment key={asset.id}>
                    {asset.type === 'image' ? (
                      <Image
                        src={urlForImage(asset.id, { width: 150 })}
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={onClick}
                      />
                    ) : (
                      <div
                        onClick={onClick}
                        title="ARKit model file cannot be displayed in the browser"
                        style={{
                          width: '150px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          verticalAlign: 'middle',
                        }}>
                        <Icon
                          size="small"
                          name="unity brand"
                          style={{ width: '60px', color: 'rgba(0,0,0,.2)' }}
                          fitted
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
              <Image
                style={{
                  cursor: 'pointer',
                  height: '150px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid rgb(0, 0, 0, .08)',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  this.setState({
                    open: true,
                    focusedImage: null,
                  });
                }}>
                <Icon style={{ width: '30px', height: '30px' }} name="plus" />
              </Image>
            </Image.Group>
            <EditProductImage
              open={open}
              image={focusedImage}
              onSave={this.onSave}
              onClose={this.onClose}
              onDelete={this.onDelete}
              onMakePrimary={this.onMakePrimary}
              product={product}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
