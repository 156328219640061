import React from 'react';
import { Divider } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { withSession } from 'stores';

@screen
@withSession
export default class Account extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Menu />
        <Divider hidden />
        <Divider hidden />
      </React.Fragment>
    );
  }
}
