import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

export default class EventStacks extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/events" component={List} exact />
        <Route path="/events/sessions" component={(props) => <List subType="session" {...props} />} />
        <Route path="/events/branded" component={(props) => <List publishScope="brand" {...props} />} />
        <Route path="/events/:id" component={Detail} />
      </Switch>
    );
  }
}
