import React from 'react';
import { Menu, Dropdown, TextArea } from 'semantic';
import ReactMarkdown from 'react-markdown';
import { Component } from 'common/helpers';

import './rich-text.less';

const LINE_TYPE_REG = /^(#{1,6})\s/;

export default class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'preview',
      lineType: 'normal',
    };
    this.ref = React.createRef();
  }

  onMenuItemClick = (evt, { name }) => {
    this.setState({
      mode: name,
    });
  };

  onTextAreaSelect = () => {
    const lineType = this.getLineType();
    this.setState({
      lineType,
    });
  };

  onTextAreaKeyDown = (evt) => {
    if (evt.key === 'b' && evt.metaKey) {
      this.applyBold();
      evt.preventDefault();
    }
  };

  onBlockChange = (evt, { value: lineType }) => {
    let { value } = this.props;
    const lineStart = this.getLineStart();
    const md = this.getMarkdownForLineType(lineType);

    let start = value.slice(0, lineStart);
    let end = value.slice(lineStart);

    const match = end.match(LINE_TYPE_REG);
    if (match) {
      end = end.replace(LINE_TYPE_REG, md ? md + ' ' : '');
    } else {
      end = `${md} ${end}`;
    }
    value = start + end;
    this.props.onChange(evt, { value });
    this.setState({
      lineType,
    });
  };

  onBoldClick = (evt) => {
    this.applyBold(evt);
  };

  applyBold(evt) {
    this.wrapSelection(evt, '**');
  }

  getMarkdownForLineType(lineType) {
    switch (lineType) {
      case 'h1':
        return '#';
      case 'h2':
        return '##';
      case 'h3':
        return '###';
      case 'h4':
        return '####';
      case 'h5':
        return '#####';
      case 'h6':
        return '######';
      default:
        return '';
    }
  }

  getLineType() {
    const { value } = this.props;
    const lineStart = this.getLineStart();
    const match = value.slice(lineStart).match(LINE_TYPE_REG);
    if (match) {
      return `h${match[1].length}`;
    } else {
      return 'normal';
    }
  }

  getLineStart() {
    let { start } = this.getRange();
    const { value } = this.props;
    while (start > 0) {
      if (value.charAt(start - 1) === '\n') {
        break;
      }
      start -= 1;
    }
    return start;
  }

  getInputElement() {
    const { ref } = this.ref.current;
    return ref.current;
  }

  getRange() {
    const el = this.getInputElement();
    const start = el.selectionStart;
    const end = el.selectionEnd;
    return { start, end };
  }

  wrapSelection(evt, wrap) {
    let { start, end } = this.getRange();
    let { value } = this.props;
    if (end - start > 0) {
      if (this.isWrapped(value, wrap, start, end)) {
        const str1 = value.slice(0, start);
        const str2 = value.slice(start + wrap.length, end - wrap.length);
        const str3 = value.slice(end);
        end -= wrap.length * 2;
        this.updateText(evt, `${str1}${str2}${str3}`, start, end);
      } else {
        const str1 = value.slice(0, start);
        const str2 = value.slice(start, end);
        const str3 = value.slice(end);
        end += wrap.length * 2;
        this.updateText(evt, `${str1}${wrap}${str2}${wrap}${str3}`, start, end);
      }
    } else {
      const str1 = value.slice(0, start);
      const str2 = value.slice(start);
      start += wrap.length;
      end += wrap.length;
      this.updateText(evt, `${str1}${wrap}${str2}`, start, end);
    }
  }

  isWrapped(str, wrap, start, end) {
    return str.slice(start, start + wrap.length) === wrap && str.slice(end - wrap.length, end) === wrap;
  }

  updateText(evt, value, start, end) {
    this.props.onChange(evt, { value });
    setTimeout(() => {
      const el = this.getInputElement();
      el.setSelectionRange(start, end);
    }, 20);
  }

  render() {
    const { value } = this.props;
    const { mode } = this.state;
    return (
      <div {...this.getProps()}>
        {this.renderMenu()}
        <div className={this.getElementClass('panel-container')}>
          {(mode === 'edit' || mode === 'split') && (
            <div className={this.getElementClass('panel', mode)}>
              <TextArea
                rows={20}
                value={value}
                ref={this.ref}
                onSelect={this.onTextAreaSelect}
                onKeyDown={this.onTextAreaKeyDown}
                onChange={this.props.onChange}
                className={this.getElementClass('textarea')}
              />
            </div>
          )}
          {(mode === 'preview' || mode === 'split') && (
            <div className={this.getElementClass('panel', mode)}>
              <ReactMarkdown allowDangerousHtml source={value} />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderMenu() {
    const { mode } = this.state;
    return (
      <Menu icon>
        <Menu.Item name="edit" active={mode === 'edit'} onClick={this.onMenuItemClick}>
          Edit
        </Menu.Item>
        <Menu.Item name="preview" active={mode === 'preview'} onClick={this.onMenuItemClick}>
          Preview
        </Menu.Item>
        <Menu.Item name="split" active={mode === 'split'} onClick={this.onMenuItemClick}>
          Split
        </Menu.Item>
        {(mode === 'edit' || mode === 'split') && (
          <React.Fragment>
            <Menu.Item onClick={this.onBoldClick} icon="bold" />
            <Menu.Item>
              <Dropdown
                value={this.state.lineType}
                onChange={this.onBlockChange}
                options={[
                  {
                    text: 'Normal',
                    value: 'normal',
                  },
                  {
                    text: 'Heading 1',
                    value: 'h1',
                  },
                  {
                    text: 'Heading 2',
                    value: 'h2',
                  },
                  {
                    text: 'Heading 3',
                    value: 'h3',
                  },
                  {
                    text: 'Heading 4',
                    value: 'h4',
                  },
                  {
                    text: 'Heading 5',
                    value: 'h5',
                  },
                  {
                    text: 'Heading 6',
                    value: 'h6',
                  },
                ]}
              />
            </Menu.Item>
          </React.Fragment>
        )}
      </Menu>
    );
  }
}
