import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';

import { modal } from 'common/helpers';
import Protected from 'common/components/Protected';

@modal
export default class EditBusinessAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      businessAccount: {},
    };
  }

  componentDidMount() {
    this.fetchBusinessAccount();
  }

  fetchBusinessAccount = async () => {
    if (!this.props.businessAccountId) return;
    this.setState({
      loading: true,
    });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/business-accounts/${this.props.businessAccountId}`,
      });

      this.setState({
        loading: false,
        businessAccount: data || {},
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  isUpdate() {
    return !!this.state.businessAccount.id;
  }

  onDelete = async () => {
    this.setState({
      loading: true,
    });
    try {
      await request({
        method: 'DELETE',
        path: `/1/business-accounts/${this.props.businessAccountId}`,
      });
      this.props.close();
      this.props.onSave();
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  onSubmit = async () => {
    try {
      const { businessAccount } = this.state;
      this.setState({
        loading: true,
        touched: true,
      });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/business-accounts/${businessAccount.id}`,
          body: businessAccount,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/business-accounts',
          body: {
            ...businessAccount,
            creatorAccount: this.props.creatorAccountId,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      businessAccount: {
        ...this.state.businessAccount,
        [name]: value,
      },
    });
  }

  render() {
    const { loading, error, businessAccount } = this.state;

    return (
      <>
        <Modal.Header>Payout details</Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)}>
            {error && <Message error content={error.message} />}
            <Form.Dropdown
              required
              selection
              clearable
              disabled={this.isUpdate()}
              name="businessType"
              label="Business Type"
              value={businessAccount.businessType || ''}
              options={[
                {
                  text: 'Individual',
                  value: 'individual',
                },
                {
                  text: 'Company',
                  value: 'company',
                },
              ]}
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Input
              value={businessAccount.name || ''}
              required
              disabled={loading}
              name="name"
              label="Legal name"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Input
              value={businessAccount.website || ''}
              type="url"
              label="Website"
              name="website"
              placeholder="https://example.com"
              pattern="https://.*"
              disabled={loading}
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Input
              value={businessAccount.bankAccountNumber || ''}
              name="bankAccountNumber"
              label="Bank account number"
              required
              disabled={loading}
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Input
              value={businessAccount.bankAccountRoutingNumber || ''}
              name="bankAccountRoutingNumber"
              label="Bank account (ACH) Routing number"
              placeholder="eg. 110000000"
              maxLength={9}
              disabled={loading}
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Input
              value={businessAccount.ein || ''}
              disabled={loading}
              required
              name="ein"
              label="Employer Identification Number (EIN)"
              placeholder="XX-XXXXXXX"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Protected endpoint="businessAccounts" scope="global">
            <Button floated="left" negative onClick={this.onDelete} content="Remove" />
          </Protected>
          <Button primary loading={loading} disabled={loading} content={'Save'} onClick={this.onSubmit} />
        </Modal.Actions>
      </>
    );
  }
}
