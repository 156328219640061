import React from 'react';
import { userHasAccess } from 'utils/permissions';
import { merge, omit, uniqBy } from 'lodash';
import bootstrap from 'utils/bootstrap';
import { addHeader, request } from 'utils/api';
import { STORAGE_KEY } from 'utils/bootstrap';

export const SessionContext = React.createContext();

let searchParams = new URLSearchParams(window.location.search);

if (searchParams.get('jwt')) {
  sessionStorage.setItem('jwt', searchParams.get('jwt'));
}

export class SessionProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      token: searchParams.get('jwt') || localStorage.getItem('jwt'),
      user: null,
      error: null,
      creatorAccount: null,
      loading: true,
    };
  }

  async componentDidMount() {
    await this.loadUser();
    if (this.props.onLoad) {
      this.props.onLoad(this.state.user);
    }
  }

  logout = async (capture) => {
    if (capture) {
      const { pathname, search } = window.location;
      this.setStored('redirect', pathname + search);
    }
    try {
      await request({
        method: 'POST',
        path: '/1/auth/logout',
      });
    } catch (err) {
      // JWT token errors may throw here
    }
    await this.setToken(null);
    document.location = '/';
  };

  setToken = async (token) => {
    if (token) {
      localStorage.setItem('jwt', token);
      this.setState({
        token,
      });
      await this.loadUser();
    } else {
      localStorage.removeItem('jwt');
      sessionStorage.removeItem('jwt');
      localStorage.removeItem('creator');
      localStorage.removeItem(STORAGE_KEY);
      this.clearUser();
    }
  };

  loadUser = async () => {
    if (localStorage.getItem('jwt')) {
      this.setState({
        user: null,
        error: null,
        loading: true,
      });
      try {
        const { user, creatorAccount } = await bootstrap();

        if (creatorAccount) {
          addHeader('Creator-Account-Id', () => {
            return sessionStorage.getItem(STORAGE_KEY) || localStorage.getItem(STORAGE_KEY);
          });
        }

        this.setState({
          user,
          creatorAccount,
          loading: false,
        });
      } catch (error) {
        if (error.message === 'jwt expired') {
          this.logout();
          return;
        }
        this.setState({
          error,
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  updateUser = (data) => {
    this.setState({
      user: merge({}, this.state.user, data),
    });
  };

  clearUser = () => {
    this.setState({
      creatorAccount: null,
      user: null,
      error: null,
    });
  };

  hasAccess = (options) => {
    return userHasAccess(this.state.user, options);
  };

  canSwitchAccounts = () => {
    return this.hasCreatorAccounts() || this.canManageAccounts();
  };

  hasCreatorAccounts = () => {
    const { user } = this.state;
    const creatorRoles = uniqBy(
      user.roles.filter((role) => {
        return role.scope === 'creatorAccount';
      }),
      'scopeRef'
    );
    return creatorRoles.length > 1;
  };

  canManageAccounts = () => {
    return this.hasAccess({
      endpoint: 'creatorAccounts',
      permission: 'write',
      scope: 'global',
    });
  };

  // Session storage

  setStored = (key, data) => {
    this.updateStored(
      merge({}, this.state.stored, {
        [key]: data,
      })
    );
    //trackSession('add', key, data);
  };

  removeStored = (key) => {
    this.updateStored(omit(this.state.stored, key));
    // trackSession('remove', key);
  };

  clearStored = () => {
    this.updateStored({});
  };

  popStored = (key) => {
    const stored = this.state.stored[key];
    if (stored) {
      this.removeStored(key);
      return stored;
    }
  };

  loadStored = () => {
    let data;
    try {
      const str = localStorage.getItem('session');
      if (str) {
        data = JSON.parse(str);
      }
    } catch (err) {
      localStorage.removeItem('session');
    }
    return data || {};
  };

  updateStored = (data) => {
    if (Object.keys(data).length > 0) {
      localStorage.setItem('session', JSON.stringify(data));
    } else {
      localStorage.removeItem('session');
    }
    this.setState({
      stored: data,
    });
  };

  render() {
    return (
      <SessionContext.Provider
        value={{
          ...this.state,
          updateUser: this.updateUser,
          loadUser: this.loadUser,
          setToken: this.setToken,
          hasAccess: this.hasAccess,
          logout: this.logout,
          canSwitchAccounts: this.canSwitchAccounts,
          canManageAccounts: this.canManageAccounts,
          hasCreatorAccounts: this.hasCreatorAccounts,
        }}>
        {this.props.children}
      </SessionContext.Provider>
    );
  }
}

export function withSession(Component) {
  Component.contextType = SessionContext;
  return Component;
}

export function useSession() {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a Session Context');
  }
  return context;
}
