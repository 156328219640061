import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Image, Confirm, Segment, Dropdown } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';

import EditCreatorAccount from 'admin/modals/EditCreatorAccount';

import { urlForImage } from 'utils/uploads';
import { Layout, Breadcrumbs, HelpTip } from 'components';
import { screen } from 'helpers';

import { SearchProvider, Status, Filters, Pagination } from 'components/search';
import Actions from '../Actions';

@screen
export default class CreatorAccountsList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        ...params,
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
      },
    });
  };

  render() {
    return (
      <>
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({ items, getSorted, setSort, reload }) => {
            return (
              <React.Fragment>
                <Breadcrumbs active="Creators" />
                <Layout horizontal spread center>
                  <h1>Creators</h1>
                  <Layout.Group>
                    <EditCreatorAccount
                      trigger={<Button primary content="New Creator" icon="plus" />}
                      onSave={reload}
                    />
                  </Layout.Group>
                </Layout>

                <Segment>
                  <Layout horizontal center spread stackable>
                    <Layout horizontal></Layout>
                    <Layout.Group>
                      <Filters.Search name="keyword" placeholder="Enter name" />
                    </Layout.Group>
                  </Layout>
                </Segment>
                <Status />

                {items.length !== 0 && (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>Cover</Table.HeaderCell>
                        <Table.HeaderCell onClick={() => setSort('name')} sorted={getSorted('name')}>
                          Name
                        </Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell
                          width={4}
                          onClick={() => setSort('createdAt')}
                          sorted={getSorted('createdAt')}>
                          Created
                          <HelpTip title="Joined" text="This is the date and time the user was created." />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={4}>
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              {item.coverImage && (
                                <Image size="tiny" src={urlForImage(item.coverImage, { width: 150 })} />
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <Link to={`/creator-accounts/${item.id}`}>{item.name}</Link>
                            </Table.Cell>
                            <Table.Cell>{item.userName}</Table.Cell>
                            <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                            <Table.Cell textAlign="center">
                              <Table.Cell textAlign="center">
                                <EditCreatorAccount
                                  creatorAccount={item}
                                  onSave={reload}
                                  trigger={<Button title="Edit Event" basic icon="edit" />}
                                />

                                <Dropdown button basic text="More">
                                  <Dropdown.Menu>
                                    <Actions item={item} onReload={reload} />
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Table.Cell>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}

                <Layout center>
                  <Pagination />
                </Layout>
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
