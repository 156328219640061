import React from 'react';
import { Message, Button, Segment, Header, Divider } from 'semantic';
import { request } from 'utils/api';

import { Layout } from 'components';
import { urlForImage } from 'utils/uploads';
import { Link } from 'react-router-dom';
import './video-editor.less';
import AssetSelector from './AssetSelector';
import { AdaptiveVideo } from 'common/components/Video';

export default class GenerateVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }

  onSubmit = async () => {
    const { event } = this.props;
    try {
      this.setState({
        loading: true,
        error: null,
      });

      const images = [];
      const products = this.props.event.products || [];

      products.forEach((product) => {
        images.push(
          ...product.productImages
            .concat()
            .slice(0, 3)
            .map((productImage) => urlForImage({ id: productImage.item }))
            .filter(Boolean)
        );
      });

      const video = this.state.asset && this.state.asset.video;

      const { data } = await request({
        method: 'POST',
        path: `/1/videos/generate`,
        body: {
          images,
          ...(video
            ? {
                videoUrl: video.originalVideoURL,
                videoDuration: video.duration,
              }
            : {}),
        },
      });

      await request({
        method: 'PATCH',
        path: `/1/events/${event.id}`,
        body: {
          streams: [
            {
              chapters: [],
              products: [],
              type: 'main',
              video: data.id,
            },
          ],
        },
      });

      this.setState({
        loading: true,
      });

      await this.props.fetchEvent();
      this.props.history.push(`/events/${this.props.event.id}/videos`);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { event } = this.props;
    const { loading, error } = this.state;

    return (
      <div className="video-editor">
        <Divider horizontal />
        {error && <Message error content={this.state.error.message} />}
        <Message>
          This will generate a video based of the product's images (first 3 of each product). With an optional video
          clip added to the start of the video.
        </Message>
        <Divider horizontal>Products</Divider>
        <Layout horizontal>
          {event.products.map((product) => {
            return (
              <div key={product.id} style={{ paddingRight: '2em' }}>
                <h3 style={{ marginTop: 0 }}>
                  <Link to={`/products/${product.id}`}>{product.name}</Link>
                </h3>
                {product.productImages
                  .concat()
                  .slice(0, 3)
                  .map((productImage) => productImage.item)
                  .filter(Boolean)
                  .map((id) => (
                    <img
                      key={id}
                      style={{ verticalAlign: 'top', marginRight: '0.5em' }}
                      src={urlForImage(id, { width: 120 })}
                    />
                  ))}
              </div>
            );
          })}
        </Layout>

        <Divider horizontal>Video</Divider>

        {this.state.asset && (
          <div style={{ height: '300px', position: 'relative', marginBottom: '2em', marginTop: '1em' }}>
            <AdaptiveVideo controls video={this.state.asset.video} />
          </div>
        )}

        {!this.state.asset && (
          <Segment placeholder>
            <Header icon>Select a video that will be appended to the start of the generated video.</Header>

            <Segment.Inline>
              <AssetSelector
                onSave={(asset) =>
                  this.setState({
                    asset,
                  })
                }
                event={event}
                trigger={<Button basic content="Select Video" />}
              />
            </Segment.Inline>
          </Segment>
        )}

        <Layout horizontal right>
          {this.state.asset && (
            <AssetSelector
              onSave={(asset) =>
                this.setState({
                  asset,
                })
              }
              event={event}
              trigger={<Button basic content="Replace Video" />}
            />
          )}
          <Button primary loading={loading} content={'Generate Video'} onClick={this.onSubmit} />
          <Button basic as={Link} to={`/events/${event.id}/videos`}>
            Close
          </Button>
        </Layout>
      </div>
    );
  }
}
