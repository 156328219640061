import React from 'react';
import { Table, Button } from 'semantic';
import { request } from 'utils/api';

import { withSession } from 'stores';
import { formatDateTime } from 'utils/date';

import { SearchProvider, Status } from 'components/search';
import UserLink from 'admin/components/UserLink';
import QRCode from 'admin/components/QRCode';

@withSession
export default class Events extends React.Component {
  onDataNeeded = async () => {
    return await request({
      method: 'GET',
      path: `/1/events/${this.props.event.id}/links`,
    });
  };

  render() {
    return (
      <>
        <SearchProvider limit={1000} onDataNeeded={this.onDataNeeded}>
          {({ items }) => {
            return (
              <>
                <Status />
                {items.length !== 0 && (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>Short URL</Table.HeaderCell>
                        <Table.HeaderCell>QR Code</Table.HeaderCell>
                        <Table.HeaderCell>User</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={3}>
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <a target="_blank" href={item.longURL} rel="noreferrer">
                                {item.shortId}
                              </a>
                            </Table.Cell>
                            <Table.Cell>
                              <QRCode style={{ display: 'block', marginTop: '1em' }} data={item.shortURL} />
                            </Table.Cell>
                            <Table.Cell>
                              <UserLink user={item.user} />
                            </Table.Cell>
                            <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                            <Table.Cell textAlign="center">
                              <Button
                                style={{ boxShadow: 'none' }}
                                content="Copy"
                                onClick={() =>
                                  //only works on https
                                  navigator.clipboard.writeText(item.shortURL)
                                }
                                icon="clipboard"
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
