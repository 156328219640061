import React from 'react';
import { Component } from 'common/helpers';
import { Draggable } from 'common/components/Draggable';

import './track.less';

export default class Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: null,
      end: null,
    };
    this.ref = React.createRef();
  }

  // Lifecycle

  componentDidMount() {
    const { start, end } = this.props;
    this.setState({
      start,
      end,
    });
  }

  componentDidUpdate(lastProps) {
    const { start, end } = this.props;
    if (start !== lastProps.start) {
      this.setState({
        start,
      });
    }
    if (end !== lastProps.end) {
      this.setState({
        end,
      });
    }
  }

  // Events

  onStartHandleDragMove = (drag) => {
    let { start, end } = this.props;

    start += this.getTime(drag.x);
    start = Math.max(start, 0);
    start = Math.min(start, end);

    this.setState({
      start,
    });
  };

  onNameDragMove = (drag) => {
    let { start, end, duration } = this.props;
    const length = end - start;

    start += this.getTime(drag.x);
    start = Math.max(start, 0);
    start = Math.min(start, duration - length);

    end = start + length;

    this.setState({
      start,
      end,
    });
  };

  onEndHandleDragMove = (drag) => {
    let { start, end, duration } = this.props;

    end += this.getTime(drag.x);
    end = Math.max(end, start);
    end = Math.min(end, duration);

    this.setState({
      end,
    });
  };

  onDragEnd = () => {
    this.props.onChange(this.state);
  };

  getWidth() {
    return this.ref.current.clientWidth;
  }

  getX(time) {
    return time * (this.getWidth() / this.props.duration);
  }

  getTime(x) {
    return x / (this.getWidth() / this.props.duration);
  }

  render() {
    return (
      <div ref={this.ref} className={this.getElementClass('container')}>
        {this.renderInner()}
      </div>
    );
  }

  renderInner() {
    const { start, end } = this.state;
    if (start != null && end != null) {
      const left = this.getX(start);
      const width = this.getX(end - start);
      return (
        <div
          style={{
            left: `${left}px`,
            width: `${width}px`,
          }}
          className={this.getElementClass('')}
          {...this.getProps()}>
          <Draggable
            onDragMove={this.onStartHandleDragMove}
            onDragEnd={this.onDragEnd}
            className={this.getElementClass('handle', 'start')}
          />
          <Draggable
            onDragMove={this.onNameDragMove}
            onDragEnd={this.onDragEnd}
            onClick={this.props.onNameClick}
            className={this.getElementClass('name')}>
            {this.props.name}
          </Draggable>
          <Draggable
            onDragMove={this.onEndHandleDragMove}
            onDragEnd={this.onDragEnd}
            className={this.getElementClass('handle', 'end')}
          />
        </div>
      );
    }
  }
}
