import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Message, Divider, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';

import { ExternalLink } from 'components/Link';

import EditEventInvite from 'admin/modals/EditEventInvite';
import { SearchProvider } from 'components';

export default class EventsInvites extends React.Component {
  onDataNeeded = async (params) => {
    const { event } = this.props;
    return await request({
      method: 'POST',
      path: '/1/event-invites/search',
      body: {
        ...params,
        event: event.id,
      },
    });
  };

  render() {
    const event = this.props.event;
    return (
      <>
        <SearchProvider
          sort={{
            field: 'startsAt',
            order: 'desc',
          }}
          onDataNeeded={this.onDataNeeded}>
          {({ items, getSorted, setSort, reload }) => {
            return (
              <>
                <EditEventInvite
                  onSave={reload}
                  trigger={<Button primary content="Create Event Invite" />}
                  event={event}
                />

                <Divider hidden />

                {items.length === 0 ? (
                  <Message>No events created yet</Message>
                ) : (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>Sharable Link</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Capacity Type</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Users Accepted</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Initial Inviter</Table.HeaderCell>
                        <Table.HeaderCell onClick={() => setSort('createdAt')} sorted={getSorted('createdAt')}>
                          Created At
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <ExternalLink href={item.shareableLink}>
                                {item.shareableLink.replace('https://', '')}
                              </ExternalLink>
                              <Button
                                basic
                                style={{ boxShadow: 'none' }}
                                title="Copy Shareable Link"
                                onClick={() =>
                                  //only works on https
                                  navigator.clipboard.writeText(item.shareableLink)
                                }
                                icon="clipboard"
                              />
                            </Table.Cell>
                            <Table.Cell>{item.capacityType}</Table.Cell>
                            <Table.Cell>
                              {item.capacityType === 'limited'
                                ? `${item.userCount} / ${item.userLimit}`
                                : item.userCount}
                            </Table.Cell>
                            <Table.Cell>
                              <Link to={`/users/${item.user.id}`}>
                                {item.user?.firstName} {item.user?.lastName}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                            <Table.Cell textAlign="center">
                              <EditEventInvite
                                event={event}
                                eventInvite={item}
                                trigger={<Button style={{ marginLeft: '20px' }} basic icon="edit" />}
                                onSave={reload}
                              />
                              <Confirm
                                negative
                                confirmText="Delete"
                                header={`Are you sure you want to delete "${item.shareableLink}"?`}
                                content="The event invite link will stop working"
                                trigger={<Button basic icon="trash" />}
                                onConfirm={async () => {
                                  await request({
                                    method: 'DELETE',
                                    path: `/1/event-invites/${item.id}`,
                                  });
                                  reload();
                                }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
