import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'helpers';

@modal
export default class EditEventInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      event: { name: `${props.event.name} clone`, access: 'private' },
    };
  }

  setField(name, value) {
    this.setState({
      event: {
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
        touched: true,
      });

      await request({
        method: 'POST',
        path: `/1/events/${this.props.event.id}/clone`,
        body: this.state.event,
      });

      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { event, touched, loading, error, hasError } = this.state;
    return (
      <>
        <Modal.Header>Clone Event {this.props.event.name}</Modal.Header>
        <Modal.Content>
          <Message>
            <Message.Header>Please Note</Message.Header>
            <Message.Content>
              - The original event will control the broadcast/vods. Including when the event starts.
              <br />- Deleting the original event will cause all the cloned events to be deleted too.
            </Message.Content>
          </Message>
          <Form error={touched && (error || hasError)}>
            {error && <Message error content={error.message} />}
            <Form.Input
              required
              label="New Name"
              value={event.name}
              onChange={(e, { value }) => this.setField('name', value)}
            />
            <Form.Dropdown
              selection
              name="access"
              label="Access"
              value={event.access}
              options={[
                {
                  text: 'Public',
                  value: 'public',
                },
                {
                  text: 'Private',
                  value: 'private',
                },
              ]}
              onChange={(e, { value }) => this.setField('access', value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary loading={loading} disabled={loading} content="Clone" onClick={this.onSubmit} />
        </Modal.Actions>
      </>
    );
  }
}
