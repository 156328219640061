import React from 'react';
import { Table, Message, Divider, Loader, Menu as SemanticMenu } from 'semantic';
import { request } from 'utils/api';

import SearchProvider from 'common/components/SearchProvider';

import LoadButton from 'admin/components/LoadButton';

import Menu from './Menu';

export default class CreatorAccounInvites extends React.Component {
  state = {
    inviteType: 'invite',
  };

  onDataNeeded = async (params) => {
    const { creatorAccount } = this.props;
    return await request({
      method: 'POST',
      path: '/1/invites/search',
      body: {
        ...params,
        creatorAccount: creatorAccount.id,
        type: this.state.inviteType,
      },
    });
  };

  render() {
    const { creatorAccount } = this.props;

    return (
      <React.Fragment>
        <Menu {...this.props} />

        {creatorAccount ? (
          <SearchProvider key={this.state.inviteType} onDataNeeded={this.onDataNeeded}>
            {({ items, reload }) => {
              return (
                <React.Fragment>
                  <SemanticMenu borderless compact>
                    <SemanticMenu.Item header>Invite Type</SemanticMenu.Item>
                    <SemanticMenu.Item
                      active={this.state.inviteType === 'invite'}
                      onClick={() => this.setState({ inviteType: 'invite' })}>
                      Invites
                    </SemanticMenu.Item>
                    <SemanticMenu.Item
                      active={this.state.inviteType === 'magic'}
                      onClick={() => this.setState({ inviteType: 'magic' })}>
                      Magic Link
                    </SemanticMenu.Item>
                  </SemanticMenu>
                  <Divider hidden />
                  {items.length === 0 ? (
                    <Message>No invites created yet</Message>
                  ) : (
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>{this.state.inviteType == 'invite' ? 'Email' : 'Link Id'}</Table.HeaderCell>
                          <Table.HeaderCell>Role</Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {items.map((invite) => {
                          return (
                            <Table.Row key={invite.id}>
                              <Table.Cell>{invite.email || invite.id}</Table.Cell>
                              <Table.Cell>
                                {invite.roles
                                  .filter((r) => r.scopeRef === creatorAccount.id)
                                  .map((r) => r.roleDefinition?.name)
                                  .join(', ')}
                              </Table.Cell>
                              <Table.Cell collapsing>{invite.status}</Table.Cell>
                              <Table.Cell textAlign="center">
                                {this.state.inviteType === 'invite' && (
                                  <LoadButton
                                    basic
                                    icon="mail"
                                    title="Resend Invite"
                                    onClick={async () => {
                                      await request({
                                        method: 'POST',
                                        path: `/1/invites/${invite.id}/resend`,
                                      });
                                      reload();
                                    }}
                                  />
                                )}
                                <LoadButton
                                  basic
                                  icon="trash"
                                  title="Delete"
                                  onClick={async () => {
                                    await request({
                                      method: 'DELETE',
                                      path: `/1/invites/${invite.id}`,
                                    });
                                    reload();
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  )}
                </React.Fragment>
              );
            }}
          </SearchProvider>
        ) : (
          <Loader active>Loading</Loader>
        )}
      </React.Fragment>
    );
  }
}
