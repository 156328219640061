import React from 'react';
import { startCase } from 'lodash';
import { Table, Message, Divider, Loader, Button } from 'semantic';
import { formatDateTime } from 'utils/date';
import { formatAddress } from 'utils/formatting';
import { request } from 'utils/api';

import { Layout } from 'common/components/Layout';
import { Confirm } from 'common/components/Semantic';

import EditAddress from 'admin/modals/EditAddress';
import HelpTip from 'admin/components/HelpTip';
import SearchProvider from 'common/components/SearchProvider';

import Menu from './Menu';

export default class UserAddresses extends React.Component {
  onDataNeeded = async (params) => {
    const { user } = this.props;
    return await request({
      method: 'POST',
      path: '/1/addresses/search',
      body: {
        ...params,
        userId: user.id,
      },
    });
  };

  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {user ? (
          <SearchProvider onDataNeeded={this.onDataNeeded}>
            {({ items: addresses, getSorted, setSort, reload }) => {
              return (
                <React.Fragment>
                  <Divider hidden />
                  <Layout horizontal right>
                    <EditAddress
                      user={user}
                      onSave={reload}
                      trigger={<Button primary size="tiny" content="Add Address" icon="plus" />}
                    />
                  </Layout>
                  {addresses.length === 0 ? (
                    <Message>No addresses added yet</Message>
                  ) : (
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Address</Table.HeaderCell>
                          <Table.HeaderCell
                            width={3}
                            sorted={getSorted('createdAt')}
                            onClick={() => setSort('createdAt')}>
                            Created
                            <HelpTip title="Created" text="This is the date and time the item was created." />
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {addresses.map((address) => {
                          return (
                            <Table.Row key={address.id}>
                              <Table.Cell>{startCase(address.type)}</Table.Cell>
                              <Table.Cell>{formatAddress(address)}</Table.Cell>
                              <Table.Cell>{formatDateTime(address.createdAt)}</Table.Cell>
                              <Table.Cell textAlign="center">
                                <EditAddress
                                  user={user}
                                  address={address}
                                  onSave={reload}
                                  trigger={<Button style={{ marginLeft: '20px' }} basic icon="edit" />}
                                />
                                <Confirm
                                  negative
                                  confirmText="Delete"
                                  header={`Are you sure you want to delete address?`}
                                  content="All data will be permanently deleted"
                                  trigger={<Button basic icon="trash" />}
                                  onConfirm={async () => {
                                    await request({
                                      method: 'DELETE',
                                      path: `/1/addresses/${address.id}`,
                                    });
                                    reload();
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  )}
                </React.Fragment>
              );
            }}
          </SearchProvider>
        ) : (
          <Loader active>Loading</Loader>
        )}
      </React.Fragment>
    );
  }
}
