import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Profile from './Profile';
import Shopify from './Shopify';
import AccountSetting from './AccountSetting';

export default class Settings extends React.Component {
  render() {
    return (
      <Switch>
        <Redirect exact path="/settings" to="/settings/profile" />
        <Route exact path="/settings/profile" component={Profile} />
        <Route exact path="/settings/shopify" component={Shopify} />
        <Route exact path="/settings/account" component={AccountSetting} />
        <Redirect exact to="/settings/profile" />
      </Switch>
    );
  }
}
