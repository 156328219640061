import React from 'react';
import { Form, Modal, Message, Button, Header } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import { modal } from 'common/helpers';
import UploadsField from 'admin/components/form-fields/Uploads';

@modal
export default class EditCreatorAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      hasError: false,
      creatorAccount: props.creatorAccount || {},
    };
  }

  isUpdate() {
    return !!this.props.creatorAccount;
  }

  setField(name, value) {
    this.setState({
      creatorAccount: {
        ...this.state.creatorAccount,
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    try {
      const { creatorAccount } = this.state;
      this.setState({
        loading: true,
        touched: true,
      });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/creator-accounts/${creatorAccount.id}`,
          body: {
            ...creatorAccount,
            coverImage: creatorAccount.coverImage?.id || creatorAccount.coverImage || null,
            profileImage: creatorAccount.profileImage?.id || creatorAccount.profileImage || null,
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/creator-accounts',
          body: {
            ...creatorAccount,
            coverImage: creatorAccount.coverImage?.id,
            profileImage: creatorAccount.profileImage?.id,
          },
        });
      }
      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { creatorAccount, touched, loading, error, hasError } = this.state;
    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit "${creatorAccount.name}"` : 'New Creator Account'}</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form error={touched && (error || hasError)}>
              {error && <Message error content={error.message} />}
              <Form.Input
                name="name"
                label="Creator name"
                required
                type="text"
                value={creatorAccount.name || ''}
                onChange={(e, { value }) => this.setField('name', value)}
              />
              <Form.Input
                name="UserName"
                label="Username"
                value={creatorAccount.userName || ''}
                onChange={(e, { value }) => this.setField('userName', value)}
              />
              <Form.Input
                name="ownerEmail"
                label="Owner email"
                type="email"
                value={creatorAccount.ownerEmail || ''}
                onChange={(e, { value, name }) => this.setField(name, value.length ? value : null, true)}
              />
              <Form.TextArea
                name="description"
                label="Bio"
                type="text"
                value={creatorAccount.description || ''}
                onChange={(e, { value }) => this.setField('description', value.length ? value : null, true)}
              />
              <Form.TextArea
                name="checkoutMessage"
                label="Checkout Message"
                type="text"
                value={creatorAccount.checkoutMessage || ''}
                onChange={(e, { value }) => this.setField('checkoutMessage', value.length ? value : null, true)}
              />

              <Form.Input
                name="Website"
                label="Website"
                type="url"
                placeholder="https://www.company.com"
                value={creatorAccount.website || ''}
                onChange={(e, { value }) => this.setField('website', value.length ? value : null, true)}
              />
              <UploadsField
                label="Cover Picture"
                name="coverImage"
                value={creatorAccount.coverImage ? [creatorAccount.coverImage] : []}
                onChange={(value) => this.setField('coverImage', value[0])}
                onError={() => this.setState({ touched: true, hasError: true })}
              />
              <UploadsField
                label="Profile Picture"
                name="coverImage"
                value={creatorAccount.profileImage ? [creatorAccount.profileImage] : []}
                onChange={(value) => this.setField('profileImage', value[0])}
                onError={() => this.setState({ touched: true, hasError: true })}
              />
              <Header>Discounts</Header>

              <Form.Checkbox
                checked={creatorAccount.discountsEnabled}
                onChange={(e, { checked, name }) => this.setField(name, checked)}
                label="Enable discounts"
                name="discountsEnabled"
              />
              <Form.Input
                disabled={!creatorAccount.discountsEnabled}
                value={creatorAccount.discountsMaxPercentage || ''}
                type="number"
                min="0"
                max="100"
                name="discountsMaxPercentage"
                label="Max discount percentage"
                onChange={(e, { name, value }) => this.setField(name, Number(value))}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
