import React from 'react';
import { Link } from 'react-router-dom';
import { Confirm, Table, Button, Icon, Segment } from 'semantic';
import { formatDateTime } from 'utils/date';
import { searchEventStacks } from 'utils/event';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { withSession } from 'stores';

import EditEventStack from 'admin/modals/EditEventStack';

import { Breadcrumbs, HelpTip, Layout } from 'components';
import Protected from 'components/Protected';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';

const statusToName = {
  active: 'Active',
  draft: 'Draft',
};

const statusOptions = Object.keys(statusToName).map((status) => {
  return {
    value: status,
    text: statusToName[status],
  };
});

@screen
@withSession
export default class EventStacks extends React.Component {
  onDataNeeded = async (params) => {
    return await searchEventStacks(params);
  };

  render() {
    return (
      <>
        <SearchProvider
          sort={{
            field: 'startsAt',
            order: 'desc',
          }}
          onDataNeeded={this.onDataNeeded}>
          {({ items, getSorted, setSort, reload }) => {
            return (
              <>
                <Breadcrumbs active="Stacks" />
                <Layout horizontal center spread>
                  <h1>Stacks</h1>

                  <Layout.Group>
                    <EditEventStack trigger={<Button primary content="New Stack" icon="plus" />} onSave={reload} />
                  </Layout.Group>
                </Layout>

                <Segment>
                  <Layout horizontal center spread stackable>
                    <Layout horizontal>
                      <Filters.Modal>
                        <Filters.Dropdown name="status" label="Status" options={statusOptions} />
                      </Filters.Modal>
                    </Layout>
                    <Layout.Group>
                      <Filters.Search name="keyword" placeholder="Enter name" />
                    </Layout.Group>
                  </Layout>
                </Segment>
                <Status />

                {items.length !== 0 && (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell onClick={() => setSort('name')} sorted={getSorted('name')}>
                          Name
                        </Table.HeaderCell>
                        <Table.HeaderCell>Public?</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Protected endpoint="creatorAccounts" scope="global">
                          <Table.HeaderCell width={3}>Creator</Table.HeaderCell>
                        </Protected>
                        <Table.HeaderCell width={3} onClick={() => setSort('startsAt')} sorted={getSorted('startsAt')}>
                          Start Date
                          <HelpTip title="Created" text="The date and time of the event stack." />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={3}>
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <Link to={`/event-stacks/${item.id}`}>{item.name}</Link>
                              {item.shareableLink && (
                                <Button
                                  basic
                                  style={{ boxShadow: 'none' }}
                                  title="Copy Shareable Link"
                                  onClick={() =>
                                    //only works on https
                                    navigator.clipboard.writeText(item.shareableLink)
                                  }
                                  icon="clipboard"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {item.access === 'public' ? (
                                <Icon title="Public" name="check-circle" />
                              ) : (
                                <Icon title="Private" name="circle regular" />
                              )}
                            </Table.Cell>
                            <Table.Cell>{statusToName[item.status]}</Table.Cell>
                            <Protected endpoint="creatorAccounts" scope="global">
                              <Table.Cell>
                                {item.creatorAccount ? (
                                  <Link to={`/creator-accounts/${item.creatorAccount.id}`}>
                                    {item.creatorAccount.name}
                                  </Link>
                                ) : (
                                  '-'
                                )}
                              </Table.Cell>
                            </Protected>

                            <Table.Cell>{formatDateTime(item.startsAt)}</Table.Cell>
                            <Table.Cell textAlign="center">
                              <EditEventStack
                                eventStack={item}
                                trigger={<Button title="Edit Event Stack" basic icon="edit" />}
                                onSave={reload}
                              />
                              <Confirm
                                negative
                                confirmText="Delete"
                                header={`Are you sure you want to delete "${item.name}"?`}
                                content="All data (including clones) will be marked as deleted"
                                trigger={<Button title="Delete Event Stack" basic icon="trash" />}
                                onConfirm={async () => {
                                  await request({
                                    method: 'DELETE',
                                    path: `/1/stacks/${item.id}`,
                                  });
                                  reload();
                                }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
                <Layout center>
                  <Pagination />
                </Layout>
              </>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
