import React from 'react';
import { request } from 'utils/api';

import Menu from './Menu';
import { Link } from 'react-router-dom';

import { Table } from 'react-tectonic';
import { formatUsd } from 'utils/formatting';

export default class CreatorAccountSales extends React.Component {
  state = {};

  componentDidMount() {
    Promise.all([this.fetchSalesbyUser(), this.fetchSalesbyLink()]);
  }

  fetchSalesbyUser = async () => {
    this.setState({
      salesByUserStatus: { loading: true },
    });

    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/attributions/sales/users',
        params: {
          creatorAccount: this.props.creatorAccount.id,
        },
      });
      this.setState({
        salesByUserStatus: { success: true },
        salesByUserData: data,
      });
    } catch (e) {
      this.setState({
        salesByUserStatus: { error: e },
      });
    }
  };

  fetchSalesbyLink = async () => {
    this.setState({
      salesByLinkStatus: { loading: true },
    });

    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/attributions/sales/links',
        params: {
          creatorAccount: this.props.creatorAccount.id,
        },
      });
      this.setState({
        salesByLinkStatus: { success: true },
        salesByLinkData: data,
      });
    } catch (e) {
      this.setState({
        salesByLinkStatus: { error: e },
      });
    }
  };

  render() {
    return (
      <>
        <Menu {...this.props} />
        <Table
          status={this.state.salesByUserStatus}
          data={this.state.salesByUserData}
          title="Sales by User"
          titleAlign="center"
          valueFieldName="Sales"
          valueField="value"
          labelFieldName="User"
          labelFormatter={(item) => item}
          rowFormatter={(item) => {
            const { object: user } = item;

            return (
              <>
                <td>
                  <Link to={`/users/${user.id}`}>{[user.firstName, user.lastName].join(' ')}</Link>
                </td>
                <td>{formatUsd(item.value)}</td>
              </>
            );
          }}
        />
        <Table
          status={this.state.salesByLinkStatus}
          data={this.state.salesByLinkData}
          title="Sales by Link"
          titleAlign="center"
          valueFieldName="Sales"
          valueField="value"
          labelFieldName="Link"
          labelFormatter={(item) => item}
          rowFormatter={(item) => {
            const { object: link } = item;
            return (
              <>
                <td>
                  <a target="_blank" href={link.longURL} rel="noreferrer">
                    {link.longURL}
                  </a>
                </td>
                <td>{formatUsd(item.value)}</td>
              </>
            );
          }}
        />
      </>
    );
  }
}
