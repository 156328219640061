import React from 'react';
import { Form, Modal, Loader, Message } from 'semantic';

import { modal } from 'helpers';
import { withSession } from 'stores';
import SearchDropDown from 'common/components/SearchDropdown';
import Protected from 'components/Protected';
import { request } from 'utils/api';

@modal
@withSession
export default class ViewPublicUploadUrl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      creatorAccount: null,
      uploadSecret: null,
    };
  }

  getUrls = () => {
    const uploadSecret = this.state.uploadSecret || this.context.creatorAccount?.uploadSecret;
    if (!uploadSecret) return {};

    return {
      creatorAccount: `${window.location.origin}/admin/uploader?secret=${uploadSecret}`,
      personalized: `${window.location.origin}/admin/uploader?secret=${uploadSecret}&user=${this.context.user.id}`,
    };
  };

  fetchCreatorAccounts({ keyword } = {}) {
    return request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    }).then(({ data }) => data);
  }

  fetchSecret = async (creatorAccount) => {
    this.setState({
      loading: true,
    });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/creator-accounts/${creatorAccount.id}`,
      });

      this.setState({
        loading: false,
        uploadSecret: data.uploadSecret,
      });
    } catch (e) {
      this.setState({
        error: e,
        uploadSecret: null,
      });
    }
  };

  render() {
    const urls = this.getUrls();
    const { loading, error } = this.state;

    return (
      <>
        <Modal.Header>View Public Upload URLs</Modal.Header>
        <Modal.Content>
          <Form>
            {loading && <Loader />}
            {error && <Message error message={error.message} />}

            {!this.context.creatorAccount?.id && (
              <Protected endpoint="creatorAccounts" scope="global">
                <Form.Field required>
                  <label>Creator Account</label>
                  <SearchDropDown
                    fluid
                    value={this.state.creatorAccount}
                    onChange={(e, { value }) => {
                      this.setState({
                        creatorAccount: value,
                      });
                      this.fetchSecret(value);
                    }}
                    onDataNeeded={this.fetchCreatorAccounts}
                  />
                </Form.Field>
              </Protected>
            )}

            <Form.Input
              label="Personalize URL (only you will be able to see uploaded assets)"
              value={urls.personalized}
              disabled={!urls.personalized}
              readOnly
              onFocus={(e) => e.target.select()}
            />

            <Form.Input
              label="Creator URL (all members can see uploaded assets)"
              value={urls.creatorAccount}
              readOnly
              disabled={!urls.creatorAccount}
              onFocus={(e) => e.target.select()}
            />
          </Form>
        </Modal.Content>
      </>
    );
  }
}
