import React from 'react';
import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'common/helpers';
import CroppedImageField from 'common/components/form-fields/CroppedImage';

@modal
export default class EditEventImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };
    this.ref = React.createRef();
  }

  onImageError = (error) => {
    this.setState({
      error,
    });
  };

  onSubmit = async () => {
    const { eventStack } = this.props;
    try {
      const image = await this.ref.current.getImage();
      if (image) {
        this.setState({
          loading: true,
        });
        await request({
          method: 'PATCH',
          path: `/1/stacks/${eventStack.id}`,
          body: {
            coverImage: image.id || image,
          },
        });
        this.setState({
          loading: false,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { eventStack } = this.props;
    const { loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>Edit {eventStack.name} Cover Image</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form id="EditEventImage" onSubmit={this.onSubmit}>
            <CroppedImageField
              ref={this.ref}
              image={eventStack.coverImage}
              onError={this.onImageError}
              targetWidth={1080}
              targetHeight={1920}
              creatorAccount={eventStack.creatorAccount?.id}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary form="EditEventImage" loading={loading} disabled={loading} content="Save" />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
