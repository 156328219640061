import React, { useState, useMemo, useEffect } from 'react';

import { useTectonicContext } from 'react-tectonic';
import { Message } from 'semantic';
import PageLoader from 'components/PageLoader';
import { request } from 'utils/api';
import './styles.less';

const COLORS = [
  ['#2185d0', '#00b5ad'],
  ['#00b5ad', '#21ba45'],
  ['#21ba45', '#b5cc18'],
  ['#b5cc18', '#fbbd08'],
  ['#fbbd08', '#86B7E7'],
];

const steps = [
  {
    event: 'session-started',
    label: 'Users Entered',
  },
  {
    event: 'event-entered',
    label: 'Event Entered',
  },
  {
    event: 'product-added-to-cart',
    label: 'Product added to cart',
  },
  {
    event: 'buyflow-entered',
    label: 'Buyflow Entered',
  },
  {
    event: 'buyflow-completed',
    label: 'Buyflow completed',
  },
];

function fetchFunnel({ timeRange, timeZone, token }, filter = {}) {
  return Promise.all(
    steps
      .map((c) => c.event)
      .map((event) => {
        return request({
          path: `/1/analytics/cardinality`,
          method: 'POST',
          token: token,
          body: {
            fields: ['clientSessionId'],
            filter: {
              terms: [...(filter.terms || []), { type: { value: event } }],
              range: { occurredAt: { gte: timeRange.from, lt: timeRange.to, time_zone: timeZone } },
            },
            collection: 'enriched-events',
          },
        });
      })
  );
}

export function Funnel(props) {
  const tectonic = useTectonicContext();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([]);

  async function loadFunnel() {
    setLoading(true);
    setError(null);
    if (!tectonic.timeRange) {
      return;
    }

    let funnels = [];
    try {
      funnels = await fetchFunnel(tectonic, props.filter, [
        'session-started',
        'event-entered',
        'product-added-to-cart',
        'buyflow-entered',
        'buyflow-completed',
      ]);
    } catch (e) {
      setLoading(false);
      setError(e);
      return;
    }

    const totalUsers = funnels[0]?.data?.clientSessionId;

    setList(
      funnels
        .map(({ data }, index) => {
          const step = steps[index];
          const percent = (data.clientSessionId / totalUsers) * 100 || 0;

          return {
            ...step,
            number: data.clientSessionId,
            label: step.label,
            percent: percent.toFixed(2),
          };
        })
        .filter((c) => c.number)
    );
    setLoading(false);
  }

  useEffect(() => {
    loadFunnel();
  }, [tectonic.timeRange, props.filter]);

  const pointsList = useMemo(() => {
    if (!list[0]) return [];
    const allWidth = list[0].number;
    const deltas = list
      .slice(1)
      .concat(list[list.length - 1])
      .map((item) => {
        return (1 - item.number / allWidth) / 2;
      });

    return list.map((item, index) => {
      let points;
      if (index === 0) {
        points = [
          [0, 0],
          [1, 0],
          [1 - deltas[index], 1],
          [deltas[index], 1],
        ];
      } else {
        points = [
          [deltas[index - 1], 0],
          [1 - deltas[index - 1], 0],
          [1 - deltas[index], 1],
          [deltas[index], 1],
        ];
      }
      return {
        ...item,
        points,
      };
    });
  }, [list]);

  return (
    <div className="funnel">
      <Message warning>Data shown in this funnel are currently only for Web</Message>
      {loading && <PageLoader />}
      {error && <Message error content={error.message} />}
      {!loading && pointsList.length === 0 && <Message content="No data yet" />}
      {pointsList.map((item, index) => {
        const path = item.points.map((p) => p[0] * 100 + '% ' + p[1] * 100 + '%').join(',');
        return (
          <div key={index} className="funnelItem">
            <div
              className="funnelItem__box"
              style={{
                background: COLORS[index][0],
                clipPath: `polygon(${path})`,
              }}
            />
            <div className="funnelItem__textContainer">
              <div className="funnelItem__text">
                {item.label}
                <div className="funnelItem__number">
                  {item.number} users - conversion {item.percent}%
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
