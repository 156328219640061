import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Message, Divider, Label, Image } from 'semantic';

import { formatCurrency } from 'utils/formatting';
import { urlForImage } from 'utils/uploads';

export default class EventsInvites extends React.Component {
  render() {
    const items = this.props.event.products;
    return (
      <>
        <Divider hidden />

        {items.length === 0 ? (
          <Message>No products added to the event yet</Message>
        ) : (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={5}>Product</Table.HeaderCell>
                <Table.HeaderCell width={5}>Price</Table.HeaderCell>
                <Table.HeaderCell width={1}>Image</Table.HeaderCell>
                <Table.HeaderCell width={5}>SKU</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <Link to={`/products/${item.id}`}>{item.name}</Link>
                    </Table.Cell>
                    <Table.Cell>
                      {item.discount && <Label>Discount: {item.discount.name}</Label>}
                      {item.price && formatCurrency(item.price)}{' '}
                      {item.originalPrice && (
                        <span style={{ textDecoration: 'line-through' }}>{formatCurrency(item.originalPrice)}</span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {item.assets.length > 0 && (
                        <Image size="tiny" src={urlForImage(item.assets[0].id, { width: 150 })} />
                      )}
                    </Table.Cell>
                    <Table.Cell>{item.sku}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </>
    );
  }
}
