import React from 'react';
import { Modal, Button, Message } from 'semantic';
import LoadButton from './LoadButton';

export default function Confirm({
  trigger,
  defaultOpen = false,
  confirmButton,
  cancelButton,
  negative,
  content,
  onConfirm,
  header,
  onClose = () => {},
}) {
  const [open, setOpen] = React.useState(defaultOpen);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();

  async function handleOnClick() {
    setLoading(true);
    try {
      await onConfirm();
    } catch (e) {
      setLoading(false);
      setError(e);
      return;
    }
    setOpen(false);
  }

  function handleOnClose() {
    setOpen(false);
    onClose();
  }

  return (
    <Modal closeIcon onClose={handleOnClose} onOpen={() => setOpen(true)} open={open} trigger={trigger}>
      <Modal.Header>{header}</Modal.Header>

      <Modal.Content>
        <>
          {content}
          {error && (
            <Message
              style={{
                borderRadius: 0,
                marginTop: '1em',
              }}
              icon="exclamation-triangle"
              header={'Something went wrong'}
              error
              content={error.message}
            />
          )}
        </>
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={() => setOpen(false)}>
          {cancelButton || 'Cancel'}
        </Button>
        <LoadButton negative={negative} primary loading={loading} onClick={handleOnClick}>
          {confirmButton || 'OK'}
        </LoadButton>
      </Modal.Actions>
    </Modal>
  );
}
