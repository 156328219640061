import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Divider, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import Protected from 'common/components/Protected';

import EditUser from 'admin/modals/EditUser';

export default function UserDetailsMenu({ user, match, onSave }) {
  const { id } = match.params;
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/users">Users</Link>}
        active={`${user?.firstName} ${user?.lastName}` || 'Loading...'}
      />
      <Layout horizontal center spread>
        <h1>
          {user?.firstName} {user?.lastName}
        </h1>
        <Layout.Group>
          {user && (
            <EditUser user={user} onSave={onSave} trigger={<Button primary icon="setting" content="Settings" />} />
          )}
        </Layout.Group>
      </Layout>

      <Menu pointing secondary>
        <Menu.Item exact name="Overview" to={`/users/${id}`} as={NavLink} />
        <Protected endpoint="users" scope="global">
          <Menu.Item exact name="Addresses" to={`/users/${id}/addresses`} as={NavLink} />
          <Menu.Item exact name="Subscriptions" to={`/users/${id}/subscriptions`} as={NavLink} />
          <Menu.Item exact name="Sales Attribution" to={`/users/${id}/sales`} as={NavLink} />
          <Menu.Item exact name="Feed" to={`/users/${id}/feed`} as={NavLink} />
        </Protected>
        <Menu.Item exact name="Insights" to={`/users/${id}/insights`} as={NavLink} />
      </Menu>
      <Divider hidden></Divider>
    </React.Fragment>
  );
}
