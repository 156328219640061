import React from 'react';
import { Divider, Statistic, Segment } from 'semantic';

import { numberWithCommas, formatUsd } from 'utils/formatting';

import { AggregateTimeSeries, SeriesChart, AggregateStats, AggregateCardinality } from 'react-tectonic';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';

export default class InsightsOverview extends React.Component {
  render() {
    return (
      <>
        <Segment basic>
          <Divider hidden />

          <Statistic.Group widths="four">
            <AggregateStats
              filter={{
                terms: [...this.props.terms],
              }}
              fields={['occurredAt']}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(data['occurredAt'].count) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Tracking Events</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>
            <AggregateCardinality
              filter={{
                terms: [...this.props.terms],
              }}
              fields={['pipelineMeta.userId']}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? numberWithCommas(data['pipelineMeta.userId']) : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Users</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>
            <AggregateCardinality
              filter={{
                terms: [...this.props.terms],
              }}
              fields={['eventId']}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>{status.success ? numberWithCommas(data['eventId']) : '...'}</Statistic.Value>
                  <Statistic.Label>Events</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>
            <AggregateCardinality
              filter={{
                terms: [...this.props.terms],
              }}
              fields={['productId']}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>{status.success ? numberWithCommas(data['productId']) : '...'}</Statistic.Value>
                  <Statistic.Label>Products</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>
          </Statistic.Group>

          <AnalyticsBlock>
            <AggregateTimeSeries
              filter={{
                terms: [...this.props.terms, { type: { value: 'event-entered' } }],
              }}
              operation="count"
              dateField="occurredAt">
              <SeriesChart
                height={300}
                title="Views over Time"
                titleAlign="center"
                chartType="line"
                valueField="count"
                valueFieldLabel="Views"
              />
            </AggregateTimeSeries>
          </AnalyticsBlock>

          <AnalyticsBlock>
            <AggregateTimeSeries
              filter={{
                terms: [...this.props.terms, { type: { value: 'product-purchased' } }],
              }}
              field="product.price"
              operation="sum"
              dateField="occurredAt">
              <SeriesChart
                height={300}
                title="Revenue over Time"
                titleAlign="center"
                chartType="bar"
                valueField="value"
                valueFormatter={formatUsd}
              />
            </AggregateTimeSeries>
          </AnalyticsBlock>
        </Segment>
      </>
    );
  }
}
