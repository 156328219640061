import React from 'react';
import { Header, Table, Loader, Image } from 'semantic';
import NotFound from 'admin/components/NotFound';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';
import Roles from 'admin/components/Roles';

export default class UserOverview extends React.Component {
  render() {
    const { user, error } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {error ? (
          <NotFound message="Sorry that user wasn't found." />
        ) : !user ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <Header as="h1">
              {user.firstName} {user.lastName}
            </Header>
            <p>{user.description}</p>
            <Header as="h3">Images</Header>
            <Image.Group size="large">
              {user.profileImage && <Image key={user.profileImage.id} src={urlForUpload(user.profileImage)} />}
            </Image.Group>
            <Header as="h3">Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Email</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Username</Table.Cell>
                  <Table.Cell>{user.userName}</Table.Cell>
                </Table.Row>
                {user.roles && (
                  <Table.Row>
                    <Table.Cell>Roles</Table.Cell>
                    <Table.Cell>
                      <Roles roles={user.roles} />
                    </Table.Cell>
                  </Table.Row>
                )}

                {user.notificationTokens?.map((token) => {
                  return (
                    <Table.Row key={token.last4}>
                      <Table.Cell>Notifications Token</Table.Cell>
                      <Table.Cell>*****{token.last4}</Table.Cell>
                    </Table.Row>
                  );
                })}

                <Table.Row>
                  <Table.Cell>Created At</Table.Cell>
                  <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
                </Table.Row>
                {user.updatedAt && (
                  <Table.Row>
                    <Table.Cell>Updated At</Table.Cell>
                    <Table.Cell>{formatDateTime(user.updatedAt)}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
