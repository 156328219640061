import React from 'react';
import { Form, Modal, Loader, Message, Button } from 'semantic';

import { modal } from 'helpers';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';

@modal
export default class DeleteProduct extends React.Component {
  state = {
    loading: true,
    events: [],
  };

  componentDidMount() {
    this.fetchProductEvents();
  }

  fetchProductEvents = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/products/${this.props.product.id}/events`,
      });

      this.setState({
        loading: false,
        events: data,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  onDelete = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    try {
      await request({
        method: 'DELETE',
        path: `/1/products/${this.props.product.id}`,
      });

      this.props.close();
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  render() {
    const { loading, error } = this.state;

    return (
      <>
        <Modal.Header>Delete Product {this.props.product?.name}</Modal.Header>
        <Modal.Content>
          <Form>
            {loading && <Loader />}
            {error && <Message error content={error.message} />}
            <Message>All data will be permanently deleted</Message>
            {this.state.events?.length > 0 && (
              <Message error>
                {this.props.product?.name} cant be deleted, while its attached to the following events: <br />
                {this.state.events?.map((event) => {
                  return (
                    <div key={event.id}>
                      <Link to={`/events/${event.id}`}>{event.name}</Link>
                    </div>
                  );
                })}
              </Message>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.onDelete}
            primary
            loading={loading}
            disabled={loading || this.state.events.length}
            content={'Delete'}
          />
        </Modal.Actions>
      </>
    );
  }
}
