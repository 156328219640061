import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List/index';
import Consumers from './List/Consumers';
import Details from './Details';

export default class Users extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/users/consumers" component={Consumers} exact />
        <Route path="/users" component={List} exact />
        <Route path="/users/:id" component={Details} />
      </Switch>
    );
  }
}
