import React from 'react';
import { Form, Modal, Message, Button, Header } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import { modal } from 'common/helpers';

@modal
export default class EditCreatorAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      creatorAccount: props.creatorAccount || {},
      templates: props.creatorAccount?.emailTemplatelOverrides?.length
        ? props.creatorAccount?.emailTemplatelOverrides
        : [{ templateId: 'order-confirmation' }],
      currentTemplateId: 'order-confirmation',
    };
  }

  isUpdate() {
    return !!this.props.creatorAccount;
  }

  setField(name, value) {
    this.setState({
      templates: this.state.templates.map((template) => {
        if (template.templateId === this.state.currentTemplateId) {
          return {
            ...template,
            [name]: value,
          };
        }
        return template;
      }),
    });
  }

  removeTemplate = () => {
    this.setState({
      currentTemplateId: null,
      templates: this.state.templates.filter((template) => template.templateId !== this.state.currentTemplateId),
    });
  };

  onSubmit = async () => {
    try {
      const { templates } = this.state;
      this.setState({
        loading: true,
        touched: true,
      });

      await request({
        method: 'PATCH',
        path: `/1/creator-accounts/${this.props.creatorAccount.id}`,
        body: {
          ...this.props.creatorAccount,
          emailTemplatelOverrides: templates.filter((t) => t.body1 || t.header1 || t.subjectText),
        },
      });

      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { creatorAccount, loading, error, templates, currentTemplateId } = this.state;
    const formFields = templates.find((template) => template.templateId === currentTemplateId) || {};

    return (
      <>
        <Modal.Header>{`Edit "${creatorAccount.name}" Templates`}</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form error={!!error}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                selection
                label="Template"
                name="currentTemplateId"
                value={currentTemplateId}
                options={[
                  {
                    text: 'Order Confirmation',
                    value: 'order-confirmation',
                  },
                ]}
                onChange={(e, { name, value }) => this.setField(name, value.length ? value : null, true)}
              />

              <Form.Input
                name="subjectText"
                label="Subject Text"
                value={formFields.subjectText || ''}
                onChange={(e, { name, value }) => this.setField(name, value.length ? value : null, true)}
              />
              <Form.Input
                name="header1"
                label="Header"
                required
                type="text"
                value={formFields.header1 || ''}
                onChange={(e, { name, value }) => this.setField(name, value.length ? value : null, true)}
              />
              <Form.TextArea
                name="body1"
                label="Body"
                type="text"
                value={formFields.body1 || ''}
                onChange={(e, { name, value }) => this.setField(name, value.length ? value : null, true)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button primary loading={loading} disabled={loading} content={'Save'} onClick={this.onSubmit} />
        </Modal.Actions>
      </>
    );
  }
}
