import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import { modal } from 'common/helpers';

@modal
export default class EditEventInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      hasError: false,
      eventInvite: props.eventInvite || {
        event: this.props.event.id,
        capacityType: 'unlimited',
      },
    };
  }

  isUpdate() {
    return !!this.props.eventInvite;
  }

  setField(name, value) {
    this.setState({
      eventInvite: {
        ...this.state.eventInvite,
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    try {
      const { eventInvite } = this.state;
      this.setState({
        loading: true,
        touched: true,
      });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/event-invites/${eventInvite.id}`,
          body: {
            capacityType: eventInvite.capacityType,
            userLimit: eventInvite.userLimit,
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/event-invites',
          body: {
            ...eventInvite,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { eventInvite, touched, loading, error, hasError } = this.state;
    const { event } = this.props;
    return (
      <>
        <Modal.Header>
          {this.isUpdate() ? `Edit Invite for ${event.name}` : `New Invite for ${event.name}`}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form error={touched && (error || hasError)}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                required
                selection
                name="capacityType"
                label="Capacity Type"
                value={eventInvite.capacityType}
                options={[
                  {
                    text: 'Limited',
                    value: 'limited',
                  },
                  {
                    text: 'Unlimited',
                    value: 'unlimited',
                  },
                ]}
                onChange={(e, { value }) => this.setField('capacityType', value)}
              />
              <Form.Input
                required
                label="Capacity Type"
                disabled={eventInvite.capacityType !== 'limited'}
                value={eventInvite.userLimit}
                onChange={(e, { value }) => this.setField('userLimit', value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
