import React from 'react';
import { Pagination } from 'semantic';

const PREV_PAGE = {
  'aria-label': 'Previous item',
  content: 'Prev',
};

const NEXT_PAGE = {
  'aria-label': 'Next Page',
  content: 'Next',
};

export default ({ page, limit, total, onPageChange, className, renderEdges = true }) => {
  const prevDisabled = page === 1;
  const nextDisabled = page * limit - total >= 0;
  return (
    <Pagination
      className={className}
      prevItem={renderEdges ? { ...PREV_PAGE, disabled: prevDisabled } : null}
      nextItem={renderEdges ? { ...NEXT_PAGE, disabled: nextDisabled } : null}
      onPageChange={onPageChange}
      activePage={page}
      firstItem={null}
      lastItem={null}
      totalPages={Math.ceil(total / limit)}
    />
  );
};
