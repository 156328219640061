import React from 'react';
import { API_URL } from 'utils/env';
import { Menu } from 'semantic';

import { withSession } from 'stores';
import { TectonicProvider } from 'react-tectonic';
import { Funnel } from 'admin/components/Funnel';

@withSession
export default class EventInsights extends React.Component {
  state = {
    view: 'all',
    platform: 'all',
  };
  handleTimeRange = (stats) => {
    const { view } = this.state;
    const liveStream = this.props.event.streams.find((c) => c.type === 'live');

    this.setState({ stats });

    if (view === 'all') {
      return {
        from: stats.from,
        to: stats.to,
      };
    } else if (view === 'live') {
      return {
        from: liveStream.startedAt,
        to: liveStream.endedAt,
      };
    } else if (view === 'vod') {
      return {
        from: liveStream.endedAt,
        to: stats.to,
      };
    }
  };

  render() {
    const { event } = this.props;
    const termFilter = [
      this.state.platform !== 'all' && { 'pipelineMeta.origin': this.state.platform },
      {
        eventId: event.id,
      },
    ].filter(Boolean);

    const filter = {
      terms: termFilter,
    };

    return (
      <TectonicProvider
        key={this.state.view}
        collection="enriched-events"
        dateField="occurredAt"
        baseUrl={API_URL}
        token={this.context.token}
        primaryColor="#000000"
        getTimeRangeFromCollectionStats={this.handleTimeRange}
        statsFilter={{ terms: [...termFilter] }}
        onRequest={(url, options) => {
          if (this.context.creatorAccount?.id) {
            options.headers['creator-account-id'] = this.context.creatorAccount?.id;
          }
          return fetch(url, options);
        }}>
        <Menu borderless compact>
          <Menu.Item header>Platform</Menu.Item>
          <Menu.Item active={this.state.platform === 'all'} onClick={() => this.setState({ platform: 'all' })}>
            All
          </Menu.Item>
          <Menu.Item active={this.state.platform === 'web'} onClick={() => this.setState({ platform: 'web' })}>
            Web
          </Menu.Item>
          <Menu.Item active={this.state.platform === 'native'} onClick={() => this.setState({ platform: 'native' })}>
            Native (IOS)
          </Menu.Item>
        </Menu>
        <br />
        <br />

        <Funnel filter={filter} />
      </TectonicProvider>
    );
  }
}
