import React from 'react';
import { Header, Table, Loader, Image, Button } from 'semantic';
import NotFound from 'admin/components/NotFound';
import Menu from './Menu';

import { ExternalLink } from 'components/Link';
import { formatDateTime } from 'utils/date';
import { urlForImage } from 'utils/uploads';

export default class UserOverview extends React.Component {
  render() {
    const { creatorAccount, error } = this.props;
    const uploaderUrl = `${window.location.origin}/admin/uploader?secret=${creatorAccount.uploadSecret}`;

    return (
      <React.Fragment>
        <Menu {...this.props} />
        {error ? (
          <NotFound message="Sorry that Creator account wasn't found." />
        ) : !creatorAccount ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <Header as="h1">{creatorAccount.name}</Header>
            <p>{creatorAccount.description}</p>

            <Header as="h3">Cover</Header>
            <Image.Group size="large">
              {creatorAccount.coverImage && (
                <Image key={creatorAccount.coverImage.id} src={urlForImage(creatorAccount.coverImage)} />
              )}
            </Image.Group>
            <Header as="h3">Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Username</Table.Cell>
                  <Table.Cell>{creatorAccount.userName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Uploader Url</Table.Cell>
                  <Table.Cell>
                    <ExternalLink href={uploaderUrl}>{uploaderUrl.replace('https://', '')}</ExternalLink>
                    <Button
                      basic
                      style={{ boxShadow: 'none' }}
                      title="Copy Link"
                      onClick={() =>
                        //only works on https
                        navigator.clipboard.writeText(uploaderUrl)
                      }
                      icon="clipboard"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Created At</Table.Cell>
                  <Table.Cell>{formatDateTime(creatorAccount.createdAt)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Updated At</Table.Cell>
                  <Table.Cell>{formatDateTime(creatorAccount.updatedAt)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
