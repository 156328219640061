import React from 'react';
import { Loader, Segment, Divider, Statistic } from 'semantic';

import { numberWithCommas, formatUsd } from 'utils/formatting';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import { API_URL } from 'utils/env';

import {
  AggregateTerms,
  DonutChart,
  Table,
  TectonicProvider,
  AggregateStats,
  Aggregate,
  MultiSeriesChart,
} from 'react-tectonic';

import { withSession } from 'stores';

@withSession
export default class UserInsights extends React.Component {
  render() {
    const { user } = this.props;
    if (!user) return <Loader />;

    const orTerms = [
      {
        'pipelineMeta.userId': user.id,
      },
      user.clientSessions?.length && {
        clientSessionId: user.clientSessions.map((c) => c.id),
      },
    ].filter(Boolean);

    return (
      <React.Fragment>
        <Menu user={this.props.user} match={this.props.match} onSave={this.props.onSave} />
        <TectonicProvider
          dateField="occurredAt"
          baseUrl={API_URL}
          primaryColor="#f5f5f5"
          collection="enriched-events"
          token={this.context.token}
          statsFilter={{ orTerms }}
          onRequest={(url, options) => {
            if (this.context.creatorAccount?.id) {
              options.headers['creator-account-id'] = this.context.creatorAccount?.id;
            }
            return fetch(url, options);
          }}>
          <Segment basic>
            <Divider hidden />
            <Statistic.Group widths="three">
              <AggregateStats
                fields={['occurredAt']}
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'event-entered' } }],
                }}>
                {({ status, data }) => (
                  <Statistic>
                    <Statistic.Value>
                      {status.success ? `${numberWithCommas(data['occurredAt'].count)}` : '...'}
                    </Statistic.Value>
                    <Statistic.Label>Event Views</Statistic.Label>
                  </Statistic>
                )}
              </AggregateStats>
              <AggregateStats
                fields={['occurredAt']}
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'product-purchased' } }],
                }}>
                {({ status, data }) => (
                  <Statistic>
                    <Statistic.Value>
                      {status.success ? `${numberWithCommas(data['occurredAt'].count)}` : '...'}
                    </Statistic.Value>
                    <Statistic.Label>Purchases</Statistic.Label>
                  </Statistic>
                )}
              </AggregateStats>

              <AggregateStats
                fields={['occurredAt']}
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'product-added-to-cart' } }],
                }}>
                {({ status, data }) => (
                  <Statistic>
                    <Statistic.Value>
                      {status.success ? `${numberWithCommas(data['occurredAt'].count)}` : '...'}
                    </Statistic.Value>
                    <Statistic.Label>Added To Cart</Statistic.Label>
                  </Statistic>
                )}
              </AggregateStats>
            </Statistic.Group>

            <Divider hidden />
          </Segment>

          <AnalyticsBlock columns={2}>
            <React.Fragment>
              <AggregateTerms
                includeTopHit
                aggField="product.name"
                operation="sum"
                field="product.price"
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'product-added-to-cart' } }],
                }}
                termsSize={10}>
                <Table
                  title="Top Products by Product Added"
                  titleAlign="center"
                  enabledControls={[]}
                  valueFieldName="Sales"
                  valueField="value"
                  valueFormatter={formatUsd}
                  rowFormatter={(item, label, value) => {
                    const { productId, product } = item.topHit._source;
                    return (
                      <>
                        <td>
                          <Link to={`/products/${productId}`}>{product.name}</Link>
                        </td>
                        <td>{value}</td>
                      </>
                    );
                  }}
                />
              </AggregateTerms>
            </React.Fragment>
            <React.Fragment>
              <AggregateTerms
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'product-purchased' } }],
                }}
                aggField="product.name"
                termsSize={10}>
                <DonutChart
                  title="Top Products by Purchase"
                  titleAlign="center"
                  enabledControls={[]}
                  limit={5}
                  percent
                />
              </AggregateTerms>
            </React.Fragment>
          </AnalyticsBlock>

          <AnalyticsBlock>
            <Aggregate
              type="time-series"
              requests={[
                {
                  operation: 'count',
                  dateField: 'occurredAt',
                  filter: {
                    orTerms,
                    terms: [{ type: { value: 'product-viewed' } }],
                  },
                },
                {
                  operation: 'count',
                  dateField: 'occurredAt',
                  filter: {
                    orTerms,
                    terms: [{ type: { value: 'buyflow-entered' } }],
                  },
                },
                {
                  operation: 'count',
                  dateField: 'occurredAt',
                  filter: {
                    orTerms,
                    terms: [{ type: { value: 'product-purchased' } }],
                  },
                },
              ]}>
              <MultiSeriesChart
                title="Event View, Buy Action and Purchase Activity"
                titleAlign="center"
                enabledControls={[]}
                height={250}
                chartType="area"
                stacked
                valueField="count"
                labels={['Viewed Product', 'BuyFlow Entered', 'Purchased Product']}
              />
            </Aggregate>
          </AnalyticsBlock>

          <AnalyticsBlock columns={3}>
            <React.Fragment>
              <AggregateTerms
                includeTopHit
                aggField="eventId"
                operation="count"
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'product-added-to-cart' } }],
                }}
                termsSize={10}>
                <Table
                  title="Top Event by Product added"
                  titleAlign="center"
                  enabledControls={[]}
                  valueField="count"
                  valueFieldName="# Added to cart"
                  rowFormatter={(item, label, value) => {
                    const { event, eventId } = item.topHit._source;
                    return (
                      <>
                        <td>
                          <Link to={`/events/${eventId}`}>{event.name}</Link>
                        </td>
                        <td>{value}</td>
                      </>
                    );
                  }}
                />
              </AggregateTerms>
            </React.Fragment>
            <React.Fragment>
              <AggregateTerms
                includeTopHit
                aggField="eventId"
                operation="count"
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'buyflow-entered' } }],
                }}
                termsSize={10}>
                <Table
                  title="Top Event by Purchase Intent"
                  titleAlign="center"
                  enabledControls={[]}
                  valueField="count"
                  valueFieldName="# Checkouts started"
                  rowFormatter={(item, label, value) => {
                    const { event, eventId } = item.topHit._source;
                    return (
                      <>
                        <td>
                          <Link to={`/events/${eventId}`}>{event.name}</Link>
                        </td>
                        <td>{value}</td>
                      </>
                    );
                  }}
                />
              </AggregateTerms>
            </React.Fragment>

            <React.Fragment>
              <AggregateTerms
                includeTopHit
                aggField="eventId"
                operation="count"
                filter={{
                  orTerms,
                  terms: [{ type: { value: 'buyflow-completed' } }],
                }}
                termsSize={10}>
                <Table
                  title="Top Event by Purchase"
                  titleAlign="center"
                  enabledControls={[]}
                  valueField="count"
                  valueFieldName="# Checkouts started"
                  rowFormatter={(item, label, value) => {
                    const { event, eventId } = item.topHit._source;
                    return (
                      <>
                        <td>
                          <Link to={`/events/${eventId}`}>{event.name}</Link>
                        </td>
                        <td>{value}</td>
                      </>
                    );
                  }}
                />
              </AggregateTerms>
            </React.Fragment>
          </AnalyticsBlock>
        </TectonicProvider>
      </React.Fragment>
    );
  }
}
