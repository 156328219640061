import { request } from './api';

export function getEventProduct(event, productId) {
  if (productId) {
    return getEventProducts(event).find((p) => {
      return p.id === productId;
    });
  }
}

function getEventProducts(event) {
  const { products = [] } = event;
  return products.flatMap((p) => {
    return [p, ...(p.subProducts || [])];
  });
}

// This is needed as we are no longer populating events inside
// the event stack in the search API.
export async function searchEventStacks(params) {
  const { data, meta } = await request({
    method: 'POST',
    path: '/1/stacks/search',
    body: params,
  });

  return {
    data,
    meta,
  };
}
