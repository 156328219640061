import React from 'react';
import PropTypes from 'prop-types';
import { Form, Card, Icon, Image, Label } from 'semantic';
import { Component } from 'common/helpers';
import AssetPicker from 'common/components/AssetPicker';
import Uploads from 'common/components/Uploads';
import { urlForImage } from 'utils/uploads';

// TODO: dedupe this with UploadsField

const ALTERNATE_ICONS = {
  csv: 'excel',
  zip: 'archive',
  model: 'archive',
};

export default class AssetsField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      selectedAssets: [],
    };
  }

  // Lifecycle

  componentDidUpdate(lastProps) {
    const { creatorAccount } = this.props;
    if (creatorAccount !== lastProps.creatorAccount) {
      this.setState({
        showPicker: false,
        selectedAssets: [],
      });
    }
  }

  // Assets

  isAssetSelected(asset) {
    return this.state.selectedAssets.some((a) => {
      return a.id === asset.id;
    });
  }

  onChooseClick = (evt) => {
    evt.stopPropagation();
    this.setState({
      showPicker: true,
    });
  };

  onAssetClick = (asset) => {
    // TODO: resolve this with single prop
    if (Array.isArray(this.props.value)) {
      this.setState({
        selectedAssets: [...this.state.selectedAssets, asset],
      });
    } else {
      this.props.onChange(asset.upload, asset);
      this.setState({
        showPicker: false,
      });
    }
  };

  onUploadClick = () => {
    this.setState({
      showPicker: false,
    });
  };

  render() {
    // TODO: this isn't the cleanest but works for now
    const { required, label, type, value, creatorAccount } = this.props;
    const { showPicker } = this.state;
    return (
      <Form.Field required={required}>
        {label && <label>{label}</label>}
        {this.renderUploads()}
        {showPicker ? (
          <AssetPicker
            type={type}
            onAssetClick={this.onAssetClick}
            creatorAccount={creatorAccount}
            errorMessage={
              <p>
                <a style={{ cursor: 'pointer' }} onClick={this.onUploadClick}>
                  Back
                </a>
              </p>
            }
          />
        ) : (
          <Uploads restricted types={[type]} single={!Array.isArray(value)} onUpload={this.onUpload}>
            {({ acceptedMessage }) => {
              return (
                <React.Fragment>
                  <div>
                    {creatorAccount ? (
                      <React.Fragment>
                        Drag a file here, click to select, or{' '}
                        <a className="link" onClick={this.onChooseClick}>
                          choose from assets
                        </a>
                        .
                      </React.Fragment>
                    ) : (
                      <React.Fragment>Drag a file here, or click to select.</React.Fragment>
                    )}
                  </div>
                  {acceptedMessage}
                </React.Fragment>
              );
            }}
          </Uploads>
        )}
      </Form.Field>
    );
  }

  delete(upload) {
    const { value, onChange } = this.props;
    if (Array.isArray(value)) {
      onChange(
        value.filter((u) => {
          return u !== upload;
        })
      );
    } else {
      onChange(null);
    }
  }

  onUpload = (data) => {
    const { value, onChange } = this.props;
    if (Array.isArray(data)) {
      onChange([...value, ...data]);
    } else {
      onChange(data);
    }
  };

  renderUploads() {
    const { type, value, renderUploaded } = this.props;
    let uploads = [];
    if (Array.isArray(value)) {
      uploads = value;
    } else if (value) {
      uploads = [value];
    }
    if (renderUploaded) {
      return renderUploaded(type, value);
    }
    if (uploads.length) {
      return (
        <div style={{ marginBottom: '10px' }}>
          {['image', 'video', 'audio'].includes(type) ? (
            <Card.Group itemsPerRow={4}>
              {uploads.map((upload) => (
                <Card key={upload.id || upload}>
                  {this.renderUpload(upload)}
                  <Icon
                    name="delete"
                    color="blue"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '5px',
                      top: '5px',
                      zIndex: 1,
                    }}
                    onClick={() => this.delete(upload)}
                  />
                </Card>
              ))}
            </Card.Group>
          ) : (
            <Label.Group color="blue">
              {uploads.map((upload) => (
                <Label key={upload.id}>
                  {this.renderIconForType()}
                  {upload.filename}
                  <Icon name="delete" style={{ cursor: 'pointer' }} onClick={() => this.delete(upload)} />
                </Label>
              ))}
            </Label.Group>
          )}
        </div>
      );
    }
  }

  renderUpload(upload) {
    const [type] = upload.mimeType?.split('/') || ['image'];
    if (type === 'image') {
      return <Image key={upload.id || upload} src={urlForImage(upload)} />;
    } else if (type === 'video') {
      return <video style={{ width: '100%' }} src={urlForImage(upload)} controls />;
    } else if (type === 'audio') {
      return <audio src={urlForImage(upload)} controls />;
    }
  }

  renderIconForType() {
    const { type } = this.props;
    return <Icon name={`${ALTERNATE_ICONS[type] || type || ''} file outline`} />;
  }
}

AssetsField.propTypes = {
  creatorAccount: PropTypes.string,
  renderUploaded: PropTypes.func,
  type: PropTypes.oneOf(['image', 'video']),
  restricted: PropTypes.bool,
};

AssetsField.defaultProps = {
  type: 'image',
  restricted: true,
};
