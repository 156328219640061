import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';

import CountriesField from 'admin/components/form-fields/Countries';
import { modal } from 'common/helpers';

@modal
export default class EditAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      address: props.address || {
        type: 'billing',
        countryCode: 'US',
      },
    };
  }

  isUpdate() {
    return !!this.props.address;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      address: {
        ...this.state.address,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { user, onSave, close } = this.props;
      const { address } = this.state;
      let updatedAddress;
      if (this.isUpdate()) {
        const { data } = await request({
          method: 'PATCH',
          path: `/1/addresses/${address.id}`,
          body: {
            ...address,
            userId: user.id,
          },
        });
        updatedAddress = data;
      } else {
        const { data } = await request({
          method: 'POST',
          path: '/1/addresses',
          body: {
            ...address,
            userId: user.id,
          },
        });
        updatedAddress = data;
      }
      if (onSave) {
        onSave(updatedAddress, this.state);
      }
      close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { address, loading, error } = this.state;
    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit Address` : 'New Address'}</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form noValidate id="edit-address" error={!!error} onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                required
                selection
                name="type"
                label="Type"
                value={address.type}
                options={[
                  {
                    text: 'Billing',
                    value: 'billing',
                  },
                  {
                    text: 'Shipping',
                    value: 'shipping',
                  },
                  {
                    text: 'Both',
                    value: 'both',
                  },
                ]}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="firstName"
                label="First Name"
                value={address.firstName || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="lastName"
                label="Last Name"
                value={address.lastName || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="line1"
                label="Line 1"
                value={address.line1 || ''}
                onChange={this.setField}
              />
              <Form.Input
                type="text"
                name="line2"
                label="Line 2"
                value={address.line2 || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="city"
                label="City"
                value={address.city || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="state"
                label="State"
                value={address.state || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="postalCode"
                label="Postal Code"
                value={address.postalCode || ''}
                onChange={this.setField}
              />
              <CountriesField label="Country" name="countryCode" value={address.countryCode} onChange={this.setField} />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-address"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </>
    );
  }
}
