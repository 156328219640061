import React from 'react';
import { withSession } from 'stores';
import { NavLink, Link } from 'react-router-dom';
import { Container, Dropdown, Menu } from 'semantic';
import { formatProfileImage } from 'utils/formatting';
import Protected from 'common/components/Protected';
import logo from 'common/assets/logo-black.svg';

import SwitchAccountModal from 'common/modals/SwitchAccount';

@withSession
export default class AppWrapper extends React.Component {
  render() {
    const { user, creatorAccount } = this.context;
    return (
      <div>
        <Menu inverted fixed="top">
          <Container>
            <Menu.Item as={Link} to="/">
              <img style={{ width: '120px' }} className="logo" src={`${logo}`} />
            </Menu.Item>
            {user && (
              <React.Fragment>
                <Protected endpoint="events" scope="creatorAccount">
                  <Menu.Item as={NavLink} to="/events">
                    Events
                  </Menu.Item>
                </Protected>
                <Protected endpoint="products" scope="creatorAccount">
                  <Menu.Item as={NavLink} to="/products">
                    Products
                  </Menu.Item>
                </Protected>

                <Protected endpoint="creatorAccounts">
                  <Menu.Item as={NavLink} to="/creator-accounts">
                    Creators
                  </Menu.Item>
                </Protected>

                <Protected endpoint="orders">
                  <Menu.Item as={NavLink} to="/orders">
                    Orders
                  </Menu.Item>
                </Protected>
                <Protected endpoint="analytics" scope="creatorAccount">
                  <Menu.Item as={NavLink} to="/insights">
                    Insights
                  </Menu.Item>
                </Protected>
                <Menu.Menu position="right">
                  <Dropdown className="account" item trigger={<span>{this.renderProfileImage()}</span>}>
                    <Dropdown.Menu>
                      <React.Fragment>
                        <Protected endpoint="causes">
                          <Menu.Item as={NavLink} to="/causes">
                            Causes
                          </Menu.Item>
                        </Protected>
                        <Protected endpoint="users">
                          <Dropdown.Item as={Link} to="/users">
                            Users
                          </Dropdown.Item>
                        </Protected>

                        <Protected endpoint="users">
                          <Dropdown.Item as={Link} to="/invites">
                            Invites
                          </Dropdown.Item>
                        </Protected>

                        <Dropdown.Item as={Link} to="/docs/getting-started">
                          API Docs
                        </Dropdown.Item>
                      </React.Fragment>

                      <Protected endpoint="productOptions">
                        <Dropdown.Item as={Link} to="/product-options">
                          Product Options
                        </Dropdown.Item>
                      </Protected>
                      <Dropdown.Item as={Link} to="/settings">
                        Settings
                      </Dropdown.Item>
                      {creatorAccount && <SwitchAccountModal trigger={<Dropdown.Item>Accounts</Dropdown.Item>} />}
                      <Dropdown.Item as={Link} to="/logout">
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Menu>
              </React.Fragment>
            )}
          </Container>
        </Menu>
        <Container style={{ marginTop: '100px', paddingBottom: '100px' }}>{this.props.children}</Container>
      </div>
    );
  }

  renderProfileImage() {
    const { user, creatorAccount } = this.context;
    if (creatorAccount) {
      return (
        <span
          title={`Currently viewing as ${creatorAccount.name}. Select or remove from "Account" to change.`}
          style={{ display: 'inline-block', border: '2px solid #f38323', borderRadius: '50%' }}>
          {formatProfileImage(creatorAccount.profileImage)}
        </span>
      );
    } else {
      return formatProfileImage(user.profileImage);
    }
  }
}
