import { useEffect, useState } from 'react';
import { request } from 'utils/api';

export default function VideoRefresh({ event, children, reload, ...props }) {
  const [video, setVideo] = useState(props.video);

  async function fetchVideo() {
    const { data } = await request({
      method: 'GET',
      path: `/1/videos/${video.id}`,
    });

    // shotstack videos are once completed uploaded to mux, reload once the video has been updated to mux
    if (data.source === 'mux') {
      reload();
      return;
    }

    setVideo(data);
  }

  useEffect(() => {
    if (video?.source === 'shotstack' && video.status === 'preparing') {
      setTimeout(() => {
        fetchVideo();
      }, 1000);
    }
  }, [video]);

  return children;
}
