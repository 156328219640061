import React from 'react';

import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

export default () => {
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item content={'All'} to={`/assets`} exact as={NavLink} />
        <Menu.Item content={'Images'} to={`/assets/images`} exact as={NavLink} />
        <Menu.Item content={'Videos'} to={`/assets/videos`} exact as={NavLink} />
      </Menu>
    </Container>
  );
};
