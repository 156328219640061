import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import { DateField } from 'common/components/form-fields';
import SearchDropdown from 'common/components/SearchDropdown';
import AssetsField from 'admin/components/form-fields/Assets';
import VideoField from 'admin/components/form-fields/Video';
import { modal } from 'common/helpers';
import { withRouter } from 'react-router-dom';
import { withSession } from '../../stores';

@modal
@withRouter
@withSession
export default class EditEventStack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      hasError: false,
      enableChat: true,
      eventStack: props.eventStack || {
        status: 'draft',
        access: 'public',
        startsAt: new Date(),
      },
    };
  }

  componentDidMount() {
    if (this.props.eventStack?.id) {
      this.fetchEventStack();
    }
  }

  fetchEventStack = async () => {
    this.setState({
      loading: true,
    });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/stacks/${this.props.eventStack.id}`,
      });

      this.setState({
        loading: false,
        eventStack: data,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  isUpdate() {
    return !!this.props.eventStack;
  }

  setStackField(name, value) {
    this.setState({
      eventStack: {
        ...this.state.eventStack,
        [name]: value,
      },
    });
  }

  mapStackRefFields(eventStack, fallback) {
    return {
      ...eventStack,
      description: eventStack.description?.length ? eventStack.description : fallback,
      creatorAccount: eventStack.creatorAccount?.id,
      coverImage: eventStack.coverImage?.id || eventStack.coverImage || fallback,
      previewVideo: eventStack.previewVideo?.id || eventStack.previewVideo || fallback,
      events: (eventStack.events || []).map((e) => e.id),
    };
  }

  onSubmit = async () => {
    try {
      const { eventStack } = this.state;
      this.setState({
        loading: true,
      });
      let updatedEventStack;

      if (this.isUpdate()) {
        const { data } = await request({
          method: 'PATCH',
          path: `/1/stacks/${eventStack.id}`,
          body: {
            ...this.mapStackRefFields(eventStack, null),
          },
        });
        updatedEventStack = data;
      } else {
        const { data } = await request({
          method: 'POST',
          path: '/1/stacks',
          body: {
            ...this.mapStackRefFields(eventStack),
          },
        });
        updatedEventStack = data;
      }
      if (this.isUpdate()) {
        this.props.close();
        this.props.onSave(updatedEventStack);
      } else {
        this.props.history.push(`/event-stacks/${updatedEventStack.id}`);
      }
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  fetchCreatorAccounts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  fetchEvents = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/events/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  fetchCauses = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/causes/search',
      body: {
        name: keyword,
      },
    });
    return data;
  };

  render() {
    const { eventStack, loading, error, hasError } = this.state;
    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit "${eventStack.name}"` : 'New Stack'}</Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            <Form error={!!error || hasError}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                name="name"
                label="Name"
                type="text"
                value={eventStack.name || ''}
                onChange={(e, { value }) => this.setStackField('name', value)}
              />
              {this.context.canManageAccounts() && !this.context.creatorAccount?.id && !this.isUpdate() && (
                <Form.Field required>
                  <label>Creator Account</label>
                  <SearchDropdown
                    fluid
                    value={eventStack.creatorAccount}
                    onChange={(e, { value }) => {
                      this.setState({
                        eventStack: {
                          ...eventStack,
                          creatorAccount: value,
                        },
                      });
                    }}
                    onDataNeeded={this.fetchCreatorAccounts}
                  />
                </Form.Field>
              )}
              <Form.Field required>
                <label>Events</label>
                <SearchDropdown
                  fluid
                  multiple
                  value={eventStack.events}
                  onChange={(e, { value }) => {
                    this.setState({
                      eventStack: {
                        ...eventStack,
                        events: value,
                      },
                    });
                  }}
                  onDataNeeded={this.fetchEvents}
                />
              </Form.Field>
              <Form.Group>
                <Form.Dropdown
                  selection
                  name="access"
                  label="Access"
                  value={eventStack.access || 'public'}
                  options={[
                    {
                      text: 'Public',
                      value: 'public',
                    },
                    {
                      text: 'Private',
                      value: 'private',
                    },
                  ]}
                  onChange={(e, { value }) => this.setStackField('access', value)}
                />
                <Form.Dropdown
                  selection
                  name="status"
                  label="Status"
                  value={eventStack.status || ''}
                  options={[
                    {
                      text: 'Active',
                      value: 'active',
                    },
                    {
                      text: 'Draft',
                      value: 'draft',
                    },
                  ]}
                  onChange={(e, { value }) => this.setStackField('status', value)}
                />
              </Form.Group>
              <DateField
                time
                clearable
                required
                name="startsAt"
                value={eventStack.startsAt}
                label="Start Date"
                onChange={(e, { value }) => this.setStackField('startsAt', value)}
              />
              <DateField
                time
                clearable
                required
                name="endsAt"
                value={eventStack.endsAt}
                label="End Date"
                onChange={(e, { value }) => this.setStackField('endsAt', value)}
              />
              <Form.TextArea
                name="description"
                label="Description"
                type="text"
                value={eventStack.description || ''}
                onChange={(e, { value }) => this.setStackField('description', value)}
              />
              <AssetsField
                label="Cover Image"
                required
                name="coverImage"
                value={eventStack.coverImage || null}
                onChange={(upload) => this.setStackField('coverImage', upload)}
                onError={() => this.setState({ hasError: true })}
                creatorAccount={eventStack.creatorAccount?.id}
              />
              <VideoField
                label="Video Preview"
                value={eventStack.previewVideo}
                onChange={(video) => {
                  this.setStackField('previewVideo', video);
                }}
                creatorAccount={eventStack.creatorAccount?.id}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
