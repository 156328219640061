import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Divider, Button, Icon, Dropdown } from 'semantic';
import { TimeRangePicker } from 'react-tectonic';
import { Layout, Breadcrumbs } from 'components';

import Overview from './Overview';
import Events from './Events';
import Sales from './Sales';
import Tracking from './Tracking';
import TrackingDetails from './TrackingDetails';
import Funnel from './Funnel';
import Menu from './Menu';

import { API_URL } from 'utils/env';

import { TectonicProvider } from 'react-tectonic';
import { screen } from 'helpers';
import { withSession } from 'stores';

@screen
@withSession
export default class Insights extends React.Component {
  state = {
    timeRange: {
      label: 'Last 7 days',
      type: 'fixed',
      to: 'now',
      from: 'now-7d/d',
    },
    platform: 'all',
  };

  getTerms() {
    if (this.state.platform === 'all') {
      return [];
    }
    return [
      {
        'pipelineMeta.origin': { value: this.state.platform },
      },
    ];
  }

  render() {
    const terms = this.getTerms();
    return (
      <>
        <TectonicProvider
          dateField="occurredAt"
          baseUrl={API_URL}
          collection="enriched-events"
          token={this.context.token}
          timeRange={this.state.timeRange}
          primaryColor="#000000"
          onRequest={(url, options) => {
            if (this.context.creatorAccount?.id) {
              options.headers['creator-account-id'] = this.context.creatorAccount?.id;
            }
            return fetch(url, options);
          }}>
          <Breadcrumbs link={<Link to="/insights">Insights</Link>} />
          <Layout horizontal center spread>
            <h1>Insights</h1>

            <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
              <Dropdown
                button
                className="button"
                value={this.state.platform}
                options={[
                  {
                    key: 'all',
                    value: 'all',
                    text: 'All',
                  },
                  {
                    key: 'native',
                    value: 'native',
                    text: 'Native (IOS)',
                  },
                  {
                    key: 'web',
                    value: 'web',
                    text: 'Web',
                  },
                ]}
                text={`Platform (${this.state.platform})`}
                onChange={(e, { value }) => {
                  this.setState({ platform: value });
                }}
              />

              <TimeRangePicker
                align="right"
                renderButton={(label, onClick) => (
                  <Button
                    style={{
                      position: 'relative',
                      top: '0',
                      margin: '-.5em 0',
                      paddingBottom: '.78571429em',
                      paddingTop: '.78571429em',
                      fontSize: '1em',
                    }}
                    onClick={onClick}>
                    <Icon name="calendar" /> {label}
                  </Button>
                )}
              />
            </div>
          </Layout>
          <Menu />
          <Divider hidden />
          <Switch>
            <Route exact path="/insights" render={(props) => <Overview terms={terms} {...props} />} />
            <Route exact path="/insights/events" render={(props) => <Events terms={terms} {...props} />} />
            <Route exact path="/insights/funnel" render={(props) => <Funnel terms={terms} {...props} />} />
            <Route exact path="/insights/sales" render={(props) => <Sales terms={terms} {...props} />} />
            <Route exact path="/insights/tracking" render={(props) => <Tracking terms={terms} {...props} />} />
            <Route
              exact
              path="/insights/tracking-details"
              render={(props) => <TrackingDetails terms={terms} {...props} />}
            />
          </Switch>
        </TectonicProvider>
      </>
    );
  }
}
