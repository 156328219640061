import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'semantic';
import { Component } from 'common/helpers';

import './text.less';

export default class TextField extends Component {
  computeRemaining() {
    const { value, maxLength } = this.props;
    if (maxLength) {
      const val = maxLength - value.length;
      const abs = Math.abs(val);
      const over = val < 0;
      return {
        abs,
        over,
      };
    }
  }

  hasError() {
    const { error } = this.props;
    const remaining = this.computeRemaining();
    return error || (remaining ? remaining.over : false);
  }

  render() {
    const { className, ...rest } = this.getProps();
    return (
      <div className={className}>
        <Form.TextArea {...rest} error={this.hasError()} />
        {this.renderMaxLength()}
      </div>
    );
  }

  renderMaxLength() {
    const remaining = this.computeRemaining();
    if (remaining) {
      const { abs, over } = remaining;
      return (
        <div className={this.getElementClass('max-length', over ? 'over' : null)}>
          {abs} character{abs === 1 ? '' : 's'} {over ? 'over' : 'remaining'}
        </div>
      );
    }
  }
}

TextField.propTypes = {
  maxLength: PropTypes.number,
};

TextField.defaultProps = {};
