import React from 'react';
import { Form, Input, Button, Message } from 'semantic';
import AutoFocus from 'admin/components/AutoFocus';

export default (props) => {
  const { error, loading } = props;
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  return (
    <AutoFocus>
      <Form
        error={!!error}
        size="large"
        onSubmit={() => {
          props.onSubmit({
            email,
            password,
          });
        }}>
        {error && <Message error content={error.message} />}
        <Form.Input
          label="Email"
          value={email}
          onChange={(e, { value }) => setEmail(value)}
          name="email"
          placeholder="E-mail Address"
          type="email"
          autoComplete="email"
        />

        <Form.Input
          label="Password"
          value={password}
          onChange={(e, { value }) => setPassword(value)}
          name="password"
          placeholder="Password"
          autoComplete="current-password"
          type="password"
        />

        <Form.Button fluid primary size="large" content="Login" loading={loading} disabled={loading} />
      </Form>
    </AutoFocus>
  );
};
