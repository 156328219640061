import React from 'react';
import { Table, Message, Divider, Loader } from 'semantic';
import { request } from 'utils/api';

import SearchProvider from 'common/components/SearchProvider';
import { formatDateTime } from 'utils/date';

import { Link } from 'react-router-dom';

export default class EventSubscribers extends React.Component {
  onDataNeeded = async () => {
    const { event } = this.props;
    return await request({
      method: 'GET',
      path: `/1/events/${event.id}/subscribers`,
    });
  };

  render() {
    const { event } = this.props;

    return (
      <>
        {event ? (
          <>
            <SearchProvider onDataNeeded={this.onDataNeeded}>
              {({ items }) => {
                return (
                  <>
                    <Divider hidden />
                    {items.length === 0 ? (
                      <Message>No subscribtion yet</Message>
                    ) : (
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Subscribed At</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {items.map(({ user, createdAt }) => {
                            return (
                              <Table.Row key={user.id}>
                                <Table.Cell>
                                  <Link to={`/users/${user.id}`}>
                                    {user.firstName} {user.lastName}
                                  </Link>
                                </Table.Cell>
                                <Table.Cell>{formatDateTime(createdAt)}</Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    )}
                  </>
                );
              }}
            </SearchProvider>
          </>
        ) : (
          <Loader active>Loading</Loader>
        )}
      </>
    );
  }
}
