import React from 'react';
import { NotificationContext } from 'stores/notifications';
import Confirm from 'admin/components/Confirm';

export default function Notifications() {
  const { notification, clear } = React.useContext(NotificationContext);

  if (!notification) {
    return null;
  }

  return (
    <Confirm
      onClose={() => {
        clear();
      }}
      defaultOpen
      {...notification}
    />
  );
}
