import React from 'react';

import FetchObject from 'components/FetchObject';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic';

export default ({ user }) => (
  <FetchObject id={user} endpoint="users/public">
    {(user) => {
      const isSuperAdmin = user.roles?.find((c) => c.role === 'superAdmin');

      return (
        <Link to={`/users/${user.id}`}>
          {[user.firstName, user.lastName].join(' ')}
          {isSuperAdmin ? (
            <>
              {' '}
              <Icon size="small" name="globe-americas" />{' '}
            </>
          ) : (
            ''
          )}
        </Link>
      );
    }}
  </FetchObject>
);
