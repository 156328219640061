import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Message } from 'semantic';
import { Component } from 'common/helpers';
import Asset from '../Asset';
import SearchProvider from '../SearchProvider';
import { request } from 'utils/api';
import { withSession } from 'stores';

@withSession
export default class AssetsField extends Component {
  fetchAssets = async (params) => {
    if (params.creatorAccount) {
      return await request({
        method: 'POST',
        path: '/1/assets/search',
        body: params,
      });
    }
  };

  getCreatorAccount() {
    // TODO: clean up creatorAccount vs creatorAccountId
    return this.props.creatorAccount || this.context.creatorAccount?.id;
  }

  // Assets

  isAssetSelected(asset) {
    const { selected } = this.props;
    return selected.some((a) => {
      return a.id === asset.id;
    });
  }

  render() {
    // TODO: can this just be creatorAccountId?
    const { type } = this.props;
    const creatorAccount = this.getCreatorAccount();
    return (
      <SearchProvider limit={18} onDataNeeded={this.fetchAssets} filters={{ type, creatorAccount }}>
        {({ items: assets }) => {
          let errorMsg;
          if (!creatorAccount) {
            errorMsg = 'No creator account found.';
          } else if (!assets.length) {
            errorMsg = `No ${type}s have been uploaded for this company yet.`;
          }
          if (errorMsg) {
            return (
              <React.Fragment>
                <Message>
                  <Message.Header>{errorMsg}</Message.Header>
                  {this.props.errorMessage && <Message.Content>{this.props.errorMessage}</Message.Content>}
                </Message>
                {this.props.errorAction}
              </React.Fragment>
            );
          }
          return (
            <Grid padded="vertically" columns={6}>
              {assets.map((asset) => {
                return (
                  <Grid.Column key={asset.id} style={{ padding: '2px' }}>
                    <Asset
                      asset={asset}
                      onClick={() => {
                        this.props.onAssetClick(asset);
                      }}
                      selected={this.isAssetSelected(asset)}
                    />
                  </Grid.Column>
                );
              })}
            </Grid>
          );
        }}
      </SearchProvider>
    );
  }
}

AssetsField.propTypes = {
  type: PropTypes.oneOf(['image', 'video']),
  onAssetClick: PropTypes.func.isRequired,
  creatorAccount: PropTypes.string,
};

AssetsField.defaultProps = {
  type: 'image',
  selected: [],
};
