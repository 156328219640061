import React from 'react';
import { Label } from 'semantic';
import { ExternalLink } from 'common/components/Link';

import shipbob from 'common/assets/shipbob.svg';
import shopify from 'common/assets/shopify.svg';
import { startCase } from 'lodash';

export default function FulFillmentLabel({ order, style }) {
  function getColor() {
    switch (order.fulfillmentStatus) {
      case 'shipped':
        return 'olive';
      case 'onhold':
        return 'yellow';
      default:
        return 'grey';
    }
  }

  function getLogo() {
    if (order.fulfillmentProvider === 'shipbob') {
      return (
        <img
          src={shipbob}
          style={{
            width: '14px',
            height: '14px',
            verticalAlign: 'middle',
            marginLeft: '5px',
            ...style,
          }}
        />
      );
    }
    if (order.fulfillmentProvider === 'shopify') {
      return (
        <img
          src={shopify}
          style={{
            width: '14px',
            height: '14px',
            verticalAlign: 'middle',
            marginLeft: '5px',
            ...style,
          }}
        />
      );
    }
  }

  return (
    <ExternalLink href={order.fulfillmentUrl}>
      <Label color={getColor()}>{startCase(order.fulfillmentStatus || 'unknown')} </Label>
      {getLogo()}
    </ExternalLink>
  );
}
