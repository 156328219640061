import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Table, Button, Segment, Image, Dropdown, Icon } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { urlForImage } from 'utils/uploads';
import { screen } from 'helpers';
import { withSession } from 'stores';
import Protected from 'components/Protected';
import EditProduct from 'admin/modals/EditProduct';
import Actions from '../Actions';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';

import { Breadcrumbs, HelpTip, Layout } from 'components';

@screen
@withSession
export default class Products extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        ...params,
        creatorAccount: this.context.creatorAccount?.id,
        types: ['individual', 'bundle', 'bundled', 'base'],
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
      },
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({ items, getSorted, setSort, reload }) => {
          return (
            <Container>
              <Header as="h2">
                <Breadcrumbs active="Events" />
                <Layout horizontal center spread>
                  Products
                  <div>
                    <Dropdown
                      button
                      floating
                      className="primary"
                      icon={null}
                      trigger={
                        <Layout horizontal spread>
                          <Icon name="plus"></Icon>
                          <div>New Product</div>
                        </Layout>
                      }>
                      <Dropdown.Menu>
                        <EditProduct
                          product={{ type: 'individual' }}
                          onSave={reload}
                          trigger={<Dropdown.Item>New Individual</Dropdown.Item>}
                        />
                        {/*
                        <EditProduct
                          product={{ type: 'bundle' }}
                          onSave={reload}
                          trigger={<Dropdown.Item>New Bundle</Dropdown.Item>}
                        />
                        <EditProduct
                          product={{ type: 'bundled' }}
                          onSave={reload}
                          trigger={<Dropdown.Item>New Bundled Item</Dropdown.Item>}
                        />
                        */}
                        <EditProduct
                          product={{ type: 'base' }}
                          onSave={reload}
                          trigger={<Dropdown.Item>New Base</Dropdown.Item>}
                        />
                        <EditProduct
                          product={{ type: 'variant' }}
                          onSave={reload}
                          trigger={<Dropdown.Item>New Variant</Dropdown.Item>}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Layout>
              </Header>
              <Segment>
                <Layout horizontal center spread stackable>
                  <Layout horizontal></Layout>
                  <Layout.Group>
                    <Filters.Search name="keyword" placeholder="Enter name" />
                  </Layout.Group>
                </Layout>
              </Segment>
              <Status />
              {items.length !== 0 && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>Image</Table.HeaderCell>
                      <Table.HeaderCell sorted={getSorted('name')} onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Protected endpoint="creatorAccounts" scope="global">
                        <Table.HeaderCell width={2}>Creator</Table.HeaderCell>
                      </Protected>
                      <Table.HeaderCell width={1}>Inventory</Table.HeaderCell>
                      <Table.HeaderCell width={3} sorted={getSorted('createdAt')} onClick={() => setSort('createdAt')}>
                        Created
                        <HelpTip title="Created" text="This is the date and time the product was created." />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => {
                      return (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {item.assets.length > 0 && (
                              <Image size="tiny" src={urlForImage(item.assets[0].id, { width: 150 })} />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Link to={`/products/${item.id}`}>
                              {item.name} [{item.type}]
                            </Link>
                          </Table.Cell>
                          <Protected endpoint="creatorAccounts" scope="global">
                            <Table.Cell>
                              {item.creatorAccount ? (
                                <Link to={`/creator-accounts/${item.creatorAccount.id}`}>
                                  {item.creatorAccount.name}
                                </Link>
                              ) : (
                                '-'
                              )}
                            </Table.Cell>
                          </Protected>
                          <Table.Cell>{item.inventory || '-'}</Table.Cell>
                          <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                          <Table.Cell textAlign="center">
                            <EditProduct
                              product={item}
                              trigger={<Button title="Edit" basic icon="edit" />}
                              onSave={reload}
                            />

                            <Dropdown button basic text="More">
                              <Dropdown.Menu>
                                <Actions item={item} onReload={reload} />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
              <Layout center>
                <Pagination />
              </Layout>
            </Container>
          );
        }}
      </SearchProvider>
    );
  }
}
