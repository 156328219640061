import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Divider, Table } from 'semantic';
import { formatDateTime } from 'utils/date';
import { formatCurrency } from 'utils/formatting';

import shipbob from 'common/assets/shipbob.svg';

import shopify from 'common/assets/shopify.svg';

import { Layout } from 'common/components/Layout';
import { ExternalLink } from 'common/components/Link';

import ReactMarkdown from 'react-markdown';

export default class ProductOverview extends React.Component {
  render() {
    const { product } = this.props;
    return (
      <div>
        <Layout horizontal center>
          <Header as="h1">{product.name}</Header>
        </Layout>
        <Divider hidden />
        <p>
          <ReactMarkdown>{product.description}</ReactMarkdown>
        </p>
        <Table definition>
          <Table.Body>
            {product.parent && (
              <Table.Row>
                <Table.Cell>Parent</Table.Cell>
                <Table.Cell>
                  <Link to={`/products/${product.parent}`}>View Parent</Link>
                </Table.Cell>
              </Table.Row>
            )}
            {product.creatorAccount && (
              <Table.Row>
                <Table.Cell>Creator</Table.Cell>
                <Table.Cell>
                  <Link to={`/creator-accounts/${product.creatorAccount?.id}`}>{product.creatorAccount.name}</Link>
                </Table.Cell>
              </Table.Row>
            )}

            {product.fulfillmentUrl && (
              <Table.Row>
                <Table.Cell>Fulfillment Url</Table.Cell>
                <Table.Cell>
                  <ExternalLink href={product.fulfillmentUrl}>
                    <img
                      src={product.fulfillmentProvider === 'shipbob' ? shipbob : shopify}
                      style={{
                        width: '18px',
                        height: '18px',
                        marginRight: '10px',
                        verticalAlign: 'sub',
                      }}
                    />
                    {product.fulfillmentUrl}
                  </ExternalLink>
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>SKU</Table.Cell>
              <Table.Cell>{product.sku || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Inventory</Table.Cell>
              <Table.Cell>{product.inventory || '-'}</Table.Cell>
            </Table.Row>
            {product.price && (
              <Table.Row>
                <Table.Cell>Price</Table.Cell>
                <Table.Cell>{formatCurrency(product.price)}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(product.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(product.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
