export default class Rect {
  constructor(top, left, width, height) {
    this.top = top;
    this.left = left;
    this.width = width;
    this.height = height;
  }

  clone() {
    return new Rect(this.top, this.left, this.width, this.height);
  }

  get right() {
    return this.left + this.width;
  }

  get bottom() {
    return this.top + this.height;
  }

  get ratio() {
    return this.width / this.height;
  }

  constrain(ratio, gx = 0.5, gy = 0.5) {
    if (ratio < this.ratio) {
      const dw = this.width - this.height * ratio;
      this.width -= dw;
      this.left += dw * gx;
    } else if (ratio > this.ratio) {
      const dh = this.height - this.width / ratio;
      this.height -= dh;
      this.top += dh * gy;
    }
  }

  offset(rect) {
    this.top -= rect.top;
    this.left -= rect.left;
  }

  scale(scale) {
    this.top *= scale;
    this.left *= scale;
    this.width *= scale;
    this.height *= scale;
  }
}
