import React from 'react';
import { Segment, Divider } from 'semantic';

import { Funnel } from 'admin/components/Funnel';

export default class FunnelScreen extends React.Component {
  render() {
    const filter = {
      terms: this.props.terms,
    };
    return (
      <>
        <Segment basic>
          <Divider hidden />
          <Funnel filter={filter} />

          <Divider hidden />
        </Segment>
      </>
    );
  }
}
