import React from 'react';
import { Modal, Form, Button } from 'semantic';
import { modal } from 'common/helpers';
import { Spacer } from 'common/components/Spacer';
import { withSession } from 'stores';
import { request } from 'utils/api';
import { STORAGE_KEY } from 'utils/bootstrap';
import SearchDropdown from 'common/components/SearchDropdown';

@modal
@withSession
class SwitchAccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      creatorAccountId: null,
    };
  }

  componentDidMount() {
    const { creatorAccount } = this.context;
    this.setState({
      creatorAccount,
      loaded: true,
    });
  }

  fetchCreatorAccounts = (keyword) => {
    const { canManageAccounts } = this.context;
    return canManageAccounts() ? this.searchAccounts(keyword) : this.listOwnAccounts();
  };

  searchAccounts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  listOwnAccounts = async () => {
    const { data } = await request({
      method: 'GET',
      path: '/1/creator-accounts/mine',
    });
    return data;
  };

  onClear = async () => {
    this.setState(
      {
        creatorAccount: null,
      },
      () => this.onSubmit()
    );
  };

  onSubmit = async () => {
    const { creatorAccount } = this.state;
    if (this.context.creatorAccount?.id !== creatorAccount?.id) {
      if (creatorAccount) {
        if (sessionStorage.getItem('jwt')) {
          sessionStorage.setItem(STORAGE_KEY, creatorAccount.id);
        } else {
          localStorage.setItem(STORAGE_KEY, creatorAccount.id);
        }
      } else {
        localStorage.removeItem(STORAGE_KEY);
      }
      window.location.reload();
    }
    this.props.close();
  };

  render() {
    const { loaded, creatorAccount } = this.state;
    return (
      <React.Fragment>
        <Modal.Content>
          <Form id="SwitchAccounts" onSubmit={this.onSubmit}>
            <h4 className="sm upper bold">Select Creator</h4>
            <p className="sm">
              While selected, you will only see data relevant to the selected account. To change or clear creator choose
              "Creator" from the dropdown below.
            </p>
            <Spacer size="xxs" />
            {loaded && (
              <SearchDropdown
                value={creatorAccount}
                onChange={(e, { value }) => {
                  this.setState({ creatorAccount: value });
                }}
                onDataNeeded={this.fetchCreatorAccounts}
                clearable={false}
                fluid
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {creatorAccount && <Button negative floated="left" basic content="Clear Creator" onClick={this.onClear} />}
          <Button basic content="Cancel" onClick={this.props.close} />
          <Button primary form="SwitchAccounts" disabled={this.state.disabled} content="Save" />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

export default SwitchAccountModal;
