import React from 'react';
import { Grid, Message, Container, Button } from 'semantic';
import { screen, Component } from 'common/helpers';
import Uploads from 'common/components/Uploads';
import { Layout } from 'common/components/Layout';

import Asset from 'common/components/Asset';
import { Spacer } from 'common/components/Spacer';
import { request } from 'utils/api';
import LogoTitle from 'components/LogoTitle';

const params = new URLSearchParams(window.location.search);
const secret = params.get('secret');
const user = params.get('user');
const token = params.get('token');
const creator = params.get('creator');
const eventId = params.get('eventId');

function getAssetsFormStorage() {
  let assets = [];
  try {
    assets = localStorage.getItem(`uploadAssets-${secret}`);
    assets = assets?.length ? JSON.parse(assets) : [];
  } catch (e) {
    console.error(e);
  }
  return assets;
}

@screen
export default class OnboardingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      touched: false,
      loading: false,
      showUploads: false,
      assets: getAssetsFormStorage(),
    };
  }

  onUpload = async (uploads) => {
    this.setState({
      error: null,
      loading: true,
    });
    try {
      const assets = await Promise.all(uploads.map(this.createNewAsset));

      const assetsState = [...this.state.assets, ...assets];
      window.localStorage.setItem(`uploadAssets-${secret}`, JSON.stringify(assetsState));

      this.setState({
        loading: false,
        showUploads: true,
        assets: assetsState,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  createNewAsset = async (upload) => {
    if (token && creator) {
      const body = {
        upload: upload.id,
        userOnly: true,
      };
      if (eventId) body.eventId = eventId;
      const { data: asset } = await request({
        method: 'POST',
        path: '/1/assets',
        token,
        body: body,
        headers: {
          'Creator-Account-Id': creator,
        },
      });
      return asset;
    } else {
      const { data: asset } = await request({
        method: 'POST',
        path: '/1/assets/public',
        body: {
          upload: upload.id,
          user,
          userOnly: user ? true : false,
          secret,
        },
      });
      return asset;
    }
  };

  showUploads = (show) => {
    this.setState({
      showUploads: show,
    });
  };

  render() {
    const { showUploads, assets } = this.state;
    return (
      <Container>
        <Spacer size="s" />
        <LogoTitle />

        <Spacer size="s" />
        <p style={{ textAlign: 'center' }}>
          Upload your assets here. You will be able to choose from these asset files when you start creating events.
        </p>

        {this.state.error && (
          <Layout center>
            <Spacer size="s" />
            <Message error content={this.state.error.message} />
          </Layout>
        )}

        <Spacer size="s" />

        <React.Fragment>
          {showUploads ? (
            <React.Fragment>
              <Layout horizontal spread>
                <Layout.Group>Your uploaded assets</Layout.Group>
                <Layout.Group>
                  <Button basic onClick={() => this.showUploads(false)}>
                    Upload More
                  </Button>
                </Layout.Group>
              </Layout>
              <Spacer size="xs" />
              <Grid columns={4}>
                {assets.map((asset) => {
                  return (
                    <Grid.Column verticalAlign="middle" key={asset.id}>
                      <Asset asset={asset} />
                    </Grid.Column>
                  );
                })}
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Layout horizontal spread>
                <Layout.Group></Layout.Group>
                <Layout.Spacer />
                <Layout.Group fixed>
                  {assets.length > 0 && (
                    <Button basic onClick={() => this.showUploads(true)}>
                      Show Assets
                    </Button>
                  )}
                </Layout.Group>
              </Layout>
              <Spacer size="xs" />
              <Uploads size="large" types={['image', 'video']} onUpload={this.onUpload}>
                <React.Fragment>
                  <Spacer size="xs" />
                  You'll be able to assign them to specific products, events and chapters later.
                </React.Fragment>
              </Uploads>
            </React.Fragment>
          )}
        </React.Fragment>
      </Container>
    );
  }
}
