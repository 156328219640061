import React from 'react';
import { withSession } from 'stores';

@withSession
export default class Home extends React.Component {
  componentDidMount() {
    const { hasAccess } = this.context;
    if (hasAccess({ endpoint: 'events', permission: 'read', scope: 'creatorAccount' })) {
      this.props.history.replace('/events');
    } else {
      // I dunno...settings??
      // TODO: fix
      document.location = '/settings';
    }
  }

  render() {
    return null;
  }
}
