import React from 'react';

import { Dropdown } from 'semantic';

import DeleteProduct from 'admin/modals/DeleteProduct';
import Confirm from 'admin/components/Confirm';
import { request } from 'utils/api';

export default function Actions({ item, onReload } = {}) {
  return (
    <>
      {item.type === 'base' && (
        <Confirm
          negative
          confirmText="Import Variants"
          header={`This will import the variants of base product?`}
          content="If there is existing variants they will be updated with new data from shopify"
          trigger={<Dropdown.Item content="Import Variants" icon="download" />}
          onConfirm={async () => {
            await request({
              method: 'POST',
              path: `/1/products/${item.id}/import`,
            });
            await onReload();
          }}
        />
      )}
      <DeleteProduct
        product={item}
        trigger={<Dropdown.Item content="Delete Product" icon="trash" />}
        onClose={async () => {
          onReload();
        }}
      />
    </>
  );
}
