import React from 'react';
import { clamp } from 'lodash';
import { Component } from 'common/helpers';
import AdaptiveVideo from '../Adaptive';
import { Icon } from 'semantic';
import { Draggable } from '../../Draggable';

import './small.less';

const SCALE = 10;

export default class SmallVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      dragging: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.documentElement.addEventListener('play', this.onDocPlay, true);
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener('play', this.onDocPlay);
  }

  play() {
    this.ref.current.play();
  }

  setTime(t) {
    this.ref.current.setTime(t);
  }

  isPlaying() {
    return this.ref.current?.isPlaying();
  }

  getDuration() {
    return this.ref.current?.getDuration();
  }

  getCurrentTime() {
    return this.ref.current?.getCurrentTime();
  }

  toggle = () => {
    this.ref.current.toggle();
  };

  onDragStart = () => {
    this.originTime = this.getCurrentTime() || 0;
    this.setState({
      touched: true,
      dragging: true,
    });
  };

  onDragMove = ({ x }) => {
    const t = clamp(this.originTime + x / SCALE, 0, this.getDuration());
    this.setTime(t);
  };

  onDragEnd = () => {
    this.setState({
      dragging: false,
    });
  };

  onSeeking = (evt) => {
    this.forceUpdate();
  };

  onTimeUpdate = (evt) => {
    this.forceUpdate();
  };

  onDurationChange = () => {
    this.forceUpdate();
  };

  onDocPlay = (evt) => {
    if (this.isPlaying() && evt.target !== this.ref.current.getVideoElement()) {
      this.toggle();
    }
  };

  render() {
    return (
      <div {...this.getProps()}>
        {this.renderToggleButton()}
        {this.renderCurrentTime()}
        <AdaptiveVideo
          ref={this.ref}
          video={this.props.video}
          src={this.props.src}
          onSeeking={this.onSeeking}
          onTimeUpdate={this.onTimeUpdate}
          onDurationChange={this.onDurationChange}
        />
      </div>
    );
  }

  renderToggleButton() {
    const { dragging } = this.state;
    const isPlaying = this.isPlaying();
    return (
      <Draggable
        className={this.getElementClass('toggle-button', isPlaying || dragging ? 'hidden' : null)}
        onClick={this.toggle}
        onDragStart={this.onDragStart}
        onDragMove={this.onDragMove}
        onDragEnd={this.onDragEnd}>
        {isPlaying ? <Icon name="pause" width={20} height={20} /> : <Icon name="play" width={19} height={23} />}
      </Draggable>
    );
  }

  renderCurrentTime() {
    const { touched } = this.state;
    const time = touched ? this.getCurrentTime() : this.getDuration();
    if (time != null) {
      const min = Math.floor(time / 60)
        .toString()
        .padStart(2, '0');
      const sec = Math.trunc(time % 60)
        .toString()
        .padStart(2, '0');
      return (
        <div className={this.getElementClass('time')}>
          {min}:{sec}
        </div>
      );
    }
  }
}
