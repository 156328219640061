import { request } from './api';

export const STORAGE_KEY = 'creatorAccountId';

export default async function bootstrap() {
  const [user, creatorAccount] = await Promise.all([loadSelf(), loadCreatorAccount()]);
  return {
    user,
    creatorAccount,
  };
}

async function loadSelf() {
  const { data } = await request({
    method: 'GET',
    path: '/1/users/me',
  });
  return data;
}

async function loadCreatorAccount() {
  let creatorAccount;
  const creatorAccountId = sessionStorage.getItem('jwt')
    ? sessionStorage.getItem(STORAGE_KEY)
    : localStorage.getItem(STORAGE_KEY);

  if (creatorAccountId) {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/creator-accounts/${creatorAccountId}`,
      });
      creatorAccount = data;
    } catch (err) {
      if (err.status === 404) {
        sessionStorage.removeItem(STORAGE_KEY);
        localStorage.removeItem(STORAGE_KEY);
      }
    }
  }

  if (!creatorAccount) {
    const { data: creatorAccounts } = await request({
      method: 'GET',
      path: '/1/creator-accounts/mine',
    });
    creatorAccount = creatorAccounts[0];
    if (creatorAccount) {
      // check if its a temp auth
      if (sessionStorage.getItem('jwt')) {
        sessionStorage.setItem(STORAGE_KEY, creatorAccount.id);
      } else {
        localStorage.setItem(STORAGE_KEY, creatorAccount.id);
      }
    } else {
      localStorage.removeItem(STORAGE_KEY);
      sessionStorage.removeItem(STORAGE_KEY);
    }
  }
  return creatorAccount;
}
