import React from 'react';

import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

export default () => {
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item content={'Users'} to={`/users`} exact as={NavLink} />
        <Menu.Item content={'Consumers'} to={`/users/consumers`} exact as={NavLink} />
      </Menu>
    </Container>
  );
};
