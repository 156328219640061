import React from 'react';
import { Form, Message, Modal, Button, Icon } from 'semantic';
import { modal } from 'common/helpers';
import CroppedImageField, { IncompatibleFileError } from 'common/components/form-fields/CroppedImage';

@modal
export default class EditProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.ref = React.createRef();
  }

  onSubmit = async () => {
    this.setState({
      error: null,
    });
    const image = await this.ref.current.getImage();
    this.props.onSave(image);
  };

  onError = async (error) => {
    if (error instanceof IncompatibleFileError) {
      const upload = await this.ref.current.createUpload(error.file);
      this.props.onSave(upload);
    } else {
      this.setState({
        error,
      });
    }
  };

  render() {
    const { image } = this.props;
    const { error } = this.state;
    return (
      <React.Fragment>
        <Modal.Content>
          <Form error={!!error} id="EditProductImage" onSubmit={this.onSubmit}>
            {this.renderImageField()}
            {error && <Message error content={error.message} />}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {image && <Button negative floated="left" content="Delete" onClick={this.props.onDelete} />}
          {image && <Button content="Make Primary" onClick={this.props.onMakePrimary} />}
          <Button primary form="EditProductImage" content="Save" />
        </Modal.Actions>
      </React.Fragment>
    );
  }

  renderImageField() {
    const { product, image } = this.props;
    if (image?.type === 'model') {
      return (
        <div
          title="ARKit model file cannot be displayed in the browser"
          style={{
            height: '378px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon
            name="unity brand"
            size="large"
            style={{ width: '120px', height: '120px', color: 'rgba(0,0,0,.2)' }}
            fitted
          />
        </div>
      );
    } else {
      return (
        <CroppedImageField
          ref={this.ref}
          image={image}
          onError={this.onError}
          targetWidth={1000}
          targetHeight={1000}
          creatorAccount={product.creatorAccount?.id}
          types={['image', 'model']}
        />
      );
    }
  }
}
