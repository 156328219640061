import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Segment, Button } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { withSession } from 'stores';
import { formatDateTime } from 'utils/date';

import { Breadcrumbs, Layout } from 'components';
import Protected from 'components/Protected';

import FetchObject from 'components/FetchObject';
import UserLink from 'admin/components/UserLink';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';

@screen
@withSession
export default class Events extends React.Component {
  onDataNeeded = async (params) => {
    const { creatorAccount } = params;
    return await request({
      method: 'POST',
      path: '/1/links/search',
      body: {
        ...params,
        creatorAccount: creatorAccount?.id,
      },
    });
  };

  render() {
    return (
      <>
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({ items, getSorted, setSort }) => {
            return (
              <>
                <Breadcrumbs active="Links" />
                <Layout horizontal center spread>
                  <h1>Links</h1>
                </Layout>
                {!this.context.creatorAccount?.id && (
                  <Protected endpoint="creatorAccounts" scope="global">
                    <Segment>
                      <Layout horizontal center right stackable>
                        <Filters.Companies style={{ marginLeft: '1em', width: '250px' }} />
                      </Layout>
                    </Segment>
                  </Protected>
                )}

                <Status />
                {items.length !== 0 && (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>Short URL</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Event</Table.HeaderCell>
                        <Table.HeaderCell width={2}>User</Table.HeaderCell>
                        <Protected endpoint="creatorAccounts" scope="global">
                          <Table.HeaderCell width={2}>Creator</Table.HeaderCell>
                        </Protected>
                        <Table.HeaderCell sorted={getSorted('createdAt')} onClick={() => setSort('createdAt')}>
                          Created At
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width={3}>
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <a target="_blank" href={item.longURL} rel="noreferrer">
                                {item.shortId}
                              </a>
                            </Table.Cell>
                            <Table.Cell>
                              <FetchObject url={`/1/events/${item.event}/shallow`}>
                                {(event) => <Link to={`/events/${event.id}`}>{event.name}</Link>}
                              </FetchObject>
                            </Table.Cell>
                            <Table.Cell>
                              <UserLink user={item.user} />
                            </Table.Cell>
                            <Protected endpoint="creatorAccounts" scope="global">
                              <Table.Cell>
                                <FetchObject id={item.creatorAccount} endpoint="creator-accounts">
                                  {(creator) => (
                                    <Link to={`/creator-accounts/${creator.id}`}>{[creator.name].join(' ')}</Link>
                                  )}
                                </FetchObject>
                              </Table.Cell>
                            </Protected>
                            <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                            <Table.Cell textAlign="center">
                              <Button
                                style={{ boxShadow: 'none' }}
                                content="Copy"
                                onClick={() =>
                                  //only works on https
                                  navigator.clipboard.writeText(item.shortURL)
                                }
                                icon="clipboard"
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
                <Layout center>
                  <Pagination />
                </Layout>
              </>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
