import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List/index';

import Images from './List/Images';

export default class Users extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/assets/videos" component={(props) => <List {...props} type="video" />} exact />
        <Route path="/assets/Images" component={Images} exact />
        <Route path="/assets" component={List} exact />
      </Switch>
    );
  }
}
