import React from 'react';
import PropTypes from 'prop-types';
import { clamp } from 'lodash';
import { Rect } from 'utils/math';
import { Component } from 'common/helpers';

import Draggable from './Draggable';

import './box.less';

export default class DraggableBox extends Component {
  onDragStart = () => {
    this.originRect = this.props.rect;
  };

  onDragMove = ({ name, x, y }) => {
    const { originRect } = this;
    if (!originRect) {
      return;
    }
    const rect = originRect.clone();
    const { minWidth, minHeight, bounds, constrain } = this.props;

    if (name === 'center') {
      rect.top = clamp(rect.top + y, bounds.top, bounds.height - rect.height);
      rect.left = clamp(rect.left + x, bounds.left, bounds.width - rect.width);
    } else {
      if (name === 'tl' || name === 'ml' || name === 'bl') {
        const right = rect.right;
        rect.left = clamp(rect.left + x, bounds.left, right - minWidth);
        rect.width = clamp(rect.width - x, minWidth, right - rect.left);
      }
      if (name === 'tl' || name === 'tm' || name === 'tr') {
        const bottom = rect.bottom;
        rect.top = clamp(rect.top + y, bounds.top, bottom - minHeight);
        rect.height = clamp(rect.height - y, minHeight, bottom - rect.top);
      }
      if (name === 'tr' || name === 'mr' || name === 'br') {
        rect.width = clamp(rect.width + x, minWidth, bounds.width - rect.left);
      }
      if (name === 'bl' || name === 'bm' || name === 'br') {
        rect.height = clamp(rect.height + y, minHeight, bounds.height - rect.top);
      }
      if (constrain) {
        rect.constrain(originRect.ratio, ...this.getHandleGravity(name));
      }
    }
    this.props.onChange(rect);
  };

  onDragEnd = () => {
    this.props.onEnd();
  };

  getHandleGravity(name) {
    return [this.getAxisGravity(name.charAt(1)), this.getAxisGravity(name.charAt(0))];
  }

  getAxisGravity(chr) {
    switch (chr) {
      case 'b':
      case 'r':
        return 0;
      case 't':
      case 'l':
        return 1;
      default:
        return 0.5;
    }
  }

  getHandleProps(name) {
    return {
      name,
      onDragStart: this.onDragStart,
      onDragMove: this.onDragMove,
      onDragEnd: this.onDragEnd,
      className: this.getElementClass('handle', name),
    };
  }

  getStyles() {
    const { rect } = this.props;
    if (rect) {
      const { top, left, width, height } = rect;
      return {
        top: `${top}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
    }
  }

  render() {
    return (
      <div style={this.getStyles()} {...this.getProps()}>
        <Draggable {...this.getHandleProps('tl')} />
        <Draggable {...this.getHandleProps('tm')} />
        <Draggable {...this.getHandleProps('tr')} />
        <Draggable {...this.getHandleProps('mr')} />
        <Draggable {...this.getHandleProps('br')} />
        <Draggable {...this.getHandleProps('bm')} />
        <Draggable {...this.getHandleProps('bl')} />
        <Draggable {...this.getHandleProps('ml')} />
        <Draggable {...this.getHandleProps('center')} />
      </div>
    );
  }
}

DraggableBox.propTypes = {
  rect: PropTypes.object,
  bounds: PropTypes.object,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  constrain: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnd: PropTypes.func,
};

DraggableBox.defaultProps = {
  minWidth: 100,
  minHeight: 100,
  constrain: false,
  onEnd: () => {},
  bounds: new Rect(-Infinity, -Infinity, Infinity, Infinity),
};
