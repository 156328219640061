import React from 'react';
import { withSession } from 'stores';
import './chat.less';
import { uniqBy } from 'lodash';

@withSession
export default class Chat extends React.Component {
  state = {
    members: [],
    messages: [],
    inputValue: '',
  };

  componentDidMount() {
    this.props.connection.onmessage = this.handleEvent;
    this.props.connection.send(
      JSON.stringify({
        user: {
          id: this.context.user.id,
          name: this.context.user.firstName,
          token: localStorage.getItem('jwt'),
          initials: [this.context.user.firstName[0], this.context.user.lastName[0]].filter(Boolean).join(''),
        },
      })
    );
  }

  handleEvent = (event) => {
    const parsedEvent = JSON.parse(event.data);

    if (parsedEvent.joined) {
      this.addMember(parsedEvent.user);
    } else if (parsedEvent.quit) {
      this.removeMember(parsedEvent.user);
    } else if (parsedEvent.message) {
      this.addMessage(parsedEvent.user, parsedEvent.message, parsedEvent.timestamp);
    }
  };

  addMember(member) {
    if (!member?.id) return;
    this.setState({
      members: uniqBy([...this.state.members, member], (member) => member.id),
    });
  }

  removeMember(target) {
    if (target?.id) return;
    this.setState({
      members: this.state.members.filter((member) => member.id !== target.id),
    });
  }

  addMessage(user, message, timestamp) {
    if (!user.id) return;
    this.setState({
      messages: [...this.state.messages, { user, message, timestamp }],
    });
  }

  sendMessage = (e) => {
    e.preventDefault();
    const { connection } = this.props; // websocket instance passed as props to the child component.

    this.setState({
      inputValue: '',
    });

    try {
      connection.send(
        JSON.stringify({
          message: this.state.inputValue,
        })
      ); //send data to the server
    } catch (error) {
      console.error(error); // catch error
    }
  };

  render() {
    const { members, messages, inputValue } = this.state;
    return (
      <div id="chatroom">
        <div id="chatlog">
          <div id="spacer"></div>
          {messages.map((message) => {
            return (
              <p title={new Date(message.timestamp).toLocaleString()} key={message.timestamp}>
                <span title={message.user.name} id="chatlog__name">
                  {message.user.initials}
                </span>{' '}
                : {message.message}
              </p>
            );
          })}
          <div id="chat-input">
            <span id="chat-input__icon">{`>`}</span>
            <form onSubmit={this.sendMessage}>
              <input
                value={inputValue}
                id="chat-input__input"
                onChange={({ target }) => {
                  this.setState({
                    inputValue: target.value,
                  });
                }}
              />
            </form>
          </div>
        </div>
        <div id="roster">
          {members.map((member) => {
            return (
              <p key={member.id}>
                {member.name}-{member.initials}
              </p>
            );
          })}
        </div>
      </div>
    );
  }
}
