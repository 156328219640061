import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Divider, Button, Dropdown } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditCreatorAccount from 'admin/modals/EditCreatorAccount';
import Actions from '../Actions';

export default function CreatorAccountDetailsMenu({ creatorAccount, match, onSave }) {
  const { id } = match.params;
  return (
    <React.Fragment>
      <Breadcrumbs link={<Link to="/creator-accounts">Creator</Link>} active={creatorAccount.name} />
      <Layout horizontal center spread>
        <h1>Creator: {creatorAccount.name}</h1>
        <Layout.Group>
          <Dropdown button basic disabled={!creatorAccount} text="Actions" style={{ marginTop: '-5px' }}>
            <Dropdown.Menu direction="left">
              {creatorAccount && <Actions item={creatorAccount} onReload={onSave} />}
            </Dropdown.Menu>
          </Dropdown>

          <EditCreatorAccount
            creatorAccount={creatorAccount}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>

      <Menu pointing secondary>
        <Menu.Item exact name="Overview" to={`/creator-accounts/${id}`} as={NavLink} />
        <Menu.Item exact name="Members" to={`/creator-accounts/${id}/members`} as={NavLink} />
        <Menu.Item exact name="Invites" to={`/creator-accounts/${id}/invites`} as={NavLink} />
        <Menu.Item exact name="Sales" to={`/creator-accounts/${id}/sales`} as={NavLink} />
      </Menu>
      <Divider hidden />
    </React.Fragment>
  );
}
