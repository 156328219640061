import React from 'react';
import { Form, Modal, Button, Segment, Header, Icon, Message } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'helpers';

@modal
export default class MagicLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      user: null,
    };
  }

  link = async () => {
    const { creatorAccount } = this.props;
    this.setState({
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: `/1/invites/magic`,
        body: {
          creatorAccount: creatorAccount.id,
        },
      });
      this.setState({
        link: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { loading, link } = this.state;
    return (
      <>
        <Modal.Header>Invite Users By Magic Link</Modal.Header>
        <Modal.Content>
          <p>
            This will create a link that can be shared with people you want to invite to{' '}
            {this.props.creatorAccount?.name}. Once created the link is valid for 5 days.
          </p>

          <div>
            {this.state.error && <Message error content={this.state.error} />}
            {!link && (
              <>
                <Segment loading={this.state.loading} placeholder style={{ minHeight: '12rem' }}>
                  <Header icon>
                    <Icon name="share" />
                    Click to create link
                  </Header>
                  {!link && <Button loading={loading} primary content="Create Link" onClick={() => this.link()} />}
                </Segment>
              </>
            )}
            {link && (
              <>
                <Header>Magic Link</Header>
                <Form.Input
                  size="large"
                  disabled={loading}
                  fluid
                  onClick={(e) => e.target.select()}
                  value={link || 'loading...'}
                  onChange={(e, { value }) => this.setField('name', value)}
                />
                <Message>Note this link will not be displayed again, so make sure to copy it.</Message>
              </>
            )}
          </div>
        </Modal.Content>
      </>
    );
  }
}
