import React from 'react';
import { Component } from 'common/helpers';
import { Draggable } from 'common/components/Draggable';

import './track-ruler.less';

const MARKER_SIZES = [1, 5, 10, 30, 60];
const MARKER_SIZE = 50;

export default class TrackRuler extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.forceUpdate();
  }

  onDragStart = () => {
    this.refTime = this.props.currentTime;
    this.props.onPlayheadDragStart();
  };

  onDragMove = ({ x }) => {
    const { duration, currentTime } = this.props;
    let time = this.refTime + (x / this.getWidth()) * duration;
    time = Math.max(0, time);
    time = Math.min(duration, time);
    this.props.onPlayheadDragMove(time);
  };

  onDragEnd = () => {
    this.props.onPlayheadDragEnd();
  };

  getWidth() {
    return this.ref.current?.clientWidth;
  }

  getX(time, width, duration) {
    return time * (width / duration || 0.00001);
  }

  getMarkerSize(duration, width) {
    let size;
    for (let s of MARKER_SIZES) {
      size = s;
      if (width / (duration / size) > MARKER_SIZE) {
        break;
      }
    }
    return size;
  }

  getMarkers() {
    const markers = [];
    const width = this.getWidth();
    if (width != null) {
      const { duration } = this.props;
      const size = this.getMarkerSize(duration, width);
      let time = 0;
      while (time < duration - size) {
        time += size;
        markers.push({
          time,
          left: this.getX(time, width, duration),
        });
      }
    }
    return markers;
  }

  render() {
    return (
      <div ref={this.ref} {...this.getProps()}>
        {this.renderPlayhead()}
        {this.renderMarkers()}
      </div>
    );
  }

  renderPlayhead() {
    const { duration, currentTime } = this.props;
    if (currentTime != null) {
      const width = this.getWidth();
      const left = this.getX(currentTime, width, duration);
      return (
        <Draggable
          style={{ left: `${left}px` }}
          onDragStart={this.onDragStart}
          onDragMove={this.onDragMove}
          onDragEnd={this.onDragEnd}
          className={this.getElementClass('playhead')}>
          <div className={this.getElementClass('playhead-bar')} />
          <div className={this.getElementClass('playhead-tip')} />
          <div className={this.getElementClass('playhead-line')} />
        </Draggable>
      );
    }
  }

  renderMarkers() {
    const markers = this.getMarkers();
    return (
      <div className={this.getElementClass('marker-container')}>
        {markers.map(({ time, left }) => {
          return (
            <div key={time} style={{ left: `${left}px` }} className={this.getElementClass('marker')}>
              {time}s
            </div>
          );
        })}
      </div>
    );
  }
}
