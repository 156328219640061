import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Confirm, Dropdown, Label, Segment } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { Layout, Breadcrumbs, HelpTip } from 'components';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';

import EditUser from 'admin/modals/EditUser';

import { withSession } from 'stores';
import Menu from './Menu';

import Protected from 'components/Protected';
import FetchObject from 'common/components/FetchObject';
import LoginAsUser from 'admin/modals/LoginAsUser';
import { screen } from 'helpers';
import Roles from 'admin/components/Roles';

@screen
@withSession
export default class UserList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...params,
        hasRoles: true,
      },
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({ items: users, getSorted, setSort, reload }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Users" />

              <Layout horizontal spread center>
                <h1>Users</h1>
                <Layout.Group>
                  <Protected permission="write" endpoint="users" scope="global">
                    <EditUser trigger={<Button primary content="New User" icon="plus" />} onSave={reload} />
                  </Protected>
                </Layout.Group>
              </Layout>

              <Protected endpoint="users" scope="global">
                <Menu />
              </Protected>
              <Segment>
                <Layout horizontal center spread stackable>
                  <Layout horizontal></Layout>
                  <Layout.Group>
                    <Filters.Search name="keyword" placeholder="Enter email" />
                  </Layout.Group>
                </Layout>
              </Segment>

              <Status />

              {users.length > 0 && (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={3} onClick={() => setSort('lastName')} sorted={getSorted('lastName')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={6} onClick={() => setSort('email')} sorted={getSorted('email')}>
                        Roles
                      </Table.HeaderCell>
                      <Table.HeaderCell onClick={() => setSort('createdAt')} sorted={getSorted('createdAt')}>
                        Joined
                        <HelpTip title="Joined" text="This is the date and time the user was created." />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {users.map((user) => {
                      return (
                        <Table.Row key={user.id}>
                          <Table.Cell>
                            {this.context.creatorAccount ? (
                              <>
                                {user.firstName} {user.lastName}
                              </>
                            ) : (
                              <Link to={`/users/${user.id}`}>
                                {user.firstName} {user.lastName}
                              </Link>
                            )}
                            <br />
                            <p>{user.email}</p>
                          </Table.Cell>

                          <Table.Cell>
                            <Roles roles={user.roles} />
                          </Table.Cell>
                          <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
                          <Table.Cell textAlign="center">
                            <Protected permission="write" endpoint="users" scope="global">
                              <EditUser
                                user={user}
                                creatorAccount={this.context.creatorAccount}
                                trigger={<Button basic icon="edit" />}
                                onSave={reload}
                              />
                            </Protected>
                            <Dropdown button basic text="More">
                              <Dropdown.Menu>
                                <Protected endpoint="users" scope="global">
                                  <LoginAsUser
                                    user={user}
                                    trigger={<Dropdown.Item text="Login as User" icon="user-secret" />}
                                  />
                                </Protected>

                                <Confirm
                                  negative
                                  confirmText="Remove"
                                  header={`Are you sure you want to remove "${user.firstName} ${user.lastName}"?`}
                                  trigger={
                                    <Dropdown.Item
                                      disabled={user.id === this.context.user?.id}
                                      text="Remove"
                                      icon="trash"
                                    />
                                  }
                                  onConfirm={async () => {
                                    await request(
                                      this.context.creatorAccount
                                        ? {
                                            method: 'POST',
                                            path: `/1/users/${user.id}/remove`,
                                          }
                                        : {
                                            method: 'DELETE',
                                            path: `/1/users/${user.id}`,
                                          }
                                    );
                                    reload();
                                  }}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
              <Layout center>
                <Pagination />
              </Layout>
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
