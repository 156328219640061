import React from 'react';
import { Form, Modal, Message, Button, Loader } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import { modal } from 'common/helpers';
import SearchDropdown from 'admin/components/SearchDropdown';
import Protected from 'components/Protected';

@modal
export default class EditOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      user: props.item?.user,
      userOnly: props.item?.userOnly,
    };
  }

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
        touched: true,
      });

      await request({
        method: 'POST',
        path: this.props.updateUrl,
        body: {
          user: this.state.user,
          userOnly: this.state.userOnly,
        },
      });

      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  fetchUsers = async (props) => {
    return request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...props,
        creatorAccount: this.props.creatorAccountId,
      },
    });
  };

  render() {
    const { touched, loading, error } = this.state;

    return (
      <>
        <Modal.Header>{`Update Ownership "${this.props.item.name}"`}</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form error={touched && !!error}>
              {loading && <Loader active />}
              {error && <Message error content={error.message} />}
              <Form.Checkbox
                label="Only visible to owner"
                checked={this.state.userOnly}
                onChange={(e, { checked }) => {
                  this.setState({
                    userOnly: checked,
                  });
                }}
              />
              <Protected endpoint="users" scope="creatorAccount" scopeRef={this.props.creatorAccountId}>
                <Form.Field required>
                  <label>User</label>
                  <SearchDropdown
                    fluid
                    objectMode={false}
                    value={this.state.user}
                    onChange={(e, { value }) => {
                      this.setState({
                        user: value.length ? value : null,
                      });
                    }}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onDataNeeded={this.fetchUsers}
                  />
                </Form.Field>
              </Protected>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button primary loading={loading} disabled={loading} content={'Update'} onClick={this.onSubmit} />
        </Modal.Actions>
      </>
    );
  }
}
