import React from 'react';
import { request } from 'utils/api';
import { truncate } from 'lodash';
import DropdownFilter from './Dropdown';

export default class CreatorAccountDropdown extends React.Component {
  onDataNeeded = async () => {
    const { data } = await request({
      method: 'POST',
      path: '/1/events/search',
    });
    return data;
  };

  render() {
    return (
      <DropdownFilter
        placeholder="Filter by Event"
        name="eventId"
        onDataNeeded={this.onDataNeeded}
        style={{
          width: '250px',
        }}
        objectMode={false}
        label=""
        getOptionLabel={(item) => truncate(item.name, { length: 26 })}
        {...this.props}
      />
    );
  }
}
