import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'admin/components/AutoFocus';
import SearchDropDown from 'common/components/SearchDropdown';
import { modal } from 'common/helpers';
import { withSession } from '../../stores';

@modal
@withSession
export default class EditDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      discount: this.props.discount || {},
    };
  }

  fetchCreatorAccounts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  fetchUsers = async (keyword) => {
    const { discount } = this.state;
    const { data } = await request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        keyword: keyword,
        creatorAccount: discount.creatorAccountId,
      },
    });
    return data;
  };

  isUpdate() {
    return !!this.props.discount?.id;
  }

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { close } = this.props;
      const { discount } = this.state;

      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/discounts/${discount.id}`,
          body: discount,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/discounts',
          body: {
            ...discount,
            type: 'percentage',
          },
        });
      }
      close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { discount, loading, error } = this.state;

    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit Discount` : 'New Discount'}</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form noValidate id="edit-discount" error={!!error} onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}

              {this.context.canManageAccounts() && !this.context.creatorAccount?.id && (
                <>
                  <Form.Field required>
                    <label>Creator Account</label>
                    <SearchDropDown
                      fluid
                      objectMode={false}
                      disabled={this.isUpdate()}
                      value={discount.creatorAccountId}
                      onChange={(e, { value }) => {
                        this.setState({
                          discount: {
                            ...discount,
                            creatorAccountId: value.id,
                            user: undefined,
                          },
                        });
                      }}
                      onDataNeeded={this.fetchCreatorAccounts}
                    />
                  </Form.Field>

                  <Form.Field required>
                    <label>User</label>
                    <SearchDropDown
                      fluid
                      key={discount?.creatorAccountId}
                      disabled={!discount.creatorAccountId || this.isUpdate()}
                      objectMode={false}
                      value={discount.userId}
                      onChange={(e, { value }) => {
                        this.setState({
                          discount: {
                            ...discount,
                            userId: value.id,
                          },
                        });
                      }}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      onDataNeeded={this.fetchUsers}
                    />
                  </Form.Field>
                </>
              )}

              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={discount.name || ''}
                onChange={(e, { name, value }) => {
                  this.setState({
                    discount: {
                      ...discount,
                      [name]: value,
                    },
                  });
                }}
              />
              <Form.Input
                required
                type="number"
                name="value"
                label="Percentage"
                disabled={this.isUpdate()}
                min="0"
                max="100"
                value={discount.value || ''}
                onChange={(e, { name, value }) => {
                  this.setState({
                    discount: {
                      ...discount,
                      [name]: value,
                    },
                  });
                }}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-discount"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </>
    );
  }
}
