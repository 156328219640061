import React from 'react';
import { withRouter } from 'react-router-dom';

@withRouter
export default class ExternalLink extends React.Component {

  getHref() {
    let { href, to, history } = this.props;
    if (!href && to) {
      href = history.createHref({ pathname: to });
    }
    return href;
  }

  render() {
    const href = this.getHref();
    return (
      <a target="_blank" rel="external noopener noreferrer" href={href}>
        {this.props.children || href}
      </a>
    );
  }
}
