import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Divider, Message } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';

import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import Events from './Events';
import Menu from './Menu';

@screen
export default class EventStack extends React.Component {
  state = {
    eventStack: null,
  };

  componentDidMount() {
    this.fetchEventStack();
  }

  componentDidUpdate(lastProps = {}) {
    const { match } = this.props;

    if (lastProps.match.params.id !== match.params.id) {
      this.setState({
        eventStack: null,
      });
      this.fetchEventStack();
    }
  }

  fetchEventStack = async () => {
    const { id } = this.props.match.params;
    const { data } = await request({
      method: 'GET',
      path: `/1/stacks/${id}`,
    });
    this.setState({
      eventStack: data,
    });
  };

  updateEventStack = (eventStack) => {
    this.setState({
      eventStack,
    });
  };

  render() {
    const { eventStack } = this.state;
    return (
      <>
        {!eventStack ? (
          <PageLoader />
        ) : (
          <React.Fragment>
            <Menu eventStack={eventStack} updateEventStack={this.updateEventStack} />
            {eventStack.deletedAt && <Message>This stack has been deleted</Message>}
            <Divider hidden />
            <Switch>
              <Route
                exact
                path="/event-stacks/:id"
                render={(props) => <Overview {...props} eventStack={eventStack} />}
              />
              <Route
                exact
                path="/event-stacks/:id/events"
                render={(props) => <Events {...props} eventStack={eventStack} />}
              />
            </Switch>
          </React.Fragment>
        )}
      </>
    );
  }
}
