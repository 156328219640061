import React from 'react';
import { Form, Button, Message } from 'semantic';

import { LANDING_PAGE_URL } from 'utils/env';

export default (props) => {
  const { error, loading } = props;
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [touched, setTouched] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);

  return (
    <Form
      error={touched}
      size="large"
      onSubmit={() => {
        setTouched(true);
        if (!accepted) return;
        const params = {
          firstName,
          lastName,
          password,
        };
        if (props.requiresEmail) {
          params.email = email;
        }

        props.onSubmit(params);
      }}>
      {error && <Message error content={error.message} />}
      {props.requiresEmail && (
        <Form.Input
          placeholder="Email"
          autoComplete="email"
          name="email"
          type="email"
          value={email}
          onChange={(e, { value }) => setEmail(value)}
          error={error?.hasField?.('email')}
        />
      )}
      <Form.Input
        placeholder="First Name"
        name="firstName"
        autoComplete="firstName"
        type="text"
        value={firstName}
        onChange={(e, { value }) => setFirstName(value)}
        error={error?.hasField?.('firstName')}
      />
      <Form.Input
        placeholder="Last Name"
        name="lastName"
        autoComplete="lastName"
        type="text"
        value={lastName}
        onChange={(e, { value }) => setLastName(value)}
        error={error?.hasField?.('lastName')}
      />
      <Form.Input
        placeholder="Password"
        autoComplete="new-password"
        name="password"
        type="password"
        value={password}
        onChange={(e, { value }) => setPassword(value)}
        error={error?.hasField?.('password')}
      />

      <Form.Checkbox
        error={touched && !accepted}
        name="acceptTerms"
        checked={accepted}
        label={
          <label>
            I agree to the{' '}
            <a target="_blank" rel="noreferrer" href={`${LANDING_PAGE_URL}/terms-and-privacy`}>
              Terms of Service
            </a>
          </label>
        }
        onChange={(e, { checked }) => setAccepted(checked)}
      />
      <Button
        style={{ margin: 0 }}
        fluid
        primary
        loading={loading}
        disabled={loading}
        size="large"
        content="Accept Invite"
      />
    </Form>
  );
};
