import React from 'react';
import { Table, Message, Divider, Header, Button } from 'semantic';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';

import SearchProvider from 'common/components/SearchProvider';
import { Layout, Breadcrumbs } from 'components';

import InviteUser from 'admin/modals/InviteUser';
import LoadButton from 'admin/components/LoadButton';
import { withSession } from 'stores';
import { screen } from 'helpers';
import Roles from 'admin/components/Roles';

@screen
@withSession
export default class Invites extends React.Component {
  state = {
    inviteType: 'invite',
  };

  onDataNeeded = async (params) => {
    return request({
      method: 'POST',
      path: '/1/invites/search',
      body: {
        ...params,
        type: this.state.inviteType,
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <SearchProvider key={this.state.inviteType} onDataNeeded={this.onDataNeeded}>
          {({ items, reload }) => {
            return (
              <React.Fragment>
                <Breadcrumbs link={<Link to="/causes">Invites</Link>} />
                <Header as="h2">
                  <Layout horizontal center spread>
                    Invites
                    <InviteUser
                      size="tiny"
                      onSave={reload}
                      creatorAccountId={this.context.creatorAccount?.id}
                      trigger={<Button primary content="Invite User" icon="plus" />}
                    />
                  </Layout>
                </Header>
                {/*
                <SemanticMenu borderless compact>
                  <SemanticMenu.Item header>Invite Type</SemanticMenu.Item>
                  <SemanticMenu.Item
                    active={this.state.inviteType === 'invite'}
                    onClick={() => this.setState({ inviteType: 'invite' })}>
                    Invites
                  </SemanticMenu.Item>
                  <SemanticMenu.Item
                    active={this.state.inviteType === 'magic'}
                    onClick={() => this.setState({ inviteType: 'magic' })}>
                    Magic Link
                  </SemanticMenu.Item>
                </SemanticMenu>
                */}
                <Divider hidden />
                {items.length === 0 ? (
                  <Message>No invites created yet</Message>
                ) : (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{this.state.inviteType == 'invite' ? 'Email' : 'Link Id'}</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((invite) => {
                        return (
                          <Table.Row key={invite.id}>
                            <Table.Cell>{invite.email || invite.id}</Table.Cell>
                            <Table.Cell>
                              <Roles roles={invite.roles} filterCreatorAccount={this.context.creatorAccount?.id} />
                            </Table.Cell>
                            <Table.Cell collapsing>{invite.status}</Table.Cell>
                            <Table.Cell textAlign="center">
                              {this.state.inviteType === 'invite' && (
                                <LoadButton
                                  basic
                                  icon="mail"
                                  title="Resend Invite"
                                  onClick={async () => {
                                    await request({
                                      method: 'POST',
                                      path: `/1/invites/${invite.id}/resend`,
                                    });
                                    reload();
                                  }}
                                />
                              )}
                              <LoadButton
                                basic
                                icon="trash"
                                title="Delete"
                                onClick={async () => {
                                  await request({
                                    method: 'DELETE',
                                    path: `/1/invites/${invite.id}`,
                                  });
                                  reload();
                                }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </React.Fragment>
    );
  }
}
