import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Image, Segment, Dropdown } from 'semantic';
import { request } from 'utils/api';
import { urlForImage } from 'utils/uploads';
import { screen } from 'helpers';
import { withSession } from 'stores';
import { formatDateTime } from 'utils/date';

import EditEvent from 'admin/modals/EditEvent';
import EditEventImage from 'admin/modals/EditEventImage';

import { Breadcrumbs, Layout } from 'components';
import Protected from 'components/Protected';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';
import Menu from './Menu';

import UserLink from 'admin/components/UserLink';

import Actions from '../Actions';

const statusToName = {
  active: 'Active',
  draft: 'Draft',
};

const statusOptions = Object.keys(statusToName).map((status) => {
  return {
    value: status,
    text: statusToName[status],
  };
});

@screen
@withSession
export default class Events extends React.Component {
  state = {
    sort: this.props.subType
      ? undefined
      : {
          field: 'createdAt',
          order: 'desc',
        },
  };

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/events/search',
      body: {
        ...params,
        subType: this.props.subType,
        publishScope: this.props.publishScope,
      },
    });
  };

  render() {
    return (
      <>
        <SearchProvider sort={this.state.sort} onDataNeeded={this.onDataNeeded}>
          {({ items, getSorted, setSort, reload }) => {
            return (
              <>
                <Breadcrumbs active="Events" />
                <Layout horizontal center spread>
                  <h1>Events</h1>

                  <Layout.Group>
                    <EditEvent trigger={<Button primary content="New Event" icon="plus" />} onSave={reload} />
                  </Layout.Group>
                </Layout>
                <Menu />

                <Segment>
                  <Layout horizontal center spread stackable>
                    <Layout horizontal>
                      {!this.context.creatorAccount && (
                        <Filters.Modal>
                          <Filters.Dropdown name="status" label="Status" options={statusOptions} />
                        </Filters.Modal>
                      )}
                    </Layout>
                    <Layout.Group>
                      <Filters.Search name="keyword" placeholder="Enter name" />
                    </Layout.Group>
                  </Layout>
                </Segment>
                <Status />

                {items.length !== 0 && (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3} onClick={() => setSort('name')} sorted={getSorted('name')}>
                          Name
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>Cover</Table.HeaderCell>
                        {!this.props.subType && (
                          <Table.HeaderCell sorted={getSorted('startsAt')} onClick={() => setSort('startsAt')}>
                            Start Date
                          </Table.HeaderCell>
                        )}
                        {/*<Table.HeaderCell>Public?</Table.HeaderCell>*/}
                        <Table.HeaderCell width={1} onClick={() => setSort('status')} sorted={getSorted('status')}>
                          Status
                        </Table.HeaderCell>

                        <Table.HeaderCell width={2}>Owner</Table.HeaderCell>

                        <Table.HeaderCell sorted={getSorted('createdAt')} onClick={() => setSort('createdAt')}>
                          Created At
                        </Table.HeaderCell>

                        <Table.HeaderCell textAlign="center" width={3}>
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <Link to={`/events/${item.id}`}>{item.name}</Link>
                              {false && item.shareableLink && (
                                <Button
                                  basic
                                  style={{ boxShadow: 'none' }}
                                  title="Copy Shareable Link"
                                  onClick={() =>
                                    //only works on https
                                    navigator.clipboard.writeText(item.shareableLink)
                                  }
                                  icon="clipboard"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <EditEventImage
                                event={item}
                                onSave={reload}
                                trigger={
                                  item.coverImage ? (
                                    <Image size="small" src={urlForImage(item.coverImage, { width: 150 })} />
                                  ) : (
                                    <Button icon="upload" basic />
                                  )
                                }
                              />
                            </Table.Cell>
                            {!this.props.subType && (
                              <Table.Cell>{item.startsAt ? formatDateTime(item.startsAt) : '-'}</Table.Cell>
                            )}
                            <Table.Cell>{statusToName[item.status]}</Table.Cell>

                            <Table.Cell>
                              <Protected endpoint="creatorAccounts" scope="global">
                                {item.creatorAccount && (
                                  <>
                                    {' '}
                                    <Link to={`/creator-accounts/${item.creatorAccount.id}`}>
                                      {item.creatorAccount.name}
                                    </Link>{' '}
                                    /{' '}
                                  </>
                                )}
                              </Protected>
                              {item.user && <UserLink user={item.user} endpoint="users/public" />}
                            </Table.Cell>

                            <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                            <Table.Cell textAlign="center">
                              <EditEvent
                                event={item}
                                trigger={<Button title="Edit Event" basic icon="edit" />}
                                onSave={reload}
                              />

                              <Dropdown button basic text="More">
                                <Dropdown.Menu>
                                  <Actions item={item} onReload={reload} />
                                </Dropdown.Menu>
                              </Dropdown>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
                <Layout center>
                  <Pagination />
                </Layout>
              </>
            );
          }}
        </SearchProvider>
      </>
    );
  }
}
