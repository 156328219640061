import React from 'react';
import { debounce } from 'lodash';
import { Dropdown } from 'semantic';
import { Component } from 'common/helpers';

import './phone-frame.less';

import MODELS from './models.json';

export class PhoneFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getOptions() {
    return MODELS.map((model) => {
      const { name } = model;
      return {
        text: name,
        value: name,
        item: model,
      };
    });
  }

  onResize = debounce(() => {
    this.forceUpdate();
  }, 200);

  onChange = (evt, { value }) => {
    const model = MODELS.find((m) => m.name === value);
    this.setState({
      selected: model,
    });
  };

  render() {
    return (
      <div {...this.getProps()}>
        <div ref={this.ref} className={this.getElementClass('stage')}>
          {this.props.children}
          {this.renderFrame()}
        </div>
        <Dropdown
          clearable
          scrolling
          placeholder="Device frame"
          className={this.getElementClass('dropdown')}
          options={this.getOptions()}
          onChange={this.onChange}
        />
      </div>
    );
  }

  renderFrame() {
    const { selected } = this.state;
    if (selected) {
      const { clientHeight: height, frame } = this.ref.current;
      const ratio = selected.physicalWidth / selected.physicalHeight;
      // Subtract the avg frame size as it sits outsize
      const frameWidth = height * ratio - 10;
      const frameStyle = selected.frame || null;
      return (
        <React.Fragment>
          <div className={this.getElementClass('mask-container')}>
            <div style={{ width: frameWidth }} className={this.getElementClass('mask')} />
          </div>
          <div style={{ width: frameWidth }} className={this.getElementClass('guide', frameStyle)} />
        </React.Fragment>
      );
    }
  }
}
