import React from 'react';
import { Popup, Form, Message, Button } from 'semantic';
import { downloadFile } from 'utils/download';

export default class ExportButton extends React.Component {
  state = {
    loading: false,
    filename: this.props.filename,
  };

  setField(name, value) {
    this.setState({
      [name]: value,
    });
  }

  onSubmit = async () => {
    const { onDataNeeded } = this.props;
    const { filename } = this.state;
    this.setState({
      loading: true,
    });
    try {
      const content = await onDataNeeded({
        format: 'csv',
        limit: 100000,
      });
      downloadFile(content, filename, 'text/csv');
      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  render() {
    const { loading, error, filename } = this.state;
    const { simple } = this.props;
    return (
      <div>
        {simple && (
          <Button
            loading={loading}
            disabled={loading}
            primary
            icon="download"
            content="Export All"
            onClick={this.onSubmit}
          />
        )}
        {!simple && (
          <Popup
            content={
              <div style={{ width: '250px' }}>
                {error && <Message error content={error.message}></Message>}
                <Form onSubmit={this.onSubmit} error={true}>
                  <Form.Input
                    value={filename}
                    type="text"
                    label="Filename"
                    onChange={(e, { value }) => this.setField('filename', value)}
                  />
                  <Button disabled={loading} loading={loading} fluid primary content="Submit" />
                </Form>
              </div>
            }
            on="click"
            trigger={
              <Button
                primary
                icon="download"
                content={this.props.content || 'Export as CSV'}
                // onClick={this.handleSubmit}
              />
            }
          />
        )}
      </div>
    );
  }
}
