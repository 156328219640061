import React from 'react';
import { Table, Button, Image, Confirm } from 'semantic';
import { urlForImage } from 'utils/uploads';
import { formatDateTime } from 'utils/date';
import EditProduct from 'admin/modals/EditProduct';
import { Link } from 'react-router-dom';

import { request } from 'utils/api';
import HelpTip from 'components/HelpTip';

export default class Bundled extends React.Component {
  render() {
    const { product } = this.props;

    return (
      <>
        <EditProduct
          product={{ type: 'bundled', parent: product.id, creatorAccount: product.creatorAccount }}
          onSave={this.props.onReload}
          trigger={<Button primary>Add item to bundle</Button>}
        />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Image</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>
                Created
                <HelpTip title="Created" text="This is the date and time the product was created." />
              </Table.HeaderCell>
              <Table.HeaderCell>Delete</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {product.subProducts.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.coverImage && <Image size="tiny" src={urlForImage(item.coverImage, { width: 150 })} />}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/products/${item.id}`}>{item.name}</Link>
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                  <Table.Cell>
                    <Confirm
                      negative
                      confirmText="Delete"
                      header={`Are you sure you want to delete "${item.name}"?`}
                      content="All data will be permanently deleted"
                      trigger={<Button basic icon="trash" />}
                      onConfirm={async () => {
                        await request({
                          method: 'DELETE',
                          path: `/1/products/${item.id}`,
                        });
                        this.props.onReload();
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}
