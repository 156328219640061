import React from 'react';
import { Menu, Button, Dropdown } from 'semantic';
import { NavLink, Link } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import EditEvent from 'admin/modals/EditEvent';

import Actions from '../Actions';

export default ({ event, updateEvent, reload }) => {
  return (
    <>
      <Breadcrumbs link={<Link to="/events">Events</Link>} active={event.name} />
      <Layout horizontal center spread>
        <h1>Event: {event.name}</h1>

        <Layout.Group>
          <Dropdown button basic disabled={!event} text="Actions" style={{ marginTop: '-5px' }}>
            <Dropdown.Menu direction="left">{event && <Actions item={event} onReload={reload} />}</Dropdown.Menu>
          </Dropdown>

          {event && (
            <EditEvent
              event={event}
              onSave={updateEvent}
              trigger={<Button primary icon="setting" content="Edit Event" />}
            />
          )}
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item exact name="Overview" to={`/events/${event.id}`} as={NavLink} />
        <Menu.Item exact name="Products" to={`/events/${event.id}/products`} as={NavLink} />
        <Menu.Item exact name="Insights" to={`/events/${event.id}/insights`} as={NavLink} />
        <Menu.Item exact name="Funnel" to={`/events/${event.id}/funnel`} as={NavLink} />

        {event.type === 'live' && (
          <Menu.Item exact name="Broadcast" to={`/events/${event.id}/broadcast`} as={NavLink} />
        )}
        {!event.parent && <Menu.Item exact name="Videos" to={`/events/${event.id}/videos`} as={NavLink} />}
        {/* <Protected endpoint="comments" scope="global">
          <Menu.Item exact name="Questions" to={`/events/${event.id}/questions`} as={NavLink} />
        </Protected> */}
        {/* <Menu.Item exact name="Invites" to={`/events/${event.id}/invites`} as={NavLink} /> */}

        {event.type === 'live' && <Menu.Item exact name="Chat" to={`/events/${event.id}/chat`} as={NavLink} />}
        <Menu.Item exact name="Links" to={`/events/${event.id}/links`} as={NavLink} />
      </Menu>
    </>
  );
};
