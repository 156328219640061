import React from 'react';

import { Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

import { Breadcrumbs, Layout } from 'components';
import { withSession } from 'stores';
import Protected from 'common/components/Protected';

@withSession
export default class Settings extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout horizontal center spread>
          <Breadcrumbs active="Account settings"></Breadcrumbs>
        </Layout>
        <Menu pointing secondary>
          <Menu.Item name="Profile" to="/settings/profile" as={NavLink} exact />
          <Protected endpoint="businessAccounts" scope="creatorAccount">
            <>
              {this.context.creatorAccount && (
                <Menu.Item name="Account Settings" to="/settings/account" as={NavLink} exact />
              )}
            </>
          </Protected>
        </Menu>
      </React.Fragment>
    );
  }
}
