import React from 'react';
import { Form, Modal, Button, Segment, Header, Icon, Divider } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'helpers';
import SearchDropdown from 'admin/components/SearchDropdown';

@modal
export default class ShareEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      user: null,
    };
  }

  link = async () => {
    const { event } = this.props;
    this.setState({
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: `/1/links`,
        body: {
          id: event.id,
          user: this.state.user,
          unique: this.state.unique,
          type: 'event',
        },
      });
      this.setState({
        link: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchUsers = (props) => {
    const { event } = this.props;

    return request({
      method: 'POST',
      path: '/1/users/search',
      body: {
        ...props,
        creatorAccount: event.creatorAccount?.id,
      },
    });
  };

  render() {
    const { loading, link } = this.state;
    return (
      <>
        <Modal.Header>Share Event {this.props.event.name}</Modal.Header>
        <Modal.Content>
          {!link && (
            <>
              <Form.Checkbox
                label="Make link unique"
                name="unique"
                onChange={(e, { checked }) => {
                  this.setState({
                    unique: checked,
                  });
                }}
              />
              <Divider />
              <Form.Field>
                <label>Attribute link to user</label>
                <SearchDropdown
                  fluid
                  objectMode={false}
                  value={this.state.user}
                  onChange={(e, { value }) => {
                    this.setState({
                      user: value,
                    });
                  }}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  onDataNeeded={this.fetchUsers}
                />
              </Form.Field>
              <Segment placeholder>
                <Header icon>
                  <Icon name="share" />
                  Click to share this event
                </Header>
                {!link && <Button loading={loading} primary content="Create Link" onClick={() => this.link()} />}
              </Segment>
            </>
          )}
          {link && (
            <>
              <Header>ShortURL</Header>
              <Form.Input
                size="large"
                disabled={loading}
                fluid
                value={link?.shortURL || 'loading...'}
                onChange={(e, { value }) => this.setField('name', value)}
              />
            </>
          )}
        </Modal.Content>
      </>
    );
  }
}
