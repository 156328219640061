import React from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Component } from '../../helpers';
import { Layout } from '../Layout';

import './sortable-list.less';

const Item = sortableElement((props) => {
  const { value, active, ...rest } = props;
  return (
    <li className={rest.getElementClass('item')}>
      <Layout horizontal center>
        {active && (
          <React.Fragment>
            <Layout.Group>
              <Handle {...rest} />
            </Layout.Group>
            <Layout.Spacer size="small" />
          </React.Fragment>
        )}
        {value}
      </Layout>
    </li>
  );
});

const List = sortableContainer((props) => {
  const { items, className, ...rest } = props;
  return (
    <ul className={className}>
      {items.map((value, index) => {
        const { key } = value;
        return <Item key={key} value={value} index={index} {...rest} />;
      })}
    </ul>
  );
});

const Handle = sortableHandle((props) => {
  return <div className={props.getElementClass('handle')} />;
});

export default class SortableList extends Component {
  onSortStart = () => {
    document.body.style.cursor = 'grabbing';
  };

  onSortEnd = (result, evt) => {
    document.body.style.cursor = '';
    this.props.onSortEnd(result, evt);
  };

  getModifiers = () => {
    const { style } = this.props;
    return [style];
  };

  render() {
    return (
      <List
        {...this.getProps()}
        active={this.props.active}
        items={this.props.children}
        onSortEnd={this.onSortEnd}
        onSortStart={this.onSortStart}
        getElementClass={this.getElementClass}
        useDragHandle
      />
    );
  }
}

SortableList.defaultProps = {
  active: false,
  style: 'list',
};
