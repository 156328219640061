import React from 'react';
import { Message, Image, Icon, Popup, List } from 'semantic';
import { Link } from 'react-router-dom';
import { Component } from 'common/helpers';
import { urlForImage } from 'utils/uploads';
import { AdaptiveVideo } from 'common/components/Video';
import { Layout } from 'components';
import { getEventProduct } from 'utils/event';
import { getTimelineTrackForTime } from 'utils/timeline';
import { formatDateTime } from 'utils/date';
import { capitalize } from 'lodash';

import './broadcast.less';

export default class EventBroadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveVideoFrag: null,
      uploadType: null,
      programTime: null,
      previewVideo: {
        error: null,
        upload: null,
        loading: null,
      },
    };
    this.ref = React.createRef();
  }

  getLiveStream(event) {
    return event.streams.find((stream) => {
      return stream.type === 'live';
    });
  }

  isLive() {
    const { event } = this.props;
    return this.getLiveStream(event)?.video?.status === 'live';
  }

  getCurrentTrack(timeline) {
    const { liveVideoFrag } = this.state;
    const { liveVideoStartedAt } = this.props.event;
    if (liveVideoFrag && liveVideoStartedAt) {
      const fragStarted = new Date(liveVideoFrag.programDateTime);
      const liveStarted = new Date(liveVideoStartedAt);
      const time = (fragStarted - liveStarted) / 1000;
      return getTimelineTrackForTime(timeline, time);
    }
  }

  getCurrentProductTrack() {
    const { event } = this.props;
    return this.getCurrentTrack(this.getLiveStream(event)?.products);
  }

  getCurrentChapterTrack() {
    const { event } = this.props;
    return this.getCurrentTrack(this.getLiveStream(event)?.chapters);
  }

  onLiveChanged = () => {
    this.props.fetchEvent();
  };

  onFragChanged = (frag) => {
    this.setState({
      liveVideoFrag: frag,
    });
  };

  render() {
    const { event } = this.props;
    const isLive = this.isLive();

    const stream = this.getLiveStream(event);
    if (!stream) {
      return <Message size="large" content="No live stream found" />;
    }

    return (
      <React.Fragment>
        <div className={this.getElementClass('stage')}>
          {this.renderCurrentChapter()}
          {this.renderCurrentProduct()}
          <AdaptiveVideo
            video={stream.video}
            onLiveChanged={this.onLiveChanged}
            onFragChanged={this.onFragChanged}
            autoPlay
            controls
          />
        </div>
        <Popup
          content={
            <List
              items={[
                `State: ${capitalize(event.state)}`,
                `Status: ${capitalize(event.status)}`,
                `Last Updated: ${formatDateTime(event.updatedAt || event.createdAt)}`,
              ]}
            />
          }
          position="bottom left"
          trigger={
            <Layout className={this.getElementClass('meta')} horizontal center>
              <Layout.Group>
                <div className={this.getElementClass('status', isLive ? 'live' : null)}>
                  {isLive ? 'Live' : 'Offline'}
                </div>
              </Layout.Group>
              <Layout.Spacer size="tiny" />
              <Layout.Group>{this.renderCounts(event, 'live')}</Layout.Group>
              <Layout.Spacer size="tiny" />
            </Layout>
          }
        />
        {!event.streams.find((c) => c.type === 'main') && (
          <>
            <Message size="small">
              <Message.Header>Stream Server</Message.Header>
              <Message.Content>
                <code>{stream.video.streamUrl}</code>
              </Message.Content>
            </Message>
            <Message size="small">
              <Message.Header>Stream Key</Message.Header>
              <Message.Content>
                <code>{stream.video.streamKey}</code>
              </Message.Content>
            </Message>
          </>
        )}
        {event.streams.find((c) => c.type === 'main') && (
          <>
            <Message size="small">
              <Message.Header>This event is scheduled to go live at</Message.Header>
              <Message.Content>
                <code>{formatDateTime(event.startsAt)}</code>
              </Message.Content>
            </Message>
          </>
        )}
        {event.parent && (
          <Message size="small">
            <Message.Header>This an cloned event</Message.Header>
            <Message.Content>
              See the <Link to={`/events/${event.parent}/broadcast`}>parent event</Link> for information about the
              broadcast
            </Message.Content>
          </Message>
        )}
      </React.Fragment>
    );
  }

  renderCounts(event, type) {
    const { viewCounts } = event;
    return (
      <span>
        <span title="Currently Viewing">
          <Icon name="dot circle" />
          {viewCounts[`${type}VideoCurrent`]}
        </span>
        &nbsp;&nbsp;
        <span title="Total Unique Views">
          <Icon name="eye" />
          {viewCounts[`${type}VideoTotal`]}
        </span>
      </span>
    );
  }

  renderCurrentChapter() {
    const track = this.getCurrentChapterTrack();
    if (track) {
      return <div className={this.getElementClass('current-chapter')}>{track.name}</div>;
    }
  }

  renderCurrentProduct() {
    const track = this.getCurrentProductTrack();
    const product = track && getEventProduct(this.props.event, track.productId);

    if (product) {
      const image = product.coverImage;

      if (image) {
        return (
          <div className={this.getElementClass('current-product')}>
            <Image className={this.getElementClass('current-product-image')} src={urlForImage(image)} />
          </div>
        );
      }
    }
  }
}
