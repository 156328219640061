import { uniq } from 'lodash';
import React, { Children, cloneElement } from 'react';
import { Loader, Message } from 'semantic';
import { request } from 'utils/api';

export default class LookupUserNames extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.fetchNames();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.fetchNames();
    }
  }

  async fetchNames() {
    const { data, search } = this.props;
    const ids = search ? data.map((item) => item._source.pipelineMeta.userId) : data.map((item) => item.key);
    try {
      const { data: namesById } = await request({
        method: 'POST',
        path: '/1/users/names/search',
        body: {
          ids: uniq(ids.filter((id) => id)),
        },
      });
      this.setState({ namesById, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const { loading, error, namesById } = this.state;
    const { children, data, search, ...props } = this.props;
    if (loading) return <Loader />;
    if (error) return <Message error content={error.message} />;

    const richData = data.map((item) => {
      const userName = namesById[search ? item._source.pipelineMeta.userId : item.key];
      return {
        userName,
        ...item,
      };
    });

    if (typeof children === 'function') {
      return children({
        data: richData,
      });
    }
    return Children.map(children, (child) => {
      return cloneElement(child, {
        data: richData,
        ...props,
      });
    });
  }
}
