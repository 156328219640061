import { API_URL } from 'utils/env';

export function urlForUpload(upload, thumbnail = false) {
  let url = `/1/uploads/${upload.hash}/image`;
  if (thumbnail) {
    url += '?thumbnail=true';
  }
  return new URL(url, API_URL).toString();
}

export function urlForImage(upload, { height, width } = {}) {
  let url = `/1/images/${upload.id || upload}/`;
  if (height) {
    url += `height=${height},`;
  }
  if (width) {
    url += `width=${width}`;
  }
  return new URL(url, API_URL).toString();
}
