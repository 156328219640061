import React from 'react';
import { Form, Modal, Message, Button, Segment } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'common/helpers';
import UsStates from 'components/form-fields/UsStates';

@modal
export default class EditShopify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      isReady: false,
      loading: false,
      error: null,
      hasError: false,
    };
  }

  async boot() {
    try {
      const { data } = await request({
        method: 'POST',
        path: `/1/account-credentials/search`,
        body: {
          type: 'shopify',
          creatorAccount: this.props.creatorAccountId,
        },
      });

      this.setState({
        isReady: true,
        shopifyAccount: data[0] || {
          taxes: [],
          shipping: [],
        },
      });
    } catch (e) {
      this.setState({ isReady: true, error: e });
    }
  }

  componentDidMount() {
    this.boot();
  }

  isUpdate() {
    return !!this.state.shopifyAccount?.id;
  }

  setField(name, value) {
    this.setState({
      shopifyAccount: {
        ...this.state.shopifyAccount,
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    try {
      const { shopifyAccount } = this.state;
      this.setState({
        loading: true,
        touched: true,
      });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/account-credentials/${shopifyAccount.id}`,
          body: shopifyAccount,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/account-credentials',
          body: {
            type: 'shopify',
            ...shopifyAccount,
            creatorAccount: this.props.creatorAccountId,
          },
        });
        this.setState({
          cause: {},
          touched: false,
        });
      }
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { shopifyAccount, touched, loading, error, hasError, isReady } = this.state;

    return (
      <>
        <Modal.Header>Manage Shopify Account</Modal.Header>
        <Modal.Content>
          <>
            {error && <Message error content={error.message} />}
            {!isReady && (
              <Segment
                loading={true}
                style={{
                  minHeight: '200px',
                }}
              />
            )}
            {isReady && (
              <Form error={touched && (error || hasError)}>
                <Form.Input
                  name="identifier"
                  label="Domain to your shopify account"
                  required
                  disabled={this.isUpdate()}
                  type="text"
                  value={shopifyAccount.identifier || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  name="apiKey"
                  label="Api Key"
                  required
                  type="text"
                  value={shopifyAccount.apiKey || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  name="secretKey"
                  label="Secret Key"
                  required
                  type="text"
                  value={shopifyAccount.secretKey || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  name="accessToken"
                  label="Access Token"
                  required
                  type="text"
                  value={shopifyAccount.accessToken || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />

                <h3 style={{ marginTop: '2em' }}>Order Settings</h3>
                <Form.Checkbox
                  checked={shopifyAccount.sendReceipt || false}
                  name="sendReceipt"
                  label="Send Receipt"
                  onChange={(e, { name, checked }) => this.setField(name, checked)}
                />
                <Form.Checkbox
                  checked={shopifyAccount.sendFulfillmentReceipt || false}
                  name="sendFulfillmentReceipt"
                  label="Send Fulfillment Receipt"
                  onChange={(e, { name, checked }) => this.setField(name, checked)}
                />

                <UsStates
                  label="Taxable states"
                  multiple
                  name="taxes"
                  value={shopifyAccount.taxes.map((tax) => tax.state)}
                  onChange={(e, { name, value }) =>
                    this.setField(
                      name,
                      value.map((state) => {
                        return {
                          ...this.state.shopifyAccount?.taxes.find((tax) => tax.state === state),
                          state: state,
                        };
                      })
                    )
                  }
                />
                <h5>Shipping Options</h5>
                {(shopifyAccount.shipping || []).map((line, index) => {
                  return (
                    <Form.Group key={line.id}>
                      <Form.Input
                        width={12}
                        name="title"
                        label="Title"
                        required
                        type="text"
                        value={line.title || ''}
                        onChange={(e, { name, value }) =>
                          this.setField(
                            'shipping',
                            shopifyAccount.shipping.map((line, i) => {
                              if (i !== index) return line;
                              return {
                                ...line,
                                [name]: value,
                              };
                            })
                          )
                        }
                      />
                      <Form.Input
                        width={4}
                        name="code"
                        label="Code"
                        type="text"
                        value={line.code || ''}
                        onChange={(e, { name, value }) =>
                          this.setField(
                            'shipping',
                            shopifyAccount.shipping.map((line, i) => {
                              if (i !== index) return line;
                              return {
                                ...line,
                                [name]: value?.length ? value : undefined,
                              };
                            })
                          )
                        }
                      />
                      <Form.Button
                        basic
                        style={{ marginTop: '22px', height: '16px' }}
                        width={1}
                        icon="trash"
                        onClick={() => {
                          this.setField(
                            'shipping',
                            shopifyAccount.shipping.filter((line, cIndex) => cIndex !== index)
                          );
                        }}
                      />
                    </Form.Group>
                  );
                })}
                {shopifyAccount.shipping.length == 0 && (
                  <Button
                    style={{ margin: 0 }}
                    onClick={() => {
                      this.setField('shipping', [...(shopifyAccount.shipping || []), {}]);
                    }}>
                    Add Shipping Line
                  </Button>
                )}
              </Form>
            )}
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            negative
            loading={loading}
            floated="left"
            disabled={!this.isUpdate()}
            content={'Remove'}
            onClick={async () => {
              await request({
                method: 'DELETE',
                path: `/1/account-credentials/${shopifyAccount.id}`,
              });
              this.props.close();
            }}
          />

          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Save' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
