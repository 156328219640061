import { hot } from 'react-hot-loader/root';
import 'react-tectonic/lib/styles.css';
import 'react-day-picker/dist/style.css';

import Loading from './screens/Loading';
import Error from './screens/Error';
import { useSession } from 'stores';

import PageCenter from 'admin/components/PageCenter';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthSwitch, Protected } from 'common/helpers/routes';

import Events from './screens/Events';
import EventStacks from './screens/EventStacks';
import Dashboard from './screens/Dashboard';
import Login from './screens/Auth/Login';
import Products from './screens/Products';
import Assets from './screens/Assets';

import Docs from './screens/Docs';

import Settings from './screens/Settings';
import Invites from './screens/Invites';
import Users from './screens/Users';
import Uploader from './screens/Uploader';
import Orders from './screens/Orders';
import Insights from './screens/Insights';
import CreatorAccounts from './screens/CreatorAccounts';
import Links from './screens/Links';
import Logout from './screens/Auth/Logout';
import ProductOptions from './screens/ProductOptions';
import Discounts from './screens/Discounts';

import ForgotPassword from './screens/Auth/ForgotPassword';
import ResetPassword from './screens/Auth/ResetPassword';
import AcceptInvite from './screens/Auth/AcceptInvite';

import './app.less';

import { Message, Button } from 'semantic';

const App = () => {
  const { loading, error, user, logout } = useSession();
  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Error error={error} />;
  }

  if (user && !user?.roles.some((c) => ['superAdmin', 'manager'].includes(c.role))) {
    return (
      <PageCenter maxWidth="400px">
        <div>
          <Message error header="Access denied" content={'You dont have the right role to access this site'} />
          <Button size="small" onClick={() => logout(true)} primary>
            Logout
          </Button>
        </div>
      </PageCenter>
    );
  }

  return (
    <Switch>
      <AuthSwitch path="/" loggedIn={Dashboard} loggedOut={Login} exact />

      <Protected path="/products" endpoint="products" permission="read" allowed={Products} />
      <Protected path="/events" endpoint="events" permission="read" allowed={Events} />

      <Protected path="/event-stacks/:id?" endpoint="stacks" permission="read" allowed={EventStacks} />

      <Protected path="/insights/:id?" endpoint="analytics" permission="read" allowed={Insights} exact />

      <Protected path="/invites" endpoint="invites" permission="read" allowed={Invites} exact />
      <Protected path="/links" endpoint="links" permission="read" allowed={Links} exact />
      <Protected path="/discounts" endpoint="discounts" permission="read" allowed={Discounts} exact />
      <Protected path="/users/admins" endpoint="users" permission="read" allowed={Users} />
      <Protected path="/users/:id?" endpoint="users" permission="read" allowed={Users} />
      <Protected path="/orders/:id?" endpoint="orders" permission="read" allowed={Orders} />
      <Protected path="/assets/:id?" endpoint="assets" permission="read" allowed={Assets} />
      <Protected path="/creator-accounts/:id?" endpoint="creatorAccounts" permission="read" allowed={CreatorAccounts} />
      <Protected path="/product-options/:id?" endpoint="productOptions" permission="read" allowed={ProductOptions} />
      <AuthSwitch path="/settings/:id?" loggedIn={Settings} />
      <AuthSwitch path="/login" loggedOut={Login} loggedIn={() => <Redirect to="/" />} exact />
      <Route path="/docs/:id?" component={Docs} />
      <Route path="/logout" component={Logout} exact />

      <Route path="/accept-invite" component={AcceptInvite} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/reset-password" component={ResetPassword} exact />

      <Route path="/uploader" component={Uploader} exact />
    </Switch>
  );
};

export default hot(App);
