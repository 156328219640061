const params = new URLSearchParams(window.location.search);

const {
  API_URL,
  INSIGHTS_API_URL,
  APP_NAME,
  SENTRY_DSN,
  ENV_NAME,
  IMAGE_RESIZE_PROXY,
  INTERCOM_APP_ID,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  LANDING_PAGE_URL,
  UPLOADS_STORE,
} = window.__ENV__;

export {
  API_URL,
  INSIGHTS_API_URL,
  APP_NAME,
  SENTRY_DSN,
  ENV_NAME,
  IMAGE_RESIZE_PROXY,
  INTERCOM_APP_ID,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  LANDING_PAGE_URL,
  UPLOADS_STORE,
};
