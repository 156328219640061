// react-hot-loader needs to be imported
// before react and react-dom
import 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';
import { SENTRY_DSN, ENV_NAME } from 'utils/env';
import { Router } from 'common/helpers';
import { SessionProvider, NotificationProvider } from 'stores';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';

import { useLayout } from 'helpers/screen';
import DashboardLayout from 'admin/layouts/Dashboard';
import PortalLayout from 'admin/layouts/Portal';

useLayout(DashboardLayout, 'Dashboard');
useLayout(PortalLayout, 'Portal');

// Icons

import { Icon } from 'semantic';
import solidIcons from 'semantic/assets/icons/solid.svg';
import brandIcons from 'semantic/assets/icons/brands.svg';
import regularIcons from 'semantic/assets/icons/regular.svg';

Icon.useSet(solidIcons);
Icon.useSet(brandIcons, 'brand');
Icon.useSet(regularIcons, 'regular');

import App from './App';

if (SENTRY_DSN && window.Sentry) {
  Sentry.init({ dsn: SENTRY_DSN, enable: !['development', 'test'].includes(ENV_NAME), enviroment: ENV_NAME });
}

const Wrapper = () => (
  <Router basename="/admin">
    <NotificationProvider>
      <SessionProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </SessionProvider>
    </NotificationProvider>
  </Router>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));
