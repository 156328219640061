import React from 'react';
import { Message, Button, Modal, Form, Divider, Header } from 'semantic';
import { modal, Component } from 'common/helpers';
import { Layout } from 'common/components/Layout';
import Protected from 'components/Protected';

import { Spacer } from 'common/components/Spacer';
import { request } from 'utils/api';
import SearchDropDown from 'admin/components/SearchDropdown';
import { withSession } from 'stores';
import UploadsField from 'admin/components/form-fields/Uploads';

@modal
@withSession
export default class EditAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      uploads: [],
      asset: this.props.asset || {},
    };
  }

  onUpload = async (uploads) => {
    this.setState({
      uploads,
    });
  };

  onSubmit = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { asset } = this.state;
    try {
      if (asset?.id) {
        await request({
          path: `/1/assets/${asset.id}`,
          method: 'PATCH',
          body: asset,
        });
      } else {
        await Promise.all(this.state.uploads.map(this.createNewAsset));
      }

      this.setState({
        loading: false,
      });
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  createNewAsset = async (upload) => {
    const { data: asset } = await request({
      method: 'POST',
      path: '/1/assets',
      body: {
        upload: upload.id,
        ...this.state.asset,
      },
    });
    return asset;
  };

  fetchCreatorAccounts = async (params) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: params,
    });
    return data;
  };

  fetchEvents = async (body) => {
    const { asset } = this.state;
    const { data } = await request({
      method: 'POST',
      path: '/1/events/search',
      body: {
        creatorAccount: asset.creatorAccount?.id || asset.creatorAccount || this.context.creatorAccount?.id,
        ...body,
      },
    });
    return data;
  };

  fetchProducts = async (body) => {
    const { asset } = this.state;
    const { data } = await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        creatorAccount: asset.creatorAccount?.id || asset.creatorAccount || this.context.creatorAccount?.id,
        ...body,
      },
    });
    return data;
  };

  render() {
    const { asset } = this.state;

    return (
      <>
        <Modal.Header>Add Assets</Modal.Header>
        <Modal.Content>
          {this.state.error && (
            <Layout center>
              <Spacer size="s" />
              <Message error content={this.state.error.message} />
            </Layout>
          )}

          <Form>
            {!this.context.creatorAccount?.id && !asset?.id && (
              <Protected endpoint="creatorAccounts" scope="global">
                <Form.Field required>
                  <label>Creator Account</label>
                  <SearchDropDown
                    fluid
                    objectMode={false}
                    value={asset.creatorAccount}
                    onChange={(e, { value }) => {
                      this.setState({
                        asset: {
                          ...asset,
                          eventId: undefined,
                          productId: undefined,
                          creatorAccount: value,
                        },
                      });
                    }}
                    onDataNeeded={this.fetchCreatorAccounts}
                  />
                </Form.Field>
              </Protected>
            )}

            <Header>References (Event or Product)</Header>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Event</label>
                <SearchDropDown
                  fluid
                  key={asset.creatorAccount?.id}
                  disabled={asset.productId}
                  objectMode={false}
                  value={asset.eventId}
                  onChange={(e, { value }) => {
                    this.setState({
                      asset: {
                        ...asset,
                        eventId: value.length ? value : undefined,
                      },
                    });
                  }}
                  onDataNeeded={this.fetchEvents}
                />
              </Form.Field>
              <Form.Field>
                <label>Product</label>
                <SearchDropDown
                  fluid
                  key={asset.creatorAccount?.id}
                  disabled={asset.eventId}
                  objectMode={false}
                  value={asset.productId}
                  onChange={(e, { value }) => {
                    this.setState({
                      asset: {
                        ...asset,
                        productId: value.length ? value : undefined,
                      },
                    });
                  }}
                  onDataNeeded={this.fetchProducts}
                />
              </Form.Field>
            </Form.Group>

            <Divider />

            {!asset.id && (
              <>
                <Header>Access</Header>
                <Form.Checkbox
                  label="Only visible for me"
                  checked={this.state.userOnly}
                  onChange={(e, { checked }) =>
                    this.setState({
                      asset: {
                        ...asset,
                        userOnly: checked,
                      },
                    })
                  }
                />

                <UploadsField
                  label="Assets"
                  name="assets"
                  types={['image', 'video']}
                  value={this.state.uploads || []}
                  onChange={(uploads) => {
                    this.setState({ uploads });
                  }}
                  onError={() => this.setState({ hasError: true })}
                />
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={this.state.loading}
            disabled={this.state.loading || (this.state.creatorAccount && this.state.uploads.length === 0)}
            content={asset.id ? 'Update' : 'Add as Asset(s)'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
