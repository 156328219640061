import React from 'react';
import { Segment, Divider, Statistic, Menu, Button, Icon } from 'semantic';
import { API_URL } from 'utils/env';
import {
  AggregateTerms,
  DonutChart,
  Table,
  TectonicProvider,
  AggregateStats,
  AggregateCardinality,
  TimeRangePicker,
} from 'react-tectonic';

import { numberWithCommas, formatUsd } from 'utils/formatting';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';
import { Link } from 'react-router-dom';
import { withSession } from 'stores';
import LookupUserNames from 'admin/components/LookupUserNames';

@withSession
export default class ProductInsights extends React.Component {
  state = {};
  render() {
    const { product } = this.props;
    const termFilter = {
      productId: product.id,
    };

    const canAccessGlobalUsers = this.context.hasAccess({
      endpoint: 'users',
      permission: 'read',
      scope: 'global',
    });

    return (
      <TectonicProvider
        dateField="occurredAt"
        baseUrl={API_URL}
        collection="enriched-events"
        token={this.context.token}
        primaryColor="#000000"
        getTimeRangeFromCollectionStats={(stats) => {
          return {
            from: stats.from,
            to: stats.to,
          };
        }}
        statsFilter={{ terms: [termFilter] }}
        onRequest={(url, options) => {
          if (this.context.creatorAccount?.id) {
            options.headers['creator-account-id'] = this.context.creatorAccount?.id;
          }
          return fetch(url, options);
        }}>
        <Menu>
          <Menu.Item position="right">
            <TimeRangePicker
              align="right"
              renderButton={(label, onClick) => (
                <Button compact onClick={onClick}>
                  <Icon name="calendar" /> {label}
                </Button>
              )}
            />
          </Menu.Item>
        </Menu>

        <Segment basic>
          <Divider hidden />

          <Statistic.Group widths="four">
            <AggregateStats
              fields={['occurredAt']}
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? `${numberWithCommas(data['occurredAt'].count)}` : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Purchases</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>

            <AggregateStats
              fields={['product.price']}
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? `${formatUsd(data['product.price'].sum)}` : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Sales</Statistic.Label>
                </Statistic>
              )}
            </AggregateStats>

            <AggregateCardinality
              fields={['pipelineMeta.userId']}
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}>
              {({ status, data }) => (
                <Statistic>
                  <Statistic.Value>
                    {status.success ? `${numberWithCommas(data['pipelineMeta.userId'])}` : '...'}
                  </Statistic.Value>
                  <Statistic.Label>Buyers</Statistic.Label>
                </Statistic>
              )}
            </AggregateCardinality>

            <AggregateCardinality
              fields={['eventId']}
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}>
              {({ status, data }) => {
                return (
                  <Statistic>
                    <Statistic.Value>
                      {status.success ? `${numberWithCommas(Math.round(data['eventId']))}` : '...'}
                    </Statistic.Value>
                    <Statistic.Label>Events</Statistic.Label>
                  </Statistic>
                );
              }}
            </AggregateCardinality>
          </Statistic.Group>

          <Divider hidden />
        </Segment>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}
              aggField="event.name"
              operation="sum"
              field="product.price"
              termsSize={10}>
              <DonutChart title="Top Events by Sales" titleAlign="center" limit={5} percent />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="eventId"
              operation="sum"
              field="product.price"
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}
              termsSize={10}>
              <Table
                status={this.state.salesbyUserStatus}
                data={this.state.salesbyUserData}
                title="Sales by Event"
                titleAlign="center"
                valueFieldName="Sales"
                valueField="value"
                valueFormatter={formatUsd}
                rowFormatter={(item, label, value) => {
                  const { topHit } = item;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${item.key}`}>{topHit._source.event?.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="event.name"
              operation="count"
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}
              termsSize={10}>
              <Table
                title="Events by Purchases"
                titleAlign="center"
                valueFieldName="Purchases"
                valueField="count"
                valueFormatter={(value) => `${Math.round(value)}`}
                rowFormatter={(item, label, value) => {
                  const { event } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${event.id}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="eventId"
              operation="count"
              filter={{
                terms: [termFilter, { type: { value: 'product-added-to-cart' } }],
              }}
              termsSize={10}>
              <Table
                title="Events by Added to Cart"
                titleAlign="center"
                valueFieldName="# Add to Cart Actions"
                valueField="count"
                valueFormatter={(value) => `${Math.round(value)}`}
                rowFormatter={(item, label, value) => {
                  const { event } = item.topHit._source;
                  return (
                    <>
                      <td>
                        <Link to={`/events/${event.id}`}>{event.name}</Link>
                      </td>
                      <td>{value}</td>
                    </>
                  );
                }}
              />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>

        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              includeTopHit
              aggField="pipelineMeta.userId"
              operation="sum"
              field="product.price"
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}
              termsSize={10}>
              <LookupUserNames>
                <Table
                  titleAlign="center"
                  title="Consumers by Sales"
                  valueFieldName="Sales"
                  valueField="value"
                  valueFormatter={formatUsd}
                  rowFormatter={(item, label, value) => {
                    const { pipelineMeta } = item.topHit._source;
                    return (
                      <>
                        <td>
                          <Link to={`/users/${pipelineMeta.userId}`}>{item.userName}</Link>
                        </td>
                        <td>{value}</td>
                      </>
                    );
                  }}
                />
              </LookupUserNames>
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: [termFilter, { type: { value: 'product-purchased' } }],
              }}
              aggField="pipelineMeta.userId"
              termsSize={10}>
              <LookupUserNames>
                <DonutChart title="Top Consumers" titleAlign="center" limit={7} percent labelField="userName" />
              </LookupUserNames>
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
      </TectonicProvider>
    );
  }
}
