import EditDiscount from 'admin/modals/EditDiscount';
import React from 'react';

import { Dropdown, Confirm } from 'semantic';

import { request } from 'utils/api';

export default function Actions({ item, onReload } = {}) {
  return (
    <>
      <Confirm
        negative
        confirmText="Delete"
        header={`Are you sure you want to delete "${item.name}"?`}
        content="This will remove the discount"
        trigger={<Dropdown.Item content="Delete discount" icon="trash" />}
        onConfirm={async () => {
          await request({
            method: 'DELETE',
            path: `/1/discounts/${item.id}`,
          });
          onReload();
        }}
      />
    </>
  );
}
