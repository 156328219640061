export default class Timer {

  constructor(options) {
    this.options = options;
  }

  start() {
    this.reset();
    this.tick();
  }

  stop() {
    clearTimeout(this.id);
  }

  reset() {
    this.startTime = new Date();
  }

  tick = () => {
    const ms = new Date() - this.startTime;
    if (ms >= this.options.duration) {
      this.options.onDone();
    } else {
      this.options.onTick(ms);
      this.id = setTimeout(this.tick, 1000);
    }
  }
}

