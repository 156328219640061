import React from 'react';
import Chat from './Chat';
import { Button, Loader, Message, Segment, Header, Divider } from 'semantic';
import { API_URL } from 'utils/env';
import { request } from 'utils/api';

export default class ChatWrapper extends React.Component {
  state = {
    ws: null,
    status: 'loading',
    chatroom: {},
    btnLoading: false,
  };

  componentDidMount() {
    this.fetchChatroom();
  }

  componentWillUnmount() {
    if (this.connectInterval) {
      clearTimeout(this.connectInterval);
    }
  }

  async fetchChatroom() {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/events/${this.props.event.id}/chat`,
      });
      this.setState({ chatroom: data }, () => this.connect());
    } catch (error) {
      this.setState({ error });
    }
  }

  enableChat = async () => {
    this.setState({ btnLoading: true });
    try {
      await request({
        method: 'POST',
        path: `/1/events/${this.props.event.id}/chat/enable`,
      });
    } catch (error) {
      this.setState({ error });
    }
    this.setState({ btnLoading: false });
    await this.fetchChatroom();
  };

  disableChat = async () => {
    this.setState({ btnLoading: true });
    try {
      await request({
        method: 'POST',
        path: `/1/events/${this.props.event.id}/chat/disable`,
      });
      this.setState({ roomId: undefined, ws: undefined, noChat: true });
    } catch (error) {
      this.setState({ error });
    }
    this.setState({ btnLoading: false });
    await this.fetchChatroom();
  };

  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect();
  };

  connect() {
    if (this.state.chatroom.status !== 'enabled') return;
    this.setState({
      status: 'connecting',
    });

    if (API_URL.includes('http:')) {
      this.setState({ error: new Error('Chat is disabled for local development') });
      return;
    }

    const ws = new WebSocket(
      `${API_URL.replace('https://', 'wss://')}/1/chat/api/room/${this.state.chatroom.roomId}/websocket`
    );

    ws.onopen = () => {
      this.setState({
        status: 'connected',
        timeout: 250,
        ws: ws,
      });
      clearTimeout(this.connectInterval);
    };

    ws.onclose = () => {
      this.connectInterval = setTimeout(this.check, Math.min(10000, this.state.timeout)); //call check function after timeout
      this.setState({
        timeout: this.state.timeout + this.state.timeout,
        status: 'reconnecting',
      });
    };

    // websocket onerror event listener
    ws.onerror = (err) => {
      this.setState({
        error: new Error(`Connection error: ${err.message}`),
      });
      ws.close();
    };
  }

  render() {
    const { status, ws, error, chatroom, btnLoading } = this.state;

    if (chatroom.status !== 'enabled') {
      return (
        <Segment placeholder>
          <Header icon>Do you want to enable the event chat.</Header>
          <Button primary onClick={this.enableChat}>
            Enable Chat
          </Button>
        </Segment>
      );
    }

    return (
      <>
        {ws && (
          <>
            <Button loading={btnLoading} onClick={this.disableChat}>
              Disable Chat
            </Button>
            <Divider />
          </>
        )}
        {error && <Message error>{error.message}</Message>}
        {!error && status !== 'connected' && <Loader active>{status}</Loader>}
        {ws && <Chat connection={ws} />}
      </>
    );
  }
}
