import React from 'react';

import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

export default () => {
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item content={'All'} to={`/events/`} exact as={NavLink} />
        <Menu.Item content={'Sessions'} to={`/events/sessions`} exact as={NavLink} />
        <Menu.Item content={'Public'} to={`/events/branded`} exact as={NavLink} />
        {/*<Menu.Item content={'Live Events'} to={`/events/live`} exact as={NavLink} />*/}
      </Menu>
    </Container>
  );
};
