import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic';
import { Component } from 'common/helpers';
import { urlForImage } from 'utils/uploads';
import { SmallVideo } from '../Video';

import './asset.less';

export default class Asset extends Component {
  getModifiers() {
    const { selected } = this.props;
    return [selected ? 'selected' : null];
  }

  render() {
    const { asset } = this.props;
    const url = urlForImage(asset.upload, this.props.width ? { width: this.props.width } : {});

    if (asset.video) {
      return (
        <div style={this.props.width ? { width: `${this.props.width}px` } : {}} {...this.getProps()}>
          <SmallVideo video={asset.video} height={this.props.width} />
        </div>
      );
    }

    return (
      <div style={this.props.width ? { width: `${this.props.width}px` } : {}} {...this.getProps()}>
        {!asset.videoId && asset.type === 'video' ? (
          <SmallVideo dontPlay src={asset.upload.rawUrl} />
        ) : (
          <Image src={url} draggable={false} />
        )}
        <div className={this.getElementClass('name')}>{asset.name}</div>
      </div>
    );
  }
}

Asset.propTypes = {
  selected: PropTypes.bool,
  asset: PropTypes.object,
};

Asset.defaultProps = {
  selected: false,
};
