import React from 'react';
import { Button, Segment, Header, Icon, Message } from 'semantic';
import EditStream from 'admin/modals/EditStream';
import Videos from './Videos';
import { Link } from 'react-router-dom';

import { PageLoader } from 'components';

import { request } from 'utils/api';

function saveStreams(event, newStreams = []) {
  const streams = newStreams.map((stream) => {
    const { video, chapters, name, ...rest } = stream;
    return {
      ...rest,
      video: video.id,
      name: name || null,
      chapters: chapters.map((chapter) => {
        const { image, ...rest } = chapter;
        return {
          ...rest,
          ...(image && {
            image: image.id || image,
          }),
        };
      }),
    };
  });

  // if the event is live then copy the products and captures from the main video
  if (event.type === 'live') {
    const liveStream = streams.find((stream) => stream.type === 'live');
    const mainStream = streams.find((stream) => stream.type === 'main');
    if (liveStream && mainStream) {
      liveStream.chapters = mainStream.chapters || [];
      liveStream.products = mainStream.products || [];
    }
  }

  return request({
    method: 'PATCH',
    path: `/1/events/${event.id}`,
    body: {
      streams,
    },
  });
}

export default class EventVideos extends React.Component {
  state = {
    loading: false,
    error: null,
  };

  addStream = async (data) => {
    this.setState({ loading: true });
    let { streams } = this.props.event;
    const newStream = {
      chapters: [],
      products: [],
      ...data,
    };
    streams = [...streams, newStream];
    try {
      await saveStreams(this.props.event, streams);
      this.props.fetchEvent();
      this.setState({
        error: null,
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  render() {
    const { event } = this.props;
    const hasVideos = event.streams.filter((s) => s.type !== 'live').length > 0;

    if (this.state.loading) return <PageLoader />;
    if (this.state.error) return <Message error content={this.state.error.message} />;

    return (
      <>
        {!hasVideos && (
          <Segment placeholder>
            <Header icon>
              <Icon name="video" />
              <br />
              We don't have any videos attached to this event.
            </Header>

            <Segment.Inline>
              <EditStream
                onOpen={this.onEditStreamOpen}
                onClose={this.onEditStreamClose}
                onComplete={this.addStream}
                trigger={<Button primary content="Upload A Video" />}
                creatorAccount={event.creatorAccount?.id}
                eventType={event.type}
              />
              <Button as={Link} to={`/events/${event.id}/video-editor/`}>
                Generate a video
              </Button>
            </Segment.Inline>
          </Segment>
        )}
        {hasVideos && <Videos {...this.props} saveStreams={saveStreams} />}
      </>
    );
  }
}
