import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Table, Segment, Checkbox, Confirm, Button } from 'semantic';

import { request } from 'utils/api';
import { screen } from 'helpers';
import { withSession } from 'stores';
import Protected from 'components/Protected';
import { SearchProvider, Status, Filters, Pagination } from 'components/search';
import { uniqBy } from 'lodash';
import { Breadcrumbs, HelpTip, Layout } from 'components';
import { formatDateTime } from 'utils/date';
import Asset from 'common/components/Asset';
import AddAssetToProduct from 'admin/modals/AddAssetToProduct';
import EditAsset from 'admin/modals/EditAsset';
import EditOwner from 'admin/modals/EditOwner';
import FetchObject from 'components/FetchObject';

import Menu from './Menu';
import ViewPublicUploadUrl from 'admin/modals/ViewPublicUploadUrls';

@screen
@withSession
export default class Assets extends React.Component {
  state = {
    selected: [],
  };

  onDataNeeded = async (params) => {
    const { creatorAccount } = params;
    return await request({
      method: 'POST',
      path: '/1/assets/search',
      body: {
        type: 'image',
        ...params,
        creatorAccount: creatorAccount?.id,
      },
    });
  };

  fetchCreatorAccounts = async () => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
    });
    return data;
  };

  toggle = (item, checked) => {
    this.setState({
      selected: checked
        ? uniqBy([...this.state.selected, item], 'id')
        : this.state.selected.filter((c) => c.id !== item.id),
    });
  };

  toggleAll = (items = [], checked) => {
    this.setState({
      selected: checked
        ? uniqBy([...this.state.selected, ...items], 'id')
        : this.state.selected.filter((c) => !items.map((i) => i.id).includes(c.id)),
    });
  };

  render() {
    const { selected } = this.state;
    const globalAccess = this.context.hasAccess({
      endpoint: 'assets',
      permission: 'write',
      scope: 'global',
    });

    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({ items, getSorted, setSort, reload }) => {
          return (
            <Container>
              <Header as="h2">
                <Breadcrumbs active="Assets" />
                <Layout horizontal center spread>
                  Images
                  <Layout.Group>
                    <ViewPublicUploadUrl
                      trigger={<Button basic content="View Uploader URLs" onClose={() => reload()} />}
                    />

                    <EditAsset
                      onClose={() => reload()}
                      trigger={<Button primary content="Add Assets" icon="plus" onClose={() => reload()} />}
                    />
                  </Layout.Group>
                </Layout>
              </Header>
              <Menu />
              <Segment>
                <Layout horizontal center spread stackable>
                  <Layout horizontal></Layout>
                  <Layout horizontal right>
                    <Filters.Events />

                    {!this.context.creatorAccount?.id && (
                      <Protected endpoint="creatorAccounts" scope="global">
                        <Filters.Companies style={{ marginLeft: '1em', width: '250px' }} />
                      </Protected>
                    )}

                    <Filters.Search
                      style={{ marginLeft: '1em', width: '250px' }}
                      name="keyword"
                      placeholder="Enter name"
                    />
                  </Layout>
                </Layout>
              </Segment>

              <Status />
              {items.length !== 0 && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ width: '1%' }}>
                        <Button
                          icon="close"
                          disabled={!selected.length}
                          basic
                          size="tiny"
                          compact
                          style={{ border: 0 }}
                          onClick={() => this.setState({ selected: [] })}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>Asset</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Access</Table.HeaderCell>
                      <Table.HeaderCell>Refences</Table.HeaderCell>
                      <Table.HeaderCell width={2}>User</Table.HeaderCell>
                      <Protected endpoint="creatorAccounts" scope="global">
                        <Table.HeaderCell width={2}>Creator</Table.HeaderCell>
                      </Protected>
                      <Table.HeaderCell width={2} sorted={getSorted('createdAt')} onClick={() => setSort('createdAt')}>
                        Created
                        <HelpTip title="Created" text="This is the date and time the product was created." />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => {
                      const canEdit = globalAccess || !item.user || item.user === this.context.user?.id;
                      return (
                        <Table.Row key={item.id}>
                          <Table.Cell style={{ width: '1%' }}>
                            <Checkbox
                              checked={selected.map((c) => c.id).includes(item.id)}
                              onChange={(e, { checked }) => {
                                this.toggle(item, checked);
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Asset asset={item} width={180} />
                          </Table.Cell>
                          <Table.Cell textAlign="center">{item.userOnly ? 'Private' : 'Team'}</Table.Cell>
                          <Table.Cell>
                            {item.eventId && (
                              <FetchObject id={item.eventId} endpoint="events">
                                {(event) => (
                                  <>
                                    Event: <br /> <Link to={`/events/${event.id}`}>{event.name}</Link>
                                  </>
                                )}
                              </FetchObject>
                            )}
                            <br />
                            {item.productId && (
                              <FetchObject id={item.productId} endpoint="products">
                                {(product) => (
                                  <>
                                    Product: <Link to={`/products/${product.id}`}>{product.name}</Link>
                                  </>
                                )}
                              </FetchObject>
                            )}
                          </Table.Cell>

                          <Table.Cell>
                            {item.user && (
                              <FetchObject id={item.user} endpoint="users">
                                {(user) => (
                                  <Link to={`/users/${user.id}`}>{[user.firstName, user.lastName].join(' ')}</Link>
                                )}
                              </FetchObject>
                            )}
                          </Table.Cell>

                          <Protected endpoint="creatorAccounts" scope="global">
                            <Table.Cell>
                              {item.creatorAccount ? (
                                <Link to={`/creator-accounts/${item.creatorAccount.id}`}>
                                  {item.creatorAccount.name}
                                </Link>
                              ) : (
                                '-'
                              )}
                            </Table.Cell>
                          </Protected>
                          <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                          <Table.Cell>
                            <Layout center>
                              <Button as="a" basic icon="download" href={item.upload.rawUrl} target="_blank" />
                              {false && (
                                <EditAsset
                                  onSave={() => reload()}
                                  trigger={<Button basic icon="edit" />}
                                  asset={item}
                                />
                              )}
                              <EditOwner
                                onSave={() => reload()}
                                item={item}
                                updateUrl={`/1/assets/${item.id}/set-user`}
                                creatorAccountId={item.creatorAccount?.id}
                                trigger={<Button disabled={!canEdit} basic icon="key" />}
                              />
                              <Confirm
                                negative
                                confirmButton="Delete"
                                header={`Are you sure you want to delete "${item.name}"?`}
                                content="All data will be permanently deleted"
                                trigger={<Button basic icon="trash" />}
                                onConfirm={async () => {
                                  await request({
                                    method: 'DELETE',
                                    path: `/1/assets/${item.id}`,
                                  });
                                  reload();
                                }}
                              />
                            </Layout>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell colSpan="6">
                        <AddAssetToProduct
                          size="small"
                          assets={selected}
                          onClose={() =>
                            this.setState({
                              selected: [],
                            })
                          }
                          trigger={
                            <Button disabled={selected.length === 0} size="small">
                              Add Images({selected.length}) to product
                            </Button>
                          }></AddAssetToProduct>
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
              <Layout center>
                <Pagination />
              </Layout>
            </Container>
          );
        }}
      </SearchProvider>
    );
  }
}
