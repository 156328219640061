import React from 'react';

import { Dropdown } from 'semantic';

import Confirm from 'admin/components/Confirm';
import { request } from 'utils/api';

import EditCreatorAccountTemplates from 'admin/modals/EditCreatorAccountTemplates';
import EditBusinessAccount from 'admin/modals/EditBusinessAccount';
import InviteUser from 'admin/modals/InviteUser';
import MagicLink from 'admin/modals/MagicLink';
import EditShopify from 'admin/modals/EditShopify';
import ImportProducts from 'admin/modals/ImportProducts';

export default function Actions({ item, onReload } = {}) {
  return (
    <>
      <InviteUser
        creatorAccountId={item}
        onSave={onReload}
        trigger={<Dropdown.Item icon="plus" content="Invite By Email" />}
      />
      <MagicLink
        creatorAccount={item}
        onSave={() => {}}
        trigger={<Dropdown.Item icon="magic" content="Invite By Magic Link" />}
      />
      <EditCreatorAccountTemplates
        creatorAccount={item}
        onSave={onReload}
        trigger={<Dropdown.Item icon="mail" content="Edit Templates" />}
      />

      <EditBusinessAccount
        onSave={() => onReload()}
        creatorAccountId={item.id}
        businessAccountId={item.businessAccount}
        trigger={<Dropdown.Item text="Manage Payout" icon="book" />}
      />

      <EditShopify
        creatorAccountId={item.id}
        onSave={() => onReload()}
        trigger={<Dropdown.Item text="Manage Shopify" icon="sync" />}
      />

      <ImportProducts creatorAccountId={item.id} trigger={<Dropdown.Item text="Import Products" icon="download" />} />

      <Confirm
        negative
        confirmText="Reset Upload Secret"
        header={`Are you sure you want to reset the upload secret for "${item.name}"?`}
        content="The asset upload tool shared with external users will stop working"
        trigger={<Dropdown.Item text="Reset Upload Secret" icon="redo" />}
        onConfirm={async () => {
          await request({
            method: 'POST',
            path: `/1/creator-accounts/${item.id}/reset-upload-secret`,
          });
          onReload();
        }}
      />
      <Confirm
        negative
        confirmText="Delete"
        header={`Are you sure you want to delete "${item.name}"?`}
        content="All data will be permanently deleted"
        trigger={<Dropdown.Item text="Delete" icon="trash" />}
        onConfirm={async () => {
          await request({
            method: 'DELETE',
            path: `/1/creator-accounts/${item.id}`,
          });
          onReload();
        }}
      />
    </>
  );
}
