import React from 'react';
import { Icon, Image } from 'semantic';
import { urlForUpload } from './uploads';

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export const truncate = (text, limit = 100) => {
  if (text.length > limit - 3) {
    return text.slice(0, limit - 3) + '...';
  }
  return text;
};

export const numberWithCommas = (x) => {
  return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithDots = (x) => {
  return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatUsdLabel = (value, precision = 2) => {
  if (isNaN(value)) return <span>&ndash;</span>;
  return <span>${(value / 100).toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>;
};

export const formatCurrency = (amountInCents, locale = 'en-US') => {
  // safari does not work with narrowSymbol option :(
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  }).format(amountInCents / 100);
};

export const formatUsd = (value) => {
  if (isNaN(value)) return '';
  const usd = Math.round(value / 100);
  return `$${numberWithCommas(usd)}`;
};

export function formatProfileImage(image) {
  return image ? (
    <Image
      size="tiny"
      style={{
        width: '20px',
        height: '20px',
        display: 'inline-block',
        marginRight: '4px',
      }}
      avatar
      src={urlForUpload(image)}
    />
  ) : (
    <Icon name="user" fitted style={{ marginRight: '4px' }} />
  );
}

export function formatAddress(address) {
  const { line1, line2, city, state, countryCode } = address;
  const parts = [line1, line2, city, state, countryCode];
  return parts.filter((p) => p).join(', ');
}

export function safeFileName(str) {
  return str.replace(/[^a-z0-9]/gi, '_').toLowerCase();
}
