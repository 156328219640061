import React from 'react';

import AnalyticsBlock from 'admin/components/AnalyticsBlock';

import {
  AggregateTimeSeries,
  SeriesChart,
  AggregateTerms,
  DonutChart,
  Table,
  Aggregate,
  MultiSeriesChart,
} from 'react-tectonic';

export default class InsightsTracking extends React.Component {
  render() {
    return (
      <>
        <AnalyticsBlock>
          <AggregateTimeSeries
            filter={{
              terms: this.props.terms,
            }}
            operation="count"
            dateField="occurredAt">
            <SeriesChart
              title="Tracking Events over Time"
              titleAlign="center"
              height={200}
              chartType="bar"
              valueField="count"
            />
          </AggregateTimeSeries>
        </AnalyticsBlock>
        <AnalyticsBlock columns={2}>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: this.props.terms,
              }}
              aggField="type"
              termsSize={8}>
              <DonutChart title="Top Tracking Events by Type" titleAlign="center" percent />
            </AggregateTerms>
          </React.Fragment>
          <React.Fragment>
            <AggregateTerms
              filter={{
                terms: this.props.terms,
              }}
              aggField="type"
              operation="count"
              termsSize={10}>
              <Table title="Tracking Events Volume" titleAlign="center" valueField="count" valueFieldName="Volume" />
            </AggregateTerms>
          </React.Fragment>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AggregateTerms
            filter={{
              terms: this.props.terms,
            }}
            aggField="type"
            operation="count"
            termsSize={10}>
            {({ data }) => {
              return (
                <Aggregate
                  type="time-series"
                  filter={{
                    terms: this.props.terms,
                  }}
                  requests={data.map((term) => {
                    return {
                      operation: 'count',
                      dateField: 'occurredAt',
                      filter: {
                        terms: [{ type: { value: term.key } }],
                      },
                    };
                  })}>
                  <MultiSeriesChart
                    title="Tracking Events by Type"
                    titleAlign="center"
                    height={400}
                    chartType="area"
                    stacked
                    valueField="count"
                    labels={data.map((term) => term.key)}
                  />
                </Aggregate>
              );
            }}
          </AggregateTerms>
        </AnalyticsBlock>

        <AnalyticsBlock>
          <AggregateTerms
            filter={{
              terms: this.props.terms,
            }}
            aggField="pipelineMeta.userAgent"
            operation="count"
            termsSize={7}>
            {({ data }) => {
              return (
                <Aggregate
                  filter={{
                    terms: this.props.terms,
                  }}
                  type="time-series"
                  requests={data.map((term) => {
                    return {
                      operation: 'count',
                      dateField: 'occurredAt',
                      filter: {
                        terms: [...this.props.terms, { 'pipelineMeta.userAgent': { value: term.key } }],
                      },
                    };
                  })}>
                  <MultiSeriesChart
                    title="Tracking Events by User Agent"
                    titleAlign="center"
                    height={370}
                    chartType="area"
                    stacked
                    valueField="count"
                    labels={data.map((term) => term.key)}
                  />
                </Aggregate>
              );
            }}
          </AggregateTerms>
        </AnalyticsBlock>
      </>
    );
  }
}
