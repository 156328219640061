import React from 'react';
import { Divider, Header, Image } from 'semantic';
import { Link } from 'react-router-dom';
import logo from 'common/assets/logo-black.svg';

export default ({ title }) => {
  return (
    <React.Fragment>
      <Link to="/">
        <Image src={logo} alt="Logo" style={{ height: '60px', margin: '0 auto' }} />
      </Link>
      <Divider hidden />
      <Header as="h3" textAlign="center" style={{ textTransform: 'uppercase' }}>
        {title}
      </Header>
    </React.Fragment>
  );
};
