import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Header, Loader } from 'semantic';
import { request } from 'utils/api';

import { PageLoader, Breadcrumbs } from 'components';
import Overview from './Overview';
import { screen } from 'helpers';

@screen
export default class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      error: null,
      loading: true,
    };
  }

  onSave = () => {
    this.fetchOrder();
  };

  componentDidMount() {
    this.fetchOrder();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchOrder();
    }
  }

  async fetchOrder() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/orders/${id}`,
      });
      this.setState({
        order: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, order } = this.state;

    if (loading) {
      return (
        <>
          <Loader active>Loading</Loader>
        </>
      );
    } else if (!order) {
      return (
        <>
          <Header content="Sorry that order wasn't found." />
        </>
      );
    }

    return (
      <>
        <Breadcrumbs link={<Link to="/orders">Orders</Link>} active={order.reference} />

        {!order ? (
          <PageLoader />
        ) : (
          <Switch>
            <Route path="/orders/:id" render={(props) => <Overview {...props} order={order} />} />
          </Switch>
        )}
      </>
    );
  }
}
