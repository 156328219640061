import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link, Redirect } from 'react-router-dom';
import { Loader, Message } from 'semantic';
import { Layout } from 'common/components/Layout';
import { withSession } from 'stores';

@withSession
export default class AuthSwitch extends React.Component {
  render() {
    const { loading, error, user } = this.context;
    const { loggedIn: LoggedInComponent, loggedOut: LoggedOutComponent, ...rest } = this.props;
    if (loading) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <Layout style={{ height: '100vh' }} center>
          <Layout.Group>
            <Message error header="Something went wrong" content={error.message} />
            <Link className="link" to="/logout">
              Logout
            </Link>
          </Layout.Group>
        </Layout>
      );
    }
    return (
      <Route
        {...rest}
        render={(props) => {
          return user ? <LoggedInComponent {...props} /> : <LoggedOutComponent {...props} />;
        }}
      />
    );
  }
}

AuthSwitch.propTypes = {
  loggedIn: PropTypes.elementType.isRequired,
  loggedOut: PropTypes.elementType,
  ...Route.propTypes,
};

AuthSwitch.defaultProps = {
  loggedOut: () => <Redirect to="/" />,
};
