import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'common/helpers';
import Timer from 'utils/Timer';

import Hls from 'hls.js';

import staticPoster from 'common/assets/static.gif';

import './stream.less';

const IS_IOS = /iPhone|iPad|iPod/.test(navigator.platform);

const MUX_RECOVERABLE_ERRORS = [
  'Playback ID not ready',
  'Live stream not active',
  'Nobody is currently streaming to this live stream endpoint',
  'No one is currently streaming to this live stream endpoint',
];

// 10s
const POLL_INTERVAL = 2000;

export default class VideoStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      timerCount: null,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.setupTimer();
    this.setupHls();
    this.setupEvents();
    this.componentDidUpdate();
  }

  componentDidUpdate(lastProps = {}) {
    const playbackUrl = this.props.video?.playbackUrl;
    const lastPlaybackUrl = lastProps.video?.playbackUrl;
    if (playbackUrl !== lastPlaybackUrl) {
      this.unloadStream();
      if (playbackUrl) {
        this.loadStream(playbackUrl);
      }
    }
  }

  componentWillUnmount() {
    this.destroyHls();
    this.destroyTimer();
    this.destroyEvents();
  }

  // Only iOS Safari supports HLS .m3u8 URLs directly. So it will be the only one to
  // directly load the playback URL into the video element. All other platforms including
  // OSX Safari will load via Hls.js library.
  //
  // Additionally, the following hack allows the video element to show a thumbnail on
  // initial load, where typically it would just be a black box:
  // https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
  getSrc() {
    const { video } = this.props;
    if (IS_IOS && video) {
      return `${video.playbackUrl}#t=0.001`;
    }
  }

  // Derived state

  canAutoPlay() {
    return this.props.autoPlay || this.isLiveVideo();
  }

  hasControls() {
    if (this.isLiveVideo()) {
      if (this.isOffline()) {
        // No controls when offline, just show poster.
        return false;
      } else {
        // iOS will attempt to play fullscreen so should always
        // have controls otherwise you can only play the live video
        // while it is taking over the screen. Otherwise just show
        // without controls while live.
        return IS_IOS;
      }
    } else {
      return this.props.controls;
    }
  }

  isOffline() {
    return this.isLiveVideo() && !this.isLoaded();
  }

  isLiveVideo() {
    const { videoType } = this.props.video;
    return videoType === 'live';
  }

  isPreparing() {
    const { videoType, status } = this.props.video;
    return videoType === 'vod' && status === 'preparing';
  }

  isLoaded() {
    if (this.hls) {
      return this.hls.media;
    } else {
      return this.ref.current?.duration;
    }
  }

  // Timer

  setupTimer() {
    this.timer = new Timer({
      duration: POLL_INTERVAL,
      onTick: this.onTimerTick,
      onDone: this.onTimerDone,
    });
  }

  destroyTimer() {
    this.timer.stop();
    this.timer = null;
  }

  onTimerTick = (ms) => {
    const timerCount = Math.round((POLL_INTERVAL - ms) / 1000);
    this.setState({
      timerCount,
    });
  };

  onTimerDone = () => {
    if (this.hls) {
      this.hls.loadSource(this.hls.url);
    } else {
      this.ref.current.load();
    }
  };

  // HLS

  setupHls() {
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.on(Hls.Events.ERROR, this.onHlsError);
      this.hls.on(Hls.Events.LEVEL_UPDATED, this.onHlsLevelUpdated);
      this.hls.on(Hls.Events.LEVEL_LOADED, this.onHlsLevelLoaded);
      this.hls.on(Hls.Events.FRAG_CHANGED, this.onHlsFragChanged);
      this.hls.on(Hls.Events.MANIFEST_LOADED, this.onHlsManifestLoaded);
    }
  }

  destroyHls() {
    if (this.hls) {
      this.hls.off(Hls.Events.ERROR, this.onHlsError);
      this.hls.off(Hls.Events.LEVEL_UPDATED, this.onHlsLevelUpdated);
      this.hls.off(Hls.Events.LEVEL_LOADED, this.onHlsLevelLoaded);
      this.hls.off(Hls.Events.FRAG_CHANGED, this.onHlsFragChanged);
      this.hls.off(Hls.Events.MANIFEST_LOADED, this.onHlsManifestLoaded);
      this.hls.destroy();
      this.hls = null;
    }
  }

  loadStream(playbackUrl) {
    if (this.hls) {
      this.setState({
        error: null,
      });
      this.hls.detachMedia();
      this.hls.loadSource(playbackUrl);
    }
  }

  unloadStream() {
    this.hls?.detachMedia();
  }

  onHlsLevelUpdated = (name, { details }) => {
    this.props.onLiveChanged(details.live);
  };

  onHlsLevelLoaded = (name, { details }) => {
    this.props.onLiveChanged(details.live);
  };

  onHlsFragChanged = (name, { frag }) => {
    if (this.props.onFragChanged) {
      this.props.onFragChanged(frag);
    }
  };

  onHlsManifestLoaded = () => {
    this.hls.attachMedia(this.ref.current);
  };

  onHlsError = (name, { fatal, ...rest }) => {
    if (fatal) {
      const message = this.getErrorMessage(rest);
      if (this.canRecoverHlsError(message)) {
        this.timer.reset();
        this.timer.start();
      } else {
        this.setState({
          error: new Error(message),
        });
      }
    }
  };

  canRecoverHlsError(message) {
    return MUX_RECOVERABLE_ERRORS.includes(message);
  }

  getErrorMessage({ details, networkDetails }) {
    let message;
    if (networkDetails) {
      message = JSON.parse(networkDetails.response).error?.messages?.[0];
    } else {
      message = details;
    }
    return message || 'Stream error';
  }

  // Video Events

  setupEvents() {
    this.ref.current.addEventListener('ended', this.onPlaybackEnded);
    this.ref.current.addEventListener('error', this.onPlaybackError);
    this.ref.current.addEventListener('click', this.onElementClick);
  }

  destroyEvents() {
    this.ref.current.removeEventListener('ended', this.onPlaybackEnded);
    this.ref.current.removeEventListener('error', this.onPlaybackError);
    this.ref.current.removeEventListener('click', this.onElementClick);
  }

  onPlaybackEnded = () => {
    if (this.isLiveVideo()) {
      this.unloadStream();
      this.forceUpdate();
    }
  };

  onPlaybackError = (evt) => {
    const code = evt.target.error.code;
    if (code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      this.timer.start();
    }
  };

  onElementClick = () => {
    // Setting the muted attribute increases likelihood that video
    // can autoplay. When user interacts with the player we can unmute,
    // doing so before this will pause the video if they haven't otherwise
    // interacted with the DOM yet.
    if (this.ref.current.muted) {
      this.ref.current.muted = false;
    }
  };

  render() {
    const { error } = this.state;
    const offline = this.isOffline();

    return (
      <div {...this.getProps()}>
        {this.renderPreparing()}
        {error && <div className={this.getElementClass('error')}>{error.message}</div>}
        <video
          ref={this.ref}
          src={this.getSrc()}
          poster={offline ? staticPoster : null}
          className={this.getElementClass('video', offline ? 'offline' : null)}
          disablePictureInPicture
          controlsList="nodownload"
          controls={this.hasControls()}
          autoPlay={this.canAutoPlay()}
          muted={this.canAutoPlay()}
        />
      </div>
    );
  }

  renderPreparing() {
    if (!this.isLoaded() && this.isPreparing()) {
      const { timerCount } = this.state;
      let msg = 'Video is preparing...';
      if (timerCount != null) {
        msg += `Will retry in ${timerCount}.`;
      }
      return <div className={this.getElementClass('preparing')}>{msg}</div>;
    }
  }
}

VideoStream.propTypes = {
  video: PropTypes.object,
  onFragChanged: PropTypes.func,
  onLiveChanged: PropTypes.func,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

VideoStream.defaultProps = {
  controls: true,
  autoPlay: false,
  onFragChanged: () => {},
  onLiveChanged: () => {},
};
