import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'common/helpers';
import UploadsField from 'admin/components/form-fields/Uploads';
import { withSession } from 'stores';
import SearchDropDown from 'common/components/SearchDropdown';

@modal
@withSession
export default class EditProductOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      loading: false,
      error: null,
      hasError: false,
      item: props.item || {},
    };
  }

  isUpdate() {
    return !!this.props.item;
  }

  setField(name, value) {
    this.setState({
      item: {
        ...this.state.item,
        [name]: value,
      },
    });
  }

  fetchCreatorAccounts = async (keyword) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/creator-accounts/search',
      body: {
        keyword,
      },
    });
    return data;
  };

  onSubmit = async () => {
    try {
      const { item } = this.state;
      this.setState({
        loading: true,
        touched: true,
      });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/product-options/${item.id}`,
          body: {
            ...item,
            image: item.image?.id || null,
            creatorAccount: item.creatorAccount?.id || null,
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/product-options',
          body: {
            ...item,
            image: item.image?.id,
          },
        });
        this.setState({
          item: {},
          touched: false,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { item, touched, loading, error, hasError } = this.state;
    return (
      <>
        <Modal.Header>{this.isUpdate() ? `Edit "${item.name}"` : 'New Product Option'}</Modal.Header>
        <Modal.Content>
          <Form error={touched && (error || hasError)}>
            {error && <Message error content={error.message} />}

            {this.context.canManageAccounts() && !this.context.creatorAccount?.id && (
              <Form.Field>
                <label>Creator Account</label>
                <SearchDropDown
                  fluid
                  value={item.creatorAccount}
                  onChange={(e, { value }) => this.setField('creatorAccount', value ? value.id : null)}
                  onDataNeeded={this.fetchCreatorAccounts}
                />
              </Form.Field>
            )}
            <Form.Input
              name="name"
              label="Name"
              required
              type="text"
              value={item.name || ''}
              onChange={(e, { value }) => this.setField('name', value)}
            />
            <Form.Input
              name="value"
              label="Value"
              required
              type="text"
              value={item.value || ''}
              onChange={(e, { value }) => this.setField('value', value)}
            />

            <UploadsField
              single
              label="Image"
              name="upload"
              value={item.image || null}
              onChange={(upload) => this.setField('image', upload)}
              onError={() => this.setState({ touched: true, hasError: true })}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}
