import React from 'react';
import { Grid, Header, Card, Image } from 'semantic';
import { Link } from 'react-router-dom';
import { urlForImage } from 'utils/uploads';

export default class EventOverview extends React.Component {
  render() {
    const { eventStack } = this.props;
    return (
      <div>
        {eventStack.events.length > 0 ? (
          <Grid style={{ marginTop: '2em' }} columns={3}>
            {eventStack.events.map((event) => {
              return (
                <Grid.Column key={event.id}>
                  <Link to={`/events/${event.id}`} key={event.id}>
                    <Card>
                      {event.coverImage && <Image src={urlForImage(event.coverImage)} />}
                      <Card.Content>{event.name}</Card.Content>
                    </Card>
                  </Link>
                </Grid.Column>
              );
            })}
          </Grid>
        ) : (
          <Header as="h3">No events attached.</Header>
        )}
      </div>
    );
  }
}
