import { API_URL } from 'utils/env';
import { ApiError, ApiParseError } from './errors';
import { addHeader, applyMiddleware } from './middleware';

addHeader('Accept', 'application/json');

addHeader('Authorization', (url, options) => {
  const token = options.token || sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  return token ? `Bearer ${token}` : null;
});

export default async function request({ path, files, ...options }) {
  options = {
    method: 'GET',
    params: {},
    headers: {},
    ...options,
  };

  const url = new URL(path, API_URL);
  url.search = new URLSearchParams(options.params);

  await applyMiddleware(url, options);

  if (files) {
    const data = new FormData();
    files.forEach((file) => {
      data.append('file', file, file.name);
    });
    for (let [key, value] of Object.entries(options.body || {})) {
      data.append(key, value);
    }
    options.body = data;
  } else if (!(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
    options.headers['Content-Type'] = 'application/json';
  }

  const res = await fetch(url, options);

  if (res.status === 204) {
    return;
  } else if (!res.ok) {
    let message, status, details;
    try {
      const data = await res.clone().json();
      message = data.error.message;
      status = data.error.status;
      details = data.error.details;
    } catch (err) {
      message = await res.clone().text();
    }
    throw new ApiError(message || res.statusText, status || res.status, details);
  }

  try {
    const contentType = res.headers.get('Content-Type').split(';')[0];
    if (contentType === 'application/json') {
      return await res.json();
    } else {
      return await res.text();
    }
  } catch (err) {
    throw new ApiParseError();
  }
}
