import React from 'react';
import { Divider, Loader, Message, Form, Button } from 'semantic';
import { request } from 'utils/api';
import RichText from 'admin/components/form-fields/RichText';

export default class ProductOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      details: props.product.details,
    };
  }

  onChange = (evt, { value }) => {
    this.setState({
      details: value,
    });
  };

  onSaveClick = async () => {
    const { product } = this.props;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { details } = this.state;
      await request({
        method: 'PATCH',
        path: `/1/products/${product.id}`,
        body: {
          details,
        },
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { loading, error } = this.state;
    return (
      <div>
        {loading && <Loader active={true} />}
        {error && <Message error content={error.message} />}
        <Form>
          <RichText value={this.state.details} onChange={this.onChange} />
          <Divider hidden />
          <Button primary content="Save" onClick={this.onSaveClick} />
        </Form>
      </div>
    );
  }
}
