const middleware = [];

export function addMiddleware(fn) {
  middleware.push(fn);
}

export async function applyMiddleware(url, options) {
  for (let fn of middleware) {
    await fn(url, options);
  }
}

export function addHeader(name, arg) {
  addMiddleware((url, options) => {
    let header = arg;
    if (typeof header === 'function') {
      header = header(url, options);
    }
    if (header) {
      options.headers[name] = header;
    }
  });
}
