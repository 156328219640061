import React from 'react';

import { Dropdown } from 'semantic';

import CloneEvent from 'admin/modals/CloneEvent';
import ShareEvent from 'admin/modals/ShareEvent';
import EditOwner from 'admin/modals/EditOwner';
import Confirm from 'admin/components/Confirm';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';

import { useSession } from 'stores';
import ApplyDiscounts from 'admin/modals/ApplyDiscounts';

export default function Actions({ item, onReload } = {}) {
  const { hasAccess, user } = useSession();

  const globalAccess = hasAccess({
    endpoint: 'events',
    permission: 'write',
    scope: 'global',
  });

  const canEdit = globalAccess || !item.user || item.user === user.id;

  return (
    <>
      <ApplyDiscounts
        event={item}
        onClose={onReload}
        trigger={<Dropdown.Item disabled={item.parent} content="Apply Discounts" icon="percent" />}
      />
      <ShareEvent
        event={item}
        onSave={onReload}
        trigger={<Dropdown.Item disabled={item.parent} content="Share Event" icon="share" />}
      />
      <CloneEvent
        event={item}
        trigger={<Dropdown.Item disabled={!globalAccess && item.parent} content="Clone Event" icon="clone" />}
        onSave={onReload}
      />
      <EditOwner
        onSave={() => onReload()}
        item={item}
        updateUrl={`/1/events/${item.id}/set-user`}
        creatorAccountId={item.creatorAccount?.id}
        trigger={<Dropdown.Item disabled={!canEdit} content="Ownership" icon="key" />}
      />
      <Dropdown.Item as={Link} to={`/events/${item.id}/subscribers`} content="View Subscribers" icon="heart" />
      <Confirm
        negative
        confirmText="Delete"
        header={`Are you sure you want to delete "${item.name}"?`}
        content="All data (including clones) will be marked as deleted"
        trigger={<Dropdown.Item content="Delete Event" icon="trash" />}
        onConfirm={async () => {
          await request({
            method: 'DELETE',
            path: `/1/events/${item.id}`,
          });
          onReload();
        }}
      />
    </>
  );
}
