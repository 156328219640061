import React from 'react';
import { Loader, Dropdown, Message, Header, Icon } from 'semantic';
import { request } from 'utils/api';
import { Component } from 'common/helpers';
import { SortableList } from 'common/components/SortableList';
import { Layout } from 'components';

export default class EventQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: 'rank',
      error: null,
      loading: true,
      questions: [],
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  componentDidUpdate(lastProps, lastState) {
    const { sort } = this.state;
    if (sort !== lastState.sort) {
      this.fetchQuestions();
    }
  }

  async fetchQuestions() {
    const { event } = this.props;
    const { sort } = this.state;
    this.setState({
      loading: true,
      error: null,
    });
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/comments/search',
        body: {
          eventId: event.id,
          commentType: 'question',
          sort: {
            order: 'desc',
            field: sort,
          },
        },
      });
      this.setState({
        loading: false,
        questions: data,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    let { questions } = this.state;
    const question = questions[oldIndex];
    questions = questions.concat();
    questions.splice(oldIndex, 1);
    questions.splice(newIndex, 0, question);
    const rank = questions.length - newIndex;
    // Set the questions first to avoid UI shift
    this.setState({
      questions,
    });
    await request({
      method: 'PATCH',
      path: `/1/comments/${question.id}`,
      body: {
        rank,
      },
    });
  };

  render() {
    const { loading, error, questions, sort } = this.state;
    if (loading) {
      return <Loader active />;
    } else if (error) {
      return <Message error content={error.message} />;
    } else if (questions.length === 0) {
      return <Header content="No questions yet." textAlign="center" />;
    }
    return (
      <div {...this.getProps()}>
        <div style={{ marginBottom: '10px' }}>
          Sort by{' '}
          <Dropdown
            inline
            value={sort}
            options={[
              {
                text: 'rank',
                value: 'rank',
              },
              {
                text: 'likes',
                value: 'likes',
              },
            ]}
            onChange={(evt, { value }) => {
              this.setState({
                sort: value,
              });
            }}
          />
        </div>
        <SortableList style="table" active={sort === 'rank'} onSortEnd={this.onSortEnd}>
          {questions.map((question) => {
            return (
              <React.Fragment key={question.id}>
                <Layout.Group>
                  <div style={{ width: '50px' }}>
                    <Icon name="heart" /> <span style={{ fontWeight: 'bold' }}>{question.likes}</span>
                  </div>
                </Layout.Group>
                <Layout.Spacer size="small" />
                <Layout.Group>
                  <Header style={{ margin: 0 }}>{question.message}</Header>
                </Layout.Group>
              </React.Fragment>
            );
          })}
        </SortableList>
      </div>
    );
  }
}
