import React from 'react';
import { Modal } from 'semantic';

export default function modal(Component) {
  return class ModalWrapper extends React.Component {
    state = {
      open: false,
    };

    onClose = () => {
      this.setState({ open: false });
      if (this.props.onClose) this.props.onClose();
    };

    onOpen = () => {
      this.setState({ open: true });
      if (this.props.onOpen) this.props.onOpen();
    };

    trapNativeEvents = (evt) => {
      // Due to react event delegation semantic can bug out by
      // catching events outside modals event though they are not
      // DOM children, so stop propagation on all events to prevent this.
      // Note: do not trap mousemove or mouseup as it's a common pattern
      // to listen to these on documentElement for drag events.
      evt.stopPropagation();
    };

    render() {
      const {
        onClose,
        onOpen,
        open = this.state.open,
        closeIcon = true,
        closeOnDimmerClick = false,
        trigger,
        size,
        ...rest
      } = this.props;

      return (
        <Modal
          onClick={this.trapNativeEvents}
          onFocus={this.trapNativeEvents}
          onBlur={this.trapNativeEvents}
          onMouseDown={this.trapNativeEvents}
          onKeyDown={this.trapNativeEvents}
          closeIcon={closeIcon}
          closeOnDimmerClick={closeOnDimmerClick}
          onOpen={this.onOpen}
          onClose={this.onClose}
          trigger={trigger}
          size={size}
          open={open}>
          {open && <Component {...rest} close={this.onClose} />}
        </Modal>
      );
    }
  };
}
