import React from 'react';
import { Message, Header, Button, Divider } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import EditBuisnessAccount from 'admin/modals/EditBusinessAccount';
import { withSession } from 'stores';
import Protected from 'common/components/Protected';
/*
import ImportProducts from 'admin/modals/ImportProducts';
import EditShopify from 'admin/modals/EditShopify';
*/

@screen
@withSession
export default class Payments extends React.Component {
  state = {};
  render() {
    const { error } = this.state;

    if (!this.context.user || !this.context.creatorAccount) return <div></div>;

    return (
      <React.Fragment>
        <Menu />
        <Divider hidden />
        <Divider hidden />
        {error && <Message error content={error.message} />}
        <Protected endpoint="businessAccounts" scope="creatorAccount">
          <>
            <Header>Payout account</Header>
            <p>Your payout account is the bank account where we will send your earnings.</p>
            <EditBuisnessAccount
              onClose={() => this.context.loadUser()}
              businessAccountId={this.context.creatorAccount.businessAccount}
              trigger={<Button content={this.context.creatorAccount.businessAccount ? 'Update' : 'Setup'} />}
            />
          </>
        </Protected>
        {/*
        <Protected endpoint="creatorAccounts" scope="creatorAccount">
          <>
            <Header>Manage Shopify</Header>
            <p>Connect your shopify account, to sell your products on because.</p>
            <EditShopify
              creatorAccountId={this.context.creatorAccount.id}
              onSave={() => this.context.updateUser()}
              trigger={<Button disabled={true} content="Manage Shopify (contact admin)" icon="sync" />}
            />
            <ImportProducts
              creatorAccountId={this.context.creatorAccount.id}
              trigger={<Button content="Import Products" icon="download" />}
            />
          </>
        </Protected>
        */}
      </React.Fragment>
    );
  }
}
