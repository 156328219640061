import React, { useEffect, useReducer } from 'react';

import { AggregateTerms, useTectonicContext } from 'react-tectonic';
import { Loader, Table, Button, Label, Segment, Dropdown, Message, Icon } from 'semantic';
import { formatRelativeTime } from 'utils/date';
import InspectObject from 'admin/modals/InspectObject';
import { Link } from 'react-router-dom';
import { formatUsd } from 'utils/formatting';
import { Layout } from 'components';
import FetchObject from 'components/FetchObject';
import SearchDropDown from 'admin/components/SearchDropdown';
import { request } from 'utils/api';
import UAParser from 'ua-parser-js';

function compileQuery(filters, terms) {
  const query = {};
  const { type, eventId, productId, clientSessionId, user, linkShortId } = filters;
  if (type) {
    if (!query.terms) {
      query.terms = [];
    }
    query.terms.push({ type });
  }
  if (eventId) {
    if (!query.terms) {
      query.terms = [];
    }
    query.terms.push({ eventId });
  }
  if (productId) {
    if (!query.terms) {
      query.terms = [];
    }
    query.terms.push({ productId });
  }

  if (terms) {
    if (!query.terms) {
      query.terms = [];
    }
    query.terms.push(...terms);
  }

  if (clientSessionId) {
    if (!query.terms) {
      query.terms = [];
    }
    query.terms.push({ clientSessionId });
  }

  if (user) {
    query.orTerms = [
      {
        'pipelineMeta.userId': user.id,
      },
      user.clientSessions?.length && {
        clientSessionId: user.clientSessions.map((c) => c.id),
      },
    ].filter(Boolean);
  }
  if (linkShortId) {
    if (!query.terms) {
      query.terms = [];
    }
    query.terms.push({ linkShortId });
  }

  return query;
}

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

async function fetchEvents(body) {
  const { data } = await request({
    method: 'POST',
    path: '/1/events/search',
    body,
  });
  return data;
}

async function fetchProducts(body) {
  const { data } = await request({
    method: 'POST',
    path: '/1/products/search',
    body,
  });
  return data;
}

async function fetchUsers(body) {
  const { data } = await request({
    method: 'POST',
    path: '/1/users/search',
    body,
  });

  return data.map((c) => {
    return {
      id: c.id,
      name: `${c.firstName} ${c.lastName} (${c.email || c.id} )`,
      value: c.id,
      clientSessions: c.clientSessions,
    };
  });
}

const limit = 100;

export default function InsightsTracking(props = {}) {
  const tectonic = useTectonicContext();
  const [state, setState] = useLocalState({
    filters: {
      terms: [...props.terms],
    },
    items: [],
    loading: true,
    sortAfter: [],
  });

  async function onDataNeeded(params = {}) {
    setState({
      loading: true,
    });

    const filters = compileQuery(state.filters, props.terms);

    try {
      const { timeRange, timeZone, token, dateField } = tectonic;
      const { data, meta } = await request({
        path: `/1/analytics/search`,
        method: 'POST',
        token: token,
        body: {
          filter: {
            ...filters,
            size: limit + 1, // trick to detect the end of the list
            searchAfter: params.searchAfter,
            range: {
              [dateField]: {
                gte: timeRange.from,
                lt: params.to || timeRange.to,
                time_zone: timeZone,
              },
            },
          },
          collection: 'enriched-events',
        },
      });

      setState({
        isEnd: data.length !== limit + 1,
        loading: false,
        items: data.slice(0, 100) || [],
        metaTotal: meta.total,
      });
    } catch (e) {
      setState({ error: e, loading: false });
    }
  }

  useEffect(() => {
    onDataNeeded({ reset: true });
  }, [tectonic.timeRange, state.filters]);

  useEffect(() => {
    onDataNeeded({ reset: true });
  }, [props.terms]);

  useEffect(() => {
    onDataNeeded({ searchAfter: state.sortAfter[state.sortAfter.length - 1] });
  }, [state.sortAfter]);

  const { filters = {}, items } = state;
  const pStyle = { marginBottom: '2px' };

  return (
    <>
      <Segment>
        <Layout horizontal center spread stackable>
          <Layout horizontal stackable>
            <SearchDropDown
              style={{ marginLeft: '10px', marginBottom: '10px' }}
              placeholder="Event"
              objectMode={false}
              value={filters.eventId}
              onChange={(e, { value }) => {
                setState({
                  filters: {
                    ...state.filters,
                    eventId: value,
                  },
                });
              }}
              onDataNeeded={fetchEvents}
            />

            <SearchDropDown
              style={{ marginLeft: '10px', marginBottom: '10px' }}
              placeholder="Product"
              objectMode={false}
              value={filters.productId}
              onChange={(e, { value }) => {
                setState({
                  filters: {
                    ...state.filters,
                    productId: value,
                  },
                });
              }}
              onDataNeeded={fetchProducts}
            />

            <SearchDropDown
              style={{ marginLeft: '10px', marginBottom: '10px' }}
              placeholder="User"
              objectMode={false}
              value={filters.userId}
              onChange={(e, { value, item }) => {
                setState({
                  filters: {
                    ...state.filters,
                    user: item,
                  },
                });
              }}
              onDataNeeded={fetchUsers}
            />

            <AggregateTerms aggField="type" termsSize={20}>
              {({ data, status }) => {
                if (status.loading || !status.success) return <Loader />;
                return (
                  <Dropdown
                    style={{ marginLeft: '10px', marginBottom: '10px' }}
                    placeholder="Tracking Event"
                    clearable
                    options={data.map(({ key, count }) => {
                      return {
                        key,
                        value: key,
                        text: `${key} (${count})`,
                      };
                    })}
                    selection
                    value={filters.type}
                    onChange={(e, { value }) => {
                      setState({
                        filters: {
                          ...state.filters,
                          type: value,
                        },
                      });
                    }}
                  />
                );
              }}
            </AggregateTerms>
          </Layout>
        </Layout>
        {(filters.clientSessionId || filters.linkShortId) && (
          <Layout horizontal center spread stackable>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1em' }}>
              <h4 style={{ marginRight: '0.4em' }}>Extra Filters:</h4>

              {Object.keys(filters)
                .filter((c) => ['clientSessionId', 'linkShortId'].includes(c))
                .map((key) => {
                  return (
                    <Label key={key} size="small">
                      {key}: {filters[key]}
                    </Label>
                  );
                })}
              <Button
                basic
                secondary
                size="tiny"
                onClick={() => {
                  setState({ filters: {} });
                }}>
                Clear
              </Button>
            </div>
          </Layout>
        )}
      </Segment>

      {state.loading && <Loader active />}
      {state.error && <Message error content={state.error.message} />}
      {!state.error && !state.loading && state.items.length === 0 && (
        <Message content="No data available for the given filter" />
      )}

      {!state.loading && state.items.length !== 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Source</Table.HeaderCell>
              <Table.HeaderCell width={3}>Tracking Event</Table.HeaderCell>
              <Table.HeaderCell width={5}>Context</Table.HeaderCell>
              <Table.HeaderCell width={2}>Occurred At</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((envelope) => {
              const item = envelope._source;

              const os = UAParser(item.pipelineMeta.userAgent)?.os?.name || 'Unknown';

              return (
                <Table.Row key={envelope._id}>
                  <Table.Cell>
                    {item.pipelineMeta.userId && (
                      <div style={pStyle}>
                        <span>
                          User:{' '}
                          <Link to={`/users/${item.pipelineMeta.userId}`}>
                            <FetchObject id={item.pipelineMeta.userId} endpoint="users">
                              {(user) => `${user.firstName} ${user.lastName}`}
                            </FetchObject>
                          </Link>
                        </span>
                      </div>
                    )}

                    <div style={pStyle}>
                      {item.clientSessionId && (
                        <Label
                          title="Filter by clientSID"
                          onClick={() => {
                            setState({
                              filters: {
                                ...state.filters,
                                clientSessionId: item.clientSessionId,
                              },
                            });
                          }}
                          style={{ cursor: 'pointer' }}>
                          ClientSID: {item.clientSessionId}
                        </Label>
                      )}
                      {item.linkShortId && (
                        <Label
                          title="linkShortId"
                          onClick={() => {
                            setState({
                              filters: {
                                ...state.filters,
                                linkShortId: item.linkShortId,
                              },
                            });
                          }}
                          style={{ cursor: 'pointer' }}>
                          Link: {item.linkShortId}
                        </Label>
                      )}
                    </div>
                    <small>
                      {item.pipelineMeta.countryCode} ({item.pipelineMeta.ipAddress}) {item.pipelineMeta.origin} ({os})
                    </small>
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setState({
                          filters: {
                            ...state.filters,
                            type: item.type,
                            filterId: Date.now(),
                          },
                        });
                      }}
                      content={item.type}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {item.event && (
                      <p style={pStyle}>
                        Event: <Link to={`/events/${item.eventId}`}>{item.event.name}</Link>
                      </p>
                    )}
                    {item.event?.creatorAccount && (
                      <p style={pStyle}>
                        Creator:{' '}
                        <Link to={`/creator-accounts/${item.event.creatorAccount.id}`}>
                          {item.event.creatorAccount.name}
                        </Link>
                      </p>
                    )}
                    {item.product && (
                      <p style={pStyle}>
                        Product: <Link to={`/products/${item.productId}`}>{item.product.name}</Link>
                      </p>
                    )}
                    {item.order && (
                      <p style={pStyle}>
                        Product: <Link to={`/orders/${item.orderId}`}>Total: {formatUsd(item.order.amount)}</Link>
                      </p>
                    )}
                  </Table.Cell>
                  <Table.Cell>{formatRelativeTime(new Date(Date.parse(item.occurredAt)))}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <InspectObject object={item} trigger={<Button basic icon="file-code" />} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}

      {state.metaTotal > items.length && (
        <div style={{ textAlign: 'center' }}>
          <Button
            basic
            icon
            disabled={state.loading || state.sortAfter.length === 0}
            onClick={() => {
              setState({ sortAfter: state.sortAfter.slice(0, -1) });
            }}>
            <Icon name="arrow-left" />
          </Button>
          <Button
            basic
            disabled={state.loading || state.sortAfter.length === 0}
            onClick={() => {
              setState({ sortAfter: [] });
            }}>
            Jump to now
          </Button>
          <Button
            basic
            icon
            disabled={state.loading || state.isEnd}
            onClick={() => {
              setState({ sortAfter: [...state.sortAfter, items[items.length - 1].sort] });
            }}>
            <Icon name="arrow-right" />
          </Button>
        </div>
      )}
    </>
  );
}
