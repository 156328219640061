import React from 'react';
import { Message, Button, Modal, Table } from 'semantic';
import { modal, Component } from 'common/helpers';

import { Spacer } from 'common/components/Spacer';

import { request } from 'utils/api';
import { SearchProvider, Status, Pagination } from 'components/search';
import { HelpTip, Layout } from 'components';
import FetchObject from 'components/FetchObject';

import { Link } from 'react-router-dom';

import Asset from 'common/components/Asset';
import { formatDateTime } from 'utils/date';
import { truncate } from 'lodash';

@modal
export default class AssetSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      uploads: [],
    };
  }

  onSubmit = () => {
    this.props.close();
    this.props.onSave(this.state.asset);
  };

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/assets/search',
      body: {
        ...params,
        type: 'video',
      },
    });
  };

  render() {
    return (
      <>
        <Modal.Header>Video Selector</Modal.Header>
        <Modal.Content>
          {this.state.error && (
            <Layout center>
              <Spacer size="s" />
              <Message error content={this.state.error.message} />
            </Layout>
          )}
          <SearchProvider limit={5} onDataNeeded={this.onDataNeeded}>
            {({ items, getSorted, setSort }) => {
              return (
                <>
                  <Status />
                  {items.length !== 0 && (
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={2}>Asset</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                          <Table.HeaderCell>Reference</Table.HeaderCell>

                          <Table.HeaderCell
                            width={2}
                            sorted={getSorted('createdAt')}
                            onClick={() => setSort('createdAt')}>
                            Created
                            <HelpTip title="Created" text="This is the date and time the product was created." />
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {items.map((item) => {
                          return (
                            <Table.Row key={item.id}>
                              <Table.Cell>
                                <Asset asset={item} width={80} />
                              </Table.Cell>
                              <Table.Cell title={item.name}>{truncate(item.name, { length: 40 })}</Table.Cell>
                              <Table.Cell>
                                {item.eventId && (
                                  <FetchObject id={item.eventId} endpoint="events">
                                    {(event) => (
                                      <>
                                        Event: <Link to={`/events/${event.id}`}>{event.name}</Link>
                                      </>
                                    )}
                                  </FetchObject>
                                )}
                                <br />
                                {item.productId && (
                                  <FetchObject id={item.productId} endpoint="products">
                                    {(product) => (
                                      <>
                                        Product: <Link to={`/products/${product.id}`}>{product.name}</Link>
                                      </>
                                    )}
                                  </FetchObject>
                                )}
                              </Table.Cell>

                              <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                              <Table.Cell>
                                <Layout center>
                                  {item.video && (
                                    <Button
                                      disabled={this.state.asset === item}
                                      content="Select"
                                      onClick={() => {
                                        this.props.close();
                                        this.props.onSave(item);
                                      }}
                                    />
                                  )}
                                </Layout>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  )}
                  <Layout center>
                    <Pagination />
                  </Layout>
                </>
              );
            }}
          </SearchProvider>
        </Modal.Content>
      </>
    );
  }
}
